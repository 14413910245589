/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { isNonApiRequest } from '@ui/shared/util';
import { Observable } from 'rxjs';
import { Locale, LOCALE } from '@ui/shared/util-locale';

@Injectable()
export class AcceptLanguageInterceptor implements HttpInterceptor {

  locale: Locale = inject(LOCALE);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (isNonApiRequest(request)) {
      return next.handle(request);
    }

    const requestClone = request.clone({
      setHeaders: {
        'Accept-Language': `${this.locale.language.code}`,
      },
    });
    return next.handle(requestClone);
  }
}

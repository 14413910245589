/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { AuthService, IdToken } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthWrapperService {
  authService = inject(AuthService);

  getAccessToken(): Observable<{ accessToken: string; idToken: string }> {
    return this.getTokenFromAuthService();
  }

  getProfileInfo(): Observable<IdToken> {
    return this.authService.idTokenClaims$;
  }

  private getTokenFromAuthService() {
    return this.authService
      .getAccessTokenSilently({ detailedResponse: true })
      .pipe(map(user => ({ accessToken: user.access_token, idToken: user.id_token })));
  }
}

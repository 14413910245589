/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { EnvironmentPortalSliceEndpoints } from './environment-slice-endpoints.model';
import { AuthConfig } from '../../oauth-ui/oauth.module';
import { FooterConfig } from '../../footer-config';
import { CookieConsentConfig } from '../../cookie-consent/cookie-consent-config';

export class EnvironmentUi {
  public production?: boolean;
  public oauth?: AuthConfig;
  public appBaseHref?: string;
  public baseHref?: string;
  public env?: string;
  public auth?: EnvironmentPortalSliceEndpoints;
  public logger?: unknown;
  public footer?: FooterConfig;
  public cookieConsent?: CookieConsentConfig;
  public porscheApi?: FrontendLink;
  public eventBookingApi?: FrontendLink;
  public frontend?: EnvironmentFrontendLinks;
  public profileLocalization?: FrontendLink;
}

class FrontendLink {
  public url: string;
}

class EnvironmentFrontendLinks {
  public portal: FrontendLink;
  public profile: FrontendLink;
  public shop: FrontendLink;
  public services: FrontendLink;
}

<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<p-modal [open]="true" (dismiss)="close()">
  <p-heading slot="header" size="large" tag="h2">{{ 'selectTranslation' | translate  }}</p-heading>
  <form [formGroup]="form">
    <p-select-wrapper data-testid="event-admin-language-selector" [label]="'selectTranslation' | translate" [hideLabel]="false">
      <select [name]="'language'" [formControlName]="'language'">
        @for (language of languages; track language) {
          <option [value]="language.locale">{{ language.code.toUpperCase() }}</option>
        }
      </select>
    </p-select-wrapper>
    <br/>
    <p-button-group class="footer">
      <p-button data-testid="event-admin-language-modal-accept-btn" (click)="switchTranslation()">
        {{ 'buttonChangeTranslation' | translate }}
      </p-button>
      <p-button data-testid="event-admin-language-modal-cancel-btn"
        [type]="'button'"
        [variant]="'secondary'"
        (click)="close()">
        {{ 'buttonCancel' | translate }}
      </p-button>
    </p-button-group>
  </form>
</p-modal>

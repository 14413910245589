<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<p-modal [open]="isModalOpen()" [dismissButton]="false">
  <p-heading slot="header" size="large" tag="h2">{{ 'adminEventDetail.dialog.title.deleteTimeslot' | translate }}</p-heading>
  <p-text>{{ 'adminEventDetail.dialog.paragraph.confirmText' | translate }}</p-text>
  @if (timeslot()) {
    <p-text [weight]="'semi-bold'">{{ getDateTime(timeslot()?.startDate) }} - {{ getDateTime(timeslot()?.endDate) }}</p-text>
  }
  <p-button-group>
    <p-button id="timeslot-delete-button-delete" (click)="deleteTimeslot()" [icon]="'delete'">
      {{ 'confirm' | translate }}
    </p-button>
    <p-button id="timeslot-delete-button-cancel" [type]="'button'" [variant]="'secondary'" (click)="handleModalClose()">
      {{ 'buttonCancel' | translate }}
    </p-button>
  </p-button-group>
</p-modal>

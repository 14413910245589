/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { FileUploadModel, FileValidateModel } from '../models/file.model';
import { FileService } from '../services/endpoints/file.service';
import { BannerNotificationService } from '@ui/shared/ui';
import { InviteErrorModel } from '../models/attendee-invite.model';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class FileFacade {
  private validateSubject = new BehaviorSubject<FileValidateModel>({
    fileValidateErrors: [],
    fileValidateSuccess: false,
    fileValidateLoading: false,
    fileValidateErrorMessage: null,
  });
  private uploadSubject = new BehaviorSubject<FileUploadModel>({
    fileUploadLoading: false,
    fileUploadSuccess: false,
  });
  private fileName = new BehaviorSubject<string>('');

  /* eslint-disable @typescript-eslint/member-ordering */
  readonly validate$: Observable<FileValidateModel> = this.validateSubject.asObservable();
  readonly upload$: Observable<FileUploadModel> = this.uploadSubject.asObservable();
  readonly fileName$: Observable<string> = this.fileName.asObservable();

  constructor(
    private fileService: FileService,
    private errorNotificationService: BannerNotificationService,
  ) {}

  validateFile(formData: FormData, country: string, eventId: string, fileName: string): void {
    this.fileName.next(fileName);
    this.validateSubject.next({ ...this.validateSubject.value, fileValidateLoading: true });
    this.fileService
      .validateData(country, eventId, formData)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          let errorProperties: InviteErrorModel[];
          if (error instanceof HttpErrorResponse) {
            if (error.error && error.error.errors) {
              errorProperties = error.error.errors;
            } else {
              errorMessage = `Unexpected error occurred. StatusCode: ${error.status} Message: ${error.error.detail}`;
            }
            this.validateSubject.next({
              ...this.validateSubject.value,
              fileValidateErrorMessage: errorMessage,
              fileValidateErrors: errorProperties,
              fileValidateLoading: false,
              fileValidateSuccess: false,
            });
          }
          return EMPTY;
        }),
        map(() => ({ ...this.validateSubject.value, fileValidateLoading: false, fileValidateSuccess: true })),
      )
      .subscribe(updatedProperties => {
        this.validateSubject.next(updatedProperties); // This will execute only if validation is successful
      });
  }

  uploadFile(formData: FormData, country: string, eventId: string): void {
    this.uploadSubject.next({ ...this.uploadSubject.value, fileUploadLoading: true });
    this.fileService
      .uploadData(country, eventId, formData)
      .pipe(
        catchError(error => {
          this.errorNotificationService.publishMessage({
            state: 'info',
            message: error.message,
            headline: `Unexpected error occurred ${error.state}`,
          });
          this.uploadSubject.next({ ...this.uploadSubject.value, fileUploadSuccess: false });
          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.uploadSubject.next({ ...this.uploadSubject.value, fileUploadLoading: false, fileUploadSuccess: true });
      });
  }

  resetValues(): void {
    this.fileName.next('');
    this.validateSubject.next({
      fileValidateErrors: [],
      fileValidateSuccess: false,
      fileValidateLoading: false,
      fileValidateErrorMessage: null,
    });
    this.uploadSubject.next({
      fileUploadLoading: false,
      fileUploadSuccess: false,
    });
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, OnChanges, OnInit, signal, SimpleChanges, viewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EventOverviewTabComponent } from '../event-overview-tab/event-overview-tab.component';
import { TranslatePipe } from '@ngx-translate/core';
import { PaginationUpdateEventDetail, PorscheDesignSystemModule, TabsUpdateEventDetail } from '@porsche-design-system/components-angular';
import { OverviewEventsResponseModel } from '../../models/overview.model';
import { FilterEventsService } from '../../services/filter-events.service';
import { OverviewEventSearchFormModel } from '../../models/form.model';
import { SpacerComponent } from '@ui/shared/ui';
import { RememberEventInOverviewListService } from '@admin/shared';
import { WINDOW } from '@ui/shared/util';

@UntilDestroy()
@Component({
  selector: 'mycontent-event-overview-table',
  templateUrl: './event-overview-table.component.html',
  styleUrls: ['./event-overview-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    PorscheDesignSystemModule,
    EventOverviewTabComponent,
    TranslatePipe,
    SpacerComponent
  ]
})
export class EventOverviewTableComponent implements OnInit, OnChanges {
  country = input<string>();
  onGoingEventsLoading = input<boolean>();
  pastEventsLoading = input<boolean>();
  unFilteredOnGoingEvents = input<OverviewEventsResponseModel>();
  unFilteredPastEvents = input<OverviewEventsResponseModel>();

  ongoingEventsTab = viewChild<EventOverviewTabComponent>('ongoingEventsTab');
  pastEventsTab = viewChild<EventOverviewTabComponent>('pastEventsTab');

  activeTabIndex = signal<number>(0);
  activePageIndex = signal<number>(1);
  filteredOnGoingEvents = signal<OverviewEventsResponseModel>({} as OverviewEventsResponseModel);
  filteredPastEvents = signal<OverviewEventsResponseModel>({} as OverviewEventsResponseModel);

  form: FormGroup<OverviewEventSearchFormModel>;

  private readonly filterEventsService = inject(FilterEventsService);
  private readonly rememberEventInOverviewListService = inject(RememberEventInOverviewListService);
  private readonly window = inject(WINDOW);

  ngOnChanges(changes: SimpleChanges) {
    if (changes.unFilteredOnGoingEvents) {
      this.checkIfEventWasRecentlyAccessedToGoToPositionInList();
      this.filteredOnGoingEvents.set(changes.unFilteredOnGoingEvents.currentValue);
      this.updateFilteredEvents(this.activeTabIndex());
      if (this.form?.controls.searchTextOngoingEvent.value) {
        this.updateFilteredEvents(this.activeTabIndex(), this.form.controls.searchTextOngoingEvent.value);
      }
    }
    if (changes.unFilteredPastEvents) {
      this.checkIfEventWasRecentlyAccessedToGoToPositionInList();
      this.filteredPastEvents.set(changes.unFilteredPastEvents.currentValue);
      this.updateFilteredEvents(this.activeTabIndex());
      if (this.form?.controls.searchTextPastEvent.value) {
        this.updateFilteredEvents(this.activeTabIndex(), this.form.controls.searchTextPastEvent.value);
      }
    }
  }

  ngOnInit() {
    this.form = new FormGroup({
      searchTextOngoingEvent: new FormControl(''),
      searchTextPastEvent: new FormControl('')
    });

    this.form.controls.searchTextOngoingEvent.valueChanges.subscribe(searchText => {
      this.form.controls.searchTextPastEvent.setValue(searchText, { emitEvent: false });
      this.updateFilteredEvents(this.activeTabIndex(), searchText);
    });
    this.form.controls.searchTextPastEvent.valueChanges.subscribe(searchText => {
      this.form.controls.searchTextOngoingEvent.setValue(searchText, { emitEvent: false });
      this.updateFilteredEvents(this.activeTabIndex(), searchText);
    });
  }

  checkIfEventWasRecentlyAccessedToGoToPositionInList() {
    this.activeTabIndex.set(this.rememberEventInOverviewListService.getTabNumber());
    this.activePageIndex.set(this.rememberEventInOverviewListService.getPageNumber());
    if (this.rememberEventInOverviewListService.getEventId() !== undefined) {
      this.rememberEventInOverviewListService.goToRememberedEventInList(
        'mycontent-event-overview-row[data-testid="event-admin-overview-event-row',
        this.rememberEventInOverviewListService.getEventId()
      );
    }
  }

  tabUpdated(event: CustomEvent<TabsUpdateEventDetail>) {
    this.activeTabIndex.set(event.detail.activeTabIndex);
    this.updateFilteredEvents(event.detail.activeTabIndex, this.form.controls.searchTextOngoingEvent.value);
  }

  updateFilteredEvents(tabIndex: number, searchText?: string) {
    if (tabIndex === 0) {
      const searchTextValue = searchText || '';
      this.filteredOnGoingEvents.set(this.filterEventsService.filterEvents(this.unFilteredOnGoingEvents(), searchTextValue));
      this.ongoingEventsTab().switchPageEvents({ detail: { page: 1 } } as CustomEvent<PaginationUpdateEventDetail>);
    } else if (tabIndex === 1) {
      const searchTextValue = searchText || '';
      this.filteredPastEvents.set(this.filterEventsService.filterEvents(this.unFilteredPastEvents(), searchTextValue));
      this.pastEventsTab().switchPageEvents({ detail: { page: 1 } } as CustomEvent<PaginationUpdateEventDetail>);
    }
  }
}

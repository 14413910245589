/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, computed, inject, input, signal } from '@angular/core';
import { DateFormatterService, DateUtilService, WINDOW } from '@ui/shared/util';
import {
  BannerNotificationService,
  CopyToClipboardComponent,
  FlexComponent,
  FlexItemComponent,
  GhostTextDirective,
  PopoverHamburgerMenuComponent,
  SpacerComponent
} from '@ui/shared/ui';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NgOptimizedImage } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { AdminFeatureToggleService, AuthoritiesFacade, EventManagementModel, ExternalLinkService } from '@admin/shared';
import { TicketService } from '../../../services/endpoints/ticket.service';
import { LocaleService } from '../../../services/locale.service';

@Component({
  selector: 'mycontent-event-management-header',
  templateUrl: './event-management-header.component.html',
  styleUrls: ['./event-management-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    RouterLink,
    FlexComponent,
    FlexItemComponent,
    NgOptimizedImage,
    TranslatePipe,
    CopyToClipboardComponent,
    SpacerComponent,
    PopoverHamburgerMenuComponent,
    GhostTextDirective
  ]
})
export class EventManagementHeaderComponent {
  eventDetails = input<EventManagementModel>();
  country = input<string>();
  currentSpaceId = input<string>();
  eventId = input<string>();
  eventDetailsLoading = input<boolean>();

  pdfSrc: string;
  ticketLoading = signal(false);

  locales = computed(() => this.localeService.getLocaleForCountry(this.country()));
  getScannerLink = computed(() => this.externalLinkService.getScannerLink(this.country()));
  getContentfulLink = computed(() => this.externalLinkService.getContentfulLink(this.currentSpaceId(), this.eventId()));
  getAddress = computed(() => this.eventDetails()?.formattedAddress);
  formattedLastUpdatedDateTimeString = computed(() => {
    if (this.eventDetails()?.modifiedAt !== undefined) {
      return this.dateFormatterService.formatDateWithTime(this.eventDetails()?.modifiedAt);
    } else {
      return '';
    }
  });

  private dateFormatterService: DateFormatterService = inject(DateFormatterService);
  private dateUtilService: DateUtilService = inject(DateUtilService);
  private externalLinkService: ExternalLinkService = inject(ExternalLinkService);
  private ticketService: TicketService = inject(TicketService);
  private bannerNotificationService: BannerNotificationService = inject(BannerNotificationService);
  private translateService: TranslateService = inject(TranslateService);
  private window: Window = inject(WINDOW);
  private localeService: LocaleService = inject(LocaleService);
  private authoritiesFacade: AuthoritiesFacade = inject(AuthoritiesFacade);
  private router: Router = inject(Router);
  private AdminFeatureToggleService: AdminFeatureToggleService = inject(AdminFeatureToggleService);

  formatDateWithTime(dateString: string): string {
    return this.dateFormatterService.formatDateWithTime(dateString);
  }

  getTimezone(dateString: string): string {
    return this.dateUtilService.getTimezoneFromDateStringOrNull(dateString);
  }

  getMyPorscheLink(country: string, locale: string, eventId: string) {
    return this.externalLinkService.getMyPorscheLink(country, locale, eventId);
  }

  isAdminOrMarket() {
    return this.authoritiesFacade.isAdmin() || this.authoritiesFacade.isMarket();
  }

  isAdmin() {
    return this.authoritiesFacade.isAdmin();
  }

  isEventCreateAndEditEnabled() {
    return this.AdminFeatureToggleService.isEventCreateAndEditEnabled();
  }

  openTicketInNewTab() {
    this.ticketLoading.set(true);
    this.ticketService.getTicket(this.eventId(), this.country()).subscribe(
      (ticket: Blob) => {
        this.ticketLoading.set(false);
        const file = new Blob([ticket], {type: 'application/pdf'});
        this.pdfSrc = URL.createObjectURL(file);
        this.window.open(this.pdfSrc, '_blank');
      },
      () => {
        this.ticketLoading.set(false);
        this.bannerNotificationService.publishMessage({
          state: 'error',
          headline: this.translateService.instant('adminEventDetail.banner.header.sampleTicketError'),
          message: this.translateService.instant('adminEventDetail.banner.paragraph.sampleTicketError')
        });
      }
    );
  }

  goToEditPage(): void {
    this.router.navigate([this.country(), 'edit', this.eventId()]);
  }

  openContentfulLink(): void {
    this.window.open(this.getContentfulLink(), '_blank');
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { LOCALE, PROFILE_LOCALE } from '../locale-token';
import { Locale } from '../models/locale';
import { Provider } from '@angular/core';

export function profileLocaleFactory(locale: Locale): string {
  const countryCode = locale.country.code;
  return countryCode.toUpperCase() === 'AQ' ? 'DE' : countryCode.toUpperCase();
}

export function provideProfileLocale(): Provider {
  return {
    provide: PROFILE_LOCALE,
    useFactory: profileLocaleFactory,
    deps: [LOCALE],
  };
}

<!--
  ~  This code is protected by intellectual property rights.
  ~  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<p-modal
  [open]="isOpen()"
  [heading]="'adminEventManagementModal.header.selectType' | translate"
  [aria]="{ 'aria-label': ('adminEventManagementModal.header.selectType' | translate) }"
  (dismiss)="isOpen.set(false)"
>
  <p-text>{{ 'adminEventManagementModal.label.forEventName' | translate: {eventTitle: event()?.title} }}</p-text>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  <div class="event-lifecycle-modal__warning-box">
    <mycontent-spacer [size]="'small'"></mycontent-spacer>
    <p-heading [size]="'small'">{{ 'adminEventManagementModal.title.importantInfo' | translate }}</p-heading>
    <p-text-list>
      <p-text-list-item>{{ 'adminEventManagementModal.paragraph.importantInfo.1' | translate }}</p-text-list-item>
      <p-text-list-item>{{ 'adminEventManagementModal.paragraph.importantInfo.2' | translate }}</p-text-list-item>
      <p-text-list-item>{{ 'adminEventManagementModal.paragraph.importantInfo.3' | translate }}</p-text-list-item>
      <p-text-list-item>{{ 'adminEventManagementModal.paragraph.importantInfo.4' | translate }}</p-text-list-item>
    </p-text-list>
  </div>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  <p-segmented-control [columns]="'1'" [value]="managementType()" (update)="onUpdate($event)">
    <p-segmented-control-item [value]="'EB'">{{ 'adminEventManagementModal.label.selection.eventBooking' | translate }}</p-segmented-control-item>
    <p-segmented-control-item [value]="'CAP'">{{ 'adminEventManagementModal.label.selection.cap' | translate }}</p-segmented-control-item>
  </p-segmented-control>
  @if (!!(error$ | async)) {
    <mycontent-spacer [size]="'medium'"></mycontent-spacer>
    <p-text [color]="'notification-error'">{{ 'adminEventManagementModal.label.errorMessage' | translate }}</p-text>
  }
  <mycontent-spacer [size]="'medium'"></mycontent-spacer>
  <p-button-group class="event-lifecycle-modal__buttons mycontent-modal-container__button-group mycontent-modal-container__button-group--pds">
    <p-button
      class="event-lifecycle-modal__button mycontent-modal-container__button mycontent-modal-container__button--pds"
      [attr.data-testid]="'event.lifecycle.confirm'"
      [variant]="'primary'"
      [type]="'submit'"
      [disabled]="!!!managementType()"
      (click)="onSubmit()"
      [loading]="loading$ | async"
      [aria]="{'aria-label': 'adminEventManagementModal.button.complete' | translate }"
    >
      {{ 'adminEventManagementModal.button.complete' | translate }}
    </p-button>

    <p-button
      class="event-lifecycle-modal__button mycontent-modal-container__button mycontent-modal-container__button--pds"
      [attr.data-testid]="'event.lifecycle.cancel'"
      [variant]="'tertiary'"
      (click)="isOpen.set(false)"
    >
      {{ 'adminEventManagementModal.button.back' | translate }}
    </p-button>
  </p-button-group>
</p-modal>

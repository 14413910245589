/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { Country } from '../resources/country-locale-mapping';
import { TranslateService } from '@ngx-translate/core';
import { WINDOW } from '@ui/shared/util';

@Injectable()
export class AdminLanguageService {

  private LANGUAGE_KEY = 'language';
  private DEFAULT_LANGUAGE = 'en_GB';

  private translateService: TranslateService = inject(TranslateService);
  private window = inject(WINDOW);

  getLanguageFromStorage(): string {
    const lang = localStorage.getItem(this.LANGUAGE_KEY);
    if(lang) {
      return lang;
    }
    this.setLanguageInStorage(this.DEFAULT_LANGUAGE);
    return this.DEFAULT_LANGUAGE;
  }

  setLanguageInStorage(selectedLanguage: string): void {
    localStorage.setItem(this.LANGUAGE_KEY, selectedLanguage);
  }

  getPossibleLanguages(): Country[] {
    return Country.languages;
  }

  switchLanguage(selectedLanguage: string): void {
    this.translateService.getTranslation(selectedLanguage);
    this.translateService.setDefaultLang(selectedLanguage);
    this.setLanguageInStorage(selectedLanguage);
    this.window.location.reload();
  }
}

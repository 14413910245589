/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { interval, Observable, startWith, Subject, switchMap, takeUntil } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { inject, Injectable } from '@angular/core';
import { FileService } from './file.service';
import { LockStatusModel } from '../../models/lock-status.model';


@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PollingService {

  private pollingInterval = 1500; // 1.5 seconds
  private stopPolling$ = new Subject<void>();
  private dataSubject$ = new Subject<LockStatusModel>();

  private fileService = inject(FileService);

  /* eslint-disable @typescript-eslint/member-ordering */
  readonly pollingStatus$: Observable<LockStatusModel> = this.dataSubject$.asObservable();

  startPolling(country: string, eventId: string): void {
    this.stopPolling$.next();
    interval(this.pollingInterval)
      .pipe(
        startWith(0),
        switchMap(() => this.fileService.checkLock(country, eventId)),
        takeUntil(this.stopPolling$)
      )
      .subscribe(data => {
        this.dataSubject$.next(data.body);
      });
  }

  public stopPolling(): void {
    this.stopPolling$.next();
  }
}


/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { FetchParticipantModel, ParticipantsDtoModel } from '../../models/participant.model';
import { CheckinModel } from '../../models/checkin.model';
import { ENVIRONMENT_ADMIN } from '@ui/shared/util';
import { RegistrationDataModel, RegistrationInformationModel } from '../../models/manual-registration.model';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {

  private ADMIN_PATH = 'admin';

  private environment = inject(ENVIRONMENT_ADMIN);
  private http = inject(HttpClient);
  private oAuthService = inject(OAuthService);

  getParticipants(fetchParticipantModel: FetchParticipantModel): Observable<ParticipantsDtoModel> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${fetchParticipantModel.country}/events/${fetchParticipantModel.eventId}/invitations-v2`;
    const headers = this.getAuthHeader();
    let params = new HttpParams();
    if (fetchParticipantModel.invitationStates?.length > 0) {
      params = params.set('states', fetchParticipantModel.invitationStates.toString());
    }
    if (fetchParticipantModel.timeslotIds?.length > 0) {
      params = params.set('timeslotIds', fetchParticipantModel.timeslotIds.toString());
    }

    return this.http.get<ParticipantsDtoModel>(url, { headers, params });
  }

  cancelParticipantRegistration(country: string, eventId: string, attendeeId: string): Observable<HttpResponse<unknown>> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/invitations/${attendeeId}/action:cancel`;
    const headers = this.getAuthHeader();

    return this.http.put<unknown>(url, {}, { headers, responseType: 'json', observe: 'response' });
  }

  checkInParticipant(country: string, eventId: string, attendeeId: string, ticketCount: number): Observable<HttpResponse<CheckinModel>> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/invitations/${attendeeId}/action:check-in`;
    let headers = this.getAuthHeader();
    headers = headers.set('Content-Type', 'application/json');

    return this.http.put<CheckinModel>(url, { ticketCount }, { headers, observe: 'response' });
  }

  getManualRegistrationData(country: string, eventId: string, attendeeId: string): Observable<RegistrationInformationModel> {
    const headers = this.getAuthHeader();
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/invitations/${attendeeId}/action:get-registration-data`;

    return this.http.get<RegistrationInformationModel>(url, { headers });
  }

  registerParticipant(country: string, eventId: string, attendeeId: string, registrationData: RegistrationDataModel): Observable<HttpResponse<void>> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/invitations/${attendeeId}/action:register`;
    const headers = this.getAuthHeader();

    return this.http.put<void>(url,
      {
        countOfAttendees: Number(registrationData.countOfAttendees),
        timeslotId: registrationData.timeslotId,
        addressId: registrationData.addressId,
        dynamicData: registrationData.dynamicData,
        phoneId: registrationData.phoneId
      },
      { headers, responseType: 'json', observe: 'response' });
  }

  updateParticipantRegistration(country: string, eventId: string, attendeeId: string, registrationData: RegistrationDataModel): Observable<HttpResponse<unknown>> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/invitations/${attendeeId}/action:update`;
    const headers = this.getAuthHeader();

    return this.http.put<unknown>(url,
      {
        countOfAttendees: Number(registrationData.countOfAttendees),
        timeslotId: registrationData.timeslotId,
        addressId: registrationData.addressId,
        dynamicData: registrationData.dynamicData,
        phoneId: registrationData.phoneId
      },
      { headers, responseType: 'json', observe: 'response' });
  }

  private getAuthHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${this.oAuthService.getIdToken()}`);
  }
}

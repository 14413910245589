/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { Country } from '../resources/country-locale-mapping';


@Injectable()
export class LocaleRegistrationService {
  registerAllLocales(): Promise<void> {
    const locales = Country.registeredLocales;

    const promises = locales.map(locale => this.loadAndRegisterLocale(locale));

    return Promise.all(promises).then(() => {});
  }

  private loadAndRegisterLocale(locale: string): Promise<void> {
    return import(`../../../../../../node_modules/@angular/common/locales/${locale}.mjs`)
      .then(module => {
        registerLocaleData(module.default);
      })
      .catch(error => {
        console.error(`Error loading locale data for ${locale}`, error);
      });
  }
}

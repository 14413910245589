/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ContactDetails } from '../contact-details/contact-details';

export interface ContactDetailsTileData extends ContactDetails {
  description?: string;
  mapImage?: string;
  name?: string;
  dealerId?: string;
}

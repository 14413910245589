/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { AuthenticationService } from '../services/auth/authentication.service';

const authFactory = (authoritiesService: AuthenticationService) => () => authoritiesService.init();


export const authoritiesServiceProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: authFactory,
  multi: true,
  deps: [AuthenticationService],
};

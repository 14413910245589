/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, model, OnInit, output, OutputEmitterRef, signal, WritableSignal } from '@angular/core';
import { PorscheDesignSystemModule, ToastManager, ToastMessage } from '@porsche-design-system/components-angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  ManualRegistrationFormModel,
  RegistrationDataModel,
  RegistrationInformationModel,
  RegistrationUserProfileFormModel,
  RegistrationUserProfileModel
} from '../../../models/manual-registration.model';
import { catchError } from 'rxjs/operators';
import { ManualRegistrationFlyoutProfileComponent } from '../manual-registration-flyout-profile/manual-registration-flyout-profile.component';
import { BannerNotificationService, SpacerComponent } from '@ui/shared/ui';
import { CountryService, EventManagementFacade } from '@admin/shared';
import { ParticipantService } from '../../../services/endpoints/participant.service';
import {
  FormFieldGroupModel,
  RegistrationBookingFormFieldModel,
  RegistrationFormFieldComponent,
  RegistrationTimeslotModel,
  SelectTimeslotComponent
} from '@ui/shared/feature-registration';
import { AsyncPipe } from '@angular/common';
import { ScrollToTargetService } from '@ui/shared/feature-shell';
import { ParticipantModel } from '../../../models/participant.model';
import { ParticipantFacade } from '../../../facades/participant.facade';
import { TimeslotFacade } from '../../../facades/timeslot.facade';

@UntilDestroy()
@Component({
  selector: 'mycontent-manual-registration-flyout',
  templateUrl: './manual-registration-flyout.component.html',
  styleUrls: ['./manual-registration-flyout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    TranslatePipe,
    ReactiveFormsModule,
    ManualRegistrationFlyoutProfileComponent,
    SelectTimeslotComponent,
    SpacerComponent,
    AsyncPipe,
    RegistrationFormFieldComponent
  ]
})
export class ManualRegistrationFlyoutComponent implements OnInit {
  attendeeId = input<string>();
  eventId = input<string>();
  participant = input<ParticipantModel>();
  country = input<string>();

  isOpen = model<boolean>();
  isEditRegistration = model<boolean>();

  closeFlyout: OutputEmitterRef<boolean> = output<boolean>();

  registrationInformation: WritableSignal<RegistrationInformationModel> = signal<RegistrationInformationModel>(undefined);
  submitClicked = signal<boolean>(false);
  preselectedTimeslot = signal<RegistrationTimeslotModel>(undefined);

  form: FormGroup<ManualRegistrationFormModel>;

  private readonly participantService = inject(ParticipantService);
  private readonly bannerNotificationService = inject(BannerNotificationService);
  private readonly translateService = inject(TranslateService);
  private readonly countryService = inject(CountryService);
  private readonly scrollToTarget: ScrollToTargetService = inject(ScrollToTargetService);
  private readonly participantFacade = inject(ParticipantFacade);
  private readonly eventManagementFacade = inject(EventManagementFacade);
  private readonly timeslotFacade = inject(TimeslotFacade);
  private readonly toastManager = inject(ToastManager);

  ngOnInit(): void {
    this.form = new FormGroup<ManualRegistrationFormModel>({
      countOfAttendees: new FormControl<number>(
        this.isEditRegistration() ? this.participant()?.registration?.countOfAttendees : null,
        [Validators.required, Validators.min(1)]
      ),
      timeslot: new FormControl<RegistrationTimeslotModel>(this.isEditRegistration() ? this.participant()?.registration?.timeslot : null),
      dynamicData: new FormArray<FormGroup<FormFieldGroupModel>>([]),
      profile: new FormGroup<RegistrationUserProfileFormModel>({
        address: new FormControl<string>(''),
        birthday: new FormControl<string>(''),
        phone: new FormControl<string>('')
      })
    });
    this.fetchRegistrationInformation();
  }

  fetchRegistrationInformation() {
    this.participantService.getManualRegistrationData(this.countryService.getCountryFromUrl(), this.eventId(), this.attendeeId())
      .pipe(
        catchError(() => {
          this.bannerNotificationService.publishMessage({
              headline: this.translateService.instant('adminEventDetail.registerEvent.error.genericFailTitle'),
              message: this.translateService.instant('adminEventDetail.registerEvent.error.genericFailParagraph'),
              state: 'error'
            }
          );
          this.close();
          return [];
        }))
      .subscribe((registrationInformation: RegistrationInformationModel) => {
        this.registrationInformation.set(registrationInformation);
        if (!this.isEditRegistration()) {
          this.updateDynamicData(registrationInformation.bookingFormFields);
        } else {
          this.updateDynamicDateForEditRegistration(registrationInformation.bookingFormFields).forEach(control =>
            this.form.controls.dynamicData.controls.push(control)
          );
          this.preselectedTimeslot.set(this.participant()?.registration?.timeslot);
        }
        this.updateFormControlValidators('address', registrationInformation.isAddressRequired);
        this.updateFormControlValidators('phone', registrationInformation.isPhoneRequired);
        this.updateFormControlValidators('birthday', registrationInformation.isBirthdayRequired);
      });
  }

  updateFormControlValidators(key: string, required: boolean): void {
    if (!this.form) {
      return;
    }
    if (required) {
      this.form.controls.profile.get(key).setValidators(Validators.required);
      this.form.controls.profile.get(key).updateValueAndValidity();
    } else {
      this.form.controls.profile.get(key).clearValidators();
      this.form.controls.profile.get(key).updateValueAndValidity();
    }
  }

  onSelectTimeslot(registrationTimeslotModel: RegistrationTimeslotModel) {
    this.form.controls.timeslot.setValue(registrationTimeslotModel);
  }

  getProspectName() {
    return `${this.registrationInformation().profile.firstName} ${this.registrationInformation().profile.lastName}`;
  }

  getFormControlState(control: AbstractControl): 'error' | 'none' {
    /* istanbul ignore next  */
    return (this.showFormInvalid() || control?.touched) && control.hasValidator(Validators.required) && !control?.valid ? 'error' : 'none';
  }

  showFormInvalid(): boolean {
    return this.submitClicked() && this.form.invalid;
  }

  submitForm() {
    this.submitClicked.set(true);
    if (this.form.invalid) {
      if (this.form.controls.countOfAttendees.invalid) {
        this.scrollToTarget.scrollToFirstInvalidControl('manual-registration-form');
        return;
      }
      if (this.form.controls.dynamicData.invalid) {
        for (const [index, control] of this.form.controls.dynamicData.controls.entries()) {
          if (control.invalid) {
            this.scrollToTarget.scrollToFirstInvalidControl(`manual-registration-form-dynamic-date-answer-${index}`);
            return;
          }
        }
      } else {
        console.log('profile invalid');
        this.scrollToTarget.scrollToFirstInvalidControl('manual-registration-form');
        return;
      }
    }

    const formValue = this.form.getRawValue();
    const registrationData: RegistrationDataModel = {
      countOfAttendees: formValue.countOfAttendees,
      timeslotId: formValue.timeslot.id,
      addressId: formValue.profile.address,
      phoneId: formValue.profile.phone,
      dynamicData: formValue.dynamicData
    };
    if (!this.isEditRegistration()) {
      this.participantService.registerParticipant(this.countryService.getCountryFromUrl(), this.eventId(), this.attendeeId(), registrationData)
        .subscribe({
          next: () => {
            this.participantFacade.updateParticipantAction({
              email: this.participant()?.attendee?.email,
              inlineLabel: this.translateService.instant('adminEventDetail.inlineNotification.label.registerEvent'),
              inlineHeader: this.translateService.instant('adminEventDetail.inlineNotification.header.registerEvent'),
              inlineParagraph: this.translateService.instant(
                'adminEventDetail.inlineNotification.paragraph.registerEvent',
                { userEmail: this.participant()?.attendee?.email }
              )
            });
            this.timeslotFacade.getTimeslots(this.country(), this.eventId());
            this.participantFacade.getParticipants();
            this.eventManagementFacade.getEventManagement(this.country(), this.eventId());
            this.close();
          },
          error: () => {
            this.bannerNotificationService.publishMessage({
              message: this.translateService.instant('adminEventDetail.registerEvent.error.genericFail'),
              state: 'error'
            });
          }
        });
    } else {
      this.participantService.updateParticipantRegistration(this.countryService.getCountryFromUrl(), this.eventId(), this.attendeeId(), registrationData)
        .subscribe({
          next: () => {
            this.toastManager.addMessage({
              text: this.translateService.instant('participantFlyout.saveChanges.success.toast.paragraph'),
              state: 'success'
            } as ToastMessage);
            this.timeslotFacade.getTimeslots(this.country(), this.eventId());
            this.participantFacade.getParticipants();
            this.eventManagementFacade.getEventManagement(this.country(), this.eventId());
            this.close();
          },
          error: () => {
            this.bannerNotificationService.publishMessage({
              headline: this.translateService.instant('participantFlyout.saveChanges.error.banner.header'),
              message: this.translateService.instant('generic.tryAgain'),
              state: 'error'
            });
          }
        });
    }
  }

  close() {
    this.isOpen.set(false);
    this.isEditRegistration.set(false);
  }

  selectProfile(registrationUserProfile: RegistrationUserProfileModel) {
    this.form.controls.profile.setValue(registrationUserProfile);
  }

  showCapacityLimitReachedWarning(): boolean {
    return this.form.controls.countOfAttendees.value > this.form.controls.timeslot.value.availableTicketCount;
  }

  private updateDynamicData(bookingFormFields: RegistrationBookingFormFieldModel[]) {
    bookingFormFields?.forEach(bookingFormField => {
      this.form.controls.dynamicData.push(
        new FormGroup<FormFieldGroupModel>({
          choices: new FormControl<string[]>(bookingFormField.choices),
          type: new FormControl<'textarea' | 'radio'>(bookingFormField.type),
          question: new FormControl<string>(bookingFormField.question),
          answer: new FormControl('', Validators.required)
        })
      );
    });
  }

  private updateDynamicDateForEditRegistration(bookingFormFields: RegistrationBookingFormFieldModel[]): FormGroup<FormFieldGroupModel>[] {
    const dynamicData = new FormArray<FormGroup<FormFieldGroupModel>>([]);
    this.participant()?.registration?.dynamicData?.formData.map(formData => {
      bookingFormFields?.forEach(bookingFormField => {
        if (bookingFormField.question === formData.question) {
          dynamicData.push(
            new FormGroup<FormFieldGroupModel>({
              choices: new FormControl<string[]>(bookingFormField.choices),
              type: new FormControl<'textarea' | 'radio'>(bookingFormField.type),
              question: new FormControl<string>(bookingFormField.question),
              answer: new FormControl(formData.answer)
            })
          );
        }
      });
    });
    return dynamicData.controls;
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */


import { ClassProvider, ValueProvider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { XTRACE_ID_PREFIX, XTraceInterceptor } from './xtrace.interceptor';

export const xTraceInterceptorProvider: ClassProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: XTraceInterceptor,
  multi: true,
};

export const xTracePrefixProvider: ValueProvider = {
  provide: XTRACE_ID_PREFIX,
  useValue: 'POCOM-MYCONTENT',
};

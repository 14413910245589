<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<mycontent-spacer [size]="'x-large'"></mycontent-spacer>
<form [formGroup]="form">
  <p-tabs [activeTabIndex]="activeTabIndex()" [size]="'medium'" (update)="tabUpdated($event)">
    <p-tabs-item
      class="event-overview__tabs-item"
      [label]="'adminMain.tabNavigation.label.ongoingEvents' | translate"
      [attr.data-testid]="'event-overview-tab-ongoing-events'"
    >
      @if (!onGoingEventsLoading()) {
        <mycontent-spacer [size]="'medium'"></mycontent-spacer>
        <div class="event-overview__search-text">
          <p-text-field-wrapper [label]="'Search'" [hideLabel]="true">
            <input
              [formControlName]="'searchTextOngoingEvent'"
              [type]="'search'"
              [placeholder]="'adminMain.search.label.searchByText' | translate"
              [attr.data-testid]="'event-admin-overview-search-ongoing-input'"
            />
          </p-text-field-wrapper>
        </div>
      }
      <mycontent-spacer [size]="'medium'"></mycontent-spacer>
      <mycontent-event-overview-tab
        #ongoingEventsTab
        [country]="country()"
        [loading]="onGoingEventsLoading()"
        [eventsAll]="filteredOnGoingEvents()"
        [currentTabIndex]="activeTabIndex()"
        [(eventsActivePage)]="activePageIndex"
      ></mycontent-event-overview-tab>
    </p-tabs-item>
    <p-tabs-item
      class="event-overview__tabs-item"
      [label]="'adminMain.tabNavigation.label.pastEvents' | translate"
      [attr.data-testid]="'event-overview-tab-past-events'"
    >
      @if (!pastEventsLoading()) {
        <mycontent-spacer [size]="'medium'"></mycontent-spacer>
        <div class="event-overview__search-text">
          <p-text-field-wrapper [label]="'Search'" [hideLabel]="true">
            <input
              [formControlName]="'searchTextPastEvent'"
              [type]="'search'"
              [placeholder]="'adminMain.search.label.searchByText' | translate"
              [attr.data-testid]="'event-admin-overview-search-past-input'"
            />
          </p-text-field-wrapper>
        </div>
      }
      <mycontent-spacer [size]="'medium'"></mycontent-spacer>
      <mycontent-event-overview-tab
        #pastEventsTab
        [country]="country()"
        [loading]="pastEventsLoading()"
        [eventsAll]="filteredPastEvents()"
        [currentTabIndex]="activeTabIndex()"
        [(eventsActivePage)]="activePageIndex"
      ></mycontent-event-overview-tab>
    </p-tabs-item>
  </p-tabs>
</form>

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { ToolsModel } from '../../models/tools.model';
import { ENVIRONMENT_ADMIN, EnvironmentAdmin } from '@ui/shared/util';

export class ToolSupportService {

  private ADMIN_PATH = 'admin';

  private http: HttpClient = inject(HttpClient);
  private oAuthService: OAuthService = inject(OAuthService);
  private environment: EnvironmentAdmin = inject(ENVIRONMENT_ADMIN);

  getCustomerData(ciamId: string, email: string): Observable<ToolsModel> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/support/customer-data`;
    const headers = this.getAuthHeader();
    let params = new HttpParams();
    if (ciamId) {
      params = params.set('ciamId', ciamId);
    }
    if (email) {
      params = params.set('email', email);
    }
    return this.http.get<ToolsModel>(url, {headers, params});
  }

  private getAuthHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${this.oAuthService.getIdToken()}`);
  }
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { InjectionToken } from '@angular/core';
import { EnvironmentUi } from './models/environment-ui.model';
import { EnvironmentAdmin } from './models/environment-admin.model';

export const ENVIRONMENT_UI = new InjectionToken<EnvironmentUi>('Environment for UI', {
  providedIn: 'root',
  factory: () => ({}),
});

export const ENVIRONMENT_ADMIN = new InjectionToken<EnvironmentAdmin>('Environment for Admin', {
  providedIn: 'root',
  factory: () => ({}),
});

<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->

@if (!showImage()) {
  <div
    class="event-create-image__import-image"
    #fileDropArea
    mycontentDragDropFile
    [backgroundColour]="PDS_THEME_LIGHT_BACKGROUND_BASE"
    (files)="onDragAndDropFile($event)"
    onclick="document.getElementById('event-create-image-upload-button').click()"
  >
    <mycontent-flex [justifyContent]="'center'">
      <p-icon [name]="'image'" [size]="'x-large'"></p-icon>
    </mycontent-flex>
    <mycontent-spacer [size]="'small'"></mycontent-spacer>
    <p-heading [size]="'medium'" [align]="'center'">{{ 'adminCreateEvent.addImageBox.header.dragHereTitle' | translate }}</p-heading>
    <mycontent-spacer [size]="'small'"></mycontent-spacer>
    <mycontent-flex [justifyContent]="'center'">
      <p-button-pure
        class="event-create-image__upload-button"
        [attr.data-testid]="'event-admin-create-image-form-upload-button'"
        [icon]="'upload'"
        [alignLabel]="'start'"
      >
        {{ 'adminCreateEvent.addImageBox.linkPure.upload' | translate }}
        <input
          id="event-create-image-upload-button"
          [attr.data-testid]="dataTestId()"
          [type]="'file'"
          accept="image/png, image/jpeg, image/jpg"
          (change)="fileChangeEvent($event)"
          style="display:none"
        >
      </p-button-pure>
    </mycontent-flex>
  </div>
}
@if (showImage()) {
  <image-cropper
    class="event-create-image__image-cropper"
    [format]="'png'"
    [maintainAspectRatio]="true"
    [aspectRatio]="aspectRatio()"
    [resizeToWidth]="resizeToWidth()"
    [resizeToHeight]="resizeToHeight()"
    [imageFile]="imageChangedEvent()"
    [output]="'base64'"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
}

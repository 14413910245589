<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->
@if(imageActive$ | async) {
  <ng-content select="[mycontentGhostImage]"></ng-content>
}

@if(ghostActive$ | async) {
  <ng-content select="[mycontentGhostImageLoader]"></ng-content>
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, Input, model, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslatePipe } from '@ngx-translate/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { DealerModel, DealerService, ImpersonationService, RoleTypeEnum, SpaceModel } from '@admin/shared';
import { AssumeRoleFormModel } from '../../models/form.model';

@Component({
  selector: 'mycontent-assume-role-modal',
  templateUrl: './assume-role-modal.component.html',
  styleUrls: ['./assume-role-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    FormsModule,
    ReactiveFormsModule,
    TranslatePipe
  ]
})
export class AssumeRoleModalComponent implements OnInit {
  isOpen = model<boolean>();

  role: string;
  form: FormGroup<AssumeRoleFormModel>;

  showDealer = signal<boolean>(false);
  showMarket = signal<boolean>(false);
  dealers = signal<DealerModel[]>([]);
  markets = signal<SpaceModel[]>([]);

  private dealerService = inject(DealerService);
  private impersonationService = inject(ImpersonationService);

  @Input() set spaces(spaces: SpaceModel[]) {
    if (!this.impersonationService.getMarkets()?.length) {
      this.impersonationService.setMarkets(spaces);
    }
    this.markets.set(this.impersonationService.getMarkets());
  }

  ngOnInit(): void {
    this.form = new FormGroup<AssumeRoleFormModel>({
      role: new FormControl(''),
      market: new FormControl('', Validators.required),
      dealer: new FormControl('', Validators.required)
    });

    this.form.valueChanges.subscribe(() => {
      if (this.form.value.role === 'market') {
        this.showMarket.set(true);
        this.showDealer.set(false);
        this.form.controls.dealer.removeValidators(Validators.required);
        this.form.controls.dealer.updateValueAndValidity({ emitEvent: false });
      }
      if (this.form.value.role === 'dealer') {
        this.impersonationService.resetRole();
        this.showMarket.set(true);
      }
      if (this.form.value.market !== '' && this.form.value.role === 'dealer' && this.form.value.dealer === '') {
        this.switchMarket(this.form.value.market);
        this.form.controls.dealer.addValidators(Validators.required);
        this.form.controls.dealer.updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  switchMarket(marketSpaceName: string) {
    this.dealerService.getDealersPerMarket(marketSpaceName, true).subscribe({
      next: (dealers: DealerModel[]) => {
        this.dealers.set(dealers);
        this.showDealer.set(true);
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
      }
    });
  }

  submit() {
    if (!this.form.valid) {
      return;
    }

    this.impersonationService.setRole({
      dealer: this.form.value.dealer,
      market: this.form.value.market,
      type: this.form.value.dealer !== '' ? RoleTypeEnum.DEALER : RoleTypeEnum.MARKET
    });
    this.close();
    document.location.href = '/';
  }

  resetAssumedRole() {
    this.impersonationService.resetRole();
    this.isOpen.set(false);
    document.location.href = '/';
  }

  close() {
    this.form.reset();
    this.isOpen.set(false);
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { ParticipantActionModel } from '../../../models/participant.model';
import { ParticipantFacade } from '../../../facades/participant.facade';
import { SpacerComponent } from '@ui/shared/ui';

@Component({
  selector: 'mycontent-participant-action-participant-banner',
  templateUrl: './participant-action-banner.component.html',
  styleUrls: ['./participant-action-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    TranslatePipe,
    SpacerComponent
  ]
})
export class ParticipantActionBannerComponent implements OnInit {

  participantAction: WritableSignal<ParticipantActionModel> = signal<ParticipantActionModel>(undefined);

  private participantFacade = inject(ParticipantFacade);

  ngOnInit(): void {
    this.participantFacade.participantAction$.subscribe(participantAction => {
      this.participantAction.set(participantAction);
    });
  }

  filterForParticipantAction() {
    const filters = this.participantFacade.getParticipantFilters();
    this.participantFacade.updateParticipantFilterNameOrEmail(`${this.participantAction().email}`);
    this.participantFacade.getParticipants({ ...filters, invitationStates: [], timeslotIds: [] });
    this.participantFacade.updateParticipantAction(undefined);
  }

  onParticipantActionDismiss() {
    this.participantFacade.updateParticipantAction(undefined);
  }
}

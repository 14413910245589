/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, ElementRef, inject, OnInit, signal, viewChild } from '@angular/core';
import { BannerNotificationService } from './banner-notification.service';
import { BannerState, PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { BannerMessage } from './banner-message.model';

@Component({
  selector: 'mycontent-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.scss'],
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
  ]
})
export class NotificationBannerComponent implements OnInit {
  errorBanner = viewChild<ElementRef>('errorBanner');

  isBannerOpen =  signal(false);
  message = signal<string>('');
  headline = signal<string>('');
  state = signal<BannerState>('info');

  bannerMessageQueue: BannerMessage[] = [];

  private bannerNotificationService = inject(BannerNotificationService);

  ngOnInit(): void {
    this.bannerNotificationService.error$.subscribe((bannerMessage: BannerMessage) => {
      this.addToQueue(bannerMessage);
      if (this.isBannerOpen()) {
        return;
      }
      this.showBanner();
    });
  }

  closeBanner(): void {
    this.bannerMessageQueue.shift();
    this.isBannerOpen.set(false);
    this.errorBanner().nativeElement.close();
    setTimeout(() => {
      this.checkQueue();
    }, 1000);
  }

  private showBanner() {
    this.isBannerOpen.set(true);
    this.state.set(this.bannerMessageQueue[0].state);
    this.message.set(this.bannerMessageQueue[0].message);
    this.errorBanner().nativeElement.showModal();

    if (this.bannerMessageQueue[0].additionalInformation) {
      this.message.set(`${this.bannerMessageQueue[0].message}, ${this.bannerMessageQueue[0].additionalInformation}`);
    }

    this.headline.set(this.bannerMessageQueue[0].headline ? this.bannerMessageQueue[0].headline : undefined);

    if (this.bannerMessageQueue[0].timeout) {
      setTimeout(() => {
        this.closeBanner();
      }, this.bannerMessageQueue[0].timeout);
    }
  }

  private checkQueue() {
    if (this.bannerMessageQueue.length) {
      this.showBanner();
    }
  }

  private addToQueue(bannerMessage: BannerMessage) {
    const isDuplicate = this.bannerMessageQueue.some(
      queuedMessage =>
        queuedMessage.message === bannerMessage.message &&
        queuedMessage.state === bannerMessage.state &&
        queuedMessage.additionalInformation === bannerMessage.additionalInformation &&
        queuedMessage.headline === bannerMessage.headline
    );
    if (!isDuplicate) {
      this.bannerMessageQueue.push(bannerMessage);
    }
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BannerMessage } from './banner-message.model';

@Injectable({
  providedIn: 'root',
})
export class BannerNotificationService {
  public error$ = new Subject<BannerMessage>();

  publishMessage(errorMessage: BannerMessage) {
    this.error$.next(errorMessage);
  }
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { ChangeDetectionStrategy, Component, forwardRef, Inject, input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatepickerDynamicFormat } from './datepicker-dynamic-format';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TranslatePipe } from '@ngx-translate/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
// @ts-ignore
import moment, { MomentInput } from 'moment';
import { DatePickerService } from '@ui/shared/util';

@UntilDestroy()
@Component({
  selector: 'mycontent-datepicker-form',
  templateUrl: './datepicker-form.component.html',
  styleUrls: ['./datepicker-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    TranslatePipe,
    PorscheDesignSystemModule
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS, useClass:
      DatepickerDynamicFormat
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true }
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatepickerFormComponent),
      multi: true
    },
    DatePickerService
  ]
})
export class DatepickerFormComponent implements ControlValueAccessor, Validator, OnChanges {
  controlLabel = input<string>();
  format = input<string>();
  calendarStartDate = input<string>();
  country = input<string>();
  dataTestId = input<string>();
  errorState = input<unknown>();
  elementId = input<string>();

  date: FormControl;

  constructor(
    private eventDateFormatterService: DatePickerService,
    private adapter: DateAdapter<MomentDateAdapter>,
    @Inject(MAT_DATE_FORMATS) private config: DatepickerDynamicFormat
  ) {
    this.date = new FormControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['format']?.currentValue) {
      this.config.format = this.format();
    }
    if (changes['country']?.currentValue) {
      const locale = this.eventDateFormatterService.getLocaleFormatForMoment(this.country());
      this.adapter.setLocale(locale);
    }
    if (changes['errorState']?.currentValue) {
      this.date.setErrors(this.errorState());
      this.date.markAsTouched();
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.date.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: (value: string) => void): void {
    this.date.valueChanges.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.date.disable();
    } else {
      this.date.enable();
    }
  }

  writeValue(date: MomentInput): void {
    const formattedTime = moment(date);
    this.date.patchValue(formattedTime, { emitEvent: true });
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.date.valid ? null : control.errors;
  }
}

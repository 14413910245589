/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { ENVIRONMENT_ADMIN } from '@ui/shared/util';
import { OverviewEventsResponseModel, OverviewTrendsModel, TrendsModel } from '../models/overview.model';

@Injectable({
  providedIn: 'root',
})
export class EventsOverviewService {

  private ADMIN_PATH = 'admin';

  private environment = inject(ENVIRONMENT_ADMIN);
  private http = inject(HttpClient);
  private oAuthService = inject(OAuthService);

  getOverviewTrends(fetchOverviewModel: TrendsModel): Observable<OverviewTrendsModel> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${fetchOverviewModel.country}/overview/trends`;

    const headers = this.getAuthHeader();
    let params = new HttpParams();
    if (fetchOverviewModel.since) {
      params = params.set('since', fetchOverviewModel.since);
    }
    if (fetchOverviewModel.dealerId) {
      params = params.set('dealer-id', fetchOverviewModel.dealerId);
    }

    return this.http.get<OverviewTrendsModel>(url, {headers, params});
  }

  getOverviewEvents(country: string, dealerId?: string, endBefore?: string, startAfter?: string, endAfter?: string): Observable<OverviewEventsResponseModel> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/overview/events`;
    const headers = this.getAuthHeader();
    let params = new HttpParams();
    if (dealerId) {
      params = params.set('dealer-id', dealerId);
    }
    if (endBefore) {
      params = params.set('end-before', endBefore); // yyyy-MM-ddTHH:mm:ssZ
    }
    if (startAfter) {
      params = params.set('start-after', startAfter); // yyyy-MM-ddTHH:mm:ssZ
    }
    if (endAfter) {
      params = params.set('end-after', endAfter); // yyyy-MM-ddTHH:mm:ssZ
    }

    return this.http.get<OverviewEventsResponseModel>(url, {headers, params});
  }

  private getAuthHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${this.oAuthService.getIdToken()}`);
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AsyncPipe } from '@angular/common';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { FlexComponent } from '@ui/shared/ui';
import { EventManagementTableComponent } from '../event-management-table/event-management-table.component';
import { EventManagementHeaderComponent } from '../event-management-header/event-management-header.component';
import { AuthoritiesFacade, CountryService, EventManagementFacade, EventManagementModel, SpaceModel, SpaceService } from '@admin/shared';
import { ParticipantFacade } from '../../../facades/participant.facade';
import { TimeslotFacade } from '../../../facades/timeslot.facade';
import { TimeslotModel } from '@ui/shared/feature-registration';

@UntilDestroy()
@Component({
  selector: 'mycontent-event-management',
  templateUrl: './event-management.component.html',
  styleUrls: ['./event-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    EventManagementHeaderComponent,
    EventManagementTableComponent,
    FlexComponent,
    PorscheDesignSystemModule,
    AsyncPipe
  ],
})
export class EventManagementComponent implements OnInit, OnDestroy {
  eventId = signal<string>('');
  country = signal<string>('');
  isAdmin = signal<boolean>(false);
  isMarket = signal<boolean>(false);
  isDealer = signal<boolean>(false);

  eventDetails$: Observable<EventManagementModel>;
  timeslots$: Observable<TimeslotModel[]>;
  selectedInvitationStates$: Observable<string[]>;
  participantsLoading$: Observable<boolean>;
  participantsError$: Observable<unknown>;
  eventDetailsLoading$: Observable<boolean>;
  timeslotsLoading$: Observable<boolean>;
  currentSpaceId$: Observable<string>;

  private readonly countryService = inject(CountryService);
  private readonly route = inject(ActivatedRoute);
  private readonly spaceService = inject(SpaceService);
  private readonly authoritiesService = inject(AuthoritiesFacade);
  private readonly participantFacade = inject(ParticipantFacade);
  private readonly eventFacade = inject(EventManagementFacade);
  private readonly timeslotFacade = inject(TimeslotFacade);

  ngOnInit() {
    this.country.set(this.countryService.getCountryFromUrl());
    this.participantsLoading$ = this.participantFacade.participantsLoading$;
    this.participantsError$ = this.participantFacade.participantsError$;
    this.eventDetailsLoading$ = this.eventFacade.eventManagementLoading$;
    this.eventDetails$ = this.eventFacade.eventManagementSuccess$;
    this.timeslots$ = this.timeslotFacade.timeslotsSuccess$;
    this.timeslotsLoading$ = this.timeslotFacade.timeslotsLoading$;
    this.currentSpaceId$ = this.spaceService.getAvailableSpaces().pipe(
      map((spaces: SpaceModel[]) => this.getCurrentSpaceId(spaces))
    );

    this.eventId.set(this.route.snapshot.paramMap.get('id'));
    this.isAdmin.set(this.authoritiesService.isAdmin());
    this.isMarket.set(this.authoritiesService.isMarket());
    this.isDealer.set(this.authoritiesService.isDealer());

    const filters = this.participantFacade.getParticipantFilters();
    this.refreshEventDetails();
    this.refreshTimeslots();
    this.participantFacade.getParticipants({...filters, country: this.country(), eventId: this.eventId()});
    this.selectedInvitationStates$ = of(filters.invitationStates);
  }

  ngOnDestroy() {
    this.participantFacade.reset();
    this.timeslotFacade.reset();
  }

  refreshEventDetails() {
    this.eventFacade.getEventManagement(this.country(), this.eventId());
  }

  refreshTimeslots() {
    this.timeslotFacade.getTimeslots(this.country(), this.eventId());
  }

  getCurrentSpaceId(spaces: SpaceModel[]): string {
    const space = spaces.find((element: SpaceModel) => element.spaceName.toLowerCase() === this.country()?.toLowerCase());
    if (space) {
      return space.spaceId;
    } else {
      return '';
    }
  }
}

<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
@if (registrationInformation()) {
  <p-flyout [open]="isOpen()" [position]="'end'" (dismiss)="close()">
    <div class="manual-registration__header" slot="header">
      <p-heading [size]="'large'" [align]="'start'">{{ 'adminEventDetail.registerEventFunction.title.createTicket' | translate }}</p-heading>
      <p-text [size]="'medium'" [align]="'start'">{{ getProspectName() }}</p-text>
    </div>
    <form id="manual-registration-form" class="manual-registration" [formGroup]="form">
      <section>
        <p-fieldset [label]="'adminEventDetail.registerEvent.subtitle.selectTicketNr' | translate">
          <p-text-field-wrapper
            [label]="'adminEventDetail.registerEvent.description.selectTicketNr' | translate"
            [state]="getFormControlState(form.controls.countOfAttendees)"
          >
            <input
              [attr.data-testid]="'event-manual-registration-ticket-count'"
              [formControlName]="'countOfAttendees'"
              [placeholder]="'adminEventDetail.registerEvent.textField.selectTicketNr.placeholder' | translate"
              [type]="'number'"
              [name]="'ticket-count'"
            />
          </p-text-field-wrapper>
          @if (form?.controls.countOfAttendees.errors?.['min'] && submitClicked()) {
            <div class="manual-registration__error">
              <p-icon [name]="'information'" [color]="'notification-error'"></p-icon>
              <p-text [color]="'notification-error'">{{ 'adminEventDetail.registerEvent.textField.minParticipantError' | translate }}</p-text>
            </div>
          }
          @if (form?.controls.countOfAttendees.errors?.['required'] && submitClicked()) {
            <div class="manual-registration__error">
              <p-icon [name]="'information'" [color]="'notification-error'"></p-icon>
              <p-text [color]="'notification-error'">{{ 'event.checkout.form.requiredInfo' | translate }}</p-text>
            </div>
          }
        </p-fieldset>
      </section>
      <mycontent-spacer [size]="'medium'"></mycontent-spacer>
      <section>
        <mycontent-spacer [size]="'small'"></mycontent-spacer>
        <p-heading [size]="'medium'" [align]="'start'">
          {{ 'adminEventDetail.registerEvent.subtitle.dateTimeslot' | translate }}
        </p-heading>
        <mycontent-spacer [size]="'small'"></mycontent-spacer>
        <mycontent-select-timeslot
          [inputToTransformTimeslot]="preselectedTimeslot()"
          [timeslots]="registrationInformation().timeslots"
          [isEditRegistration]="isEditRegistration()"
          (selectedTimeslotToEmitToParent)="onSelectTimeslot($event)"
        ></mycontent-select-timeslot>
      </section>
      <mycontent-spacer [size]="'medium'"></mycontent-spacer>
      @for (control of form.controls.dynamicData.controls; track control; let formFieldIndex = $index; let lastQuestion = $last) {
        <section [formArrayName]="'dynamicData'">
          <ng-container [formGroup]="control">
            <mycontent-registration-form-field
              id="manual-registration-form-dynamic-date-answer-{{ formFieldIndex }}"
              formControlName="answer"
              [question]="control.controls.question.value"
              [choices]="control.controls.choices.value"
              [questionType]="control.controls.type.value"
              [index]="formFieldIndex"
              [isSubmitted]="submitClicked()"
            ></mycontent-registration-form-field>
          </ng-container>
        </section>
        @if (!lastQuestion) {
          <mycontent-spacer [size]="'large'"></mycontent-spacer>
        }
      }
      <mycontent-spacer [size]="'small'"></mycontent-spacer>
      <section>
        <mycontent-manual-registration-flyout-profile
          (profileSelection)="selectProfile($event)"
          [isAddressRequired]="registrationInformation()?.isAddressRequired"
          [isBirthdayRequired]="registrationInformation()?.isBirthdayRequired"
          [isPhoneRequired]="registrationInformation()?.isPhoneRequired"
          [profile]="registrationInformation()?.profile"
        ></mycontent-manual-registration-flyout-profile>
      </section>
    </form>
    <div slot="footer">
      @if (showCapacityLimitReachedWarning()) {
        <p-inline-notification
          [state]="'warning'"
          [dismissButton]="false"
          [heading]="'adminEventDetail.registerEvent.warning.title.timeslotFull' | translate"
          [description]="'adminEventDetail.registerEvent.warning.paragraph.timeslotFull' | translate"
        ></p-inline-notification>
        <mycontent-spacer [size]="'small'"></mycontent-spacer>
      }
      <p-button [attr.data-testid]="'manual-registration-submit'" (click)="submitForm()">
        {{ isEditRegistration() ? ('button.saveChanges' | translate) : ('adminEventDetail.registerEvent.button.completeProcess' | translate) }}
      </p-button>
    </div>
  </p-flyout>
}

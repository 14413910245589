<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->

@if (participantAction()) {
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  <p-inline-notification
    [attr.data-testid]="'quick-add-inline-notification'"
    [heading]="participantAction().inlineHeader"
    [description]="participantAction().inlineParagraph"
    [state]="'success'"
    [actionLabel]="participantAction().inlineLabel"
    [actionIcon]="'user'"
    (action)="filterForParticipantAction()"
    (dismiss)="onParticipantActionDismiss()"
  ></p-inline-notification>
  <mycontent-spacer [size]="'x-small'"></mycontent-spacer>
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { LOCALE } from '../locale-token';
import { Locale } from '../models/locale';
import { angularLocaleData } from '../resources/angular-locale-data';

export function localeDataInitializerFactory(locale: Locale): () => Promise<void> {
  return async () => {
    const getLocale = angularLocaleData[locale.code];
    if (!getLocale) {
      throw new Error(`Locale data not found: ${locale.code}`);
    }
    try {
      const dataPromise = getLocale();
      const data = (await dataPromise).default;
      registerLocaleData(data, locale.code);
    } catch (err: unknown) {}
  };
}

export function provideLocaleDataInitializer(): Provider {
  return {
    provide: APP_INITIALIZER,
    useFactory: localeDataInitializerFactory,
    deps: [LOCALE],
    multi: true,
  };
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mycontent-notification-error-inline',
  templateUrl: './notification-error-inline.component.html',
  styleUrls: ['./notification-error-inline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class PuiNotificationErrorInlineComponent {}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, Input, model, output, signal } from '@angular/core';
import { DatePickerService, isDateInThePastValidator } from '@ui/shared/util';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { DatepickerFormComponent, FlexComponent, GridComponent, GridItemComponent, SpacerComponent } from '@ui/shared/ui';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { CountryService } from '@admin/shared';
import { TimeslotModel } from '@ui/shared/feature-registration';

@Component({
  selector: 'mycontent-event-management-timeslot-edit-modal',
  templateUrl: './event-management-timeslot-edit-modal.component.html',
  styleUrls: ['./event-management-timeslot-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    FormsModule,
    ReactiveFormsModule,
    FlexComponent,
    DatepickerFormComponent,
    TranslatePipe,
    GridComponent,
    GridItemComponent,
    SpacerComponent
  ]
})
export class EventManagementTimeslotEditModalComponent {
  eventStartDate = input<string>();
  eventEndDate = input<string>();
  isEditModal = input<boolean>();

  isModalOpen = model<boolean>();

  closeModal = output<TimeslotModel>();

  attendees = signal<number>(0);
  timeslot = signal<TimeslotModel>({});
  calenderEndDateInitializer = signal<string>('');
  format = signal<string>('');
  country = signal<string>('');

  form: FormGroup;

  private formatterService = inject(DatePickerService);
  private countryService = inject(CountryService);

  constructor() {
    this.country.set(this.countryService.getCountryFromUrl());
    this.format.set(this.formatterService.getDateFormat(this.country()).datePickerFormat);
    this.form = new FormGroup<unknown>({
      startDate: new FormControl('', { validators: [Validators.required, isDateInThePastValidator()] }),
      startTime: new FormControl('12:00', { validators: [Validators.required] }),
      endDate: new FormControl('', { validators: [Validators.required, isDateInThePastValidator()] }),
      endTime: new FormControl('12:00', { validators: [Validators.required] }),
      capacity: new FormControl<number>(null,
        { validators: [Validators.required, this.valueMustBeFilledValidator(), this.capacitySmallerRegisteredUsersValidator()] }
      ),
      maxCountOfAttendeesPerTicket: new FormControl<number>(null,
        { validators: [Validators.required, this.valueMustBeFilledValidator(), this.tickersPerAttendeeGreaterThan25()] }
      )
    }, { validators: [this.isEndDateAfterStartDateValidator(), this.tickersPerAttendeeGreaterCapacityValidator()] });
    this.form.get('startDate').valueChanges.subscribe(startDateValue => {
      if (!this.form.get('endDate').value) {
        this.form.get('endDate').setValue(startDateValue);
        this.calenderEndDateInitializer.set(startDateValue);
      }
    });
  }

  @Input() set setTimeslot(timeslot: TimeslotModel) {
    if (timeslot) {
      this.attendees.set(timeslot.registeredTicketCount);
      this.timeslot.set(timeslot);

      this.form.patchValue({
        startDate: this.removeTimezoneFromDateString(timeslot.startDate),
        startTime: this.getTimeFromDateTimeString(timeslot.startDate),
        endDate: this.removeTimezoneFromDateString(timeslot.endDate),
        endTime: this.getTimeFromDateTimeString(timeslot.endDate),
        capacity: timeslot.capacity,
        maxCountOfAttendeesPerTicket: timeslot.maxCountOfAttendees
      });
    }
  }

  isEndDateAfterStartDateValidator(): ValidatorFn {
    // @ts-ignore
    return (formGroup: FormGroup): ValidationErrors | null => {
      const isEndDateAfterStartDate = this.validateDates(formGroup);
      return isEndDateAfterStartDate ? { isEndDateAfterStartDate: true } : null;
    };
  }

  valueMustBeFilledValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isFilled = !!(control.value) && (parseInt(control.value) !== 0);
      return isFilled ? null : { isFilled: { value: control.value } };
    };
  }

  capacitySmallerRegisteredUsersValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isCapacitySmaller = false;
      if (this.attendees() !== 0) {
        isCapacitySmaller = parseInt(control.value) < this.attendees();
      }
      return isCapacitySmaller ? { capacitySmallerRegisteredUsers: { value: control.value } } : null;
    };
  }

  tickersPerAttendeeGreaterCapacityValidator(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const isPerAttendeeCapacityGreater = parseInt(formGroup?.get('capacity').value) < parseInt(formGroup?.get('maxCountOfAttendeesPerTicket').value);
      return isPerAttendeeCapacityGreater ? { isPerAttendeeCapacityGreater: { value: true } } : null;
    };
  }

  tickersPerAttendeeGreaterThan25(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isPerAttendeeGreaterThan25 = !!(control.value) && (parseInt(control.value) > 25);
      return isPerAttendeeGreaterThan25 ? { isPerAttendeeGreaterThan25: { value: true } } : null;
    };
  }

  initCalenderStartDate(): string {
    if (this.timeslot()?.startDate) {
      return this.timeslot()?.startDate;
    } else {
      return this.eventStartDate();
    }
  }

  handleModalClose(event: MouseEvent, elementName: string) {
    if (elementName === 'innerDiv') {
      event.stopPropagation();
      return;
    }
    this.closeModal.emit(null);
    this.isModalOpen.set(false);
  }

  saveTimeslotAndCloseModal() {
    if (this.form.invalid) {
      return;
    }

    const startDate = this.getDateStringFromEpoch(this.form.value.startDate);
    const endDate = this.getDateStringFromEpoch(this.form.value.endDate);
    this.timeslot.set({
      ...this.timeslot(),
      startDate: `${startDate}T${this.form.value.startTime}`,
      endDate: `${endDate}T${this.form.value.endTime}`,
      capacity: this.form.value.capacity,
      maxCountOfAttendees: this.form.value.maxCountOfAttendeesPerTicket
    });
    this.closeModal.emit(this.timeslot());
  }

  getFormControlState(controlName: string) {
    const control = this.form.get(controlName);
    return (this.form.invalid || control.touched) && !control.valid ? 'error' : 'none';
  }

  getFormControlError(controlName: string) {
    const control = this.form.get(controlName);
    return (this.form.invalid || control.touched) && !control.valid ? this.form.get(controlName).errors : null;
  }

  private validateDates(formGroup: FormGroup) {
    const eventStartDate = new Date(this.formatterService.removeTimezoneFromDateString(this.eventStartDate()));
    const eventEndDate = new Date(this.formatterService.removeTimezoneFromDateString(this.eventEndDate()));

    const startDate = this.getDateStringFromEpoch(formGroup.get('startDate').value);
    const endDate = this.getDateStringFromEpoch(formGroup.get('endDate').value);
    const startDateTime = new Date(`${startDate}T${formGroup.get('startTime').value}`);
    const endDateTime = new Date(`${endDate}T${formGroup.get('endTime').value}`);

    const areInvalidDates = isNaN(startDateTime.getDate()) || isNaN(endDateTime.getDate());
    const startDateAfterEndDate = startDateTime.getTime() >= endDateTime.getTime();
    const eventStartDateAfterStartDate = eventStartDate.getTime() > startDateTime.getTime();
    const eventStartDateAfterEndDate = eventStartDate.getTime() >= endDateTime.getTime();
    const eventEndDateBeforeStartDate = eventEndDate.getTime() < startDateTime.getTime();
    const eventEndDateBeforeEndDate = eventEndDate.getTime() < endDateTime.getTime();

    return areInvalidDates || startDateAfterEndDate || (eventStartDateAfterStartDate || eventStartDateAfterEndDate || eventEndDateBeforeStartDate || eventEndDateBeforeEndDate);
  }

  private removeTimezoneFromDateString(dateString: string): string {
    return this.formatterService.removeTimezoneFromDateString(dateString);
  }

  private getTimeFromDateTimeString(dateString: string): string {
    return this.formatterService.getTimeAndRemoveTimezoneFromDateTimeString(dateString);
  }

  private getDateStringFromEpoch(epoch: number): string {
    return this.formatterService.getDateStringFromEpoch(epoch);
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@ui/shared/util';

@Injectable({
  providedIn: 'root'
})
export class RememberEventInOverviewListService {
  private readonly EVENT_ID = 'overview-event-eventId';
  private readonly TAB_NUMBER = 'overview-event-tabIndex';
  private readonly PAGE_NUMBER = 'overview-event-pageIndex';

  private readonly window = inject(WINDOW);

  setEventId(eventId: string) {
    if (eventId !== undefined) {
      this.window.sessionStorage.setItem(this.EVENT_ID, eventId);
    }
  }

  getEventId(): string {
    const eventId = this.window.sessionStorage.getItem(this.EVENT_ID);
    return eventId !== undefined && eventId !== null ? eventId : undefined;
  }

  setTabNumber(tabNumber: number) {
    this.window.sessionStorage.setItem(this.TAB_NUMBER, String(tabNumber));
  }

  getTabNumber(): number {
    const tabNumber = parseInt(this.window.sessionStorage.getItem(this.TAB_NUMBER));
    return isNaN(tabNumber) ? 0 : tabNumber;
  }

  setPageNumber(pageNumber: number) {
    this.window.sessionStorage.setItem(this.PAGE_NUMBER, String(pageNumber));
  }

  getPageNumber(): number {
    const pageNumber = parseInt(this.window.sessionStorage.getItem(this.PAGE_NUMBER));
    return isNaN(pageNumber) ? 1 : pageNumber;
  }

  resetValuesInSession(): void {
    this.window.sessionStorage.removeItem(this.EVENT_ID);
    this.window.sessionStorage.removeItem(this.TAB_NUMBER);
    this.window.sessionStorage.removeItem(this.PAGE_NUMBER);
  }

  goToRememberedEventInList(element: string, id: string) {
    const elementSelector = `${element}-${id}"]`;
    if (elementSelector) {
      setTimeout(() => {
        const selectedElement = this.window.document.querySelector(elementSelector);
        if (selectedElement) {
          selectedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          this.resetValuesInSession();
        }
      }, 650);
    }
  }
}

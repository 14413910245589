<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<mycontent-grid>
  <mycontent-grid-item [size]="{ base: 7, m: 7 }">
    <div class="header__porsche-logo">
      <p-wordmark></p-wordmark>
    </div>
  </mycontent-grid-item>
  <mycontent-grid-item style="float: right" [size]="{ base: 5, m: 5}">
    <mycontent-flex [justifyContent]="'flex-end'">
      <p-button-group class="header__button-group">
        @if (isAdmin) {
          <p-button-pure
            title="tool"
            [type]="'button'"
            [hideLabel]="true"
            [icon]="'wrenches'"
            [size]="{base: 'x-small', s: 'small'}"
            [routerLink]="'/tools'"
          >
            Tools
          </p-button-pure>
        }
        @if (isAdmin || isImpersonated) {
          <p-button-pure
            [attr.data-testid]="'event-admin-assume-role-btn'"
            title="{{ 'header.role.title' | translate }}"
            [type]="'button'"
            [hideLabel]="true"
            [icon]="'accessibility'"
            [size]="{base: 'x-small', s: 'small'}"
            (click)="isAssumeRoleModalOpen.set(true)"
          >
            {{ 'header.role.title' | translate }}
          </p-button-pure>
        }
        <p-button-pure
          title="{{ 'translationButton' | translate}}"
          [attr.data-testid]="'event-admin-language-btn'"
          [type]="'button'"
          [icon]="'configurate'"
          [hideLabel]="true"
          [size]="{base: 'x-small', s: 'small'}"
          (click)="isLanguageModalOpen.set(true)"
        >
          {{ 'translationButton' | translate }}
        </p-button-pure>
        <p-icon title="{{ username }}" [name]="'user'" [size]="'small'"></p-icon>
        <p-button-pure
          [attr.data-testid]="'event-admin-logout-btn'"
          title="logout"
          [icon]="'logout'"
          [hideLabel]="true"
          (click)="logout()"
        >
          Logout
        </p-button-pure>
      </p-button-group>
    </mycontent-flex>
  </mycontent-grid-item>
</mycontent-grid>
@if (isAssumeRoleModalOpen()) {
  <mycontent-assume-role-modal [spaces]="spaces$ | async" [(isOpen)]="isAssumeRoleModalOpen"></mycontent-assume-role-modal>
}
@if (isLanguageModalOpen()) {
  <mycontent-language-selector-modal [(isOpen)]="isLanguageModalOpen"></mycontent-language-selector-modal>
}

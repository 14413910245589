/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollToTargetService {

  scrollToFirstInvalidControl(formId: string, create?: boolean) {
    const form = document.getElementById(formId);
    let firstInvalidControl: Element;
    if (create) {
      firstInvalidControl = form.querySelectorAll('[ng-reflect-state="error"]')[0];
    } else {
      firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
    }
    if (firstInvalidControl === undefined) {
      firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
      if (firstInvalidControl === undefined) {
        form.scrollIntoView({ behavior: 'smooth', block: 'center' });
        (form as HTMLElement).focus();
      } else {
        firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
        (firstInvalidControl as HTMLElement).focus();
      }
    } else {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      (firstInvalidControl as HTMLElement).focus();
    }
  }
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { AuthClientConfig, AuthConfig as Auth0AuthConfig, AuthGuard, AuthHttpInterceptor, AuthModule, AuthService } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AUTH_CONFIG, AUTH_ENABLED, AUTH_GUARD, AUTH_SERVICE } from './inject-tokens';

export type AuthConfig = Auth0AuthConfig & {
  logoutRedirectUri?: string;
  skipTargetUriParam?: boolean;
};

@NgModule({
  imports: [
    AuthModule.forRoot()
  ]
})
export class OAuthModule {
  static forRoot(config?: AuthConfig): ModuleWithProviders<OAuthModule> {
    return {
      ngModule: OAuthModule,
      providers: [
        {
          provide: AUTH_CONFIG,
          useValue: config
        },
        {
          provide: AUTH_ENABLED,
          useFactory: (authConfig: AuthConfig) => !!authConfig,
          deps: [AUTH_CONFIG]
        },
        {
          provide: AUTH_SERVICE,
          useFactory: (authService: AuthService, authConfig: AuthConfig) => authConfig ? authService : null,
          deps: [AuthService, AUTH_CONFIG]
        },
        {
          provide: AUTH_GUARD,
          useFactory: (authGuard: AuthGuard, authConfig: AuthConfig) => authConfig ? authGuard : null,
          deps: [AuthGuard, AUTH_CONFIG]
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (
            authClientConfig: AuthClientConfig,
            authConfig: AuthConfig
          ) => () => {
            authClientConfig.set(authConfig ?? {} as AuthConfig);
          },
          deps: [AuthClientConfig, AUTH_CONFIG],
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthHttpInterceptor,
          multi: true
        }
      ]
    };
  }
}

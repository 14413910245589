/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Observable, of } from 'rxjs';
import type { CookieConsentStatusProvider } from '../../cookie-consent-status-provider';
import { Injectable } from '@angular/core';
import { CookieConsentServiceName } from '../../cookie-consent-typings';

export class ConstantValueCookieConsentStatusProvider implements CookieConsentStatusProvider {
  constructor(private readonly constantValue: boolean) {
  }

  async init(): Promise<void> {
    return Promise.resolve();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isConsentGiven(_: CookieConsentServiceName): Observable<boolean> {
    return of(this.constantValue);
  }

  getCookiePolicyUrl(): string {
    return undefined;
  }

  isConsentRequired(): boolean {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openCookieInformationModal(_serviceName: CookieConsentServiceName) {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateConsent(consentSystemName: CookieConsentServiceName) {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getConsentDisplayName(consentSystemName: CookieConsentServiceName): string {
    return undefined;
  }
}

@Injectable({
  providedIn: 'root'
})
export class NoCookieConsentNeededStatusProvider extends ConstantValueCookieConsentStatusProvider {

  constructor() {
    super(true);
  }
}

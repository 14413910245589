/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  InputSignalWithTransform,
  OnChanges,
  output,
  OutputEmitterRef,
  signal,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PorscheDesignSystemModule, SegmentedControlUpdateEventDetail, SelectUpdateEventDetail } from '@porsche-design-system/components-angular';
import { TranslatePipe } from '@ngx-translate/core';
import { DateFormatterService, DateUtilService } from '@ui/shared/util';
import { uniqBy } from 'lodash-es';
import { ReactiveFormsModule } from '@angular/forms';
import { RegistrationTimeslotModel, TimeslotModel } from '../../models/timeslot.model';
import { PUI_BREAKPOINT, PuiBreakpoint, ShowMoreBoxComponent, SpacerComponent } from '@ui/shared/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'mycontent-select-timeslot',
  templateUrl: './select-timeslot.component.html',
  styleUrls: ['./select-timeslot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    TranslatePipe,
    ReactiveFormsModule,
    SpacerComponent,
    ShowMoreBoxComponent,
    AsyncPipe
  ]
})
export class SelectTimeslotComponent implements OnChanges {
  readonly TIMESLOT_DISPLAY_LIMIT = 6;

  timeslots: InputSignalWithTransform<RegistrationTimeslotModel[], TimeslotModel[]> = input([], {
    transform: (timeslots: TimeslotModel[]) => this.processAndInitTimeslots(timeslots)
  });
  inputToTransformTimeslot: InputSignalWithTransform<void, TimeslotModel> = input(undefined, {
    transform: (timeslot: TimeslotModel) => {
      if (timeslot) {
        this.selectedTimeslot.set({
          ...timeslot,
          startDateOnly: this.dateUtilService.getDateFromStringDateTimeObject(timeslot.startDate)
        } as TimeslotModel);
      }
    }
  });
  isEditRegistration = input<boolean>(false);

  selectedTimeslotToEmitToParent: OutputEmitterRef<RegistrationTimeslotModel> = output<RegistrationTimeslotModel>();

  possibleTimeslotTimesForSpecificDate = signal<RegistrationTimeslotModel[]>(undefined);
  showMoreTimeslots = signal<boolean>(false);
  selectedTimeslot = signal<RegistrationTimeslotModel>(undefined);

  possibleTimeslotDates = computed(() => this.filterPossibleDates(this.timeslots()));
  multiStartDates = computed(() => this.calculateMultiStartDates(this.timeslots()));
  multipleTimeslotsPerDate = computed(() => this.calculateMultipleTimeslotsPerDate(this.timeslots()));
  isOneTimeslotLongerThan24h = computed(() => this.calculateIfATimeslotsIsLongerThan24h(this.timeslots()));

  getSelectedTimeSlotIdForDateInMultiDayMultiTime = computed(() => {
    const startDate = this.selectedTimeslot().startDateOnly;
    const timeslot = this.possibleTimeslotDates().find((dateTimeslot: RegistrationTimeslotModel) => dateTimeslot.startDateOnly === startDate);
    return timeslot ? timeslot.id : '';
  });

  breakpoint$: Observable<PuiBreakpoint> = inject(PUI_BREAKPOINT);
  isMobileView$ = this.breakpoint$.pipe(
    map(breakpoint => {
      /* istanbul ignore next */
      switch (breakpoint.name) {
        case 'base':
        case 'xs':
        case 's':
        case 'm':
          return 4;
        default:
          return 6;
      }
    })
  );

  private readonly dateUtilService = inject(DateUtilService);
  private readonly dateFormatterService = inject(DateFormatterService);

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isEditRegistration?.currentValue) {
      this.timeslotPreselection(this.selectedTimeslot(), this.timeslots());
    }
  }

  processAndInitTimeslots(timeslotModels: TimeslotModel[]): RegistrationTimeslotModel[] {
    const copy = [...timeslotModels];
    const processedTimeslots = copy
      .sort((timeslotA, timeslotB) => new Date(timeslotA.startDate).getTime() - new Date(timeslotB.startDate).getTime())
      .map(timeslotModel => ({
        ...timeslotModel,
        startDateOnly: this.dateUtilService.getDateFromStringDateTimeObject(timeslotModel.startDate)
      })) as RegistrationTimeslotModel[];
    this.timeslotPreselection(processedTimeslots[0], processedTimeslots);
    return processedTimeslots;
  }

  timeslotPreselection(timeslot: RegistrationTimeslotModel, timeslots: RegistrationTimeslotModel[]) {
    if (this.calculateMultiStartDates(timeslots) && this.calculateMultipleTimeslotsPerDate(timeslots) && !this.calculateIfATimeslotsIsLongerThan24h(timeslots)) {
      this.selectTimeslotAndCalculatePossibleTimesForMultiDayMultiTimeEvent(timeslot, timeslots);
    } else {
      if (!this.selectedTimeslot()) {
        this.selectTimeslot(timeslot);
      }
    }
  }

  filterPossibleDates(timeslots: RegistrationTimeslotModel[]): RegistrationTimeslotModel[] {
    let dates: RegistrationTimeslotModel[] = [];
    timeslots.forEach(timeslot => {
      dates.push(timeslot);
    });
    dates = uniqBy(dates, 'startDateOnly');
    return dates;
  }

  onUpdateTimeslotSelection($event: CustomEvent<SelectUpdateEventDetail> | CustomEvent<SegmentedControlUpdateEventDetail>) {
    const timeslot = this.timeslots().find(ts => ts.id === $event.detail.value);
    this.selectTimeslot(timeslot);
  }

  selectTimeslot(timeslot: RegistrationTimeslotModel) {
    this.selectedTimeslot.set(timeslot);
    this.selectedTimeslotToEmitToParent.emit(timeslot);
  }

  formatFullEventRange(registrationTimeslotModel: RegistrationTimeslotModel) {
    return this.dateFormatterService.formatFullEventRange(registrationTimeslotModel.startDate, registrationTimeslotModel.endDate);
  }

  formatEventStartDateNoTime(registrationTimeslotModel: RegistrationTimeslotModel) {
    return this.dateFormatterService.formatDateNoTime(registrationTimeslotModel.startDate);
  }

  displayTimeRange(registrationTimeslotModel: RegistrationTimeslotModel) {
    return this.dateFormatterService.formatTimeRange(registrationTimeslotModel.startDate, registrationTimeslotModel.endDate);
  }

  selectTimeslotAndCalculatePossibleTimesForMultiDayMultiTimeEvent(timeslotModel: RegistrationTimeslotModel, timeslots: RegistrationTimeslotModel[]) {
    this.possibleTimeslotTimesForSpecificDate.set(timeslots.filter(timeslot => timeslot.startDateOnly === timeslotModel.startDateOnly));
    this.showMoreTimeslots.set(false);
    if (!this.selectedTimeslot()) {
      this.selectTimeslot(this.possibleTimeslotTimesForSpecificDate()[0]);
    }
  }

  selectTimeslotById(id: string) {
    const timeslot = this.timeslots().find(ts => ts.id === id);
    this.selectTimeslot(timeslot);
  }

  calculateMultiStartDates(timeslots: RegistrationTimeslotModel[]) {
    return uniqBy(timeslots, 'startDateOnly').length > 1;
  }

  calculateMultipleTimeslotsPerDate(timeslots: RegistrationTimeslotModel[]) {
    return timeslots.length !== uniqBy(timeslots, 'startDateOnly').length;
  }

  calculateIfATimeslotsIsLongerThan24h(timeslots: RegistrationTimeslotModel[]) {
    return timeslots.some(timeslot => !this.dateFormatterService.isEndDate24HoursAfterStartDate(new Date(timeslot.startDate), new Date(timeslot.endDate)));
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core';
import { COMMA_DELIMITER, DELIMITERS } from '../models/delimiter.model';

@Injectable({
  providedIn: 'root',
})
export class CsvPreProcessingService {

  private reduceSequenceOfSameCharsToOne = new RegExp(`(.)(?=\\1{${  1  }})`, 'g');

  public normalize(text: string): string {
    const delimiter = this.guessDelimiter(text);
    if (delimiter === COMMA_DELIMITER) {
      return text;
    }

    const unquotedDelimitersRegex = new RegExp(`${delimiter}(?=(?:(?:[^"]*"){2})*[^"]*$)`);
    return text
      .split('\n')
      .map(t => t
        .split(unquotedDelimitersRegex)
        .filter(it => it !== undefined)
        .map(it => (it.includes(COMMA_DELIMITER) && !it.startsWith('"')) ? `"${it}"` : it)
        .join(COMMA_DELIMITER))
      .join('\n');
  }

  public getHeaders(text: string): string[] {
    return text.split('\n')?.[0].split(COMMA_DELIMITER);
  }

  private guessDelimiter(text: string): string {
    const head = this.reduceString(text.split('\n')?.[0]);
    let delimiter = DELIMITERS[0];
    let max = 0;
    DELIMITERS.forEach(del => {
      const count = head.split(del).length - 1;
      if (count > max) {
        delimiter = del;
        max = count;
      }
    });
    return delimiter;
  }

  private reduceString(str: string) {
    return str.replace(this.reduceSequenceOfSameCharsToOne, '');
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { EventOverviewTableComponent } from '../event-overview-table/event-overview-table.component';
import { EventOverviewHeaderComponent } from '../event-overview-header/event-overview-header.component';
import { OverviewTrendsModel, TrendsModel } from '../../models/overview.model';
import { AuthoritiesFacade, SpaceModel, SpaceService } from '@admin/shared';
import { EventsOverviewFacade } from '../../facades/events-overview.facade';
import { toSignal } from '@angular/core/rxjs-interop';
import { SpacerComponent } from '@ui/shared/ui';

@Component({
  selector: 'mycontent-event-overview',
  templateUrl: './event-overview.component.html',
  styleUrls: ['./event-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    EventOverviewHeaderComponent,
    EventOverviewTableComponent,
    AsyncPipe,
    SpacerComponent
  ]
})
export class EventOverviewComponent implements OnInit {

  country = signal<string>('');
  isAdmin = signal<boolean>(false);
  isMarket = signal<boolean>(false);
  isDealer = signal<boolean>(false);

  fetchOverviewTrendsModel = signal<TrendsModel>({} as TrendsModel);

  overviewTrends$: Observable<OverviewTrendsModel>;
  currentSpaceId$: Observable<string>;
  spaces$: Observable<SpaceModel[]>;

  private route = inject(ActivatedRoute);
  private authoritiesService = inject(AuthoritiesFacade);
  private spaceService = inject(SpaceService);
  private eventsOverviewFacade = inject(EventsOverviewFacade);

  /* eslint-disable @typescript-eslint/member-ordering */
  onGoingEventsLoading = toSignal(this.eventsOverviewFacade.unfilteredOnGoingEventsLoading$);
  pastEventsLoading = toSignal(this.eventsOverviewFacade.unfilteredPastEventsLoading$);
  unfilteredOnGoingEvents = toSignal(this.eventsOverviewFacade.unfilteredOnGoingEventsSuccess$);
  unfilteredPastEvents = toSignal(this.eventsOverviewFacade.unfilteredPastEventsSuccess$);

  ngOnInit() {
    this.currentSpaceId$ = this.spaceService.getAvailableSpaces().pipe(map(spaces => this.getCurrentSpaceId(spaces)));
    this.spaces$ = this.spaceService.getAvailableSpaces();

    this.country.set(this.route.snapshot.paramMap.get('country'));
    this.isAdmin.set(this.authoritiesService.isAdmin());
    this.isMarket.set(this.authoritiesService.isMarket());
    this.isDealer.set(this.authoritiesService.isDealer());
    const dealerId = this.authoritiesService.isDealer() ? this.authoritiesService.getOrgId() : '';

    this.eventsOverviewFacade.getOnGoingEvents(this.country()).subscribe();
    this.eventsOverviewFacade.getPastEvents(this.country()).subscribe();

    this.fetchOverviewTrendsModel.set({
      dealerId,
      country: this.country(),
      since: ''
    });

    this.overviewTrends$ = this.eventsOverviewFacade.getOverviewTrends(this.fetchOverviewTrendsModel());
  }

  fetchTrends(filter: string) {
    this.fetchOverviewTrendsModel.set(this.eventsOverviewFacade.updateOverviewTrendsParams(this.fetchOverviewTrendsModel(), filter));
    this.overviewTrends$ = this.eventsOverviewFacade.getOverviewTrends(this.fetchOverviewTrendsModel());
  }

  getCurrentSpaceId(spaces: SpaceModel[]): string {
    const space = spaces.find(element => element.spaceName.toLowerCase() === this.country().toLowerCase());
    if (space) {
      return space.spaceId;
    } else {
      return '';
    }
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { Version } from '../../models/support.models';

@Injectable()
export class SupportService {

  constructor(private http: HttpClient,
              private oauthService: OAuthService) {
  }
  public loadVersion(): Observable<Version> {
    const url = `${environment.backend.url}/support/version`;

    const headers = this.getAuthHeader();

    return this.http.get<Version>(url, {headers});
  }

  private getAuthHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${  this.oauthService.getIdToken()}`);
  }
}

<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<mycontent-spacer [size]="'x-large'"></mycontent-spacer>
<mycontent-grid [direction]="{ base: 'column', s: 'row' }">
  <mycontent-grid-item [size]="12">
    <p-button-pure [icon]="'arrow-right'" [routerLink]="'/'">Back</p-button-pure>
  </mycontent-grid-item>
  <mycontent-spacer [size]="'x-large'"></mycontent-spacer>
  <mycontent-grid-item [size]="12">
    <p-headline variant="headline-2">MyContent - Support-Tool</p-headline>
    <br/>
  </mycontent-grid-item>
  <mycontent-grid-item [size]="{base: 12, s: 3}">
    <p-text-field-wrapper [label]="'CiamId'">
      <input [type]="'text'" [name]="'ciamId'" [(ngModel)]="ciamId"/>
    </p-text-field-wrapper>
  </mycontent-grid-item>
  <mycontent-grid-item [size]="{base: 12, s: 3}">
    <p-text-field-wrapper [label]="'Email address'">
      <input [type]="'email'" [name]="'email'" [(ngModel)]="email"/>
    </p-text-field-wrapper>
  </mycontent-grid-item>
  <mycontent-grid-item [size]="{base: 12, s: 3}">
    <br/>
    <p-button [variant]="'primary'" (click)="getData()">Get Data</p-button>
  </mycontent-grid-item>
  <mycontent-grid-item [size]="{base: 12, s: 9}">
    @if (loader()) {
      <p-spinner [size]="'small'" aria-label="Loading"></p-spinner>
    }
  </mycontent-grid-item>

  @if (error()) {
    <mycontent-grid-item [size]="12">
      <p-text [color]="'notification-error'">Error while loading data.<br/>
        {{ error().status }}
      </p-text>
      <p-text [color]="'notification-error'">
        {{ error().error.code }}
      </p-text>
      <p-text [color]="'notification-error'">
        {{ error().error.message }}
      </p-text>
      <p-text [color]="'notification-error'">
        {{ error().error.detail }}
      </p-text>
    </mycontent-grid-item>
    <mycontent-spacer [size]="'xx-large'"></mycontent-spacer>
  }

  @if (data().ciamId) {
    <mycontent-spacer [size]="'medium'"></mycontent-spacer>
    <mycontent-grid-item [size]="12">
      <div class="tool__data-response">
        <p-text class="tool__data-response-field">
          <strong>CIAM ID:</strong> {{ data().ciamId }}
        </p-text>
        <p-text class="tool__data-response-field">
          <strong>Porsche ID:</strong> {{ data().porscheId }}
        </p-text>
        <p-text class="tool__data-response-field">
          <strong>Date of Birth:</strong> {{ formatDateOfBirth() }}
        </p-text>
        <p-text class="tool__data-response-field">
          <strong>First Name:</strong> {{ data().firstName }}
        </p-text>
        <p-text class="tool__data-response-field">
          <strong>Last Name:</strong> {{ data().lastName }}
        </p-text>
        <p-text class="tool__data-response-field">
          <strong>Salutation:</strong> {{ data().salutation }}
        </p-text>
        <p-text class="tool__data-response-field">
          <strong>Salutation Value:</strong> {{ data().salutationValue }}
        </p-text>
        <p-text class="tool__data-response-field">
          <strong>Preferred Language:</strong> {{ data().preferredLanguage }}
        </p-text>
        @if (data().titleValue) {
          <p-text class="tool__data-response-field">
            <strong>Title Value:</strong> {{ data().titleValue }}
          </p-text>
        }
      </div>
    </mycontent-grid-item>
    <mycontent-spacer [size]="'x-large'"></mycontent-spacer>
  }
</mycontent-grid>

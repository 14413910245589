/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { FormArray, FormControl, FormGroup } from '@angular/forms';

export interface FormFieldsFormModel {
  dynamicData:  FormArray<FormGroup<FormFieldGroupModel>>;
}

export interface FormFieldGroupModel {
  choices?: FormControl<string[]>;
  type?: FormControl<'textarea' | 'radio'>;
  question: FormControl<string>;
  answer: FormControl<string>;
}

export interface RegistrationBookingFormFieldModel {
  type: 'textarea' | 'radio';
  question: string;
  choices: string[];
}

export interface FormFieldRequest {
  question: string;
  answer: string;
}

export const I_PREFER_NOT_TO_ANSWER = 'I_PREFER_NOT_TO_ANSWER';

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, model, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { PorscheDesignSystemModule, ToastManager, ToastMessage } from '@porsche-design-system/components-angular';
import { LockEnum, LockStatusModel } from '../../../models/lock-status.model';
import { PollingService } from '../../../services/endpoints/polling.service';
import { ParticipantFacade } from '../../../facades/participant.facade';
import { InvitationFacade } from '../../../facades/invitation.facade';
import { INVITED } from '../../../models/invitation-state.model';
import { GetPollingStatusPercentageComponent } from '../../get-polling-status-percentage/get-polling-status-percentage.component';
import { BannerNotificationService, SpacerComponent } from '@ui/shared/ui';

@Component({
  selector: 'mycontent-participant-send-invite-one-modal',
  templateUrl: './participant-send-invite-one-modal.component.html',
  styleUrls: ['./participant-send-invite-one-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    GetPollingStatusPercentageComponent,
    AsyncPipe,
    TranslatePipe,
    SpacerComponent
  ]
})
export class ParticipantSendInviteOneModalComponent implements OnInit {
  country = input<string>();
  eventId = input<string>();
  attendeeId = input<string>();
  email = input<string>();
  isOpen = model<boolean>();

  pollingStatus$: Observable<LockStatusModel>;

  private unsubscribePollingStatus$: Subject<void> = new Subject<void>();
  private pollingService = inject(PollingService);
  private participantFacade = inject(ParticipantFacade);
  private invitationFacade = inject(InvitationFacade);
  private bannerNotificationService = inject(BannerNotificationService);
  private translateService = inject(TranslateService);
  private toastManager = inject(ToastManager);

  ngOnInit() {
    this.pollingService.startPolling(this.country(), this.eventId());
    this.pollingStatus$ = this.pollingService.pollingStatus$;
  }

  closeSendInvitation(): void {
    this.pollingService.stopPolling();
    this.isOpen.set(false);
  }

  onDismiss(): void {
    this.pollingService.stopPolling();
    this.isOpen.set(false);
  }

  sendInvite(): void {
    this.invitationFacade.triggerSingleInvitation({
      country: this.country(),
      eventId: this.eventId(),
      attendeeId: this.attendeeId(),
      email: this.email()
    }).pipe(
      takeUntil(this.unsubscribePollingStatus$),
      tap(() => {
        this.pollingStatus$.pipe(
          takeUntil(this.unsubscribePollingStatus$)
        ).subscribe(lockStatus => {
          if (lockStatus.status === LockEnum.FINISHED_OK) {
            this.closeSendInvitation();
            this.participantFacade.updateParticipantFilterNameOrEmail(undefined);
            this.participantFacade.updateParticipantAction(undefined);
            const filters = this.participantFacade.getParticipantFilters();
            this.participantFacade.getParticipants({ ...filters, invitationStates: [INVITED], timeslotIds: [] });
            this.unsubscribePollingStatus$.next();
            const message = this.translateService.instant('participantFlyout.sendInvitation.success.toast.paragraph', { email: this.email() });
            this.showToastMessage(message, 'success');
          }
          if (lockStatus.status === LockEnum.FINISHED_ERROR) {
            this.closeSendInvitation();
            this.unsubscribePollingStatus$.next();
            this.bannerNotificationService.publishMessage({
              state: 'error',
              headline: this.translateService.instant('participantFlyout.sendInvitation.error.banner.header', { email: this.email() }),
              message: this.translateService.instant('generic.tryAgain')
            });
          }
        });
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return error;
      })).subscribe();
  }

  showToastMessage(message: string, state: string) {
    this.toastManager.addMessage({
      text: message,
      state
    } as ToastMessage);
  }
}

<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<p-tabs [size]="'medium'">
  <p-tabs-item
    class="event-detail__tabs-item"
    [label]="'adminEventDetail.tabNavigation.label.timeslotCapacity' | translate"
    [attr.data-testid]="'event-details-tab-timeslot'"
  >
    <mycontent-spacer [size]="'medium'"></mycontent-spacer>
    <mycontent-event-management-timeslot
      [country]="country()"
      [eventId]="eventId()"
      [eventDetails]="eventDetails()"
      [timeslots]="timeslots()"
      [timeslotsLoading]="timeslotsLoading()"
      (refreshTimeslots)="refreshTimeslots.emit()"
      (refreshEventDetails)="refreshEventDetails.emit()"
    ></mycontent-event-management-timeslot>
  </p-tabs-item>
  <p-tabs-item
    class="event-detail__tabs-item"
    [label]="'adminEventDetail.tabNavigation.label.attendanceInvitee' | translate"
    [attr.data-testid]="'event-details-tab-attendee-invite'"
  >
    <mycontent-spacer [size]="'medium'"></mycontent-spacer>
    <mycontent-event-management-attendee-invite
      [isAdmin]="isMarket()"
      [isMarket]="isAdmin()"
      [isDealer]="isDealer()"
      [eventId]="eventId()"
      [timeslots]="timeslots()"
      [setEventDetails]="eventDetails()"
      [country]="country()"
      [selectedInvitationStates]="selectedInvitationStates()"
      (refreshEventDetails)="refreshEventDetails.emit()"
    ></mycontent-event-management-attendee-invite>
    <mycontent-spacer [size]="'medium'"></mycontent-spacer>
    <mycontent-participant-table
      [eventDetails]="eventDetails()"
      [timeslots]="timeslots()"
      [country]="country()"
      [eventId]="eventId()"
      [isParticipantsLoading]="isParticipantsLoading()"
    ></mycontent-participant-table>
  </p-tabs-item>
  <p-tabs-item
    class="event-detail__tabs-item"
    [label]="'adminEventDetail.tabNavigation.label.eventSettings' | translate"
    [attr.data-testid]="'event-details-tab-settings'"
  >
    <mycontent-event-management-settings
      [isOpenRegistrationEnabled]="eventDetails()?.isOpenRegistration"
      [isPublishToFeedEnabled]="eventDetails()?.isPublic"
      [hoursBeforeRegistrationCloses]="eventDetails()?.hoursBeforeRegistrationCloses"
      [isWaitingListEnabled]="eventDetails()?.isWaitingListEnabled"
      [waitingListCount]="eventDetails()?.waitingListCount"
      [country]="country()"
      [eventId]="eventId()"
    ></mycontent-event-management-settings>
  </p-tabs-item>
</p-tabs>
<mycontent-spacer [size]="'medium'"></mycontent-spacer>

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { HttpRequest } from '@angular/common/http';

export function isNonApiRequest(request: HttpRequest<unknown>) {
  return request.url.match(/\/api[.|/]/) === null && request.url.match(/\/event-booking[.|/]/) === null;
}

<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<mycontent-grid [direction]="'column'">
  <mycontent-grid-item [size]="12">
    <p-heading [size]="'large'" [tag]="'h3'">{{ 'countrySelection' | translate }}</p-heading>
  </mycontent-grid-item>
</mycontent-grid>
<br/>
<mycontent-flex [direction]="'row'" [wrap]="'wrap'" [justifyContent]="'space-around'">
  @for (space of spaces; track space) {
    <mycontent-flex-item class="tool-tile" [width]="{ base: 'half', l: 'one-quarter' }">
      <a style="display: block"
         [routerLink]="'/' + space.spaceName.toLowerCase() + '/events'"
         [attr.data-testid]="'country-selection-box-' + space.spaceName.toUpperCase()">
        <p-icon [name]="'globe'" [size]="'large'"></p-icon>
        <br/>
        <p-button-pure [icon]="'arrow-right'">{{ space.spaceName.toUpperCase() }}</p-button-pure>
      </a>
    </mycontent-flex-item>
  }
</mycontent-flex>

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core';
import { RoleModel, RoleTypeEnum } from '../models/role.model';
import { SpaceModel } from '../models/space.model';

@Injectable({
  providedIn: 'root'
})
export class ImpersonationService {

  private ROLE_KEY = 'assumedRole';
  private MARKET_KEY = 'markets';

  constructor() {}

  setRole(role: RoleModel) {
    sessionStorage.setItem(this.ROLE_KEY, JSON.stringify(role));
  }

  getRole(): RoleModel | null {
    const role = sessionStorage.getItem(this.ROLE_KEY);
    if (role) {
      return JSON.parse(role) as RoleModel;
    }
    return null;
  }

  setMarkets(role: SpaceModel[]) {
    if (role) {
      sessionStorage.setItem(this.MARKET_KEY, JSON.stringify(role));
    }
  }

  getMarkets() {
    const role = sessionStorage.getItem(this.MARKET_KEY);
    if (role) {
      return JSON.parse(role) as SpaceModel[];
    }
    return [];
  }

  resetRole() {
    sessionStorage.setItem(this.ROLE_KEY, '');
  }

  isMarketRole() {
    const roleString = sessionStorage.getItem(this.ROLE_KEY);
    if (roleString) {
      const role = JSON.parse(roleString) as RoleModel;
      return role.type === RoleTypeEnum.MARKET;
    }
    return false;
  }

  isDealerRole() {
    const roleString = sessionStorage.getItem(this.ROLE_KEY);
    if (roleString) {
      const role = JSON.parse(roleString) as RoleModel;
      return role.type === RoleTypeEnum.DEALER;
    }
    return false;
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, model, output, signal } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { PorscheDesignSystemModule, ToastManager, ToastMessage } from '@porsche-design-system/components-angular';
import { CancelParticipantModel } from '../../../models/participant.model';
import { BannerNotificationService, SpacerComponent } from '@ui/shared/ui';
import { HttpErrorResponse } from '@angular/common/http';
import { ParticipantFacade } from '../../../facades/participant.facade';

@Component({
  selector: 'mycontent-participant-cancel-modal',
  templateUrl: './participant-cancel-modal.component.html',
  styleUrls: ['./participant-cancel-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
    imports: [
        PorscheDesignSystemModule,
        TranslatePipe,
        SpacerComponent
    ]
})
export class ParticipantCancelModalComponent {
  bookingCancellationText = input<string>();
  country = input<string>();
  eventId = input<string>();
  attendeeId = input<string>();

  isOpen = model<boolean>();

  updateParticipantModelEventManagementModel = output<void>();

  cancelParticipantLoading = signal<boolean>(false);

  private bannerNotificationService = inject(BannerNotificationService);
  private participantFacade = inject(ParticipantFacade);
  private toastManager = inject(ToastManager);
  private translateService = inject(TranslateService);

  closeCancelParticipant(): void {
    this.isOpen.set(false);
  }

  triggerCancelParticipant(): void {
    this.cancelParticipant({country: this.country(), eventId: this.eventId(), attendeeId: this.attendeeId()} as CancelParticipantModel);
  }

  cancelParticipant(cancelParticipant: CancelParticipantModel): void {
    this.cancelParticipantLoading.set(true);
    this.participantFacade.cancelParticipant(cancelParticipant).subscribe({
      next: () => {
        this.cancelParticipantLoading.set(false);
        const message = this.translateService.instant('adminEventDetail.toast.cancelTicketSuccess');
        this.isOpen.set(false);
        this.showToastMessage(message, 'success');
        this.updateParticipantModelEventManagementModel.emit();
        },
      error: (error: HttpErrorResponse) => {
        console.log(error);
        this.cancelParticipantLoading.set(false);
        this.isOpen.set(false);
        this.bannerNotificationService.publishMessage({
          state: 'error',
          message: this.translateService.instant('adminEventDetail.toast.cancelTicketFail'),
        });
      },
    });
  }

  showToastMessage(message: string, state: string) {
    this.toastManager.addMessage({
      text: message,
      state
    } as ToastMessage);
  }
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { MediaType } from './media-type';

const webP = new Image();
let isWebPSupported = false;
webP.src = 'data:image/webp;base64,UklGRi4AAABXRUJQVlA4TCEAAAAvAUAAEB8wA  iMwAgSSNtse/cXjxyCCmrYNWPwmHRH9jwMA';
webP.onload = webP.onerror = function () {
  isWebPSupported = webP.height === 2;
};

@Injectable({
  providedIn: 'root',
})
export class ImageUtilsService {
  get contentfulImageFormat() {
    return `fm=${isWebPSupported ? 'webp' : 'png'}`;
  }

  get isWebPSupported() {
    return isWebPSupported;
  }

  public generateImageLoaderImageFromContentfulAsset(
    imageUrl: string,
    imageType: MediaType,
    placeholder: string | SafeUrl = '',
    fallback: string | SafeUrl = '',
    quality?: number,
    sizes: Breakpoint[] = [
      { size: 'xs', width: 0 },
      { size: 'sm', width: 660 },
      { size: 'md', width: 1200 },
      { size: 'lg', width: 1600 },
    ]
  ): ImageLoaderImageData {
    let imageQuality = quality;
    if (imageQuality === undefined || imageQuality === null) {
      if (imageType !== null && imageType !== undefined && imageType.toLowerCase() === 'image/jpeg') {
        imageQuality = 80;
      }
      if (imageType !== null && imageType !== undefined && imageType.toLowerCase() === 'image/png') {
        imageQuality = 90;
      }
    }

    const images = imageUrl
      ? sizes.map((size, index) => {
          const imageWidth = sizes[index + 1] ? sizes[index + 1].width : sizes[sizes.length - 1].width;
          return {
            size: size.size,
            x1: `${imageUrl}?w=${imageWidth}&q=${imageQuality}&${this.contentfulImageFormat}`,
            x2: `${imageUrl}?w=${imageWidth * 2}&q=${imageQuality}&${this.contentfulImageFormat}`,
          };
        })
      : [];

    return {
      sizes,
      image: {
        images,
        placeholder: (placeholder || `${imageUrl}?w=${50}&q=${imageQuality}&${this.contentfulImageFormat}`) as string,
        fallback: fallback as string,
      },
    };
  }

  public getContentfulImage(imageUrl: string, imageWidth?: number) {
    return imageUrl && imageWidth ? `${imageUrl}?w=${imageWidth}&${this.contentfulImageFormat}` : imageUrl;
  }

  public generateImageLoaderImage(
    imageUrl: string,
    placeholder: string | SafeUrl = '',
    fallback: string | SafeUrl = '',
    sizes: Breakpoint[] = [
      { size: 'xs', width: 0 },
      { size: 'sm', width: 660 },
      { size: 'md', width: 1200 },
      { size: 'lg', width: 1600 },
    ]
  ) {
    const images = imageUrl
      ? sizes.map(size => ({
          size: size.size,
          x1: imageUrl,
          x2: imageUrl,
        }))
      : [];

    return {
      sizes,
      image: {
        images,
        placeholder: placeholder ? (placeholder as string) : '',
        fallback: fallback ? (fallback as string) : '',
      },
    } as ImageLoaderImageData;
  }

  // Generates source set with default sizes or custom sizes.
  // Default sizes are fitting for fullscreen images due to standard breakpoints.
  // Function is using the contentful api parameter for progessive image loading.
  public generateSrcset(imageUrl: string, sizes: number[] = [660, 1200, 1600]) {
    return sizes.map(size => `${imageUrl}?w=${size}&${this.contentfulImageFormat} ${size}w`).join(',');
  }

  /**
   * Function to test if a image can be loaded. The returned url can directly
   * be used in the ui since the ressource exists and is accessible.
   * @param url The ressource that should be tested.
   * @param fallbackUrl Fallback in case the first ressource cannot be loaded.
   * @param initializeWithFallback The returned observable emits fallbackUrl as first value.
   */
  public tryLoad(url: string, fallbackUrl: string | SafeUrl, initializeWithFallback = false) {
    return new Observable<string>(observer => {
      if (initializeWithFallback && fallbackUrl) {
        this.loadImage(fallbackUrl)
          .then(() => observer.next(fallbackUrl as string))
          .catch(() => {
            observer.next(undefined);
          });
      }

      if (url !== null && url !== undefined) {
        this.loadImage(url)
          .then(() => {
            observer.next(url);
            observer.complete();
          })
          .catch(() => {
            if (!initializeWithFallback) {
              this.loadImage(fallbackUrl)
                .then(() => {
                  observer.next(fallbackUrl as string);
                  observer.complete();
                })
                .catch(() => observer.complete());
            } else {
              observer.complete();
            }
          });
      } else {
        if (!initializeWithFallback && fallbackUrl) {
          this.loadImage(fallbackUrl)
            .then(() => {
              observer.next(fallbackUrl as string);
              observer.complete();
            })
            .catch(() => {
              observer.next(undefined);
              observer.complete();
            });
        } else {
          observer.next(undefined);
          observer.complete();
        }
      }
    });
  }

  private async loadImage(url: string | SafeUrl) {
    return new Promise<void>((resolve, reject) => {
      // don't load if data image is base64
      if (url.toString().indexOf('url(data:') !== -1) {
        resolve();
        return;
      }
      const img = new Image();
      img.onload = () => {
        resolve();
      };
      img.onerror = () => {
        reject();
      };
      img.src = url.toString();
    });
  }
}

export interface ImageLoaderImageData {
  image: ResponsiveImage;
  sizes: Breakpoint[];
}

export interface Breakpoint {
  size: Size;
  width: number;
}

export interface ResponsiveImage {
  placeholder: string;
  fallback: string;
  images: RetinaImage[];
}

export interface RetinaImage {
  size: Size;
  x1?: string;
  x2?: string;
}

export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<p-modal [open]="isOpen()" (dismiss)="closeImportCSV()">
  <p-heading slot="header" size="large" tag="h2">{{ 'details.buttonCSVImport' | translate }}</p-heading>
  <div
    class="attendee-invite__import-csv"
    #fileDropArea
    mycontentDragDropFile
    [backgroundColour]="PDS_THEME_LIGHT_BACKGROUND_SURFACE"
    (files)="onDragAndDropFile($event)"
  >
    <mycontent-flex [alignContent]="'center'" [justifyContent]="'center'">
      <p-icon [name]="'copy'" [size]="'x-large'"></p-icon>
    </mycontent-flex>
    <mycontent-spacer [size]="'small'"></mycontent-spacer>
    <mycontent-flex [alignContent]="'center'" [justifyContent]="'center'">
      <p-text>{{ 'adminEventDetail.dialog.paragraph.chooseDragDrop' | translate }}</p-text>
    </mycontent-flex>
    <mycontent-spacer [size]="'small'"></mycontent-spacer>
    <mycontent-flex [alignContent]="'center'" [justifyContent]="'center'">
      <p-button onclick="document.getElementById('participant-list').click()" [icon]="'copy'">
        {{ 'adminEventDetail.dialog.button.chooseCSV' | translate }}
        <input
          id="participant-list"
          [type]="'file'"
          data-testid="select-csv-file"
          accept="text/csv"
          #fileInput
          (click)="resetSteps()"
          (change)="onSelectFile($event)"
          style="display:none"
        ></p-button>
    </mycontent-flex>
    <mycontent-spacer [size]="'small'"></mycontent-spacer>
    <mycontent-flex [alignContent]="'center'" [justifyContent]="'center'">
      @if (selectFileSuccess === false) {
        <p-inline-notification
          [heading]="'Errors'"
          [description]="selectFileErrorMessage"
          [state]="'error'"
          [dismissButton]="false"
        ></p-inline-notification>
      }
      @if (fileValidate().fileValidateLoading) {
        <p-spinner [size]="'small'" aria-label="Loading page content"></p-spinner>
      }
      @if (fileValidate().fileValidateSuccess && fileName()) {
        <p-text [attr.data-testid]="'csv-upload-success-text'">{{ fileName() }}</p-text>
        <p-icon [attr.data-testid]="'csv-upload-success-icon'" [color]="'notification-success'" [name]="'success'" aria-label="Success icon"></p-icon>
      }
    </mycontent-flex>
    @if (!fileValidate().fileValidateSuccess) {
      <mycontent-flex [wrap]="'wrap'" [alignContent]="'center'" [justifyContent]="'center'">
        @if (fileValidate().fileValidateErrorMessage !== null) {
          <mycontent-flex-item [alignSelf]="'center'">
            <p-inline-notification
              [heading]="('details.csvUploadError.title' | translate) + ' `' + fileName() + '`'"
              [description]="fileValidate().fileValidateErrorMessage"
              [state]="'error'"
              [dismissButton]="false"
            ></p-inline-notification>
          </mycontent-flex-item>
        }
        @if (fileValidate().fileValidateErrors?.length > 0) {
          <mycontent-flex-item [alignSelf]="'center'">
            <p-table [caption]="'Preview Errors in your Data'">
              <p-table-head>
                <p-table-head-row>
                  <p-table-head-cell>Row</p-table-head-cell>
                  <p-table-head-cell>Error</p-table-head-cell>
                  <p-table-head-cell>|</p-table-head-cell>
                  <!--  fill the remaining table head cells for formatting reason-->
                  @for (item of this.uploadDataHead; track item) {
                    <p-table-head-cell>{{ item }}</p-table-head-cell>
                  }
                </p-table-head-row>
              </p-table-head>
              <p-table-body>
                @for (error of fileValidate().fileValidateErrors; track error) {
                  <p-table-row>
                    <p-table-cell>{{ error.csvErrorData ? error.csvErrorData.lineNumber + 1 : 0 }}</p-table-cell>
                    <p-table-cell>{{ error.errorDescription || error.errorType }}</p-table-cell>
                    <p-table-cell>|</p-table-cell>
                    @for (cell of error.csvErrorData.value.split(error.csvErrorData.delimiter); track cell; let colNr = $index) {
                      <p-table-cell
                        [style.background-color]="this.error.csvErrorData.columnNumber === colNr ? '#efc1c1' : ''">
                        {{ cell }}
                      </p-table-cell>
                    }
                  </p-table-row>
                }
              </p-table-body>
            </p-table>
          </mycontent-flex-item>
        }
      </mycontent-flex>
    }
  </div>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  @if (fileName()) {
    <p-text>{{ fileName() }}</p-text>
  }
  <mycontent-get-polling-status-percentage [lock]="pollingStatus$ | async"></mycontent-get-polling-status-percentage>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  @if ((pollingStatus$ | async)?.status !== 'RUNNING') {
    <p-button-group class="footer">
      <p-button
        [attr.data-testid]="'select-csv-button'"
        [variant]="'primary'"
        [disabled]="!fileValidate().fileValidateSuccess"
        [loading]="(fileUpload$ | async).fileUploadLoading"
        (click)="uploadData()"
      >
        {{ 'adminEventDetail.dialog.button.addInvitee' | translate }}
      </p-button>
      <p-button [type]="'button'" [variant]="'secondary'" [icon]="'close'" (click)="closeImportCSV()">
        {{ 'buttonCancel' |translate }}
      </p-button>
    </p-button-group>
  }
</p-modal>

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Meta } from '@angular/platform-browser';

export const IOS = 'iOS';
export const ANDROID = 'Android';

const US = 'us';
const CA = 'ca';
const CN = 'cn';

export type AppOS = typeof IOS | typeof ANDROID;

export type AppStoreResource = 'https://apps.apple.com/app/id1542743354' | 'https://apps.apple.com/cn/app/%E4%BF%9D%E6%97%B6%E6%8D%B7/id1492218590?ign-itscg=30200&ign-itsct=apps_box_link' | 'https://apps.apple.com/app/id1559529059';
export type PlayStoreResource = 'https://play.google.com/store/apps/details?id=com.porsche.one' | 'https://sj.qq.com/appdetail/cn.porsche.app' | 'https://play.google.com/store/apps/details?id=de.porsche.one';

export const getStoreResource = (countryCode: string, os: AppOS): AppStoreResource | PlayStoreResource => {
  const country = countryCode.toLowerCase();
  if (os === IOS) {
    if( country === US || country === CA) {
      return 'https://apps.apple.com/app/id1542743354';
    } else if (country === CN) {
      return 'https://apps.apple.com/cn/app/%E4%BF%9D%E6%97%B6%E6%8D%B7/id1492218590?ign-itscg=30200&ign-itsct=apps_box_link';
    } else {
      return 'https://apps.apple.com/app/id1559529059';
    }
  }
  if (os === ANDROID) {
    if( country === US || country === CA) {
      return 'https://play.google.com/store/apps/details?id=com.porsche.one';
    } else if (country === CN) {
      return 'https://sj.qq.com/appdetail/cn.porsche.app';
    } else {
      return 'https://play.google.com/store/apps/details?id=de.porsche.one';
    }
  }
  return undefined;
};

export const setAppMetaTag = (meta: Meta, countryCode: string) => {
  const appId = countryCode === US ? '1542743354' : '1559529059';
  const appURL = getStoreResource(countryCode, IOS);

  const contentValue = `app-id=${appId}, app-argument=${appURL}`;

  if (meta.getTag('name="apple-itunes-app"')) {
    meta.updateTag({ name: 'apple-itunes-app', content: contentValue });
  } else {
    meta.addTag({ name: 'apple-itunes-app', content: contentValue });
  }
};

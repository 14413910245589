/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Directive } from '@angular/core';
import { getConfigProvider } from '../ngx-ghosts-configuration';

@Directive({
  selector: '[mycontentGhostImage]',
  standalone: true,
  providers: [getConfigProvider()],
})
export class GhostImageDirective {}

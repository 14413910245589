/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { LocaleCountry } from './locale-country';
import { LocaleLanguage } from './locale-language';

export interface Locale {
  country: LocaleCountry;
  language: LocaleLanguage;
  languages: LocaleLanguage[];
  code: string;
  codeDashed: string;
}

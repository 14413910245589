/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, model, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslatePipe } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { LockEnum, LockStatusModel } from '../../../models/lock-status.model';
import { PollingService } from '../../../services/endpoints/polling.service';
import { ParticipantFacade } from '../../../facades/participant.facade';
import { InvitationFacade } from '../../../facades/invitation.facade';
import { INVITED } from '../../../models/invitation-state.model';
import { GetPollingStatusPercentageComponent } from '../../get-polling-status-percentage/get-polling-status-percentage.component';
import { SpacerComponent } from '@ui/shared/ui';

@Component({
  selector: 'mycontent-participant-send-invite-all-modal',
  templateUrl: './participant-send-invite-all-modal.component.html',
  styleUrls: ['./participant-send-invite-all-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    GetPollingStatusPercentageComponent,
    AsyncPipe,
    TranslatePipe,
    SpacerComponent
  ]
})
export class ParticipantSendInviteAllModalComponent implements OnInit {
  country = input<string>();
  eventId = input<string>();

  isOpen = model<boolean>();

  countOfReadyToInviteParticipants: string;
  pollingStatus$: Observable<LockStatusModel>;

  private unsubscribePollingStatus$: Subject<void> = new Subject<void>();
  private pollingService = inject(PollingService);
  private participantFacade = inject(ParticipantFacade);
  private invitationFacade = inject(InvitationFacade);

  ngOnInit() {
    this.pollingService.startPolling(this.country(), this.eventId());
    this.pollingStatus$ = this.pollingService.pollingStatus$;
    this.countOfReadyToInviteParticipants = this.participantFacade.getCountOfReadyToInviteParticipants();
  }

  closeSendInvitation(): void {
    this.pollingService.stopPolling();
    this.isOpen.set(false);
  }

  onDismiss(): void {
    this.pollingService.stopPolling();
    this.isOpen.set(false);
  }

  sendInvite(): void {
    this.invitationFacade.triggerInvitationMails({ country: this.country(), eventId: this.eventId() }).pipe(
      takeUntil(this.unsubscribePollingStatus$),
      tap(() => {
        this.pollingStatus$.pipe(
          takeUntil(this.unsubscribePollingStatus$)
        ).subscribe(lockStatus => {
          if (lockStatus.status === LockEnum.FINISHED_OK) {
            this.closeSendInvitation();
            this.participantFacade.updateParticipantFilterNameOrEmail(undefined);
            this.participantFacade.updateParticipantAction(undefined);
            const filters = this.participantFacade.getParticipantFilters();
            this.participantFacade.getParticipants({ ...filters, invitationStates: [INVITED], timeslotIds: [] });
            this.unsubscribePollingStatus$.next();
          }
          if (lockStatus.status === LockEnum.FINISHED_ERROR) {
            // TODO: add logic for unsuccessful lock
            this.closeSendInvitation();
            this.unsubscribePollingStatus$.next();
          }
        });
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return error;
      })).subscribe();
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, inject, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router, RouterLink } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslatePipe } from '@ngx-translate/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { SpaceModel, SpaceService } from '@admin/shared';
import { FlexComponent, FlexItemComponent, GridComponent, GridItemComponent } from '@ui/shared/ui';

@UntilDestroy()
@Component({
  selector: 'mycontent-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss'],
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    RouterLink,
    TranslatePipe,
    GridComponent,
    GridItemComponent,
    FlexComponent,
    FlexItemComponent,
  ],
})
export class CountrySelectorComponent implements OnInit {

  spaces: SpaceModel[];
  country = '';

  private oauthService = inject(OAuthService);
  private router = inject(Router);
  private spaceService = inject(SpaceService);

  ngOnInit(): void {
    const claims = this.oauthService.getIdentityClaims();
    this.country = claims['custom:country'];
    this.getAvailableCountriesAndRedirect();
  }

  getAvailableCountriesAndRedirect() {
    this.spaceService.getAvailableSpaces()
      .subscribe(spaces => {
        spaces.sort((a, b) => {
          if (a.spaceName > b.spaceName) {
            return 1;
          } else if (a.spaceName < b.spaceName) {
            return -1;
          }
          return 0;
        });
        this.spaces = spaces;
        if (spaces.length === 1) {
          const route = `/${this.spaces[0].spaceName.toLowerCase()}/events`;
          this.router.navigate([route]);
        }
      });
  }
}

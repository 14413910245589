/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { EventManagementModel, PatchEventRequest } from '../models/event-management.model';
import { ENVIRONMENT_ADMIN } from '@ui/shared/util';

@Injectable({
  providedIn: 'root',
})
export class EventsManagementService {

  private ADMIN_PATH = 'admin';

  private environment = inject(ENVIRONMENT_ADMIN);
  private http = inject(HttpClient);
  private oAuthService = inject(OAuthService);

  getEventManagement(country: string, eventId: string): Observable<EventManagementModel> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/details`;
    const headers = this.getAuthHeader();

    return this.http.get<EventManagementModel>(url, {headers});
  }

  updateEvent(country: string, eventId: string, updateEventRequest: PatchEventRequest): Observable<EventManagementModel> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}`;
    const headers = this.getAuthHeader();

    return this.http.patch<EventManagementModel>(url, updateEventRequest, {headers});
  }

  deleteEvent(country: string, eventId: string): Observable<void> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}`;
    const headers = this.getAuthHeader();

    return this.http.delete<void>(url, {headers});
  }

  private getAuthHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${this.oAuthService.getIdToken()}`);
  }
}

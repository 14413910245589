/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { addDays, isAfter } from 'date-fns';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DateUtilService } from './date-util.service';
import { UiFormatSpecialCaseService } from './ui-format-special-case.service';

export interface SplitDateRangeModel {
  startDate: Date;
  timezoneStartDate: string;
  endDate: Date;
  timezoneEndDate: string;
}

export interface SplitDateModel {
  date: Date;
  timezone: string;
}

@Injectable({ providedIn: 'root' })
export class DateFormatterService {

  private datePipe = inject(DatePipe);
  private dateUtilService = inject(DateUtilService);
  private translateService = inject(TranslateService);
  private uiFormatSpecialCaseService = inject(UiFormatSpecialCaseService);

  isEndDate24HoursAfterStartDate(startDate: Date, endDate: Date): boolean {
    return isAfter(addDays(startDate, 1), endDate);
  }

  formatBirthdayDateFromProfileFormat(birthdayProfileFormat: string): string {
    // input e.g 19951201 -> profile birthday format
    const year = birthdayProfileFormat.slice(0, 4);
    const month = birthdayProfileFormat.slice(4, 6);
    const day = birthdayProfileFormat.slice(6, 8);
    const date = new Date(`${year}-${month}-${day}`);
    return this.uiFormatSpecialCaseService.getFormatForMediumDate(date, undefined);
  }

  formatFullEventRange(startDateString: string, endDateString: string): string {
    const {
      startDate,
      endDate,
      timezoneStartDate,
      timezoneEndDate
    } = this.extractTimezonesFromDates(startDateString, endDateString);

    if (this.isEndDate24HoursAfterStartDate(startDate, endDate)) {
      if (this.dateUtilService.isSameDay(startDateString, endDateString)) {
        // 13 Jan. 2024, 10:00 - 12:00
        return `${this.uiFormatSpecialCaseService.getFormatForMediumDate(startDate, timezoneStartDate)}, ` +
          `${this.uiFormatSpecialCaseService.getFormatForShortTime(startDate, timezoneStartDate)} - ` +
          `${this.uiFormatSpecialCaseService.getFormatForShortTime(endDate, timezoneEndDate)}`;
      }
      const nextDay = this.translateService.instant('general.label.nextDay');
      // 13 Jan. 2024, 10:00 - 12:00 (next day)
      return `${this.uiFormatSpecialCaseService.getFormatForMediumDate(startDate, timezoneStartDate)}, ` +
        `${this.uiFormatSpecialCaseService.getFormatForShortTime(startDate, timezoneStartDate)} - ` +
        `${this.uiFormatSpecialCaseService.getFormatForShortTime(endDate, timezoneEndDate)} (${nextDay})`;
    }
    // 13 Jan. 2024, 22:00 - 18 Jan. 2024, 12:00
    return `${this.uiFormatSpecialCaseService.getFormatForMediumDate(startDate, timezoneStartDate)}, ` +
      `${this.uiFormatSpecialCaseService.getFormatForShortTime(startDate, timezoneStartDate)} - ` +
      `${this.uiFormatSpecialCaseService.getFormatForMediumDate(endDate, timezoneEndDate)}, ` +
      `${this.uiFormatSpecialCaseService.getFormatForShortTime(endDate, timezoneEndDate)}`;
  }

  formatFullEventRangeNoStartDate(startDateString: string, endDateString: string): string {
    // eg 10:00 - 12:00
    // OR 10:00 - 12:00 (next day)
    // OR 22:00 - 18 Jan. 2024, 12:00
    const {
      startDate,
      endDate,
      timezoneStartDate,
      timezoneEndDate
    } = this.extractTimezonesFromDates(startDateString, endDateString);

    if (this.isEndDate24HoursAfterStartDate(startDate, endDate)) {
      return this.formatTimeRange(startDateString, endDateString);
    }

    return `${this.uiFormatSpecialCaseService.getFormatForShortTime(startDate, timezoneStartDate)} - ` +
      `${this.uiFormatSpecialCaseService.getFormatForMediumDate(endDate, timezoneEndDate)}, ` +
      `${this.uiFormatSpecialCaseService.getFormatForShortTime(endDate, timezoneEndDate)}`;
  }

  formatTimeRange(startDateString: string, endDateString: string): string {
    // e.g 13:00 - 15:00 or 13:00 - 15:00 (next day)
    const {
      startDate,
      endDate,
      timezoneStartDate,
      timezoneEndDate
    } = this.extractTimezonesFromDates(startDateString, endDateString);

    const startDateFormatted = this.uiFormatSpecialCaseService.getFormatForShortTime(startDate, timezoneStartDate);
    const endDateFormatted = this.uiFormatSpecialCaseService.getFormatForShortTime(endDate, timezoneEndDate);

    if (this.dateUtilService.isSameDay(startDateString, endDateString)) {
      return `${startDateFormatted} - ${endDateFormatted}`;
    }
    const nextDay = this.translateService.instant('general.label.nextDay');
    return `${startDateFormatted} - ${endDateFormatted} (${nextDay})`;
  }

  formatDateNoTime(dateString: string): string {
    // e.g 13 Jan. 2024
    const { date, timezone } = this.extractTimezonesFromDate(dateString);
    return this.uiFormatSpecialCaseService.getFormatForMediumDate(date, timezone);
  }

  formatDateNoYearNoTime(dateString: string): string {
    // e.g 13. Jan
    const { date, timezone } = this.extractTimezonesFromDate(dateString);
    return this.datePipe.transform(date, 'MMM d', timezone);
  }

  formatDateWithTime(dateString: string): string {
    // e.g 13 Jan. 2024, 10:00
    const { date, timezone } = this.extractTimezonesFromDate(dateString);
    return `${this.uiFormatSpecialCaseService.getFormatForMediumDate(date, timezone)}, ` +
      `${this.uiFormatSpecialCaseService.getFormatForShortTime(date, timezone)}`;
  }

  formatTime(dateString: string): string {
    // 10:00
    const { date, timezone } = this.extractTimezonesFromDate(dateString);
    return `${this.uiFormatSpecialCaseService.getFormatForShortTime(date, timezone)}`;
  }

  formatEndTime(startDateString: string, endDateString: string): string {
    if (this.dateUtilService.isNextDay(startDateString, endDateString)) {
      const nextDay = this.translateService.instant('general.label.nextDay');
      // 10:00 (next Day)
      return `${this.formatTime(endDateString)} (${nextDay})`;
    }

    if (this.dateUtilService.isMoreThanNextDay(startDateString, endDateString)) {
      return this.formatDateWithTime(endDateString);
    }
    return this.formatTime(endDateString);
  }

  formatDateTimeShort(dateString: string): string {
    const { date, timezone } = this.extractTimezonesFromDate(dateString);

    return `${this.datePipe.transform(date, 'short', timezone)}`;
  }

  private extractTimezonesFromDates(startDateString: string, endDateString: string): SplitDateRangeModel {
    const timezoneStartDate = this.dateUtilService.getTimezoneFromDateStringOrNull(startDateString);
    const timezoneEndDate = this.dateUtilService.getTimezoneFromDateStringOrNull(endDateString);
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    return { startDate, timezoneStartDate, endDate, timezoneEndDate };
  }

  private extractTimezonesFromDate(dateString: string): SplitDateModel {
    const timezone = this.dateUtilService.getTimezoneFromDateStringOrNull(dateString);
    const date = new Date(dateString);
    return { date, timezone };
  }
}

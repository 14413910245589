/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */


import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { ENVIRONMENT_ADMIN } from '@ui/shared/util';

@Injectable({
  providedIn: 'root',
})
export class InvitationService {

  private ADMIN_PATH = 'admin';

  private environment = inject(ENVIRONMENT_ADMIN);
  private http = inject(HttpClient);
  private oAuthService = inject(OAuthService);

  triggerAllInvitationEmails(country: string, eventId: string): Observable<HttpResponse<void>> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/invitations/action:invite`;
    const headers = this.getAuthHeader();

    return this.http.post<void>(url, {}, {headers, responseType: 'json', observe: 'response'});
  }

  triggerSingleInvitation(country: string, eventId: string, attendeeId: string): Observable<HttpResponse<void>> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/invitations/${attendeeId}/action:invite`;
    const headers = this.getAuthHeader();

    return this.http.post<void>(url, {}, {headers, responseType: 'json', observe: 'response'});
  }

  triggerSingleInvitationNotification(country: string, eventId: string, attendeeId: string): Observable<HttpResponse<void>> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/invitations/action:resend-invitation-confirmation`;
    const headers = this.getAuthHeader();

    const payload = {attendees: [{attendeeId: Number(attendeeId)}]};

    return this.http.put<void>(url, payload, {headers, responseType: 'json', observe: 'response'});
  }

  private getAuthHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${  this.oAuthService.getIdToken()}`);
  }
}

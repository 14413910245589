/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, inject, OnInit } from '@angular/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AuthoritiesFacade } from '@admin/shared';
import { SupportService } from '../../services/endpoints/support.service';
import { Version } from '../../models/support.models';

@Component({
  selector: 'mycontent-status-page',
  templateUrl: './status-page.component.html',
  styleUrls: ['./status-page.component.scss'],
  imports: [
    PorscheDesignSystemModule,
    DatePipe
  ],
  standalone: true
})
export class StatusPageComponent implements OnInit {
  isAdmin: boolean;
  versionInformation: Version;

  private authoritiesService = inject(AuthoritiesFacade);
  private supportService = inject(SupportService);
  private router = inject(Router);

  ngOnInit(): void {
    this.isAdmin = this.authoritiesService.isAdmin();
    this.supportService.loadVersion().subscribe(
      version => this.versionInformation = version,
      () => this.router.navigate(['/'])
    );
  }
}

<!--
~  This code is protected by intellectual property rights.
~  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
@if (primaryIcon() && primaryText()) {
  <mycontent-flex
    [direction]="'row'"
    [justifyContent]="'space-between'"
    [className]="isClickable() ? 'link-item__container-clickable' : 'link-item__container-not-clickable'"
    (click)="isClickable() ? redirect() : undefined"
  >
    <mycontent-flex-item [width]="'auto'">
      <mycontent-flex [direction]="'row'">
        <mycontent-flex-item class="link-item__primary-icon" [width]="'auto'">
          <p-icon [name]="primaryIcon()" [aria]="{ 'aria-label': primaryIcon() + 'icon' }"></p-icon>
        </mycontent-flex-item>
        <mycontent-flex-item [width]="'auto'">
          <p-text class="link-item__primary-text" [size]="'small'">{{ primaryText() }}</p-text>
          <p-text [size]="'x-small'" [color]="'contrast-high'">{{ secondaryText() }}</p-text>
        </mycontent-flex-item>
      </mycontent-flex>
    </mycontent-flex-item>
    @if (isExternalLink() && isClickable()) {
      <mycontent-flex-item [width]="'auto'">
        <p-icon [name]="secondaryIcon()" [aria]="{ 'aria-label': secondaryIcon() + 'icon' }"></p-icon>
      </mycontent-flex-item>
    }
  </mycontent-flex>
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, map, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastManager, ToastMessage } from '@porsche-design-system/components-angular';
import { TranslateService } from '@ngx-translate/core';
import { InvitationService } from '../services/endpoints/invitation.service';
import { SendAllInvitationsModel, SendSingleInvitationsModel } from '../models/participant.model';
import { BannerNotificationService } from '@ui/shared/ui';

@Injectable({
  providedIn: 'root'
})
export class InvitationFacade {

  private loadingSubject = new BehaviorSubject<boolean>(false);

  /* eslint-disable @typescript-eslint/member-ordering */
  readonly loading$ = this.loadingSubject.asObservable();

  private invitationService = inject(InvitationService);
  private toastManager = inject(ToastManager);
  private translateService = inject(TranslateService);
  private bannerNotificationService = inject(BannerNotificationService);

  triggerInvitationMails(sendInvitation: SendAllInvitationsModel) {
    this.loadingSubject.next(true);
    return this.invitationService.triggerAllInvitationEmails(sendInvitation.country, sendInvitation.eventId)
      .pipe(
        map(response => {
          this.toastManager.addMessage({
            text: this.translateService.instant('details.msgInvitationMailSuccessful'),
            state: 'success'
          } as ToastMessage);
          return response.status === 200;
        }),
        catchError(error => {
          console.log(error);
          this.bannerNotificationService.publishMessage({
            state: 'info',
            message: this.translateService.instant('details.msgInvitationMailError'),
            additionalInformation: error.status
          });
          return EMPTY;
        }),
        tap(() => this.loadingSubject.next(false))
      );
  }

  triggerSingleInvitation(sendInvitation: SendSingleInvitationsModel) {
    this.loadingSubject.next(true);
    return this.invitationService.triggerSingleInvitation(sendInvitation.country, sendInvitation.eventId, sendInvitation.attendeeId)
      .pipe(
        map(response => {
          this.toastManager.addMessage({
            text: this.translateService.instant('participantFlyout.sendInvitation.success.toast.paragraph', { email: sendInvitation.email }),
            state: 'success'
          } as ToastMessage);
          return response.status === 200;
        }),
        catchError(error => {
          console.log(error);
          this.bannerNotificationService.publishMessage({
            state: 'info',
            message: this.translateService.instant('participantFlyout.sendInvitation.error.banner.header', { email: sendInvitation.email }),
            additionalInformation: error.status
          });
          return EMPTY;
        }),
        tap(() => this.loadingSubject.next(false))
      );
  }

  triggerSingleInvitationNotification(sendInvitationConfirmation: SendSingleInvitationsModel) {
    this.loadingSubject.next(true);
    return this.invitationService.triggerSingleInvitationNotification(sendInvitationConfirmation.country, sendInvitationConfirmation.eventId, sendInvitationConfirmation.attendeeId)
      .pipe(
        map(response => {
          this.toastManager.addMessage({
            text: this.translateService.instant('adminEventDetail.toast.reactivateInvitationSuccessful', { email: sendInvitationConfirmation.email }),
            state: 'success'
          } as ToastMessage);
          return response.status === 200;
        }),
        catchError(error => {
          console.log(error);
          this.bannerNotificationService.publishMessage({
            state: 'error',
            message: this.translateService.instant('adminEventDetail.banner.paragraph.reactivateInvitationFail', { email: sendInvitationConfirmation.email }),
            additionalInformation: error.status
          });
          return EMPTY;
        }),
        tap(() => this.loadingSubject.next(false))
      );
  }
}

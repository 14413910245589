/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT_ADMIN } from '@ui/shared/util';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { map, Observable, shareReplay } from 'rxjs';
import { SpaceModel } from '../models/space.model';

@Injectable({
  providedIn: 'root',
})
export class SpaceService {

  private ADMIN_PATH = 'admin';

  private environment = inject(ENVIRONMENT_ADMIN);
  private http = inject(HttpClient);
  private oAuthService = inject(OAuthService);

  getAvailableSpaces(): Observable<SpaceModel[]> {
    const headers = this.getAuthHeader();
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/spaces`;

    return this.http.get<SpaceModel[]>(url, {headers}).pipe(
      map((spaces: SpaceModel[]) => {
        spaces.sort((a, b) => {
          if (a.spaceName > b.spaceName) {
            return 1;
          } else if (a.spaceName < b.spaceName) {
            return -1;
          }
          return 0;
        });
        return spaces;
      }),
    );
  }

  getSpace(countryCode: string): Observable<SpaceModel> {
    const headers = this.getAuthHeader();
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/spaces`;

    return this.http.get<SpaceModel[]>(url, {headers}).pipe(
      map((spaces: SpaceModel[]) => spaces.filter(space => space.spaceName.toUpperCase() === countryCode.toUpperCase())[0]),
      shareReplay(1),
    );
  }

  private getAuthHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${this.oAuthService.getIdToken()}`);
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */



import { SpaceModel } from '../models/space.model';

export const spacesResponse: SpaceModel[] = [
  {
    spaceName: 'DE',
    spaceId: '3n7mkgerxx7t',
    defaultLocale: 'de_DE',
    supportedLocales: ['de_DE'],
  },
  {
    spaceName: 'ES',
    spaceId: 'af8zuw83aico',
    defaultLocale: 'es_ES',
    supportedLocales: ['es_ES'],
  },
  {
    spaceName: 'FR',
    spaceId: '8df1gmgw99kc',
    defaultLocale: 'fr_FR',
    supportedLocales: ['fr_FR'],
  },
  {
    spaceName: 'GB',
    spaceId: 'sobzln37b0f9',
    defaultLocale: 'en_GB',
    supportedLocales: ['en_GB'],
  },
  {
    spaceName: 'PT',
    spaceId: 'rz7601tcbj7t',
    defaultLocale: 'pt_PT',
    supportedLocales: ['pt_PT'],
  },
  {
    spaceName: 'SG',
    spaceId: '200gt6dtg203',
    defaultLocale: 'en_GB',
    supportedLocales: ['en_GB'],
  },
  {
    spaceName: 'US',
    spaceId: 'if2f7i6odbfv',
    defaultLocale: 'en_US',
    supportedLocales: ['en_US'],
  },
];

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { UntilDestroy } from '@ngneat/until-destroy';
import { MatOption } from '@angular/material/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { PorscheDesignSystemModule, TagColor } from '@porsche-design-system/components-angular';
import { Subscription } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { FetchParticipantModel } from '../../../models/participant.model';
import { ParticipantFacade } from '../../../facades/participant.facade';
import { FilterParticipantsService } from '../../../services/filter-participants.service';
import { participantStateListWithoutAdded, ParticipantStateType } from '../../../models/invitation-state.model';

@UntilDestroy()
@Component({
  selector: 'mycontent-participant-status-filter',
  templateUrl: './participant-status-filter.component.html',
  styleUrls: ['./participant-status-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatSelect,
    NgClass,
    MatSelectTrigger,
    MatOption,
    PorscheDesignSystemModule,
    TranslatePipe
  ]
})
export class ParticipantStatusFilterComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('allStatusSelected') allStatusesSelected: MatOption;

  showStatusSelect = false;
  defaultOption = 'ALL_STATUSES';
  form: FormGroup;
  fetchParticipants: FetchParticipantModel;
  invitationStates = participantStateListWithoutAdded;

  private participantFacade = inject(ParticipantFacade);
  private filterService = inject(FilterParticipantsService);
  private filterSubscription: Subscription;

  ngOnInit(): void {
    this.form = new FormGroup({
      invitationStatus: new FormControl([]),
      filter: new FormControl()
    });

    this.fetchParticipants = this.participantFacade.getParticipantFilters();
  }

  ngAfterViewInit(): void {
    this.filterSubscription = this.participantFacade.participantsFilter$.subscribe(newFilters => {
      this.fetchParticipants = newFilters;

      this.handleStatusFiltersExternallyChanged(newFilters);
    });
  }

  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
  }

  getInvitationStateColour = (state: ParticipantStateType): TagColor => this.filterService.getInvitationStateColour(state);

  statusFilterDropdownChanged(isOpened: boolean) {
    this.showStatusSelect = isOpened;
    if (!isOpened) {
      const states = this.form.get('invitationStatus').value.filter(statusFilter => statusFilter !== this.defaultOption && statusFilter);
      if (states.toString() !== this.fetchParticipants.invitationStates.toString()) {
        this.participantFacade.getParticipants({ ...this.fetchParticipants, invitationStates: states });
      }
    }
  }

  toggleFilterStatusPerOne() {
    const stateWithoutDefault = this.form.get('invitationStatus').value.filter(statusFilter => statusFilter !== this.defaultOption);
    if (stateWithoutDefault.length >= this.invitationStates.length) {
      this.allStatusesSelected.select();
      return;
    }
    this.allStatusesSelected.deselect();
  }

  toggleAllStatusSelection() {
    if (this.allStatusesSelected.selected) {
      this.allStatusesSelected.select();
      this.form.get('invitationStatus').patchValue([...(this.invitationStates ? this.invitationStates.map(statusFilter => statusFilter) : undefined), this.defaultOption]);
    } else {
      this.allStatusesSelected.deselect();
      this.form.get('invitationStatus').patchValue([]);
    }
  }

  handleStatusFiltersExternallyChanged(newFilters: FetchParticipantModel) {
    if (newFilters.invitationStates.length === participantStateListWithoutAdded.length) {
      this.form.get('invitationStatus').patchValue([...(this.invitationStates ? this.invitationStates.map(statusFilter => statusFilter) : undefined), this.defaultOption]);
    } else {
      this.form.get('invitationStatus').patchValue(newFilters.invitationStates);
    }
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, forwardRef, inject, input, ViewEncapsulation } from '@angular/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { TranslatePipe } from '@ngx-translate/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { I_PREFER_NOT_TO_ANSWER } from '../../models/form-field.model';
import { FlexComponent, PUI_BREAKPOINT, PuiBreakpoint, SpacerComponent } from '@ui/shared/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'mycontent-registration-form-field',
  templateUrl: './registration-form-field.component.html',
  styleUrls: ['./registration-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    TranslatePipe,
    ReactiveFormsModule,
    SpacerComponent,
    FlexComponent,
    AsyncPipe
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RegistrationFormFieldComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RegistrationFormFieldComponent),
      multi: true
    }
  ]
})
export class RegistrationFormFieldComponent implements ControlValueAccessor, Validator {
  question = input<string>();
  questionType = input<'radio' | 'textarea'>();
  choices = input<string[]>();
  index = input<number>();
  isSubmitted = input<boolean>(false);

  readonly I_PREFER_NOT_TO_ANSWER = I_PREFER_NOT_TO_ANSWER;

  breakpoint$: Observable<PuiBreakpoint> = inject(PUI_BREAKPOINT);
  isMobileView$ = this.breakpoint$.pipe(
    map(breakpoint => {
      /* istanbul ignore next */
      switch (breakpoint.name) {
        case 'base':
        case 'xs':
        case 's':
        case 'm':
          return true;
        default:
          return false;
      }
    })
  );

  form: FormControl<string>;

  constructor() {
    this.form = new FormControl<string>('');
  }

  registerOnChange(fn: (value: string) => void): void {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: (value: string) => void): void {
    this.form.valueChanges.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form?.disable();
    } else {
      this.form?.enable();
    }
  }

  writeValue(form: string): void {
    this.form.patchValue(form, { emitEvent: true });
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : control.errors;
  }

  preferNotToAnswerClicked() {
    if (this.form.value !== I_PREFER_NOT_TO_ANSWER) {
      this.form.setValue(I_PREFER_NOT_TO_ANSWER);
    } else {
      this.form.setValue('');
    }
  }

  getFormFieldErrorState() {
    return this.isSubmitted() && this.form.value === '' ? 'error' : 'none';
  }
}

<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<p-modal [open]="isOpen()" (dismiss)="onDismiss()">
  <p-heading [size]="'large'" [tag]="'h2'">{{ 'adminEventDetail.dialog.title.reactivateInvitation' | translate }}</p-heading>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  <mycontent-get-polling-status-percentage [lock]="pollingStatus$ | async"></mycontent-get-polling-status-percentage>
  <p-text>{{ 'adminEventDetail.dialog.paragraph.reactivateInvitation' | translate: {email: email()} }}</p-text>
  @if((pollingStatus$ | async)?.status !== 'RUNNING') {
    <mycontent-spacer [size]="'medium'"></mycontent-spacer>
    <p-button-group class="footer">
      <p-button (click)="sendInviteConfirmation()">{{ 'confirm' | translate }}</p-button>
      <p-button [type]="'button'" [variant]="'secondary'" [icon]="'close'" (click)="closeSendInvitation()">
        {{ 'buttonCancel' | translate }}
      </p-button>
    </p-button-group>
  }
</p-modal>

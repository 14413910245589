<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

@if (isAdmin && versionInformation && versionInformation.git && versionInformation.instance) {
  <p-heading>Git:</p-heading>
  <p-text>Commit Id Abbrev: {{ versionInformation.git.commitIdAbbrev }}</p-text>
  <p-text>Commit Time: {{ versionInformation.git.commitTime | date: 'long' }}</p-text>
  <p-text>Commit Message: {{ versionInformation.git.commitMessageShort }}</p-text>
  <p-text>Build Time: {{ versionInformation.git.buildTime | date: 'long' }}</p-text>
  <p-heading>Instance:</p-heading>
  <p-text>Server Start Time: {{ versionInformation.instance.serverStartTime | date: 'long' }}</p-text>
  <p-text>Spring Context Reload Time: {{ versionInformation.instance.springContextReloadTime | date: 'long' }}</p-text>
}


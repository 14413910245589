<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<p-modal [open]="isOpen()" (dismiss)="close()">
  <p-heading slot="header" [size]="'large'" [tag]="'h2'">{{ 'Assume role' }}</p-heading>
  <form [formGroup]="form">
    <div class="assume-role">
      <div>
        <p-radio-button-wrapper class="assume-role__radio" [label]="'header.role.market.label' | translate" [hideLabel]="false">
          <input
            type="radio"
            [attr.data-testid]="'event-admin-assume-role-modal-market-radio-btn'"
            [attr.name]="'role'"
            [value]="'market'"
            [formControlName]="'role'"
          />
        </p-radio-button-wrapper>
        <p-radio-button-wrapper class="assume-role__radio" [label]="'header.role.dealer.label' | translate" [hideLabel]="false">
          <input
            type="radio"
            [attr.data-testid]="'event-admin-assume-role-modal-dealer-radio-btn'"
            [attr.name]="'role'"
            [value]="'dealer'"
            [formControlName]="'role'"
          />
        </p-radio-button-wrapper>
      </div>
      <div>
        @if (showMarket()) {
          <p-select-wrapper
            class="assume-role__select"
            [filter]="true"
            [label]="'header.role.market.label' | translate"
            [hideLabel]="false"
          >
            <select [attr.data-testid]="'event-admin-assume-role-modal-market-selector'" [name]="'market'" [formControlName]="'market'">
              @for (space of markets(); track space) {
                <option [value]="space.spaceName">{{space.spaceName}}</option>
              }
            </select>
          </p-select-wrapper>
        }
        @if (showDealer()) {
          <p-select-wrapper
            class="assume-role__select"
            [filter]="true"
            [label]="'header.role.dealer.label' | translate"
            [hideLabel]="false"
          >
            <select [attr.data-testid]="'event-admin-assume-role-modal-dealer-selector'" [name]="'dealer'" [formControlName]="'dealer'">
              @for (dealer of dealers(); track dealer) {
                <option [value]="dealer.dealerId">{{dealer.dealerName}}</option>
              }
            </select>
          </p-select-wrapper>
        }
      </div>
      <div>
        <br/>
        <p-button-group class="footer">
          <p-button
            [attr.data-testid]="'event-admin-assume-role-modal-accept-btn'"
            [disabled]="form.invalid"
            (click)="submit()"
          >
            {{ 'header.role.submit' | translate }}
          </p-button>
          <p-button
            [attr.data-testid]="'event-admin-assume-role-modal-reset-btn'"
            [type]="'button'"
            [variant]="'secondary'"
            (click)="resetAssumedRole()"
          >
            {{ 'header.role.reset' | translate }}
          </p-button>
          <p-button
            [attr.data-testid]="'event-admin-assume-role-modal-cancel-btn'"
            [type]="'button'"
            [variant]="'secondary'"
            (click)="close()"
          >
            {{ 'buttonCancel' | translate }}
          </p-button>
        </p-button-group>
      </div>
    </div>
  </form>
</p-modal>

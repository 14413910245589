/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { ProfileModel } from '../models/profile.model';
import { ENVIRONMENT_ADMIN } from '@ui/shared/util';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {

  private environment = inject(ENVIRONMENT_ADMIN);
  private http = inject(HttpClient);
  private oauthService = inject(OAuthService);

  fetchProfileInformation() {
    const url = `${this.environment.backend.url}/whoami`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.oauthService.getIdToken()}`);
    return this.http.get<ProfileModel>(url, {headers});
  }
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PuiBreakpointName } from './breakpoint';

@Injectable()
export class BreakpointTestingController {
  constructor(private breakpointName$: BehaviorSubject<PuiBreakpointName>) {}

  set(breakpointName: PuiBreakpointName) {
    this.breakpointName$.next(breakpointName);
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { FlexComponent, GridComponent, GridItemComponent, SpacerComponent } from '@ui/shared/ui';
import { NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { AdminFeatureToggleService, ExternalLinkService, SpaceModel } from '@admin/shared';
import { OverviewTrendsEnum, OverviewTrendsModel } from '../../models/overview.model';

@Component({
  selector: 'mycontent-event-overview-header',
  templateUrl: './event-overview-header.component.html',
  styleUrls: ['./event-overview-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    RouterLink,
    GridComponent,
    GridItemComponent,
    NgOptimizedImage,
    FlexComponent,
    FormsModule,
    ReactiveFormsModule,
    TranslatePipe,
    SpacerComponent
  ]
})
export class EventOverviewHeaderComponent implements OnInit {
  country = input<string>();
  isAdmin = input<boolean>();
  isMarket = input<boolean>();
  isDealer = input<boolean>();
  currentSpaceId = input<string>();
  spaces = input<SpaceModel[]>();
  overviewTrends = input<OverviewTrendsModel>();

  refreshTrends = output<string>();

  isAdminOrMarket = computed(() => this.isAdmin() || this.isMarket());

  form: FormGroup;

  userTrendFilters: OverviewTrendsEnum[] = [
    OverviewTrendsEnum.ALL_TIME,
    OverviewTrendsEnum.LAST_30_DAYS,
    OverviewTrendsEnum.LAST_7_DAYS,
    OverviewTrendsEnum.LAST_24_HOURS
  ];

  private externalLinkService = inject(ExternalLinkService);
  private AdminFeatureToggleService: AdminFeatureToggleService = inject(AdminFeatureToggleService);

  ngOnInit() {
    this.form = new FormGroup({
      overviewTrends: new FormControl(this.userTrendFilters[0])
    });

    this.form.get('overviewTrends').valueChanges.subscribe((interaction: string) => {
      this.refreshTrends.emit(interaction);
    });
  }

  isEventCreateAndEditEnabled() {
    return this.AdminFeatureToggleService.isEventCreateAndEditEnabled();
  }

  getContentfulLink() {
    return this.externalLinkService.getContentfulLink(this.currentSpaceId());
  }

  getPercentage(numberA: number, numberB: number): number {
    if (numberA && numberB) {
      const percentage = (numberA / numberB) * 100;
      return Math.round(percentage);
    } else {
      return 0;
    }
  }
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export const angularLocaleData: Record<string, () => Promise<{ default: unknown }>> = {
  cs_CZ: () => import('@angular/common/locales/cs'),
  cs_SK: () => import('@angular/common/locales/cs'),
  da_DK: () => import('@angular/common/locales/da'),
  de_AT: () => import('@angular/common/locales/de'),
  de_AQ: () => import('@angular/common/locales/de'),
  de_CH: () => import('@angular/common/locales/de-CH'),
  de_DE: () => import('@angular/common/locales/de'),
  de_LI: () => import('@angular/common/locales/de-LI'),
  de_LU: () => import('@angular/common/locales/de-LU'),
  en_AQ: () => import('@angular/common/locales/en'),
  en_BG: () => import('@angular/common/locales/en'),
  en_CY: () => import('@angular/common/locales/en-CY'),
  en_CZ: () => import('@angular/common/locales/en'),
  en_DK: () => import('@angular/common/locales/en-DK'),
  en_EE: () => import('@angular/common/locales/en'),
  en_FI: () => import('@angular/common/locales/en-FI'),
  en_GB: () => import('@angular/common/locales/en-GB'),
  en_GR: () => import('@angular/common/locales/en'),
  en_HR: () => import('@angular/common/locales/en'),
  en_HU: () => import('@angular/common/locales/en'),
  en_IE: () => import('@angular/common/locales/en-IE'),
  en_LT: () => import('@angular/common/locales/en'),
  en_LV: () => import('@angular/common/locales/en'),
  en_MT: () => import('@angular/common/locales/en-MT'),
  en_NL: () => import('@angular/common/locales/en-NL'),
  en_PL: () => import('@angular/common/locales/en'),
  en_PT: () => import('@angular/common/locales/en'),
  en_RO: () => import('@angular/common/locales/en'),
  en_SE: () => import('@angular/common/locales/en-SE'),
  en_SI: () => import('@angular/common/locales/en-SI'),
  en_SK: () => import('@angular/common/locales/en'),
  en_US: () => import('@angular/common/locales/en'),
  es_ES: () => import('@angular/common/locales/es'),
  et_EE: () => import('@angular/common/locales/et'),
  fi_FI: () => import('@angular/common/locales/fi'),
  fr_FR: () => import('@angular/common/locales/fr'),
  it_IT: () => import('@angular/common/locales/it'),
  ja_JP: () => import('@angular/common/locales/ja'),
  ko_KR: () => import('@angular/common/locales/ko'),
  lt_LT: () => import('@angular/common/locales/lt'),
  lv_LV: () => import('@angular/common/locales/lv'),
  nl_BE: () => import('@angular/common/locales/nl-BE'),
  nl_NL: () => import('@angular/common/locales/nl'),
  no_NO: () => import('@angular/common/locales/no'),
  pl_PL: () => import('@angular/common/locales/pl'),
  pt_PT: () => import('@angular/common/locales/pt-PT'),
  ru_RU: () => import('@angular/common/locales/ru'),
  sv_SE: () => import('@angular/common/locales/sv'),
  tr_TR: () => import('@angular/common/locales/tr'),
  zh_CN: () => import('@angular/common/locales/zh'),
  en_AE: () => import('@angular/common/locales/en'),
  en_AL: () => import('@angular/common/locales/en'),
  en_AM: () => import('@angular/common/locales/en'),
  en_AZ: () => import('@angular/common/locales/en'),
  en_BA: () => import('@angular/common/locales/en'),
  en_BH: () => import('@angular/common/locales/en'),
  en_BN: () => import('@angular/common/locales/en'),
  en_BR: () => import('@angular/common/locales/en'),
  en_CH: () => import('@angular/common/locales/en-CH'),
  en_EG: () => import('@angular/common/locales/en'),
  en_GE: () => import('@angular/common/locales/en'),
  en_GI: () => import('@angular/common/locales/en-GI'),
  en_HK: () => import('@angular/common/locales/en-HK'),
  en_ID: () => import('@angular/common/locales/en'),
  en_IL: () => import('@angular/common/locales/en-IL'),
  en_IN: () => import('@angular/common/locales/en-IN'),
  en_IS: () => import('@angular/common/locales/en'),
  en_JO: () => import('@angular/common/locales/en'),
  en_KE: () => import('@angular/common/locales/en-KE'),
  en_KH: () => import('@angular/common/locales/en'),
  en_KR: () => import('@angular/common/locales/en'),
  en_KW: () => import('@angular/common/locales/en'),
  en_KZ: () => import('@angular/common/locales/en'),
  en_LB: () => import('@angular/common/locales/en'),
  en_LI: () => import('@angular/common/locales/en'),
  en_LK: () => import('@angular/common/locales/en'),
  en_MD: () => import('@angular/common/locales/en'),
  en_ME: () => import('@angular/common/locales/en'),
  en_MK: () => import('@angular/common/locales/en'),
  en_MN: () => import('@angular/common/locales/en'),
  en_MY: () => import('@angular/common/locales/en-MY'),
  en_NO: () => import('@angular/common/locales/en'),
  en_NZ: () => import('@angular/common/locales/en-NZ'),
  en_OM: () => import('@angular/common/locales/en'),
  en_PH: () => import('@angular/common/locales/en-PH'),
  en_PK: () => import('@angular/common/locales/en-PK'),
  en_PR: () => import('@angular/common/locales/en-PR'),
  en_QA: () => import('@angular/common/locales/en'),
  en_RS: () => import('@angular/common/locales/en'),
  en_SA: () => import('@angular/common/locales/en'),
  en_SG: () => import('@angular/common/locales/en-SG'),
  en_SY: () => import('@angular/common/locales/en'),
  en_TH: () => import('@angular/common/locales/en'),
  en_TR: () => import('@angular/common/locales/en'),
  en_TT: () => import('@angular/common/locales/en-TT'),
  en_TW: () => import('@angular/common/locales/en'),
  en_VN: () => import('@angular/common/locales/en'),
  en_YE: () => import('@angular/common/locales/en'),
  en_ZA: () => import('@angular/common/locales/en-ZA'),
  ru_CY: () => import('@angular/common/locales/ru'),
  ru_EE: () => import('@angular/common/locales/ru'),
  ru_LT: () => import('@angular/common/locales/ru'),
  ru_LV: () => import('@angular/common/locales/ru'),
  zh_HK: () => import('@angular/common/locales/zh'),
  zh_TW: () => import('@angular/common/locales/zh'),
  en_CN: () => import('@angular/common/locales/en'),
  en_AU: () => import('@angular/common/locales/en-AU'),
  ru_BY: () => import('@angular/common/locales/ru-BY'),
  ru_UA: () => import('@angular/common/locales/ru-UA'),
  it_CH: () => import('@angular/common/locales/it-CH'),
  it_LI: () => import('@angular/common/locales/it'),
  en_JP: () => import('@angular/common/locales/en'),
  en_CA: () => import('@angular/common/locales/en-CA'),
  pt_BR: () => import('@angular/common/locales/pt'),
  es_AD: () => import('@angular/common/locales/es'),
  es_AR: () => import('@angular/common/locales/es-AR'),
  es_BO: () => import('@angular/common/locales/es-BO'),
  es_CL: () => import('@angular/common/locales/es-CL'),
  es_CO: () => import('@angular/common/locales/es-CO'),
  es_CR: () => import('@angular/common/locales/es-CR'),
  es_DO: () => import('@angular/common/locales/es-DO'),
  es_EC: () => import('@angular/common/locales/es-EC'),
  es_GT: () => import('@angular/common/locales/es-GT'),
  es_HN: () => import('@angular/common/locales/es-HN'),
  es_MX: () => import('@angular/common/locales/es-MX'),
  es_PA: () => import('@angular/common/locales/es-PA'),
  es_PE: () => import('@angular/common/locales/es-PE'),
  es_PR: () => import('@angular/common/locales/es-PR'),
  es_PY: () => import('@angular/common/locales/es-PY'),
  es_SV: () => import('@angular/common/locales/es-SV'),
  es_UY: () => import('@angular/common/locales/es-UY'),
  es_VE: () => import('@angular/common/locales/es-VE'),
  fr_MC: () => import('@angular/common/locales/fr-MC'),
  fr_CH: () => import('@angular/common/locales/fr-CH'),
  fr_GP: () => import('@angular/common/locales/fr-GP'),
  fr_HT: () => import('@angular/common/locales/fr-HT'),
  fr_LI: () => import('@angular/common/locales/fr'),
  fr_NC: () => import('@angular/common/locales/fr-NC'),
  fr_PF: () => import('@angular/common/locales/fr-PF'),
  fr_BE: () => import('@angular/common/locales/fr-BE'),
  fr_LU: () => import('@angular/common/locales/fr-LU'),
  fr_CA: () => import('@angular/common/locales/fr-CA'),
  sr_RS: () => import('@angular/common/locales/sr-Latn'),
  sr_ME: () => import('@angular/common/locales/sr-Latn-ME'),
};

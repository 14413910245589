/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, model, signal } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PorscheDesignSystemModule, ToastManager, ToastMessage } from '@porsche-design-system/components-angular';
import { BannerNotificationService, FlexComponent } from '@ui/shared/ui';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { BookingService } from '../../../services/endpoints/booking.service';
import { ResendRegistrationRequests, ResendRegistrationResponse } from '../../../models/resend-registration.model';

@UntilDestroy()
@Component({
  selector: 'mycontent-participant-resend-registration-modal',
  templateUrl: './participant-resend-registration-modal.component.html',
  styleUrls: ['./participant-resend-registration-modal.component.scss'],
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    FlexComponent,
    TranslatePipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipantResendRegistrationModalComponent {
  country = input<string>();
  eventId = input<string>();
  ciamId = input<string>();
  mail = input<string>();

  isOpen = model<boolean>();

  isLoading = signal<boolean>(false);

  private bookingService = inject(BookingService);
  private toastManager = inject(ToastManager);
  private bannerNotificationService = inject(BannerNotificationService);
  private translateService = inject(TranslateService);

  resendRegistration() {
    this.isLoading.set(true);
    const userInfo: ResendRegistrationRequests = {
      attendees: [{ ciamId: this.ciamId() }]
    };
    return this.bookingService
      .resendRegistrationEmailForUser(this.country(), this.eventId(), userInfo)
      .subscribe({
        next: (response: ResendRegistrationResponse) => {
          this.isLoading.set(false);
          if (response.attendees[0].errors.length > 0) {
            this.bannerNotificationService.publishMessage({
              state: 'error',
              message: this.translateService.instant('adminEventDetail.banner.paragraph.resendRegistrationFail', { email: this.mail() }),
              additionalInformation: this.mail()
            });
            this.close();
          } else {
            this.toastManager.addMessage({
              text: this.translateService.instant('adminEventDetail.toast.resendRegistrationSuccessful', { email: this.mail() }),
              state: 'success'
            } as ToastMessage);
            this.close();
          }
        },
        error: () => {
          this.isLoading.set(false);
          this.bannerNotificationService.publishMessage({
            state: 'error',
            message: this.translateService.instant('adminEventDetail.banner.paragraph.resendRegistrationFail'),
            additionalInformation: this.mail()
          });
          this.close();
        }
      });
  }

  close() {
    this.isOpen.set(false);
  }
}

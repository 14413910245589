/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, signal, Signal } from '@angular/core';
import { Router } from '@angular/router';
import { DateFormatterService, DateUtilService } from '@ui/shared/util';
import { EventLifecycleModalComponent } from '../event-lifecycle-modal/event-lifecycle-modal.component';
import { TranslatePipe } from '@ngx-translate/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { FlexComponent, GridComponent, GridItemComponent, SpacerComponent } from '@ui/shared/ui';
import { OverviewEventResponseModel } from '../../models/overview.model';
import { EventOverviewDeleteEventModalComponent } from '../event-overview-delete-event-modal/event-overview-delete-event-modal.component';
import { AuthoritiesFacade, RememberEventInOverviewListService } from '@admin/shared';

@Component({
  selector: 'mycontent-event-overview-row',
  templateUrl: './event-overview-row.component.html',
  styleUrls: ['./event-overview-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    GridComponent,
    GridItemComponent,
    FlexComponent,
    PorscheDesignSystemModule,
    EventLifecycleModalComponent,
    TranslatePipe,
    EventOverviewDeleteEventModalComponent,
    SpacerComponent
  ]
})
export class EventOverviewRowComponent implements OnInit {
  country = input<string>();
  event = input<OverviewEventResponseModel>();
  currentPageIndex = input<number>(1);
  currentTabIndex = input<number>(0);

  isAdmin = signal<boolean>(false);

  isCountryUS: Signal<boolean> = computed(() => this.country()?.toUpperCase() === 'US');
  isConfigurationRequired: Signal<boolean> = computed(() => this.event()?.isConfigurationMissing);
  isCapEvent: Signal<boolean> = computed(() => this.event()?.managementType === 'CAP');
  isOpenRegistration: Signal<boolean> = computed(() => this.event()?.isOpenRegistration);

  isEventLifecycleModalOpen = signal<boolean>(false);
  isDeleteEventModalOpen = signal<boolean>(false);

  private readonly router = inject(Router);
  private readonly dateFormatterService = inject(DateFormatterService);
  private readonly authoritiesService = inject(AuthoritiesFacade);
  private readonly rememberEventInOverviewListService = inject(RememberEventInOverviewListService);
  private dateUtilService: DateUtilService = inject(DateUtilService);

  ngOnInit() {
    this.isAdmin.set(this.authoritiesService.isAdmin());
  }

  getEventFullFormat(event: OverviewEventResponseModel): string {
    return this.dateFormatterService.formatFullEventRange(event?.startDate, event?.endDate);
  }

  redirectToEvent(): void {
    if (this.event()?.managementType === null) {
      this.openEventLifecycleModal();
    } else {
      this.rememberEventInOverviewListService.setEventId(this.event()?.id);
      this.rememberEventInOverviewListService.setPageNumber(this.currentPageIndex());
      this.rememberEventInOverviewListService.setTabNumber(this.currentTabIndex());
      this.router.navigate([`/${this.country()}/events/${this.event().id}`]);
    }
  }

  openEventLifecycleModal(): void {
    this.isEventLifecycleModalOpen.set(true);
  }

  openDeleteEventModal(event): void {
    event.stopPropagation();
    this.isDeleteEventModalOpen.set(true);
  }


  displayHostnames(): string {
    return this.event()?.hostNames.toString().replace(',', ', ');
  }

  getTimezone(dateString: string): string {
    return this.dateUtilService.getTimezoneFromDateStringOrNull(dateString);
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, map, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TimeslotService } from '../services/endpoints/timeslot.service';
import { TimeslotsDtoModel } from '../models/timeslot.model';
import { TimeslotModel } from '@ui/shared/feature-registration';

@Injectable({
  providedIn: 'root',
})
export class TimeslotFacade {
  private timeslotService = inject(TimeslotService);

  private timeslotLoadingSubject = new BehaviorSubject<boolean>(false);
  private timeslotErrorSubject = new BehaviorSubject<unknown>(null);
  private timeslotSuccessSubject = new BehaviorSubject<TimeslotModel[]>(null);

  /* eslint-disable @typescript-eslint/member-ordering */
  readonly timeslotsLoading$ = this.timeslotLoadingSubject.asObservable();
  readonly timeslotsSuccess$ = this.timeslotSuccessSubject.asObservable();

  getTimeslots(country: string, eventId: string) {
    this.timeslotLoadingSubject.next(true);
    this.timeslotErrorSubject.next(null);
    return this.timeslotService.getTimeslotsForEvent(country, eventId)
      .pipe(
        map((timeslotDtoModel: TimeslotsDtoModel) => timeslotDtoModel.items),
        catchError(error => {
          this.timeslotErrorSubject.next(error);
          this.timeslotLoadingSubject.next(false);
          return EMPTY;
        }),
        tap(timeslots => {
          this.timeslotSuccessSubject.next(timeslots);
          this.timeslotLoadingSubject.next(false);
        }),
      ).subscribe();
  }

  reset() {
    this.timeslotSuccessSubject.next(undefined);
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */


export interface TimeslotModel {
  id?: string;
  startDate?: string;
  endDate?: string;
  capacity?: number;
  maxCountOfAttendees?: number;
  registeredTicketCount?: number;
  checkedInTicketCount?: number;
  isRegistrationClosed?: boolean;
  availableTicketCount?: number;
}

export interface RegistrationTimeslotModel extends TimeslotModel {
  startDateOnly?: string;
  startTimeOnly?: string;
  startTimeNoTimezone?: string;
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, Input, output, signal } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DateFormatterService } from '@ui/shared/util';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { EventManagementTimeslotDeleteModalComponent } from '../event-management-timeslot-delete-modal/event-management-timeslot-delete-modal.component';
import { EventManagementTimeslotEditModalComponent } from '../event-management-timeslot-edit-modal/event-management-timeslot-edit-modal.component';
import { BannerNotificationService, FlexComponent } from '@ui/shared/ui';
import { EventManagementTimeslotRowComponent } from '../event-management-timeslot-row/event-management-timeslot-row.component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { EventManagementFacade, EventManagementModel } from '@admin/shared';
import { GroupedTimeslotsModel } from '../../../models/timeslot.model';
import { TimeslotService } from '../../../services/endpoints/timeslot.service';
import { TimeslotModel } from '@ui/shared/feature-registration';

@UntilDestroy()
@Component({
  selector: 'mycontent-event-management-timeslot',
  templateUrl: './event-management-timeslot.component.html',
  styleUrls: ['./event-management-timeslot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    EventManagementTimeslotRowComponent,
    FlexComponent,
    EventManagementTimeslotEditModalComponent,
    EventManagementTimeslotDeleteModalComponent,
    TranslatePipe,
    ReactiveFormsModule,
    AsyncPipe
  ],
  providers: [
    EventManagementFacade
  ]
})
export class EventManagementTimeslotComponent {
  eventId = input<string>();
  country = input<string>();
  eventDetails = input<EventManagementModel>();
  timeslotsLoading = input<boolean>();

  refreshTimeslots = output();
  refreshEventDetails = output();

  isCreateTimeslotModalOpen = false;
  isDeleteTimeslotModalOpen = false;
  selectedTimeslot: TimeslotModel = {};
  isEditModal: boolean;

  groupedTimeslots = signal<GroupedTimeslotsModel[]>([]);

  private timeslotService = inject(TimeslotService);
  private dateFormatterService = inject(DateFormatterService);
  private bannerNotificationService = inject(BannerNotificationService);
  private translateService = inject(TranslateService);

  @Input() set timeslots(timeslots: TimeslotModel[]) {
    if (timeslots?.length > 0) {
      this.groupTimeslotsByDate(timeslots);
    }
  }

  formatDate(dateString: string): string {
    return this.dateFormatterService.formatDateNoTime(dateString);
  }

  groupTimeslotsByDate(timeslots: TimeslotModel[]): void {
    timeslots.sort((tsA, tsB) => new Date(tsA.startDate).getTime() - new Date(tsB.startDate).getTime());
    const groupedLists: GroupedTimeslotsModel[] = [];

    for (const timeslot of timeslots) {
      let formattedDate = timeslot.startDate.slice(0, 10);
      formattedDate += `T00:00:00Z`;

      const groupedTimeslotsIndex = groupedLists.findIndex(grouped => grouped.date === formattedDate);

      if (groupedTimeslotsIndex === -1) {
        groupedLists.push({
          date: formattedDate,
          timeslots: [timeslot]
        });
      } else {
        groupedLists[groupedTimeslotsIndex].timeslots.push(timeslot);
      }
    }

    this.groupedTimeslots.set(groupedLists);
  }

  openTimeslotModalForCreation() {
    this.isEditModal = false;
    this.selectedTimeslot = null;
    this.isCreateTimeslotModalOpen = true;
  }

  openTimeslotModalForEditing(selectedTimeslot: TimeslotModel) {
    this.isEditModal = true;
    this.selectedTimeslot = selectedTimeslot;
    this.isCreateTimeslotModalOpen = true;
  }

  openDeleteTimeslotModal(selectedTimeslot: TimeslotModel) {
    this.selectedTimeslot = selectedTimeslot;
    this.isDeleteTimeslotModalOpen = true;
  }

  deleteTimeslot(shouldDelete: boolean) {
    this.isDeleteTimeslotModalOpen = false;
    if (shouldDelete) {
      this.timeslotService.deleteTimeslotsById(this.country(), this.eventId(), this.selectedTimeslot.id)
        .subscribe(() => {
          this.refreshTimeslots.emit();
          this.refreshEventDetails.emit();
        });
    }
  }

  handleEditTimeslotModal(timeslot: TimeslotModel) {
    if (timeslot === null) {
      this.isCreateTimeslotModalOpen = false;
      this.selectedTimeslot = null;
      return;
    }
    if (timeslot.id) {
      this.timeslotService.updateTimeslotsById(this.country(), this.eventId(), timeslot).subscribe({
        next: () => {
          this.refreshTimeslots.emit();
          this.refreshEventDetails.emit();
          this.isCreateTimeslotModalOpen = false;
        },
        error: () => {
          this.bannerNotificationService.publishMessage({
            headline: this.translateService.instant('adminEventDetail.registerEvent.error.genericFailTitle'),
            message: this.translateService.instant('adminEventDetail.registerEvent.error.genericFailParagraph'),
            state: 'error'
          });
          this.isCreateTimeslotModalOpen = false;
        }
      });

      this.selectedTimeslot = null;
      return;
    }
    if (timeslot) {
      if (!this.timeslots || !this.timeslots.length) {
        this.timeslots = [];
      }
      this.timeslotService.createTimeslotsById(this.country(), this.eventId(), timeslot).subscribe({
        next: () => {
          this.refreshTimeslots.emit();
          this.refreshEventDetails.emit();
          this.isCreateTimeslotModalOpen = false;
        },
        error: () => {
          this.bannerNotificationService.publishMessage({
            headline: this.translateService.instant('adminEventDetail.registerEvent.error.genericFailTitle'),
            message: this.translateService.instant('adminEventDetail.registerEvent.error.genericFailParagraph'),
            state: 'error'
          });
          this.isCreateTimeslotModalOpen = false;
        }
      });
      this.selectedTimeslot = null;
    }
  }
}

<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<p-modal [open]="isOpen()" (dismiss)="onDismiss()">
  <p-heading slot="header" size="large" tag="h2">{{ 'adminEventDetail.dialog.title.download' | translate }}</p-heading>
  <p-text>{{ 'adminEventDetail.dialog.paragraph.downloadConfirm' | translate }}</p-text>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  <p-button-group class="footer">
    <p-button (click)="downloadCSV()">{{ 'confirm' |translate }}</p-button>
    <p-button [type]="'button'" [variant]="'secondary'" [icon]="'close'" (click)="closeDownloadCSV()">{{ 'buttonCancel' |translate }}</p-button>
  </p-button-group>
</p-modal>

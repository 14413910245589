/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { WINDOW } from './window/window.provider';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectorService {

  window: Window = inject(WINDOW);

  isMobileAppleDeviceExcludingIOS16(): boolean {
    const userAgent = this.window.navigator.userAgent;
    const mobileAppleDevice = /iPhone|iPad/i.test(userAgent);
    const isIOSVersion16 =  /iphone OS 16(_\d+)?/i.test(userAgent);
    return mobileAppleDevice && !isIOSVersion16;
  }

  isMobileAppleDevice(): boolean {
    const userAgent = this.window.navigator.userAgent;
    return /iPhone|iPad/i.test(userAgent);
  }

  isAppleDevice(): boolean {
    const userAgent = this.window.navigator.userAgent;
    return /iPhone|iPad|Macintosh/i.test(userAgent);
  }

  isSafariBrowser(): boolean {
    const userAgent = this.window.navigator.userAgent;
    return /Safari/.test(userAgent) && !/Chrome|CriOS|FxiOS|EdgiOS/.test(userAgent);
  }

  isMobileSafariOnIOS(): boolean {
    return this.isMobileAppleDevice() && this.isSafariBrowser();
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { FormControl } from '@angular/forms';

export interface EventManagementModel {
  id?: string;
  spaceId?: string;
  title?: string;
  isOpenRegistration?: boolean;
  isPublic?: boolean;
  startDate?: string;
  endDate?: string;
  zoneId?: string;
  formattedAddress?: string;
  invitationCount?: number;
  capacity?: number;
  registeredTicketCount?: number;
  checkedInTicketCount?: number;
  cancelledRegistrationCount?: number;
  managementType?: string;
  isConfigurationMissing: boolean;
  hostNames?: string[];
  hoursBeforeRegistrationCloses?: number;
  isRegistrationClosed?: boolean;
  bookingCancellationText?: string;
  modifiedAt?: string;
  createdAt?: string;
  isWaitingListEnabled?: boolean;
  waitingListCount?: number;
}

export interface PatchEventRequest {
  managementType?: string;
  hoursBeforeRegistrationCloses?: number;
  isOpenRegistration?: boolean;
  isPublic?: boolean;
  isWaitingListEnabled?: boolean;
}

export interface EventManagementSettingsFormModel {
  customRegistration: FormControl<number>;
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { ENVIRONMENT_ADMIN } from '@ui/shared/util';
import { TimeslotsDtoModel } from '../../models/timeslot.model';
import { TimeslotModel } from '@ui/shared/feature-registration';

@Injectable({
  providedIn: 'root'
})
export class TimeslotService {

  private ADMIN_PATH = 'admin';

  private environment = inject(ENVIRONMENT_ADMIN);
  private http = inject(HttpClient);
  private oAuthService = inject(OAuthService);

  deleteTimeslotsById(country: string, eventId: string, timeslotId: string): Observable<void> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/timeslots/${timeslotId}`;
    const headers = this.getAuthHeader();
    return this.http.delete<void>(url, {headers});
  }

  getTimeslotsForEvent(country: string, eventId: string): Observable<TimeslotsDtoModel> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/timeslots`;

    const headers = this.getAuthHeader();
    const params = new HttpParams();

    return this.http.get<TimeslotsDtoModel>(url, {headers, params});
  }

  updateTimeslotsById(country: string, eventId: string, timeslot: TimeslotModel): Observable<TimeslotModel> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/timeslots/${timeslot.id}`;
    const body = {
      capacity: timeslot.capacity,
      maxCountOfAttendees: timeslot.maxCountOfAttendees,
      startDate: timeslot.startDate,
      endDate: timeslot.endDate,
    };
    const headers = this.getAuthHeader();
    return this.http.put<TimeslotModel>(url, body, {headers});
  }

  createTimeslotsById(country: string, eventId: string, timeslot: TimeslotModel): Observable<TimeslotModel> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/timeslots`;
    const body = {
      capacity: timeslot.capacity,
      maxCountOfAttendees: timeslot.maxCountOfAttendees,
      startDate: timeslot.startDate,
      endDate: timeslot.endDate,
    };
    const headers = this.getAuthHeader();
    return this.http.post<TimeslotModel>(url, body, {headers});
  }

  private getAuthHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${  this.oAuthService.getIdToken()}`);
  }
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export interface OneGAComponentClick {
  clickElementType: string;
  clickElementName: string;
  clickElementId: string;
  targetUrl?: string;
  disableSnakeCase?: boolean;
}

export interface OneGADataLayer {
  context: OneGADataLayerContext;
  visitor: OneGADataLayerVisitor;
  pageExperience: OneGADataLayerPageExperience;
  impressions: OneGADataLayerImpressions[];
  event: string;
  pushedToGTM: boolean;
}

export interface OneGADataLayerContext {
  applicationId: string;
  country: string;
  environment: string;
  eventAction: string;
  language: string;
  server: string;
  timestamp: string;
}

export interface OneGADataLayerVisitor {
  loginStatus: boolean;
  details: string[];
  userType: string[];
  useragent: string;
}

export interface OneGADataLayerPageExperience {
  pageName: string;
  pageId: string;
}

export interface OneGADataLayerImpressions {
  category: string;
  productname: string;
}

<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->

@if (questionType() === 'radio') {
  <p-text>{{ question() }}</p-text>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  @for (choice of choices(); track choice; let indexChoices = $index; let last = $last) {
    @if (form.value === I_PREFER_NOT_TO_ANSWER) {
      <p-radio-button-wrapper [label]="choice" [hideLabel]="false">
        <input type="radio" [value]="choice" disabled/>
      </p-radio-button-wrapper>
    } @else {
      <p-radio-button-wrapper [label]="choice" [hideLabel]="false" [state]="getFormFieldErrorState()">
        <input
          type="radio"
          [attr.data-testid]="'registration-input-radio-' + index() + '-' + indexChoices"
          [formControl]="form"
          [name]="'answer-' + index()"
          [value]="choice"
        />
      </p-radio-button-wrapper>
    }
    <mycontent-spacer [size]="(isMobileView$ | async) ? 'medium' : 'small'"></mycontent-spacer>
  }
  <p-checkbox-wrapper [label]="'eventCheckout.label.checkbox.noAnswer' | translate" [state]="getFormFieldErrorState()">
    <input
      [type]="'checkbox'"
      [checked]="form.value === I_PREFER_NOT_TO_ANSWER"
      (click)="preferNotToAnswerClicked()"
    />
  </p-checkbox-wrapper>
  @if (getFormFieldErrorState() === 'error') {
    <mycontent-spacer [size]="'small'"></mycontent-spacer>
    <mycontent-flex [direction]="'row'">
      <p-icon [name]="'exclamation'" [color]="'notification-error'"></p-icon>
      <p-text [color]="'notification-error'">{{ 'event.checkout.form.requiredInfo' | translate }}</p-text>
    </mycontent-flex>
  }
}
@if (questionType() === 'textarea') {
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  <div class="manual-registration__mandatory-data">
    @if (form.value === I_PREFER_NOT_TO_ANSWER) {
      <p-textarea-wrapper [label]="question()" [hideLabel]="false">
          <textarea class="event-registration-form-textfield" disabled></textarea>
      </p-textarea-wrapper>
    } @else {
      <p-textarea-wrapper [label]="question()" [hideLabel]="false" [state]="getFormFieldErrorState()">
          <textarea
            class="event-registration-form-textfield"
            [attr.data-testid]="'registration-input-textarea-' + index()"
            [formControl]="form"
            rows="1"
          ></textarea>
      </p-textarea-wrapper>
    }
    <mycontent-spacer [size]="(isMobileView$ | async) ? 'medium' : 'small'"></mycontent-spacer>
    <p-checkbox-wrapper
      [label]="'eventCheckout.label.checkbox.noAnswer' | translate"
      [state]="getFormFieldErrorState()"
    >
      <input
        [type]="'checkbox'"
        [checked]="form.value === I_PREFER_NOT_TO_ANSWER"
        [name]="question()"
        (click)="preferNotToAnswerClicked()"
      />
    </p-checkbox-wrapper>
    @if (getFormFieldErrorState() === 'error') {
      <mycontent-spacer [size]="'small'"></mycontent-spacer>
      <mycontent-flex [direction]="'row'">
        <p-icon [name]="'exclamation'" [color]="'notification-error'"></p-icon>
        <p-text [color]="'notification-error'">{{ 'event.checkout.form.requiredInfo' | translate }}</p-text>
      </mycontent-flex>
    }
  </div>
}



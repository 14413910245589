/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export const UNDEFINED = 'UNDEFINED';
export const ADDED = 'ADDED';
export const INVITED = 'INVITED';
export const REGISTERED = 'REGISTERED';
export const CANCELED = 'CANCELED';
export const PORSCHE_ID_NOT_FOUND = 'PORSCHE_ID_NOT_FOUND';
export const CHECKED_IN = 'CHECKED_IN';
export const READY_FOR_INVITATION = 'READY_FOR_INVITATION';
export const ON_WAITING_LIST = 'ON_WAITING_LIST';

export const participantStateListWithoutAdded: string[] = [
  REGISTERED,
  CHECKED_IN,
  CANCELED,
  UNDEFINED,
  PORSCHE_ID_NOT_FOUND,
  INVITED,
  READY_FOR_INVITATION,
  ON_WAITING_LIST,
];

export type ParticipantStateType =
  | typeof UNDEFINED
  | typeof ADDED
  | typeof INVITED
  | typeof REGISTERED
  | typeof CANCELED
  | typeof PORSCHE_ID_NOT_FOUND
  | typeof CHECKED_IN
  | typeof READY_FOR_INVITATION
  | typeof ON_WAITING_LIST;

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { ParticipantTableComponent } from '../../participant/participant-table/participant-table.component';
import { EventManagementAttendeeInviteComponent } from '../event-management-attendee-invite/event-management-attendee-invite.component';
import { EventManagementTimeslotComponent } from '../event-management-timeslot/event-management-timeslot.component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { EventManagementModel } from '@admin/shared';
import { EventManagementSettingsComponent } from '../event-management-settings/event-management-settings.component';
import { SpacerComponent } from '@ui/shared/ui';
import { TimeslotModel } from '@ui/shared/feature-registration';

@Component({
  selector: 'mycontent-event-management-table',
  templateUrl: './event-management-table.component.html',
  styleUrls: ['./event-management-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    EventManagementTimeslotComponent,
    EventManagementAttendeeInviteComponent,
    ParticipantTableComponent,
    TranslatePipe,
    EventManagementSettingsComponent,
    SpacerComponent
  ]
})
export class EventManagementTableComponent {
  timeslots = input<TimeslotModel[]>();
  country = input<string>();
  eventId = input<string>();
  isMarket = input<boolean>();
  isAdmin = input<boolean>();
  isDealer = input<boolean>();
  eventDetails = input<EventManagementModel>();
  isParticipantsLoading = input<boolean>();
  participantsError = input<unknown>();
  selectedInvitationStates = input<string[]>();
  timeslotsLoading = input<boolean>();

  refreshTimeslots = output();
  refreshEventDetails = output();
}

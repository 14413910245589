/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export const environment = {
  oidc: {
    authority: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_oGBM9GWEr',
    cognito: 'https://event-booking-pp.auth.eu-central-1.amazoncognito.com',
    client_id: '3rol6rnqlv336o3smto4od6vh2',
    url: 'https://admin.events.porsche-preview.com/',
    logoutUrl: 'https://ppnlite.porsche.com/ppnmda/linklist',
  },
  backend: {
    url: 'https://event-booking.content.aws.porsche-preview.cloud/event-booking',
  },
  fossUrl: 'https://events.porsche.com/license.txt',
  stage: 'dev',
};

<!--
 ~ This code is protected by intellectual property rights.
 ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 -->

<p-modal [open]="isOpen()" (dismiss)="onDismiss()">
  <p-heading slot="header" [size]="'large'" tag="h2">{{ 'adminEventDetail.dialog.title.deleteInvitee' | translate }}</p-heading>
  <div class="delete-invitee">
    <mycontent-flex [direction]="'row'" [justifyContent]="'flex-start'">
      <p-text>{{ 'adminEventDetail.dialog.paragraph.deleteInvitee' | translate }}</p-text>
    </mycontent-flex>
    <mycontent-spacer [size]="'medium'"></mycontent-spacer>
    <mycontent-flex [direction]="'row'" [justifyContent]="'flex-start'">
      <p-button-group>
        <p-button
          [attr.data-testid]="'delete-invitee-modal-accept-btn'"
          [icon]="'check'"
          [loading]="loading()"
          (click)="submit()"
        >
          {{ 'confirm' | translate }}
        </p-button>
        <p-button
          [attr.data-testid]="'delete-invitee-modal-cancel-btn'"
          [icon]="'close'"
          [type]="'button'"
          [variant]="'secondary'"
          (click)="close()"
        >
          {{ 'buttonCancel' | translate }}
        </p-button>
      </p-button-group>
    </mycontent-flex>
  </div>
</p-modal>

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export class CookieConsentConfig {
  enable?: boolean;
}

export const COOKIE_CONSENT_DEFAULT_CONFIG: CookieConsentConfig = {
  enable: false,
};

<!--
~  This code is protected by intellectual property rights.
~  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<section class="spacing-top-page-block">
  <ng-container *mycontentGhostText="!content(); length: 'fill'">
    <p-text [innerHTML]="sanitizeContent()"></p-text>
  </ng-container>
</section>

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export interface OverviewTrendsModel {
  invitationsSentCount: number;
  ticketsCreatedCount: number;
  checkInCount: number;
  cancellationCount: number;
}

export interface OverviewEventsResponseModel {
  items: OverviewEventResponseModel[];
}

export interface OverviewEventResponseModel {
  id: string;
  title: string;
  capacity?: number;
  hostNames?: string[];
  ticketCount?: number;
  cancellationCount?: number;
  startDate: string;
  endDate: string;
  timeZone?: string;
  isOpenRegistration?: boolean;
  managementType?: string;
  isConfigurationMissing: boolean;
  isRegistrationClosed?: boolean;
}

export interface TrendsModel {
  country: string;
  dealerId: string;
  since: string;
}

export enum OverviewTrendsEnum {
  ALL_TIME = 'ALL_TIME',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_24_HOURS = 'LAST_24_HOURS',
}

<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<form [formGroup]="form">
  <mat-select
    class="participants-table__header-selector mat-select-class table-header"
    multiple
    [attr.data-testid]="'filter-status'"
    [ngClass]="{'arrow-up': showStatusSelect}"
    [placeholder]="'adminEventDetail.attendance.table.header.status' | translate"
    [formControlName]="'invitationStatus'"
    (openedChange)="statusFilterDropdownChanged($event)"
  >
    <mat-select-trigger>{{ 'adminEventDetail.attendance.table.header.status' | translate }}</mat-select-trigger>
    <mat-option
      #allStatusSelected
      class="mat-select-option__select-all"
      [attr.data-testid]="'filter-status-selectAll'"
      [value]="defaultOption"
      (click)="toggleAllStatusSelection()"
    >
      {{ 'adminEventDetail.attendance.table.statusLabel.ALL_STATUSES' | translate }}
    </mat-option>
    @for (option of invitationStates; track option; let i = $index) {
      <mat-option
        class="mat-select-option__option"
        [attr.data-testid]="'filter-status-group-selectOption-' + i"
        [value]="option"
        (click)="toggleFilterStatusPerOne()"
      >
        <p-tag [color]="getInvitationStateColour(option)">
          {{ 'adminEventDetail.attendance.table.statusLabel.' + option | translate }}
        </p-tag>
      </mat-option>
    }
  </mat-select>
</form>

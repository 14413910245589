/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, output, OutputEmitterRef } from '@angular/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslatePipe } from '@ngx-translate/core';
import { RegistrationProfileModel, RegistrationUserProfileFormModel, RegistrationUserProfileModel } from '../../../models/manual-registration.model';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DateFormatterService } from '@ui/shared/util';
import { SpacerComponent } from '@ui/shared/ui';

@UntilDestroy()
@Component({
  selector: 'mycontent-manual-registration-flyout-profile',
  templateUrl: './manual-registration-flyout-profile.component.html',
  styleUrls: ['./manual-registration-flyout-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    TranslatePipe,
    ReactiveFormsModule,
    SpacerComponent
  ]
})
export class ManualRegistrationFlyoutProfileComponent implements OnInit {
  profile = input<RegistrationProfileModel>();
  isAddressRequired = input<boolean>();
  isBirthdayRequired = input<boolean>();
  isPhoneRequired = input<boolean>();

  profileSelection: OutputEmitterRef<RegistrationUserProfileModel> = output<RegistrationUserProfileModel>();

  displayBirthday = computed(() => this.dateFormatterService.formatBirthdayDateFromProfileFormat(this.profile()?.birthday));

  profileForm: FormGroup<RegistrationUserProfileFormModel>;

  private dateFormatterService = inject(DateFormatterService);

  ngOnInit() {
    const addresses = this.profile()?.addresses || [];
    const phones = this.profile()?.phones || [];
    this.profileForm = new FormGroup({
      birthday: new FormControl(this.isBirthdayRequired() ? this.profile()?.birthday : null),
      address: new FormControl(this.isAddressRequired() ? addresses[0]?.addressId : null),
      phone: new FormControl(this.isPhoneRequired() ? phones[0]?.phoneId : null)
    });
    this.profileSelection.emit(this.profileForm.getRawValue());

    this.profileForm.valueChanges.subscribe(() => {
      this.profileSelection.emit(this.profileForm.getRawValue());
    });
  }
}

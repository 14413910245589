/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

// Country code and default locale mapping for markets with bookable events.
export class Country {
  public static readonly languages = [
    { code: 'de', locale: 'de_DE' },
    { code: 'es', locale: 'es_ES' },
    { code: 'fr', locale: 'fr_FR' },
    { code: 'en', locale: 'en_GB' },
    { code: 'pt', locale: 'pt_PT' },
    { code: 'jp', locale: 'ja_JP' },
    { code: 'it', locale: 'it_IT' },
    { code: 'no', locale: 'no_NO' },
    { code: 'zh', locale: 'zh_TW' },
    { code: 'nl', locale: 'nl_NL' }
  ] as Country[];

  public static readonly registeredLocales = [
    'en-NZ',
    'en-AU',
    'en-SG',
    'en-GB',
    'en',
    'ja',
    'ko',
    'de-CH',
    'it',
    'ms',
    'zh',
    'nl',
    'no',
    'pt',
    'es',
    'de',
    'fr'
  ];

  public readonly code: string;

  private constructor(code: string, public readonly locale: string) {
    this.code = code.toLowerCase();
  }
}

export class CountryModel {
  country: string;
  locale: string;
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AdminTranslateLoaderService implements TranslateLoader {

  private url = window.location.host.includes('preview') ? 'https://translations.events.porsche-preview.com' : 'https://translations.events.porsche.com';

  constructor(private http: HttpClient) {}

  getTranslation(language: string): Observable<unknown> {
    if (!language) {
      return this.http.get(`${this.url}/event-booking-admin-translations/en_GB.json`);
    } else {
      return this.http.get(`${this.url}/event-booking-admin-translations/${language}.json`);
    }
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, Input, ViewEncapsulation } from '@angular/core';
import { getConfigProvider, NGX_GHOSTS_CONFIGURATION, NgxGhostsConfiguration } from '../ngx-ghosts-configuration';
import { DOCUMENT } from '@angular/common';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'mycontent-ghost',
  templateUrl: './ghost.component.html',
  styleUrls: ['./ghost.component.scss'],
  standalone: true,
  imports: [],
  providers: [getConfigProvider()],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GhostComponent {
  @Input() fillHorizontal = false;
  @Input() fillVertical = false;
  @Input() height: number;
  @Input() width: number;
  @Input() ghostClass: string;
  @Input() textGhost: boolean;

  private document: HTMLDocument;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(DOCUMENT) document: any,
    @Inject(NGX_GHOSTS_CONFIGURATION) private config: NgxGhostsConfiguration,
    private elementRef: ElementRef
  ) {
    this.document = document;
  }

  get glowLeft() {
    switch (this.config.animationStrategy) {
      case 'EqualStartAndEnd':
      case 'EqualStartAndSpeed':
        return '0px';
      case 'OneAnimation':
        // eslint-disable-next-line no-case-declarations
        const left = this.elementRef.nativeElement.getBoundingClientRect().left;
        return `-${left}px`;
      default:
        return '';
    }
  }

  get glowWidth() {
    switch (this.config.animationStrategy) {
      case 'EqualStartAndEnd':
        return '100%';
      case 'EqualStartAndSpeed':
      case 'OneAnimation':
        return `${this.document.body.clientWidth}px`;
      default:
        return '';
    }
  }

  get glowHidden() {
    return this.config.animationStrategy === 'None';
  }

  @HostListener('window:resize', [])
  onWindowResize() {}
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { InjectionToken, inject } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { puiBreakpoints, PuiBreakpoint } from './breakpoint';

export const puiBreakpointFactory = (breakpointObserver: BreakpointObserver) => {
  const mediaQueries = puiBreakpoints.map(breakpoint => `(min-width: ${breakpoint.minSize}px)`);
  return breakpointObserver.observe(mediaQueries).pipe(
    map(result => {
      if (!result.matches) {
        return null;
      }
      const matches = puiBreakpoints.filter((breakpoint, index) => result.breakpoints[mediaQueries[index]]);
      return matches[matches.length - 1];
    })
  );
};

export const PUI_BREAKPOINT = new InjectionToken<Observable<PuiBreakpoint>>('BREAKPOINT (Active viewport breakpoint)', {
  providedIn: 'root',
  factory: () => puiBreakpointFactory(inject(BreakpointObserver)),
});

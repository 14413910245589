/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, ElementRef, HostListener, inject, signal, ViewEncapsulation } from '@angular/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';

@Component({
  selector: 'mycontent-popover-hamburger-menu',
  templateUrl: './popover-hamburger-menu.component.html',
  styleUrls: ['./popover-hamburger-menu.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule
  ],
})
export class PopoverHamburgerMenuComponent {

  isMenuOpen = signal<boolean>(false);

  private eRef: ElementRef = inject(ElementRef);

  @HostListener('document:click', ['$event'])
  clickEvent(event: MouseEvent) {
    // clicked outside popover
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.isMenuOpen.set(false);
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if(event.code === 'Escape'){
      this.isMenuOpen.set(false);
    }
  }

  toggleMenu() {
    this.isMenuOpen.set(!this.isMenuOpen());
  }
}

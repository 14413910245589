/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from '@angular/core';
import { IconName, PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { FlexComponent, FlexItemComponent, SpacerComponent } from './../../index';
import { WINDOW } from '@ui/shared/util';

@Component({
  selector: 'mycontent-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    SpacerComponent,
    FlexComponent,
    FlexItemComponent,
  ]
})
export class InfoCardComponent {
  primaryIcon = input.required<IconName>();
  primaryText = input.required<string>();

  secondaryText = input<string>();
  secondaryIcon = input<IconName>('external');

  link = input<string>();
  isExternalLink = input<boolean>(false);

  isClickable: Signal<boolean> = computed(() => !!this.link());

  window = inject(WINDOW);

  redirect() {
    this.window.open(this.link(), '_blank');
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UiFormatSpecialCaseService {

  private datePipe = inject(DatePipe);
  private locale = inject(LOCALE_ID);

    getFormatForMediumDate(date: Date, timezone: string): string {
      if (this.locale === 'en_SG') {
        return this.datePipe.transform(date, 'fullDate', timezone);
      } else {
        return this.datePipe.transform(date, 'mediumDate', timezone);
      }
    }

    getFormatForShortTime(date: Date, timezone: string): string {
      if (this.locale === 'en_SG') {
        const timeFormat =  this.datePipe.transform(date, 'hh:mm a', timezone);
        if (timeFormat.includes('am')) {
          return timeFormat.replace('am', 'a.m.');
        } else {
          return timeFormat.replace('pm', 'p.m.');
        }
      } else {
        return this.datePipe.transform(date, 'shortTime', timezone);
      }
    }
}

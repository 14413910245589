<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<mycontent-flex [direction]="'row'" [justifyContent]="'space-between'">
  <mycontent-flex-item>
    <mycontent-flex [direction]="'row'" [justifyContent]="'space-between'">
      <mycontent-flex-item class="event-details-row-element-wrapper__start-time">
        <div>
          <p-text [weight]="'regular'">{{ 'adminEventDetail.timeslots.listItem.label.start' | translate }}</p-text>
          <ng-container *mycontentGhostText="timeslotsLoading(); length: 'fill';">
            <p-text [weight]="'bold'" id="{{'timeslot-start-date-' + timeslot()?.id}}">
              {{ displayStartTime() }}
            </p-text>
          </ng-container>
        </div>
      </mycontent-flex-item>
      <mycontent-flex-item class="event-details-row-element-wrapper__end-time">
        <div>
          <p-text>{{ 'adminEventDetail.timeslots.listItem.label.end' | translate }}</p-text>
          <ng-container *mycontentGhostText="timeslotsLoading(); length: 'fill';">
            <p-text [weight]="'bold'" id="{{'timeslot-end-date-' + timeslot()?.id}}">
              {{ displayEndTime() }}
            </p-text>
          </ng-container>
        </div>
      </mycontent-flex-item>
    </mycontent-flex>
  </mycontent-flex-item>
  <mycontent-flex-item>
    <mycontent-flex [direction]="'row'" [justifyContent]="'space-between'">
      <mycontent-flex-item class="event-details-row-element-wrapper">
        <div>
          <p-text>
            {{ 'adminEventDetail.timeslots.listItem.label.ticketNr' | translate }}
          </p-text>
          <ng-container *mycontentGhostText="timeslotsLoading(); length: 'fill';">
            <p-text [weight]="'bold'" id="{{'timeslot-max-attendees-' + timeslot()?.id}}">
              {{ timeslot()?.maxCountOfAttendees }}
            </p-text>
          </ng-container>
        </div>
      </mycontent-flex-item>
      <mycontent-flex-item class="event-details-row-element-wrapper">
        <p-tag [color]="'background-surface'" [class]="'event-details-row-element-wrapper__tag'">
          <div class="event-detail-row-tag-wrapper">
            <p-text>
              {{ 'adminEventDetail.timeslots.listItem.label.capacity' | translate }}
            </p-text>
            <ng-container *mycontentGhostText="timeslotsLoading(); length: 'fill';">
              <p-text [weight]="'bold'" id="{{'timeslot-capacity-' + timeslot()?.id}}">
                {{ timeslot()?.capacity }}
              </p-text>
            </ng-container>
          </div>
        </p-tag>
      </mycontent-flex-item>
      <mycontent-flex-item class="event-details-row-element-wrapper">
        <p-tag [color]="'notification-info-soft'" [class]="'event-details-row-element-wrapper__tag'">
          <div class="event-detail-row-tag-wrapper">
            <p-text>
              {{ 'adminEventDetail.timeslots.listItem.label.tickets' | translate }}
            </p-text>
            <ng-container *mycontentGhostText="timeslotsLoading(); length: 'fill';">
              <p-text [weight]="'bold'" id="{{'timeslot-current-count-attendees-' + timeslot()?.id}}">
                {{ timeslot()?.registeredTicketCount }}
              </p-text>
            </ng-container>
          </div>
        </p-tag>
      </mycontent-flex-item>
      <mycontent-flex-item class="event-details-row-element-wrapper">
        @if (timeslot()?.isRegistrationClosed) {
          <p-tag class="event-details-row-element-wrapper__tag" [attr.data-testid]="'timeslot-row-tag-registration-closed'" [color]="'notification-error-soft'">
            <div class="event-detail-row-tag-wrapper">
              <p-text>{{ 'adminEventDetail.timeslots.listItem.label.registration' | translate }}</p-text>
              <p-text [weight]="'bold'" [attr.data-testid]="'timeslot-registration-closed'">
                {{ 'adminEventDetail.timeslots.listItem.label.closed' | translate }}
              </p-text>
            </div>
          </p-tag>
        } @else {
          <p-tag class="event-details-row-element-wrapper__tag" [attr.data-testid]="'timeslot-row-tag-registration-open'" [color]="'notification-success-soft'">
            <div class="event-detail-row-tag-wrapper">
              <p-text>{{ 'adminEventDetail.timeslots.listItem.label.registration' | translate }}</p-text>
              <p-text [weight]="'bold'" [attr.data-testid]="'timeslot-registration-open'">
                {{ 'adminEventDetail.timeslots.listItem.label.timeBefore' | translate : { numberOfHours: hoursBeforeRegistrationCloses() } }}
              </p-text>
            </div>
          </p-tag>
        }
      </mycontent-flex-item>
      <mycontent-flex-item class="event-details-row-element-wrapper">
        <p-button
          id="{{'edit-timeslot-button-' + timeslot()?.id}}"
          class="edit-button"
          [variant]="'secondary'"
          [hideLabel]="true"
          [icon]="'edit'"
          (click)="openAddTimeslotModal(timeslot())">
        </p-button>
      </mycontent-flex-item>
      <mycontent-flex-item class="event-details-row-element-wrapper">
        <p-button
          id="{{'delete-timeslot-button-' + timeslot()?.id}}"
          [variant]="'secondary'"
          [hideLabel]="true"
          [icon]="'delete'"
          [disabled]="doesTimeslotHaveAttendees(timeslot())"
          (click)="openDeleteTimeslotModal(timeslot())">
        </p-button>
      </mycontent-flex-item>
    </mycontent-flex>
  </mycontent-flex-item>
</mycontent-flex>

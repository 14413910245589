/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, ElementRef, inject, input, model, OnInit, output, viewChild } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { DragDropFileDirective, FileHandle, FlexComponent, FlexItemComponent, SpacerComponent } from '@ui/shared/ui';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { LockEnum, LockStatusModel } from '../../../models/lock-status.model';
import { FileUploadModel } from '../../../models/file.model';
import { PollingService } from '../../../services/endpoints/polling.service';
import { CsvPreProcessingService } from '../../../services/csv-pre-processing.service';
import { FileFacade } from '../../../facades/file.facade';
import { ParticipantFacade } from '../../../facades/participant.facade';
import { PORSCHE_ID_NOT_FOUND, READY_FOR_INVITATION, UNDEFINED } from '../../../models/invitation-state.model';
import { GetPollingStatusPercentageComponent } from '../../get-polling-status-percentage/get-polling-status-percentage.component';
import { toSignal } from '@angular/core/rxjs-interop';

@UntilDestroy()
@Component({
  selector: 'mycontent-participant-import-csv-modal',
  templateUrl: './participant-import-csv-modal.component.html',
  styleUrls: ['./participant-import-csv-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    DragDropFileDirective,
    FlexComponent,
    FlexItemComponent,
    GetPollingStatusPercentageComponent,
    AsyncPipe,
    TranslatePipe,
    SpacerComponent
  ]
})
export class ParticipantImportCsvModalComponent implements OnInit {
  readonly PDS_THEME_LIGHT_BACKGROUND_SURFACE = '#eeeff2';

  eventId = input<string>();
  country = input<string>();

  isOpen = model<boolean>();

  inviteSuccessful = output<boolean>();

  fileDropArea = viewChild<ElementRef>('fileDropArea');
  fileInput = viewChild<ElementRef>('fileInput');

  uploadDataHead: string[];
  uploadDataAsText: string;
  isDataValid: boolean | null = null;
  fileToUpload: File;
  selectFileSuccess: boolean;
  selectFileErrorMessage: string;

  pollingStatus$: Observable<LockStatusModel>;
  fileUpload$: Observable<FileUploadModel>;

  private unsubscribePollingStatus$: Subject<void> = new Subject<void>();

  private translateService = inject(TranslateService);
  private fileFacade = inject(FileFacade);
  private pollingService = inject(PollingService);
  private participantFacade = inject(ParticipantFacade);
  private csvPreProcessingService = inject(CsvPreProcessingService);
  private toastManager = inject(ToastManager);

  /* eslint-disable @typescript-eslint/member-ordering */
  fileValidate = toSignal(this.fileFacade.validate$);
  fileName = toSignal(this.fileFacade.fileName$);

  ngOnInit(): void {
    this.pollingService.startPolling(this.country(), this.eventId());
    this.pollingStatus$ = this.pollingService.pollingStatus$;
    this.fileUpload$ = this.fileFacade.upload$;
  }

  closeImportCSV(): void {
    this.pollingService.stopPolling();
    this.isOpen.set(false);
  }

  onDragAndDropFile(files: FileHandle[]): void {
    this.isDataValid = null;
    if (files.length > 0) {
      this.onFileInput(files[0]);
    }
  }

  onSelectFile(event: Event): void {
    this.isDataValid = null;
    if ((event.target as HTMLInputElement).files.length > 0) {
      const file = (event.target as HTMLInputElement).files[0];
      this.onFileInput({ file, name: file.name });
    }
  }

  async onFileInput(fileHandle: FileHandle) {
    this.isDataValid = false;
    if (fileHandle.name.toLowerCase().endsWith('.csv')) {
      this.fileToUpload = fileHandle.file;
      this.isDataValid = true;

      try {
        // try reading csv header information for display in error case
        this.uploadDataAsText = await this.fileToUpload.text();
        this.uploadDataAsText = this.csvPreProcessingService.normalize(this.uploadDataAsText);
        this.uploadDataHead = this.csvPreProcessingService.getHeaders(this.uploadDataAsText);
        // moved that from line 97 (before closing else) to only validate if file is read successfully
        // because we pass the file content instead of the file itself to the validate/upload methods
        this.selectFileSuccess = true;
        this.validateData(fileHandle.name);
      } catch (e) {
        console.error('error parsing for csv header', e);
        this.selectFileSuccess = false;
      }
    } else {
      this.selectFileErrorMessage = this.translateService.instant('details.msgFileUploadValidationError');
      this.selectFileSuccess = false;
    }
  }

  public resetSteps() {
    this.fileInput().nativeElement.value = '';
    this.fileFacade.resetValues();
  }

  validateData(fileName: string) {
    const formData = this.getDataToUpload();
    this.fileFacade.validateFile(formData, this.country(), this.eventId(), fileName);
  }

  uploadData() {
    const formData = this.getDataToUpload();
    this.fileFacade.uploadFile(formData, this.country(), this.eventId());
    this.pollUntilFinished();
  }

  pollUntilFinished(): void {
    this.pollingStatus$.pipe(
      takeUntil(this.unsubscribePollingStatus$)
    ).subscribe(lockStatus => {
      if (lockStatus.status === LockEnum.FINISHED_OK) {
        const filters = this.participantFacade.getParticipantFilters();
        this.participantFacade.getParticipants({
          ...filters, invitationStates: [READY_FOR_INVITATION, UNDEFINED, PORSCHE_ID_NOT_FOUND]
        });
        this.unsubscribePollingStatus$.next();
        this.resetSteps();
        this.closeImportCSV();
        this.toastManager.addMessage({
          text: this.translateService.instant('adminEventDetail.toast.csvUploadSuccessful'),
          state: 'success'
        });
      }
      if (lockStatus.status === LockEnum.FINISHED_ERROR) {
        // TODO: add logic for unsuccessful lock
        this.unsubscribePollingStatus$.next();
      }
    });
  }

  private getDataToUpload(): FormData {
    const formData = new FormData();
    formData.append('file', new Blob([this.uploadDataAsText], { type: 'text/csv' }), this.fileToUpload.name);
    return formData;
  }
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { InjectionToken } from '@angular/core';

export const DO_NOT_TRACK = new InjectionToken<boolean>('DoNotTrack', {
  providedIn: 'root',
  factory: () => navigator.doNotTrack === '1',
});

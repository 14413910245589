<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<form [formGroup]="form">
  <p-text-field-wrapper class="participants-table__name-search" [attr.data-testid]="'participant-text-filter'" [label]="'Search'" [hideLabel]="true">
    <input
      [formControlName]="'filter'"
      [attr.data-testid]="'participant-filter-byNameAndEmail'"
      [type]="'search'"
      [placeholder]="'adminEventDetail.participant.textField.placeholder.search' | translate"
    />
  </p-text-field-wrapper>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  <mycontent-grid class="participants-table__header" [attr.data-testid]="'participant-table'" [direction]="'row'">
    <mycontent-grid-item [size]="3">
      <p-text [weight]="'bold'">{{ 'adminEventDetail.attendance.table.header.name' | translate }}</p-text>
    </mycontent-grid-item>
    <mycontent-grid-item [size]="3">
      <mycontent-participant-status-filter />
    </mycontent-grid-item>
    <mycontent-grid-item [size]="2">
      <mycontent-participant-timeslot-filter [timeslots]="timeslots()" />
    </mycontent-grid-item>
    <mycontent-grid-item [size]="4">
    </mycontent-grid-item>
  </mycontent-grid>
  <mycontent-participant-action-participant-banner></mycontent-participant-action-participant-banner>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  @if (isParticipantsLoading()) {
    <mycontent-flex [direction]="'row'" [justifyContent]="'space-around'" class="participants-table__loader">
      <mycontent-flex-item class="participants-table__loader">
        <p-spinner [attr.data-testid]="'participant-loading-spinner'" [size]="'small'" aria-label="Loading participants list"></p-spinner>
        <p-text class="participants-table__loader-text" [size]="'medium'">{{ 'details.msgLoadingParticipantList' | translate }}</p-text>
      </mycontent-flex-item>
    </mycontent-flex>
    <mycontent-spacer [size]="'small'"></mycontent-spacer>
  }

  @for (participant of paginatedParticipants().list; track participant.attendee.email) {
    <mycontent-participant-row
      [attr.data-testid]="'participant-row-email-'+ participant?.attendee?.email"
      [participant]="participant"
      [eventId]="eventId()"
      [country]="country()"
      [bookingCancellationText]="eventDetails()?.bookingCancellationText"
      [timezone]="eventDetails()?.zoneId"
      [waitingListCount]="eventDetails()?.waitingListCount"
      (refreshEventManagementModel)="refreshEventManagementModel()"
      (refreshParticipantsModel)="updateParticipants()"
    ></mycontent-participant-row>
    <mycontent-spacer [size]="'small'"></mycontent-spacer>
  }
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  <p-pagination
    [totalItemsCount]="paginatedParticipants().totalItemsCount"
    [itemsPerPage]="paginatedParticipants().itemsPerPage"
    [activePage]="paginatedParticipants().activePage"
    (update)="switchPageParticipants($event)"
  ></p-pagination>
  <mycontent-spacer [size]="'xx-large'"></mycontent-spacer>
</form>

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Provider } from '@angular/core';
import { LOCALE } from '../locale-token';
import { Country } from '../models/country';
import { Language } from '../models/language';
import { Locale } from '../models/locale';
import { countries } from '../resources/countries';
import { languages } from '../resources/languages';
import { timeZones } from '../resources/time-zones';
import { buildLocale } from '../utils/build-locale';
import { parseLocaleCode } from '../utils/parse-locale-code';
import { provideLocaleDataInitializer } from './provide-locale-data-initializer';
import { provideLocaleId } from './provide-locale-id';
import { WINDOW } from '@ui/shared/util';
import { provideProfileLocale } from './provide-profile-locale';

export function localeFactory(window: Window): Locale {
  const routeMatches = window.location.pathname.match(/\/(\w{2})(?:\/(\w{2}[-_]\w{2})){0,1}(?:\/|$)/);
  const routeCountryCode = routeMatches?.length > 1 ? routeMatches[1].toLocaleLowerCase() : null;
  const routeLanguageCode = routeMatches?.length > 2 ? normalizeLanguageCode(routeMatches[2]) : null;

  let country: Country;
  if (routeCountryCode) {
    country = getCountry(routeCountryCode);
  }
  if (!country) {
    country = getDefaultCountry();
  }

  let language: Language;
  if (routeLanguageCode) {
    language = country.languages.find(it => it.code === routeLanguageCode);
  }
  if (!language) {
    language = getDefaultLanguage(country, window);
  }
  return buildLocale(country, language);
}

function normalizeLanguageCode(languageCode: string): string {
  if (languageCode?.length === 2) {
    return languages.find(it => it.code.startsWith(languageCode))?.code || null;
  }
  if (languageCode?.length === 5) {
    const parsed = parseLocaleCode(languageCode);
    return parsed ? `${parsed.languageCodeShort}_${parsed.countryCode.toUpperCase()}` : null;
  }
  return null;
}

function getCountry(countryCode: string) {
  return countries.find(it => it.code === countryCode);
}

function getDefaultCountry(): Country {
  const isChinaOrigin = window.location.origin.endsWith('.cn');

  if (isChinaOrigin) {
    return getCountry('cn');
  }

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const country = getCountry(timeZones[timeZone]);
  if (!country) {
    console.error(`Country not found for time zone: ${timeZone}`);
    return getCountry('gb');
  }
  return country;
}

function getDefaultLanguage(country: Country, window: Window): Language {
  const languageCodes = window.navigator.languages.map(it => normalizeLanguageCode(it));
  const language = languageCodes
    .map(languageCode => country.languages.find(it => it.code === languageCode))
    .find(it => !!it);
  return language || country.defaultLanguage;
}

export function provideLocale(): Provider[] {
  return [
    {
      provide: LOCALE,
      useFactory: localeFactory,
      deps: [WINDOW],
    },
    provideLocaleId(),
    provideLocaleDataInitializer(),
    provideProfileLocale(),
  ];
}

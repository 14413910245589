/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Language } from '../models/language';

export const deDE: Language = {
  code: 'de_DE',
  names: {
    cs_CZ: 'němčina',
    da_DK: 'tysk',
    de_DE: 'Deutsch',
    en_GB: 'German',
    en_US: 'German',
    es_ES: 'alemán',
    et_EE: 'saksa',
    fi_FI: 'saksa',
    fr_FR: 'allemand',
    it_IT: 'tedesco',
    ja_JP: 'ドイツ語',
    lt_LT: 'vokiečių',
    lv_LV: 'vācu',
    nl_NL: 'Duits',
    no_NO: 'tysk',
    pl_PL: 'niemiecki',
    pt_PT: 'alemão',
    ru_RU: 'немецкий',
    sr_RS: 'nemački',
    sv_SE: 'tyska',
    tr_TR: 'Almanca',
    zh_CN: '德文',
    zh_TW: '德文',
    ko_KR: '독일어',
  },
};

export const enGB: Language = {
  code: 'en_GB',
  names: {
    cs_CZ: 'angličtina',
    da_DK: 'engelsk',
    de_DE: 'Englisch',
    en_GB: 'English',
    en_US: 'English',
    es_ES: 'inglés',
    et_EE: 'inglise',
    fi_FI: 'englanti',
    fr_FR: 'anglais',
    it_IT: 'inglese',
    ja_JP: '英語',
    lt_LT: 'anglų',
    lv_LV: 'angļu',
    nl_NL: 'Engels',
    no_NO: 'engelsk',
    pl_PL: 'angielski',
    pt_PT: 'inglês',
    ru_RU: 'английский',
    sr_RS: 'engleski',
    sv_SE: 'engelska',
    tr_TR: 'İngilizce',
    zh_CN: '英文',
    zh_TW: '英文',
    ko_KR: '영어',
  },
};

export const enUS: Language = {
  code: 'en_US',
  names: {
    cs_CZ: 'angličtina',
    da_DK: 'engelsk',
    de_DE: 'Englisch',
    en_GB: 'English',
    en_US: 'English',
    es_ES: 'inglés',
    et_EE: 'inglise',
    fi_FI: 'englanti',
    fr_FR: 'anglais',
    it_IT: 'inglese',
    ja_JP: '英語',
    lt_LT: 'anglų',
    lv_LV: 'angļu',
    nl_NL: 'Engels',
    no_NO: 'engelsk',
    pl_PL: 'angielski',
    pt_PT: 'inglês',
    ru_RU: 'английский',
    sr_RS: 'engleski',
    sv_SE: 'engelska',
    tr_TR: 'İngilizce',
    zh_CN: '英语',
    zh_TW: '英语',
    ko_KR: '영어',
  },
};

export const esES: Language = {
  code: 'es_ES',
  names: {
    cs_CZ: 'španělština',
    da_DK: 'spansk',
    de_DE: 'Spanisch',
    en_GB: 'Spanish',
    en_US: 'Spanish',
    es_ES: 'español',
    et_EE: 'hispaania',
    fi_FI: 'espanja',
    fr_FR: 'espagnol',
    it_IT: 'spagnolo',
    ja_JP: 'スペイン語',
    lt_LT: 'ispanų',
    lv_LV: 'spāņu',
    nl_NL: 'Spaans',
    no_NO: 'spansk',
    pl_PL: 'hiszpański',
    pt_PT: 'espanhol',
    ru_RU: 'испанский',
    sr_RS: 'španski',
    sv_SE: 'spanska',
    tr_TR: 'İspanyolca',
    zh_CN: '西班牙文',
    zh_TW: '西班牙文',
    ko_KR: '스페인어',
  },
};

export const frFR: Language = {
  code: 'fr_FR',
  names: {
    cs_CZ: 'francouzština',
    da_DK: 'fransk',
    de_DE: 'Französisch',
    en_GB: 'French',
    en_US: 'French',
    es_ES: 'francés',
    et_EE: 'prantsuse',
    fi_FI: 'ranska',
    fr_FR: 'français',
    it_IT: 'francese',
    ja_JP: 'フランス語',
    lt_LT: 'prancūzų',
    lv_LV: 'franču',
    nl_NL: 'Frans',
    no_NO: 'fransk',
    pl_PL: 'francuski',
    pt_PT: 'francês',
    ru_RU: 'французский',
    sr_RS: 'francuski',
    sv_SE: 'franska',
    tr_TR: 'Fransızca',
    zh_CN: '法文',
    zh_TW: '法文',
    ko_KR: '프랑스어',
  },
};

export const itIT: Language = {
  code: 'it_IT',
  names: {
    cs_CZ: 'italština',
    da_DK: 'italiensk',
    de_DE: 'Italienisch',
    en_GB: 'Italian',
    en_US: 'Italian',
    es_ES: 'italiano',
    et_EE: 'itaalia',
    fi_FI: 'italia',
    fr_FR: 'italien',
    it_IT: 'italiano',
    ja_JP: 'イタリア語',
    lt_LT: 'italų',
    lv_LV: 'itāļu',
    nl_NL: 'Italiaans',
    no_NO: 'italiensk',
    pl_PL: 'włoski',
    pt_PT: 'italiano',
    ru_RU: 'итальянский',
    sr_RS: 'italijan',
    sv_SE: 'italienska',
    tr_TR: 'İtalyanca',
    zh_CN: '意大利文',
    zh_TW: '意大利文',
    ko_KR: '이탈리아어',
  },
};

export const jaJP: Language = {
  code: 'ja_JP',
  names: {
    cs_CZ: 'japonština',
    da_DK: 'japansk',
    de_DE: 'Japanisch',
    en_GB: 'Japanese',
    en_US: 'Japanese',
    es_ES: 'japonés',
    et_EE: 'jaapani',
    fi_FI: 'japani',
    fr_FR: 'japonais',
    it_IT: 'giapponese',
    ja_JP: '日本語',
    lt_LT: 'japonų',
    lv_LV: 'japāņu',
    nl_NL: 'Japans',
    no_NO: 'japansk',
    pl_PL: 'japoński',
    pt_PT: 'japonês',
    ru_RU: 'японский',
    sr_RS: 'japanski',
    sv_SE: 'japanska',
    tr_TR: 'Japonca',
    zh_CN: '日文',
    zh_TW: '日文',
    ko_KR: '일본어',
  },
};

export const ptPT: Language = {
  code: 'pt_PT',
  names: {
    cs_CZ: 'portugalština',
    da_DK: 'portugisisk',
    de_DE: 'Portugiesisch',
    en_GB: 'Portuguese',
    en_US: 'Portuguese',
    es_ES: 'portugués',
    et_EE: 'portugali',
    fi_FI: 'portugali',
    fr_FR: 'portugais',
    it_IT: 'portoghese',
    ja_JP: 'ポルトガル語',
    lt_LT: 'portugalų',
    lv_LV: 'portugāļu',
    nl_NL: 'Portugees',
    no_NO: 'portugisisk',
    pl_PL: 'portugalski',
    pt_PT: 'português',
    ru_RU: 'португальский',
    sr_RS: 'portugalski',
    sv_SE: 'portugisiska',
    tr_TR: 'Portekizce',
    zh_CN: '葡萄牙文',
    zh_TW: '葡萄牙文',
    ko_KR: '포르투갈어',
  },
};

export const ruRU: Language = {
  code: 'ru_RU',
  names: {
    cs_CZ: 'ruština',
    da_DK: 'russisk',
    de_DE: 'Russisch',
    en_GB: 'Russian',
    en_US: 'Russian',
    es_ES: 'ruso',
    et_EE: 'vene',
    fi_FI: 'venäjä',
    fr_FR: 'russe',
    it_IT: 'russo',
    ja_JP: 'ロシア語',
    lt_LT: 'rusų',
    lv_LV: 'krievu',
    nl_NL: 'Russisch',
    no_NO: 'russisk',
    pl_PL: 'rosyjski',
    pt_PT: 'russo',
    ru_RU: 'русский',
    sr_RS: 'ruski',
    sv_SE: 'ryska',
    tr_TR: 'Rusça',
    zh_CN: '俄文',
    zh_TW: '俄文',
    ko_KR: '러시아어',
  },
};

export const csCZ: Language = {
  code: 'cs_CZ',
  names: {
    cs_CZ: 'čeština',
    da_DK: 'tjekkisk',
    de_DE: 'Tschechisch',
    en_GB: 'Czech',
    en_US: 'Czech',
    es_ES: 'checo',
    et_EE: 'tšehhi',
    fi_FI: 'tšekki',
    fr_FR: 'tchèque',
    it_IT: 'ceco',
    ja_JP: 'チェコ語',
    lt_LT: 'čekų',
    lv_LV: 'čehu',
    nl_NL: 'Tsjechisch',
    no_NO: 'tsjekkisk',
    pl_PL: 'czeski',
    pt_PT: 'tcheco',
    ru_RU: 'чешский',
    sr_RS: 'češki',
    sv_SE: 'tjeckiska',
    tr_TR: 'Çekçe',
    zh_CN: '捷克文',
    zh_TW: '捷克文',
    ko_KR: '체코어',
  },
};

export const daDK: Language = {
  code: 'da_DK',
  names: {
    cs_CZ: 'dánština',
    da_DK: 'dansk',
    de_DE: 'Dänisch',
    en_GB: 'Danish',
    en_US: 'Danish',
    es_ES: 'danés',
    et_EE: 'taani',
    fi_FI: 'tanska',
    fr_FR: 'danois',
    it_IT: 'danese',
    ja_JP: 'デンマーク語',
    lt_LT: 'danų',
    lv_LV: 'dāņu',
    nl_NL: 'Deens',
    no_NO: 'dansk',
    pl_PL: 'duński',
    pt_PT: 'dinamarquês',
    ru_RU: 'датский',
    sr_RS: 'danski',
    sv_SE: 'danska',
    tr_TR: 'Danca',
    zh_CN: '丹麦文',
    zh_TW: '丹麦文',
    ko_KR: '덴마크어',
  },
};

export const etEE: Language = {
  code: 'et_EE',
  names: {
    cs_CZ: 'estonština',
    da_DK: 'estisk',
    de_DE: 'Estnisch',
    en_GB: 'Estonian',
    en_US: 'Estonian',
    es_ES: 'estonio',
    et_EE: 'eesti',
    fi_FI: 'viro',
    fr_FR: 'estonien',
    it_IT: 'estone',
    ja_JP: 'エストニア語',
    lt_LT: 'estų',
    lv_LV: 'igauņu',
    nl_NL: 'Estisch',
    no_NO: 'estisk',
    pl_PL: 'estoński',
    pt_PT: 'estoniano',
    ru_RU: 'эстонский',
    sr_RS: 'estonski',
    sv_SE: 'estniska',
    tr_TR: 'Estonya Dili',
    zh_CN: '爱沙尼亚文',
    zh_TW: '爱沙尼亚文',
    ko_KR: '에스토니아어',
  },
};

export const fiFI: Language = {
  code: 'fi_FI',
  names: {
    cs_CZ: 'finština',
    da_DK: 'finsk',
    de_DE: 'Finnisch',
    en_GB: 'Finnish',
    en_US: 'Finnish',
    es_ES: 'finés',
    et_EE: 'soome',
    fi_FI: 'suomi',
    fr_FR: 'finnois',
    it_IT: 'finlandese',
    ja_JP: 'フィンランド語',
    lt_LT: 'suomių',
    lv_LV: 'somu',
    nl_NL: 'Fins',
    no_NO: 'finsk',
    pl_PL: 'fiński',
    pt_PT: 'finlandês',
    ru_RU: 'финский',
    sr_RS: 'finski',
    sv_SE: 'finska',
    tr_TR: 'Fince',
    zh_CN: '芬兰文',
    zh_TW: '芬兰文',
    ko_KR: '핀란드어',
  },
};

export const ltLT: Language = {
  code: 'lt_LT',
  names: {
    cs_CZ: 'litevština',
    da_DK: 'litauisk',
    de_DE: 'Litauisch',
    en_GB: 'Lithuanian',
    en_US: 'Lithuanian',
    es_ES: 'lituano',
    et_EE: 'leedu',
    fi_FI: 'liettua',
    fr_FR: 'lituanien',
    it_IT: 'lituano',
    ja_JP: 'リトアニア語',
    lt_LT: 'lietuvių',
    lv_LV: 'lietuviešu',
    nl_NL: 'Litouws',
    no_NO: 'litauisk',
    pl_PL: 'litewski',
    pt_PT: 'lituano',
    ru_RU: 'литовский',
    sr_RS: 'litvanski',
    sv_SE: 'litauiska',
    tr_TR: 'Litvanyaca',
    zh_CN: '立陶宛文',
    zh_TW: '立陶宛文',
    ko_KR: '리투아니아어',
  },
};

export const lvLV: Language = {
  code: 'lv_LV',
  names: {
    cs_CZ: 'lotyština',
    da_DK: 'lettisk',
    de_DE: 'Lettisch',
    en_GB: 'Latvian',
    en_US: 'Latvian',
    es_ES: 'letón',
    et_EE: 'läti',
    fi_FI: 'latvia',
    fr_FR: 'letton',
    it_IT: 'lettone',
    ja_JP: 'ラトビア語',
    lt_LT: 'latvių',
    lv_LV: 'latviešu',
    nl_NL: 'Lets',
    no_NO: 'latvisk',
    pl_PL: 'łotewski',
    pt_PT: 'letão',
    ru_RU: 'латышский',
    sr_RS: 'letonski',
    sv_SE: 'lettiska',
    tr_TR: 'Letonca',
    zh_CN: '拉脱维亚文',
    zh_TW: '拉脱维亚文',
    ko_KR: '라트비아어',
  },
};

export const nlNL: Language = {
  code: 'nl_NL',
  names: {
    cs_CZ: 'nizozemština',
    da_DK: 'hollandsk',
    de_DE: 'Niederländisch',
    en_GB: 'Dutch',
    en_US: 'Dutch',
    es_ES: 'neerlandés',
    et_EE: 'hollandi',
    fi_FI: 'hollanti',
    fr_FR: 'néerlandais',
    it_IT: 'olandese',
    ja_JP: 'オランダ語',
    lt_LT: 'olandų',
    lv_LV: 'holandiešu',
    nl_NL: 'Nederlands',
    no_NO: 'nederlandsk',
    pl_PL: 'niderlandzki',
    pt_PT: 'holandês',
    ru_RU: 'нидерландский',
    sr_RS: 'holandski',
    sv_SE: 'holländska',
    tr_TR: 'Felemenkçe',
    zh_CN: '荷兰文',
    zh_TW: '荷兰文',
    ko_KR: '네덜란드어',
  },
};

export const noNO: Language = {
  code: 'no_NO',
  names: {
    cs_CZ: 'norština',
    da_DK: 'norsk',
    de_DE: 'Norwegisch',
    en_GB: 'Norwegian',
    en_US: 'Norwegian',
    es_ES: 'noruego',
    et_EE: 'norra',
    fi_FI: 'norja',
    fr_FR: 'norvégien',
    it_IT: 'norvegese',
    ja_JP: 'ノルウェー語',
    lt_LT: 'norvegų',
    lv_LV: 'norvēģu',
    nl_NL: 'Noors',
    no_NO: 'norsk bokmål',
    pl_PL: 'norweski',
    pt_PT: 'norueguês',
    ru_RU: 'норвежский',
    sr_RS: 'norveški',
    sv_SE: 'norska',
    tr_TR: 'Norveççe',
    zh_CN: '挪威文',
    zh_TW: '挪威文',
    ko_KR: '노르웨이어',
  },
};

export const plPL: Language = {
  code: 'pl_PL',
  names: {
    cs_CZ: 'polština',
    da_DK: 'polsk',
    de_DE: 'Polnisch',
    en_GB: 'Polish',
    en_US: 'Polish',
    es_ES: 'polaco',
    et_EE: 'poola',
    fi_FI: 'puola',
    fr_FR: 'polonais',
    it_IT: 'polacco',
    ja_JP: 'ポーランド語',
    lt_LT: 'lenkų',
    lv_LV: 'poļu',
    nl_NL: 'Pools',
    no_NO: 'polsk',
    pl_PL: 'polski',
    pt_PT: 'polonês',
    ru_RU: 'польский',
    sr_RS: 'poljski',
    sv_SE: 'polska',
    tr_TR: 'Lehçe',
    zh_CN: '波兰文',
    zh_TW: '波兰文',
    ko_KR: '폴란드어',
  },
};

export const svSE: Language = {
  code: 'sv_SE',
  names: {
    cs_CZ: 'švédština',
    da_DK: 'svensk',
    de_DE: 'Schwedisch',
    en_GB: 'Swedish',
    en_US: 'Swedish',
    es_ES: 'sueco',
    et_EE: 'rootsi',
    fi_FI: 'ruotsi',
    fr_FR: 'suédois',
    it_IT: 'svedese',
    ja_JP: 'スウェーデン語',
    lt_LT: 'švedų',
    lv_LV: 'zviedru',
    nl_NL: 'Zweeds',
    no_NO: 'svensk',
    pl_PL: 'szwedzki',
    pt_PT: 'sueco',
    ru_RU: 'шведский',
    sr_RS: 'švedski',
    sv_SE: 'svenska',
    tr_TR: 'İsveççe',
    zh_CN: '瑞典文',
    zh_TW: '瑞典文',
    ko_KR: '스웨덴어',
  },
};

export const trTR: Language = {
  code: 'tr_TR',
  names: {
    cs_CZ: 'turečtina',
    da_DK: 'tyrkisk',
    de_DE: 'Türkisch',
    en_GB: 'Turkish',
    en_US: 'Turkish',
    es_ES: 'turco',
    et_EE: 'türgi',
    fi_FI: 'turkki',
    fr_FR: 'turc',
    it_IT: 'turco',
    ja_JP: 'トルコ語',
    lt_LT: 'turkų',
    lv_LV: 'turku',
    nl_NL: 'Turks',
    no_NO: 'tyrkisk',
    pl_PL: 'turecki',
    pt_PT: 'turco',
    ru_RU: 'турецкий',
    sr_RS: 'turski',
    sv_SE: 'turkiska',
    tr_TR: 'Türkçe',
    zh_CN: '土耳其文',
    zh_TW: '土耳其文',
    ko_KR: '터키어',
  },
};

export const koKR: Language = {
  code: 'ko_KR',
  names: {
    cs_CZ: 'korejština',
    da_DK: 'koreansk',
    de_DE: 'Koreanisch',
    en_GB: 'Korean',
    en_US: 'Korean',
    es_ES: 'coreano',
    et_EE: 'korea',
    fi_FI: 'korea',
    fr_FR: 'coréen',
    it_IT: 'coreano',
    ja_JP: '韓国語',
    lt_LT: 'korėjiečių',
    lv_LV: 'korejiešu',
    nl_NL: 'Koreaans',
    no_NO: 'koreansk',
    pl_PL: 'koreański',
    pt_PT: 'coreano',
    ru_RU: 'корейский',
    sr_RS: 'korejski',
    sv_SE: 'koreanska',
    tr_TR: 'Korece',
    zh_CN: '韩文',
    zh_TW: '韩文',
    ko_KR: '한국어',
  },
};

export const zhCN: Language = {
  code: 'zh_CN',
  names: {
    cs_CZ: 'čínština',
    da_DK: 'kinesisk',
    de_DE: 'Chinesisch',
    en_GB: 'Chinese',
    en_US: 'Chinese',
    es_ES: 'chino',
    et_EE: 'hiina',
    fi_FI: 'kiina',
    fr_FR: 'chinois',
    it_IT: 'cinese',
    ja_JP: '中国語',
    lt_LT: 'kinų',
    lv_LV: 'ķīniešu',
    nl_NL: 'Chinees',
    no_NO: 'kinesisk',
    pl_PL: 'chiński',
    pt_PT: 'chinês',
    ru_RU: 'китайский',
    sr_RS: 'kineski',
    sv_SE: 'kinesiska',
    tr_TR: 'Çince',
    zh_CN: '中文',
    zh_TW: '中文',
    ko_KR: '중국어',
  },
};

export const zhTW: Language = {
  code: 'zh_TW',
  names: {
    cs_CZ: 'čínština',
    da_DK: 'kinesisk',
    de_DE: 'Chinesisch',
    en_GB: 'Chinese',
    en_US: 'Chinese',
    es_ES: 'chino',
    et_EE: 'hiina',
    fi_FI: 'kiina',
    fr_FR: 'chinois',
    it_IT: 'cinese',
    ja_JP: '中国語',
    lt_LT: 'kinų',
    lv_LV: 'ķīniešu',
    nl_NL: 'Chinees',
    no_NO: 'kinesisk',
    pl_PL: 'chiński',
    pt_PT: 'chinês',
    ru_RU: 'китайский',
    sr_RS: 'kineski',
    sv_SE: 'kinesiska',
    tr_TR: 'Çince',
    zh_CN: '中文',
    zh_TW: '中文',
    ko_KR: '중국어',
  },
};

export const srRS: Language = {
  code: 'sr_RS',
  names: {
    cs_CZ: 'srbština',
    da_DK: 'serbisk',
    de_DE: 'Serbisch',
    en_GB: 'Serbian',
    en_US: 'Serbian',
    es_ES: 'serbio',
    et_EE: 'serblane',
    fi_FI: 'serbia',
    fr_FR: 'serbe',
    it_IT: 'serbo',
    ja_JP: 'セルビア語',
    lt_LT: 'serbų',
    lv_LV: 'serbu',
    nl_NL: 'Servisch',
    no_NO: 'serbisk',
    pl_PL: 'serbski',
    pt_PT: 'sérvio',
    ru_RU: 'сербский',
    sv_SE: 'serbiska',
    sr_RS: 'srpski',
    tr_TR: 'Sırpça',
    zh_CN: '塞尔维亚',
    zh_TW: '塞尔维亚',
    ko_KR: '세르비아 사람',
  },
};

export const languages: Language[] = [
  csCZ,
  daDK,
  deDE,
  enGB,
  enUS,
  esES,
  etEE,
  fiFI,
  frFR,
  itIT,
  jaJP,
  koKR,
  ltLT,
  lvLV,
  nlNL,
  noNO,
  plPL,
  ptPT,
  ruRU,
  srRS,
  svSE,
  trTR,
  zhCN,
  zhTW,
];

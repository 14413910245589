/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export * from './lib/providers/provide-locale';
export * from './lib/locale-token';
export * from './lib/models/locale';
export * from './lib/utils/build-locale';
export * from './lib/resources/countries';
export * from './lib/resources/languages';
export * from './lib/models/language';

<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<form [formGroup]="form">
  <mat-select multiple
              class="participants-table__header-selector mat-select-class table-header"
              data-testid="filter-timeslot"
              [ngClass]="{'arrow-up': showTimeslotSelect}"
              [placeholder]="'adminEventDetail.attendance.table.header.timeslot' | translate"
              formControlName="timeslot"
              (openedChange)="timeslotFilterDropdownChanged($event)">
    <mat-select-trigger class="table-header">
      {{ 'adminEventDetail.attendance.table.header.timeslot' | translate }}
    </mat-select-trigger>
    <mat-option #allTimeslotsSelected
                class="mat-select-option__select-all"
                data-testid="filter-timeslot-selectAll"
                (click)="toggleAllTimeslotsSelection()"
                [value]="defaultOption">
      {{ 'details.allTimeslots' | translate }}
    </mat-option>
    @for (groupedTimeslot of groupedTimeslots; track groupedTimeslot; let first = $first; let i = $index) {
      <mat-option class="mat-select-option__group-header"
                  [attr.data-testid]="'filter-timeslot-group-selectOption-' + i"
                  [ngClass]="{'mat-select-option__divider-top': !first, 'indeterminate': isIndeterminate(groupedTimeslot.date)}"
                  [value]="groupedTimeslot.date"
                  (click)="handleTimeslotGroupSelection(groupedTimeslot.date)">
        <p-text [weight]="'bold'">{{ getDate(groupedTimeslot.date) }}</p-text>
      </mat-option>
      @for (timeslot of groupedTimeslot.timeslots; track timeslot; let j = $index) {
        <mat-option [attr.data-testid]="'filter-timeslot-group-' + i + '-option-' + j"
                    class="mat-select-option__grouped-option"
                    [value]="timeslot.id"
                    (click)="selectTimeslot(timeslot.id)">
          <p-text>{{ getTime(timeslot.startDate, timeslot.endDate)}}</p-text>
        </mat-option>
      }
    }
  </mat-select>
</form>

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, model, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { EventManagementFacade } from '@admin/shared';
import { OverviewEventResponseModel } from '../../models/overview.model';
import { DeleteEventFormModel } from '../../models/form.model';
import { EventsOverviewFacade } from '../../facades/events-overview.facade';
import { SpacerComponent } from '@ui/shared/ui';

@Component({
  selector: 'mycontent-event-overview-delete-event-modal',
  templateUrl: './event-overview-delete-event-modal.component.html',
  styleUrls: ['./event-overview-delete-event-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    TranslatePipe,
    ReactiveFormsModule,
    AsyncPipe,
    HttpClientModule,
    SpacerComponent
  ],
  providers: [
    EventManagementFacade
  ]
})
export class EventOverviewDeleteEventModalComponent implements OnInit {
  country = input<string>();
  event = input<OverviewEventResponseModel>();

  isOpen = model<boolean>();

  form: FormGroup<DeleteEventFormModel>;

  private readonly eventsFacade = inject(EventManagementFacade);
  private readonly toastManager = inject(ToastManager);
  private readonly translateService = inject(TranslateService);
  private readonly eventsOverviewFacade = inject(EventsOverviewFacade);

  /* eslint-disable @typescript-eslint/member-ordering */
  error$ = this.eventsFacade.deleteEventError$;
  loading$ = this.eventsFacade.deleteEventLoading$;

  ngOnInit(): void {
    this.form = new FormGroup({
      deleteEvent: new FormControl<string>('', { validators: [Validators.required, this.stringMatchesRequiredString()] })
    });
  }

  stringMatchesRequiredString(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isStringEqualToString = !!(control.value) && control.value.toString() === (this.event()?.title);
      return isStringEqualToString ? null : { isStringEqualToString: { value: true } };
    };
  }

  onSubmit(): void {
    this.eventsFacade.deleteEvent(this.country(), this.event().id).subscribe(() => {
      this.isOpen.set(false);
      this.toastManager.addMessage({
        text: this.translateService.instant('adminDeleteEventModal.success'),
        state: 'success'
      });
      this.eventsOverviewFacade.getOnGoingEvents(this.country()).subscribe();
      this.eventsOverviewFacade.getPastEvents(this.country()).subscribe();
    });
  }
}

<!--
~  This code is protected by intellectual property rights.
~  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<ng-container *mycontentGhostText="!data; length: 27">
  @if (data.phone) {
    <mycontent-spacer [size]="(isMobileView$ | async) ? 'medium' : 'small'"></mycontent-spacer>
    <mycontent-info-card
      (click)="contactClickInfo.next('telephone')"
      [primaryIcon]="'phone'"
      [link]="'tel:' + data.phone"
      [primaryText]="data.phone"
      [secondaryText]="'eventDetail.listItem.label.callOrganiser' | translate">
    </mycontent-info-card>
  }
</ng-container>
<ng-container *mycontentGhostText="!data; length: 27">
  @if (data.mailAddress) {
    <mycontent-spacer [size]="(isMobileView$ | async) ? 'medium' : 'small'"></mycontent-spacer>
    <mycontent-info-card
      (click)="contactClickInfo.next('email')"
      [primaryIcon]="'email'"
      [link]="'mailto:' + data.mailAddress"
      [primaryText]="data.mailAddress"
      [secondaryText]="'eventDetail.listItem.label.sendEmail' | translate">
    </mycontent-info-card>
  }
</ng-container>
<ng-container *mycontentGhostText="!data; length: 27">
  @if (data.webpage) {
    <mycontent-spacer [size]="(isMobileView$ | async) ? 'medium' : 'small'"></mycontent-spacer>
    <mycontent-info-card
      (click)="contactClickInfo.next('webpage')"
      [primaryIcon]="'globe'"
      [link]="webpageLink"
      [primaryText]="data.webpageLabel || data.webpage"
      [isExternalLink]="true"
      [secondaryText]="'eventDetail.listItem.label.visitWebsite' | translate">
    </mycontent-info-card>
  }
</ng-container>


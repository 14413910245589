/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { BannerNotificationService } from '@ui/shared/ui';
import { TicketService } from '../../../services/endpoints/ticket.service';
import { WINDOW } from '@ui/shared/util';
import { NgClass } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'mycontent-participant-download-ticket-button',
  templateUrl: './participant-download-ticket-button.component.html',
  styleUrls: ['./participant-download-ticket-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    FormsModule,
    ReactiveFormsModule,
    TranslatePipe,
    NgClass
  ]
})
export class ParticipantDownloadTicketButtonComponent {
  eventId = input<string>();
  attendeeId = input<string>();
  country = input<string>();
  hideLabel = input<boolean>();

  isTicketLoading = signal<boolean>(false);

  private ticketService = inject(TicketService);
  private window: Window = inject(WINDOW);
  private bannerNotificationService = inject(BannerNotificationService);
  private translateService = inject(TranslateService);

  openTicketInNewTab(event: MouseEvent) {
    event.stopPropagation();
    this.isTicketLoading.set(true);
    this.bannerNotificationService.publishMessage({
      state: 'info',
      headline: this.translateService.instant('participantFlyout.inlineNotification.header.newTabInfo'),
      message: this.translateService.instant('participantFlyout.inlineNotification.paragraph.newTabInfo')
    });
    this.ticketService.getTicketPerCustomer(this.eventId(), this.attendeeId(), this.country()).subscribe({
      next: (ticket: Blob) => {
        const file = new Blob([ticket], { type: 'application/pdf' });
        const pdfSrc = URL.createObjectURL(file);
        this.window.open(pdfSrc, '_blank');
        this.isTicketLoading.set(false);
      },
      error: () => {
        this.bannerNotificationService.publishMessage({
          state: 'error',
          headline: this.translateService.instant('adminEventDetail.banner.header.sampleTicketError'),
          message: this.translateService.instant('adminEventDetail.banner.paragraph.sampleTicketError')
        });
        this.isTicketLoading.set(false);
      }
    });
  }
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

/* istanbul ignore file */

import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

export const flyInOutAnimation = trigger('flyInOut', [
  state('in', style({ opacity: 1 })),
  transition('void => *', [style({ opacity: 0 }), animate(250)]),
]);

const durationInMs = 350;

export const expandAnimation = [
  transition(`:enter`, [
    style({
      height: 0,
      opacity: 0,
      overflow: 'hidden'
    }),
    animate(`${durationInMs}ms cubic-bezier(0.230, 1.000, 0.320, 1.000)`, style({
      height: `*`,
      opacity: 1
    }))
  ]),
  transition(`:leave`, [
    style({
      height: `*`,
      opacity: 1,
      overflow: 'hidden'
    }),
    animate(`${durationInMs}ms cubic-bezier(0.230, 1.000, 0.320, 1.000)`, style({
      height: 0,
      opacity: 0
    }))
  ])
];

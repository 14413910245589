/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, HostBinding, inject, Input, ViewEncapsulation } from '@angular/core';
import { clamp } from 'lodash-es';
import { WINDOW } from '@ui/shared/util';

@Component({
  selector: 'mycontent-flex',
  templateUrl: './flex.html',
  styleUrls: ['./flex.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [],
})
export class FlexComponent {
  @Input() inline = false;
  @Input() gap: 'none' | 'x-small' | 'small' | 'medium' | 'large' | 'x-large' | 'xx-large' = 'none';

  @HostBinding('style.justify-content')
  @Input() justifyContent: 'start' | 'flex-start' | 'end' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';

  @HostBinding('style.flex-wrap')
  @Input() wrap: 'nowrap' | 'wrap' | 'wrap-reverse' = 'nowrap';

  @HostBinding('style.flex-direction')
  @Input() direction: 'row' | 'row-reverse' | 'column' |'column-reverse' = 'row';

  @HostBinding('style.align-items')
  @Input() alignItems: 'stretch' | 'flex-start' | 'end' | 'center' | 'baseline' = 'stretch';

  @HostBinding('style.align-content')
  @Input() alignContent: 'stretch' | 'flex-start' | 'end' | 'center' | 'baseline' = 'stretch';

  private readonly window = inject(WINDOW);

  @HostBinding('style.display')
  get flex() {
    return this.inline ? 'inline-flex' : 'flex';
  }

  @HostBinding('style.gap')
  get flexGap() {
    switch (this.gap) {
      case 'none':
        return `0px`;
      case 'x-small':
        return `${clamp(4, this.window.visualViewport.width * 0.0025 + 3, 8)}px`;
      case 'small':
        return `${clamp(8, this.window.visualViewport.width * 0.005 + 6, 16)}px`;
      case 'medium':
        return `${clamp(16, this.window.visualViewport.width * 0.0125 + 12, 36)}px`;
      case 'large':
        return `${clamp(32, this.window.visualViewport.width * 0.0275 + 23, 76)}px`;
      case 'x-large':
        return `${clamp(48, this.window.visualViewport.width * 0.03 + 38, 96)}px`;
      case 'xx-large':
        return `${clamp(80, this.window.visualViewport.width * 0.075 + 56, 200)}px`;
      default:
        return `0px`;
    }
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT_ADMIN, EnvironmentAdmin } from '@ui/shared/util';
import { CountryService } from './country.service';
import { AuthoritiesFacade } from '../facades/authorities.facade';

@Injectable({
  providedIn: 'root'
})
export class AdminFeatureToggleService {

  DEALER_COUNTRIES = ['AU', 'BE', 'CA', 'CH', 'FR', 'IT', 'MX', 'MY', 'NO', 'NZ', 'PR', 'SG', 'TW'];

  private readonly environment: EnvironmentAdmin = inject(ENVIRONMENT_ADMIN);
  private readonly countryService: CountryService = inject(CountryService);
  private readonly authoritiesFacade: AuthoritiesFacade = inject(AuthoritiesFacade);

  isEventCreateAndEditEnabled(): boolean {
    if (this.isAdmin()) {
      return true;
    } else {
      const country = this.countryService.getCountryFromUrl();
      if (this.isNotDealerMarket(country)) {
        return this.isMarket();
      } else {
        return true;
      }
    }
  }

  isPreviewOnlyEnabled(): boolean {
    return this.environment.stage !== 'prod';
  }

  isCAPAllowed(): boolean {
    return this.isMarket() || this.isAdmin();
  }

  private isNotDealerMarket(country: string): boolean {
    return !this.DEALER_COUNTRIES.includes(country.toUpperCase());
  }

  private isAdmin(): boolean {
    return this.authoritiesFacade.isAdmin();
  }

  private isMarket(): boolean {
    return this.authoritiesFacade.isMarket();
  }
}

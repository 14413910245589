/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, computed, HostListener, inject, signal } from '@angular/core';
import { ToolSupportService } from '../../services/endpoints/tool-support.service';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToolsModel } from '../../models/tools.model';
import { GridComponent, GridItemComponent, SpacerComponent } from '@ui/shared/ui';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'mycontent-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss'],
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    FormsModule,
    RouterModule,
    GridComponent,
    GridItemComponent,
    SpacerComponent,
  ],
  providers: [
    ToolSupportService
  ]
})
export class ToolsComponent {
  ciamId: string;
  email: string;

  loader = signal<boolean>(false);
  error = signal<string | HttpErrorResponse>(null);
  data = signal<ToolsModel>({});

  formatDateOfBirth = computed(() => {
    if (this.data().dateOfBirth) {
      let dateString = this.data().dateOfBirth;
      dateString = `${dateString.slice(0, 4)  } ${  dateString.slice(4)}`;
      dateString = `${dateString.slice(0, 7)  } ${  dateString.slice(7)}`;
      return dateString;
    } else {
      return 'No date of birth available';
    }
  });

  private supportService: ToolSupportService = inject(ToolSupportService);

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.getData();
    }
  }

  getData(): void {
    this.loader.set(true);
    this.data.set({});

    if (this.ciamId || this.email) {
      this.error.set(null);
      this.supportService.getCustomerData(this.ciamId, this.email).subscribe({
        next: response => {
          this.data.set(response);
          this.loader.set(false);
        },
        error: (error: HttpErrorResponse) => {
          this.error.set(error);
          this.loader.set(false);
        },
      });
    } else {
      this.error.set('Please check the search data. CiamId OR email are needed');
      this.loader.set(false);
    }
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, model, output } from '@angular/core';
import { DateFormatterService } from '@ui/shared/util';
import { TranslatePipe } from '@ngx-translate/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { TimeslotModel } from '@ui/shared/feature-registration';

@Component({
  selector: 'mycontent-event-management-timeslot-delete-modal',
  templateUrl: './event-management-timeslot-delete-modal.component.html',
  styleUrls: ['./event-management-timeslot-delete-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    TranslatePipe
  ]
})
export class EventManagementTimeslotDeleteModalComponent {

  isModalOpen = model<boolean>();
  timeslot = input<TimeslotModel>();

  deleteModal = output<boolean>();

  private dateFormatterService = inject(DateFormatterService);

  handleModalClose() {
    this.deleteModal.emit(false);
  }

  deleteTimeslot() {
    this.deleteModal.emit(true);
  }

  getDateTime(dateString: string) {
    return this.dateFormatterService.formatDateWithTime(dateString);
  }
}

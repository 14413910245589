/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { EventManagementModel } from '../models/event-management.model';

export const eventDetailsResponse: EventManagementModel = {
  cancelledRegistrationCount: 5,
  checkedInTicketCount: 5,
  capacity: 45,
  registeredTicketCount: 10,
  invitationCount: 2,
  formattedAddress: '1234 Sample Street, Sample City',
  startDate: '2023-08-10T10:00:00+01:00',
  endDate: '2023-08-10T16:00:00+01:00',
  title: 'Sample Event',
  zoneId: '+01:00',
  isOpenRegistration: true,
  isConfigurationMissing: false,
  managementType: 'EB',
};

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export const CH = 'CH';
export const DE = 'DE';
export const ES = 'ES';
export const FR = 'FR';
export const GB = 'GB';
export const IT = 'IT';
export const JP = 'JP';
export const KR = 'KR';
export const PT = 'PT';
export const SG = 'SG';
export const US = 'US';
export const MY = 'MY';
export const TW = 'TW';
export const NZ = 'NZ';
export const AU = 'AU';
export const NO = 'NO';


export const de_DE = 'de_DE';
export const fr_FR = 'fr_FR';
export const en_GB = 'en_GB';
export const en = 'en';
export const it_IT = 'it_IT';




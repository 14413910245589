/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT_ADMIN } from '@ui/shared/util';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { map, Observable, shareReplay } from 'rxjs';
import { DealerModel, DealerTagsResponseModel } from '../models/dealer.model';

@Injectable({
  providedIn: 'root',
})
export class DealerService {

  private ADMIN_PATH = 'admin';

  private environment = inject(ENVIRONMENT_ADMIN);
  private http = inject(HttpClient);
  private oAuthService = inject(OAuthService);

  getDealersPerMarket(marketSpaceName: string, includeTestDealer?: boolean): Observable<DealerModel[]> {
    const headers = this.getAuthHeader();
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${marketSpaceName}/dealer-tags?include-test-dealer=${includeTestDealer ? 'true' : 'false'}`;

    return this.http.get<DealerTagsResponseModel>(url, {headers}).pipe(
      map((response: DealerTagsResponseModel) => response.tags),
      shareReplay(1),
    );
  }

  private getAuthHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${this.oAuthService.getIdToken()}`);
  }
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */


import { NoCookieConsentNeededStatusProvider } from './constant/constant-value-cookie-consent-status-provider';
import { CookieConsentStatusProvider } from '../cookie-consent-status-provider';
import { UscCookieConsentStatusProviderService } from './usc/usc-cookie-consent-status-provider.service';

const uscCookieConsentStatusProviderCountries = ['DE', 'ES', 'PT', 'FR', 'IT', 'CH', 'GB', 'IE', 'US'];

const CookieConsentCountryProviderMap = (
  {
    ...defineServiceForList(uscCookieConsentStatusProviderCountries, UscCookieConsentStatusProviderService),
    DEFAULT: {
      service: NoCookieConsentNeededStatusProvider
    }
  }
);

function defineServiceForList(countryList: string[], service: unknown) {
  return countryList.reduce((result, country) => ({
    ...result, [country]: { service }
  }), {});
}

interface CookieConsentCountryProviderConfig {
  service: CookieConsentStatusProvider;
}

export function getCookieConsentConfigByCountry(country: string): CookieConsentCountryProviderConfig {
  let config = CookieConsentCountryProviderMap[country];
  if (!config) {
    config = CookieConsentCountryProviderMap.DEFAULT;
  }
  return config;
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, inject, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { RedirectService } from '../../services/redirect.service';

@Component({
  selector: 'mycontent-auth-callback',
  template: `
    <p>
      <!-- Auth Callback worked but you should redirect on from this page -->
    </p>
  `,
  standalone: true
})
export class AuthCallbackComponent implements OnInit {

  private router = inject(Router);
  private redirectService = inject(RedirectService);

  ngOnInit() {
    const redirectUrl = this.redirectService.getRedirectUrl();
    if (redirectUrl) {
      this.redirectService.removeRedirectUrl();
      this.router.navigate([redirectUrl]);
    } else {
      this.router.navigate(['/']);
    }
  }
}

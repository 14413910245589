/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { LockStatusModel } from '../../models/lock-status.model';
import { ENVIRONMENT_ADMIN } from '@ui/shared/util';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private ADMIN_PATH = 'admin';

  private environment = inject(ENVIRONMENT_ADMIN);
  private http = inject(HttpClient);
  private oAuthService = inject(OAuthService);

  validateData(country: string, eventId: string, formData: FormData): Observable<HttpResponse<unknown>> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/invitations/import-csv?validate-only=true`;
    const headers = this.getAuthHeader();
    return this.http.post<unknown>(url, formData, {headers, observe: 'response'});
  }

  uploadData(country: string, eventId: string, formData: FormData): Observable<HttpResponse<unknown>> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/invitations/import-csv`;
    const headers = this.getAuthHeader();
    return this.http.post<unknown>(url, formData, {headers, observe: 'response'});
  }

  getCsvExportFileData(country: string, eventId: string, states: string[]): Observable<object> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/invitations/export-csv`;
    const headers = this.getAuthHeader();
    let params = new HttpParams();
    if (states?.length > 0) {
      params = params.set('states', states.toString());
    }

    return this.http.get<object>(url, {headers, responseType: 'blob' as 'json', params});
  }

  checkLock(country: string, eventId: string): Observable<HttpResponse<LockStatusModel>> {
    const url = `${this.environment.backend.url}/admin/${country}/events/${eventId}/invitations/lock/status`;

    return this.http.get<LockStatusModel>(url, {headers: this.getAuthHeader(), observe: 'response'});
  }

  private getAuthHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${this.oAuthService.getIdToken()}`);
  }
}

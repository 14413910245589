<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<p-modal [open]="isOpen()" (dismiss)="close()">
  <p-heading slot="header" [size]="'large'" [tag]="'h2'">{{ 'adminEventDetail.dialog.title.resendRegistration' | translate }}</p-heading>
  <div>
    <mycontent-flex [direction]="'row'" [justifyContent]="'flex-start'">
      <p-text>{{ 'adminEventDetail.dialog.paragraph.resendRegistration' | translate: {email: mail() } }}</p-text>
    </mycontent-flex>
    <mycontent-flex [direction]="'row'" [justifyContent]="'flex-start'">
      <p-button-group class="footer resend-registration__footer">
        <p-button
          [attr.data-testid]="'resend-registration-accept-btn'"
          [icon]="'check'"
          [loading]="isLoading()"
          (click)="resendRegistration()"
        >
          {{ 'confirm' | translate }}
        </p-button>
        <p-button
          [attr.data-testid]="'resend-registration-cancel-btn'"
          [icon]="'close'"
          [type]="'button'"
          [variant]="'secondary'"
          (click)="close()"
        >
          {{ 'buttonCancel' | translate }}
        </p-button>
      </p-button-group>
    </mycontent-flex>
  </div>
</p-modal>

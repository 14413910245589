/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DocumentTitleService {
  // en dash (mac os: <option>+<-> key, windows: <ctrl>+<-> key)
  private SEPARATOR = ' – ';
  private staticKeyTranslated = this.translateService.instant('document.title.myPorsche');

  constructor(private titleService: Title, private translateService: TranslateService) {}

  public setTitle(title: string[]) {
    this.translateService
      .get(title)
      .pipe(take(1))
      .subscribe(translated => {
        this.setTitleRaw(Object.values(translated));
      });
  }

  public setTitleRaw(title: string[]) {
    this.titleService.setTitle(this.buildTitle(title));
  }

  public resetTitle() {
    this.titleService.setTitle(this.staticKeyTranslated);
  }

  private buildTitle(title: string[]) {
    return `${title.join(this.SEPARATOR)}${this.SEPARATOR}${this.staticKeyTranslated}`;
  }
}

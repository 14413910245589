/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaygroundSetupComponent } from './playground-setup.component';
import { playgroundLanguageProvider } from './playground-language-provider';

@NgModule({
  declarations: [PlaygroundSetupComponent],
  exports: [PlaygroundSetupComponent],
  imports: [CommonModule],
  providers: [playgroundLanguageProvider],
})
export class PlaygroundSetupModule {}

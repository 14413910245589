/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component } from '@angular/core';
import { PlaygroundSetupService } from './playground-setup.service';

@Component({
  selector: 'mycontent-playground-setup',
  template: ``,
  styles: [],
})
export class PlaygroundSetupComponent {
  constructor(playgroundSetupService: PlaygroundSetupService) {
    playgroundSetupService.setup();
  }
}

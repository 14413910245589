/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export interface CookieConsentModel {
  [key: string]: string | boolean;
  templateId: string;
  consentStatus: boolean;
  technicalName: string;
  dataProcessor: string;
}

export const USC_CONSENT_CHANGED_EVENT = 'consent_changed';

export interface UscCustomEvent extends Event {
  detail?: {
    data?: {
      event: string;
      name: string;
      status: boolean;
    };
  };
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable, Injector } from '@angular/core';
import { CookieConsentStatusProvider } from '../cookie-consent-status-provider';
import { getCookieConsentConfigByCountry } from './cookie-consent-status-provider.config';
import { NoCookieConsentNeededStatusProvider } from './constant/constant-value-cookie-consent-status-provider';
import { CookieConsentConfig } from '../cookie-consent-config';

@Injectable()
export class CookieConsentStatusProviderFactory {
  constructor(
    private readonly config: CookieConsentConfig,
    private injector: Injector) {}

  create(urlCountry: string): CookieConsentStatusProvider {
    if (!this.config.enable) {
      return this.injector.get(NoCookieConsentNeededStatusProvider);
    }
    const country = urlCountry.toUpperCase();
    const countryConfig = getCookieConsentConfigByCountry(country);

    return this.injector.get(countryConfig.service);
  }
}

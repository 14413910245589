/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export class EnvironmentAdmin {
  oidc?: {
    authority?: string;
    cognito?: string;
    client_id?: string;
    url?: string;
  };
  backend?: {
    url: string;
  };
  fossUrl?: string;
  stage?: string;
}

<!--
~  This code is protected by intellectual property rights.
~  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<div class="image-ghost" [style.--ratio-width]="ratioWidth()" [style.--ratio-height]="ratioHeight()">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    [style.backgroundColor]="backgroundColor()"
    version="1.1"
    viewBox="0 0 393 295.9"
    preserveAspectRatio="xMidYMid meet"
    xml:space="preserve"
    class="image-ghost__background"
    >
    @if (withIcon()) {
      <g transform="matrix(0.178117048346056, 0, 0, 0.16897600540723218, 161.5, 122.94999999999999)">
        <path
          d="M378.4,0H14.6C6.5,0,0,6.6,0,14.7v266.5c0,8.1,6.5,14.7,14.6,14.7h363.8c8.1,0,14.6-6.6,14.6-14.7V14.7
          C393,6.6,386.5,0,378.4,0z M352.5,255.9h-32.7l-74.3-89.1l-32.8,40.6l-82.4-108L74.1,255.9H40.8V40.7h311.7V255.9z"
          class="image-ghost__stroke"
          [style.fill]="iconColor()"
          />
        <circle cx="286.8" cy="98.9" r="24.6" class="image-ghost__stroke" [style.fill]="iconColor()" />
      </g>
    }
  </svg>
</div>

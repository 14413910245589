/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Country } from '../models/country';
import {
  csCZ,
  daDK,
  deDE,
  enGB,
  enUS,
  esES,
  etEE,
  fiFI,
  frFR,
  itIT,
  jaJP,
  koKR,
  ltLT,
  lvLV,
  nlNL,
  noNO,
  plPL,
  ptPT,
  ruRU,
  srRS,
  svSE,
  trTR,
  zhCN,
  zhTW,
} from './languages';

export const albania: Country = {
  code: 'al',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const andorra: Country = {
  code: 'ad',
  defaultLanguage: esES,
  languages: [esES],
};

export const argentina: Country = {
  code: 'ar',
  defaultLanguage: esES,
  languages: [esES],
};

export const armenia: Country = {
  code: 'am',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const antarctica: Country = {
  code: 'aq',
  defaultLanguage: enUS,
  languages: [enUS, deDE],
};

export const austria: Country = {
  code: 'at',
  defaultLanguage: deDE,
  languages: [deDE],
};

export const australia: Country = {
  code: 'au',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const azerbaijan: Country = {
  code: 'az',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const bahrain: Country = {
  code: 'bh',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const belarus: Country = {
  code: 'by',
  defaultLanguage: ruRU,
  languages: [ruRU],
};

export const belgium: Country = {
  code: 'be',
  defaultLanguage: frFR,
  languages: [frFR, nlNL],
};

export const bolivia: Country = {
  code: 'bo',
  defaultLanguage: esES,
  languages: [esES],
};

export const bosnia: Country = {
  code: 'ba',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const brazil: Country = {
  code: 'br',
  defaultLanguage: ptPT,
  languages: [ptPT, enGB],
};

export const brunei: Country = {
  code: 'bn',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const bulgaria: Country = {
  code: 'bg',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const canada: Country = {
  code: 'ca',
  defaultLanguage: enUS,
  languages: [enUS, frFR],
};

export const cambodia: Country = {
  code: 'kh',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const chile: Country = {
  code: 'cl',
  defaultLanguage: esES,
  languages: [esES],
};

export const china: Country = {
  code: 'cn',
  defaultLanguage: zhCN,
  languages: [enGB, zhCN],
};

export const columbia: Country = {
  code: 'co',
  defaultLanguage: esES,
  languages: [esES],
};

export const costaRica: Country = {
  code: 'cr',
  defaultLanguage: esES,
  languages: [esES],
};

export const croatia: Country = {
  code: 'hr',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const cyprus: Country = {
  code: 'cy',
  defaultLanguage: enGB,
  languages: [enGB, ruRU],
};

export const czechRepublic: Country = {
  code: 'cz',
  defaultLanguage: csCZ,
  languages: [enGB, csCZ],
};

export const denmark: Country = {
  code: 'dk',
  defaultLanguage: daDK,
  languages: [enGB, daDK],
};

export const dominicanRepublic: Country = {
  code: 'do',
  defaultLanguage: esES,
  languages: [esES],
};

export const ecuador: Country = {
  code: 'ec',
  defaultLanguage: esES,
  languages: [esES],
};

export const egypt: Country = {
  code: 'eg',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const estonia: Country = {
  code: 'ee',
  defaultLanguage: etEE,
  languages: [etEE, ruRU, enGB],
};

export const finland: Country = {
  code: 'fi',
  defaultLanguage: fiFI,
  languages: [enGB, fiFI],
};

export const france: Country = {
  code: 'fr',
  defaultLanguage: frFR,
  languages: [frFR],
};

export const frenchPolinesia: Country = {
  code: 'pf',
  defaultLanguage: frFR,
  languages: [frFR],
};

export const georgia: Country = {
  code: 'ge',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const germany: Country = {
  code: 'de',
  defaultLanguage: deDE,
  languages: [deDE],
};

export const gibraltar: Country = {
  code: 'gi',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const greatBritain: Country = {
  code: 'gb',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const greece: Country = {
  code: 'gr',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const guadeloupe: Country = {
  code: 'gp',
  defaultLanguage: frFR,
  languages: [frFR],
};

export const guatemala: Country = {
  code: 'gt',
  defaultLanguage: esES,
  languages: [esES],
};

export const haiti: Country = {
  code: 'ht',
  defaultLanguage: frFR,
  languages: [frFR],
};

export const honduras: Country = {
  code: 'hn',
  defaultLanguage: esES,
  languages: [esES],
};

export const hongkong: Country = {
  code: 'hk',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const hungary: Country = {
  code: 'hu',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const iceland: Country = {
  code: 'is',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const india: Country = {
  code: 'in',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const indonesia: Country = {
  code: 'id',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const ireland: Country = {
  code: 'ie',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const israel: Country = {
  code: 'il',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const italy: Country = {
  code: 'it',
  defaultLanguage: itIT,
  languages: [itIT],
};

export const japan: Country = {
  code: 'jp',
  defaultLanguage: jaJP,
  languages: [enGB, jaJP],
};

export const jordan: Country = {
  code: 'jo',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const kazakhstan: Country = {
  code: 'kz',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const kenya: Country = {
  code: 'ke',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const kuwait: Country = {
  code: 'kw',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const latvia: Country = {
  code: 'lv',
  defaultLanguage: lvLV,
  languages: [ruRU, lvLV, enGB],
};

export const lebanon: Country = {
  code: 'lb',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const lichtenstein: Country = {
  code: 'li',
  defaultLanguage: deDE,
  languages: [enGB, deDE, frFR, itIT],
};

export const lithuania: Country = {
  code: 'lt',
  defaultLanguage: ltLT,
  languages: [ruRU, ltLT, enGB],
};

export const luxembourg: Country = {
  code: 'lu',
  defaultLanguage: deDE,
  languages: [deDE, frFR],
};

export const macedonia: Country = {
  code: 'mk',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const malaysia: Country = {
  code: 'my',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const malta: Country = {
  code: 'mt',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const mexico: Country = {
  code: 'mx',
  defaultLanguage: esES,
  languages: [esES],
};

export const moldova: Country = {
  code: 'md',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const monaco: Country = {
  code: 'mc',
  defaultLanguage: frFR,
  languages: [frFR],
};

export const mongolia: Country = {
  code: 'mn',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const montenegro: Country = {
  code: 'me',
  defaultLanguage: srRS,
  languages: [enGB, srRS],
};

export const netherlands: Country = {
  code: 'nl',
  defaultLanguage: nlNL,
  languages: [enGB, nlNL],
};

export const norway: Country = {
  code: 'no',
  defaultLanguage: noNO,
  languages: [enGB, noNO],
};

export const newCaledonia: Country = {
  code: 'nc',
  defaultLanguage: frFR,
  languages: [frFR],
};

export const newZealand: Country = {
  code: 'nz',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const oman: Country = {
  code: 'om',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const pakistan: Country = {
  code: 'pk',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const panama: Country = {
  code: 'pa',
  defaultLanguage: esES,
  languages: [esES],
};

export const paraguay: Country = {
  code: 'py',
  defaultLanguage: esES,
  languages: [esES],
};

export const peru: Country = {
  code: 'pe',
  defaultLanguage: esES,
  languages: [esES],
};

export const philippines: Country = {
  code: 'ph',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const poland: Country = {
  code: 'pl',
  defaultLanguage: plPL,
  languages: [enGB, plPL],
};

export const portugal: Country = {
  code: 'pt',
  defaultLanguage: ptPT,
  languages: [enGB, ptPT],
};

export const puertoRico: Country = {
  code: 'pr',
  defaultLanguage: enUS,
  languages: [enUS, esES],
};

export const qatar: Country = {
  code: 'qa',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const romania: Country = {
  code: 'ro',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const russia: Country = {
  code: 'ru',
  defaultLanguage: ruRU,
  languages: [ruRU],
};

export const salvador: Country = {
  code: 'sv',
  defaultLanguage: esES,
  languages: [esES],
};

export const saudiArabia: Country = {
  code: 'sa',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const serbia: Country = {
  code: 'rs',
  defaultLanguage: srRS,
  languages: [enGB, srRS],
};

export const singapore: Country = {
  code: 'sg',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const slovakia: Country = {
  code: 'sk',
  defaultLanguage: csCZ,
  languages: [enGB, csCZ],
};

export const slovenia: Country = {
  code: 'si',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const southAfrica: Country = {
  code: 'za',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const southKorea: Country = {
  code: 'kr',
  defaultLanguage: koKR,
  languages: [koKR, enGB],
};

export const spain: Country = {
  code: 'es',
  defaultLanguage: esES,
  languages: [esES],
};

export const sriLanka: Country = {
  code: 'lk',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const sweden: Country = {
  code: 'se',
  defaultLanguage: svSE,
  languages: [enGB, svSE],
};

export const switzerland: Country = {
  code: 'ch',
  defaultLanguage: deDE,
  languages: [deDE, enGB, itIT, frFR],
};

export const syria: Country = {
  code: 'sy',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const taiwan: Country = {
  code: 'tw',
  defaultLanguage: zhTW,
  languages: [zhTW, enGB],
};

export const thailand: Country = {
  code: 'th',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const trinidadAndTobago: Country = {
  code: 'tt',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const turkey: Country = {
  code: 'tr',
  defaultLanguage: trTR,
  languages: [enGB, trTR],
};

export const ukraine: Country = {
  code: 'ua',
  defaultLanguage: ruRU,
  languages: [ruRU],
};

export const unitedArabEmirates: Country = {
  code: 'ae',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const unitedStates: Country = {
  code: 'us',
  defaultLanguage: enUS,
  languages: [enUS],
};

export const uruguay: Country = {
  code: 'uy',
  defaultLanguage: esES,
  languages: [esES],
};

export const venezuela: Country = {
  code: 've',
  defaultLanguage: esES,
  languages: [esES],
};

export const vietnam: Country = {
  code: 'vn',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const yemen: Country = {
  code: 'ye',
  defaultLanguage: enGB,
  languages: [enGB],
};

export const countries: Country[] = [
  albania,
  andorra,
  antarctica,
  argentina,
  armenia,
  austria,
  australia,
  azerbaijan,
  bahrain,
  belarus,
  belgium,
  bolivia,
  bosnia,
  brazil,
  brunei,
  bulgaria,
  canada,
  cambodia,
  chile,
  china,
  columbia,
  costaRica,
  croatia,
  cyprus,
  czechRepublic,
  denmark,
  dominicanRepublic,
  ecuador,
  egypt,
  estonia,
  finland,
  france,
  frenchPolinesia,
  georgia,
  germany,
  gibraltar,
  greatBritain,
  greece,
  guadeloupe,
  guatemala,
  haiti,
  honduras,
  hongkong,
  hungary,
  iceland,
  india,
  indonesia,
  ireland,
  israel,
  italy,
  japan,
  jordan,
  kazakhstan,
  kenya,
  kuwait,
  latvia,
  lebanon,
  lichtenstein,
  lithuania,
  luxembourg,
  macedonia,
  malaysia,
  malta,
  mexico,
  moldova,
  monaco,
  mongolia,
  montenegro,
  netherlands,
  norway,
  newCaledonia,
  newZealand,
  oman,
  pakistan,
  panama,
  paraguay,
  peru,
  philippines,
  poland,
  portugal,
  puertoRico,
  qatar,
  romania,
  russia,
  salvador,
  saudiArabia,
  serbia,
  singapore,
  slovakia,
  slovenia,
  southAfrica,
  southKorea,
  spain,
  sriLanka,
  sweden,
  switzerland,
  syria,
  taiwan,
  thailand,
  trinidadAndTobago,
  turkey,
  ukraine,
  unitedArabEmirates,
  unitedStates,
  uruguay,
  venezuela,
  vietnam,
  yemen,
];

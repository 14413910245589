<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
@if (!hide()) {
  <p-button-pure
    id="{{ elementId() + '-show-more' }}"
    class="show-more-show-less-arrow__button"
    [attr.data-testid]="elementId() + '-show-more-button'"
    [icon]="'arrow-head-down'"
    [hideLabel]="true"
    (click)="switchView(true)"
  >
    Show more
  </p-button-pure>
}
@if (hide()) {
  <p-button-pure
    id="{{ elementId() + '-show-less' }}"
    class="show-more-show-less-arrow__button"
    [attr.data-testid]="elementId() + '-show-less-button'"
    [icon]="'arrow-head-up'"
    [hideLabel]="true"
    (click)="switchView(false)"
  >
    Show less
  </p-button-pure>
}

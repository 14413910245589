<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<dialog class="error-banner__dialog" #errorBanner>
  <div class="error-banner__container">
    <p-inline-notification
      [state]="state()"
      [heading]="headline()"
      [description]="message()"
      (dismiss)="closeBanner()"
    ></p-inline-notification>
  </div>
</dialog>


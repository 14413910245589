/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export function playgroundLanguageProviderFactory(translate: TranslateService) {
  return () => translate.setDefaultLang('de_DE');
}

export const playgroundLanguageProvider = {
  provide: APP_INITIALIZER,
  useFactory: playgroundLanguageProviderFactory,
  multi: true,
  deps: [TranslateService],
};

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { DateFormatterService } from '@ui/shared/util';
import { TranslatePipe } from '@ngx-translate/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { FlexComponent, FlexItemComponent, GhostTextDirective } from '@ui/shared/ui';
import { TimeslotModel } from '@ui/shared/feature-registration';

@Component({
  selector: 'mycontent-event-management-timeslot-row',
  templateUrl: './event-management-timeslot-row.component.html',
  styleUrls: ['./event-management-timeslot-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FlexComponent,
    FlexItemComponent,
    PorscheDesignSystemModule,
    TranslatePipe,
    GhostTextDirective
  ]
})
export class EventManagementTimeslotRowComponent {
  timeslot = input<TimeslotModel>();
  hoursBeforeRegistrationCloses = input<number>();
  timeslotsLoading = input<boolean>();

  openModalEmitter = output<TimeslotModel>();
  openDeleteModalEmitter = output<TimeslotModel>();

  private dateFormatterService = inject(DateFormatterService);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  displayStartTime = computed(() => {
    if (this.timeslot()?.startDate) {
      return this.dateFormatterService.formatTime(this.timeslot()?.startDate);
    }
    return '';
  });

  // eslint-disable-next-line @typescript-eslint/member-ordering
  displayEndTime = computed(() => {
    if (this.timeslot()?.endDate) {
      return this.dateFormatterService.formatEndTime(this.timeslot()?.startDate, this.timeslot()?.endDate);
    }
    return '';
  });

  openAddTimeslotModal(timeslot: TimeslotModel) {
    this.openModalEmitter.emit(timeslot);
  }

  openDeleteTimeslotModal(timeslot: TimeslotModel) {
    this.openDeleteModalEmitter.emit(timeslot);
  }

  doesTimeslotHaveAttendees(timeslot: TimeslotModel): boolean {
    return Number(timeslot?.registeredTicketCount) > 0;
  }
}

<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<span
  class="ghost {{ ghostClass }}"
  [class.ghost--text]="textGhost"
  [class.ghost--fill-horizontal]="fillHorizontal"
  [class.ghost--fill-vertical]="fillVertical"
  [style.height]="height + 'px'"
  [style.width]="width + 'px'"
>
  <ng-content></ng-content>
  <span class="ghost__glow" [class.ghost__glow--hidden]="glowHidden" [style.left]="glowLeft" [style.width]="glowWidth"
    >&nbsp;</span
  >
</span>

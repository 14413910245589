<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
@if (loading()) {
  <mycontent-flex class="event-overview__loading">
    <mycontent-flex-item>
      <p-spinner [size]="'large'" aria="{ 'aria-label': 'Loading page content' }"></p-spinner>
      <p-text>{{ 'details.msgLoadingEventsList' | translate }}</p-text>
    </mycontent-flex-item>
  </mycontent-flex>
}
@if (!loading()) {
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  @for (event of eventsPaginated(); track event.id) {
    <mycontent-event-overview-row
      id="{{event.title}}"
      [attr.data-testId]="'event-admin-overview-event-row-' + event.id"
      [country]="country()"
      [event]="event"
      [currentPageIndex]="eventsActivePage()"
      [currentTabIndex]="currentTabIndex()"
    ></mycontent-event-overview-row>
  }
  <p-pagination
    [totalItemsCount]="eventsAll()?.items?.length"
    [itemsPerPage]=eventsItemsPerPage()
    [activePage]=eventsActivePage()
    (update)="switchPageEvents($event)"
  ></p-pagination>
  @if (eventsAll()?.items?.length === 0) {
    <p-text>{{ 'list.emptyEventList' | translate }}</p-text>
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ImpersonationService } from '@admin/shared';

@Injectable()
export class RoleInterceptor implements HttpInterceptor {

  backendUrl = environment.backend.url;

  constructor(private roleService: ImpersonationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let requestClone = request.clone();

    if (this.roleService.isMarketRole() && request.url.includes(this.backendUrl)) {
      requestClone = request.clone({
        setHeaders: {
          'X-Impersonation-MarketCountry': `${this.roleService.getRole().market}`,
        },
      });
    }
    if (this.roleService.isDealerRole() && request.url.includes(this.backendUrl)) {
      requestClone = request.clone({
        setHeaders: {
          'X-Impersonation-DealerId': `${this.roleService.getRole().dealer}`,
          'X-Impersonation-MarketCountry': `${this.roleService.getRole().market}`,
        },
      });
    }

    return next.handle(requestClone);
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, input, model, viewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { TranslatePipe } from '@ngx-translate/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FlexComponent } from '../flex/flex/flex.component';
import { DragDropFileDirective, FileHandle } from '../drag-drop/drag-drop-file.directive';
import { SpacerComponent } from '../spacer';

@Component({
  selector: 'mycontent-image-upload-crop',
  templateUrl: './image-upload-crop.component.html',
  styleUrls: ['./image-upload-crop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    PorscheDesignSystemModule,
    FlexComponent,
    TranslatePipe,
    ImageCropperComponent,
    DragDropFileDirective,
    SpacerComponent
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploadCropComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ImageUploadCropComponent),
      multi: true
    }
  ]
})
export class ImageUploadCropComponent implements ControlValueAccessor, Validator {
  readonly PDS_THEME_LIGHT_BACKGROUND_BASE = '#ffffff';

  aspectRatio = input<number>(3 / 1);
  fileName = input<string>();
  resizeToWidth = input<number>(0);
  resizeToHeight = input<number>(0);
  dataTestId = input<string>();

  imageChangedEvent = model<File | undefined>(undefined);
  showImage = model<boolean>(false);

  fileDropArea = viewChild<ElementRef>('fileDropArea');

  image: FormControl = new FormControl();

  onDragAndDropFile(files: FileHandle[]): void {
    if (files.length > 0 && (files[0].file.type === 'image/jpg' || files[0].file.type === 'image/png' || files[0].file.type === 'image/jpeg')) {
      this.imageChangedEvent.set(files[0].file);
      this.showImage.set(true);
    }
  }

  fileChangeEvent(event): void {
    if (event.target.files.length > 0) {
      this.imageChangedEvent.set(event.target.files[0]);
      this.showImage.set(true);
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.writeValue(event.base64);
  }

  imageLoaded() {
    this.showImage.set(true);
  }

  loadImageFailed() {
    console.log('image load failed');
  }

  registerOnChange(fn: unknown): void {
    this.image.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: unknown): void {
    this.image.valueChanges.subscribe(fn);
  }

  writeValue(image: unknown): void {
    this.image.patchValue(image, { emitEvent: true });
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.image.valid ? null : control.errors;
  }
}

<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
@if (event()) {
  <mycontent-grid id="{{event()?.title + '-button'}}" class="event-row__container" (click)="redirectToEvent()">
    <mycontent-grid-item [size]="7">
      @if (event()?.isRegistrationClosed) {
        <p-tag class="event-row__event-name-column-tag" data-testid="event-row__tag-registration-closed" [color]="'notification-error-soft'">
          {{ 'adminEventDetail.overview.label.registrationClosed' | translate }}
        </p-tag>
      }
      @if (isConfigurationRequired()) {
        <p-tag class="event-row__event-name-column-tag" [color]="'notification-warning-soft'">
          {{ 'adminMain.listItem.label.configRequired' | translate }}
        </p-tag>
      }
      @if (isCapEvent()) {
        <p-tag class="event-row__event-name-column-tag" [color]="'background-surface'">
          {{ 'adminMain.listItem.label.capEvent' | translate }}
        </p-tag>
      }
      @if (isOpenRegistration()) {
        <p-tag class="event-row__event-name-column-tag" [color]="'background-surface'">
          {{ 'adminEventDetail.overview.label.openRegistration' | translate }}
        </p-tag>
      }
      <p-text class="event-row__event-name-column event-row__event-title" [weight]="'bold'" [size]="'medium'" >
        {{ event()?.title }}
      </p-text>

      <p-text class="event-row__event-name-column" [size]="'x-small'">
        {{ getEventFullFormat(event()) }}
        @if (isCountryUS()) {
          ({{ getTimezone(event()?.startDate) }})
        }
      </p-text>
      <p-text class="event-row__event-name-column-tag" [size]="'x-small'" data-testid="event-row_hostnames">
        <ng-container>
          {{ displayHostnames() }}
        </ng-container>
      </p-text>
    </mycontent-grid-item>
    <mycontent-grid-item [size]="5" [alignSelf]="'center'">
      <div class="event-row__group">
        @if (isAdmin()) {
          <p-button-pure
            id="{{event()?.title + '-delete-button'}}"
            class="event-row__tag event-row__tag-details"
            [attr.data-testId]="'event-admin-delete-open-modal-' + event()?.id"
            [icon]="'none'"
            [hideLabel]="true"
            (click)="openDeleteEventModal($event)">
            <p-icon [name]="'delete'"></p-icon>
          </p-button-pure>
        }
        <p-tag class="event-row__tag" [color]="'notification-error-soft'">
          {{ 'adminMain.listItem.label.cancellations' | translate }}:
          <strong style="white-space: pre-line;">{{ event()?.cancellationCount }}</strong>
        </p-tag>
        <p-tag class="event-row__tag" [color]="'notification-info-soft'">
          {{ 'adminMain.listItem.label.tickets' | translate }}:
          <strong style="white-space: nowrap;">{{ event()?.ticketCount }}</strong>
        </p-tag>
        <p-tag class="event-row__tag" [color]="'background-surface'">
          {{ 'adminMain.listItem.label.capacity' | translate }}:
          <strong style="white-space: nowrap;">{{ event()?.capacity }}</strong>
        </p-tag>
      </div>
    </mycontent-grid-item>
  </mycontent-grid>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  @if (isEventLifecycleModalOpen()) {
    <mycontent-event-lifecycle-modal
      [(isOpen)]="isEventLifecycleModalOpen"
      [country]="country()"
      [event]="event()">
    </mycontent-event-lifecycle-modal>
  }
  @if (isDeleteEventModalOpen()) {
    <mycontent-event-overview-delete-event-modal
      [(isOpen)]="isDeleteEventModalOpen"
      [country]="country()"
      [event]="event()">
    </mycontent-event-overview-delete-event-modal>
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { GhostTextLength } from '../models';
import { getConfigProvider } from '../ngx-ghosts-configuration';
import { GhostComponent } from '../ghost/ghost.component';

@Component({
  selector: 'mycontent-ghost-text-block',
  templateUrl: './ghost-text-block.component.html',
  styleUrls: ['./ghost-text-block.component.scss'],
  standalone: true,
  imports: [
    GhostComponent
  ],
  providers: [getConfigProvider()],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GhostTextBlockComponent {
  @Input() lines: GhostTextLength[];
  @Input() ghostClass: string;

  fillHorizontal(length: GhostTextLength) {
    return length === 'fill';
  }

  ghostContent(length: GhostTextLength) {
    return length === 'fill'
      ? '0'
      : Array(length)
          .fill('0')
          .join('');
  }
}

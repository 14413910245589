/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import {
  Directive,
  HostListener, model
} from '@angular/core';

@Directive({
  selector: '[mycontentAppHover]',
  standalone: true,
})
export class HoverDirective {
  mycontentAppHover = model<boolean>(false);

  @HostListener('mouseenter') onMouseEnter() {
    this.mycontentAppHover.set(true);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.mycontentAppHover.set(false);
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, inject, model, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminLanguageService, Country } from '@admin/shared';
import { TranslatePipe } from '@ngx-translate/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { LanguageFormModel } from '../../models/form.model';

@Component({
  selector: 'mycontent-language-selector-modal',
  templateUrl: './language-selector-modal.component.html',
  styleUrls: ['./language-selector-modal.component.scss'],
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    FormsModule,
    ReactiveFormsModule,
    TranslatePipe
  ]
})
export class LanguageSelectorModalComponent implements OnInit {
  isOpen = model<boolean>();

  language = 'en_GB';
  languages: Country[];
  form: FormGroup<LanguageFormModel>;

  private adminLanguageService: AdminLanguageService = inject(AdminLanguageService);

  ngOnInit(): void {
    this.languages = this.adminLanguageService.getPossibleLanguages();
    this.language = this.adminLanguageService.getLanguageFromStorage();

    this.form = new FormGroup({
      language: new FormControl<string>(this.language ? this.language : 'en_GB')
    });
  }


  switchTranslation() {
    const selectedLanguage = this.form.controls.language.value;
    this.adminLanguageService.switchLanguage(selectedLanguage);
    this.close();
  }

  close() {
    this.isOpen.set(false);
  }
}

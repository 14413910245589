/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, inject, OnInit, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { LanguageSelectorModalComponent } from '../language-selector-modal/language-selector-modal.component';
import { AssumeRoleModalComponent } from '../assume-role-modal/assume-role-modal.component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { RouterLink } from '@angular/router';
import { AuthoritiesFacade, SpaceModel, SpaceService } from '@admin/shared';
import { FlexComponent, GridComponent, GridItemComponent } from '@ui/shared/ui';
import { AuthenticationService } from '../../services/auth/authentication.service';

@Component({
  selector: 'mycontent-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    AssumeRoleModalComponent,
    LanguageSelectorModalComponent,
    AsyncPipe,
    TranslatePipe,
    RouterLink,
    GridComponent,
    GridItemComponent,
    FlexComponent
  ]
})
export class HeaderComponent implements OnInit {
  username = '<MyName>';
  isLanguageModalOpen = signal<boolean>(false);
  isAssumeRoleModalOpen = signal<boolean>(false);

  private spaceService = inject(SpaceService);
  private authoritiesService = inject(AuthoritiesFacade);
  private authenticationService = inject(AuthenticationService);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  spaces$: Observable<SpaceModel[]> = this.spaceService.getAvailableSpaces();

  get isAdmin(): boolean {
    return this.authoritiesService.isAdmin();
  }

  get isImpersonated(): boolean {
    return this.authoritiesService.isImpersonated();
  }

  ngOnInit(): void {
    this.username = sessionStorage.getItem('user');
  }

  logout(): void {
    this.authenticationService.handleLogOut();
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, model, OnInit, output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { PorscheDesignSystemModule, TextFieldWrapperState } from '@porsche-design-system/components-angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { FlexComponent } from '@ui/shared/ui';
import { BookingService } from '../../../services/endpoints/booking.service';
import { ParticipantFacade } from '../../../facades/participant.facade';
import { AddAttendeesResponse, AttendeeError } from '../../../models/add-user.model';
import { QuickAddFormModel } from '../../../models/participant.model';

@UntilDestroy()
@Component({
  selector: 'mycontent-participant-quick-add-modal',
  templateUrl: './participant-quick-add-modal.component.html',
  styleUrls: ['./participant-quick-add-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    FormsModule,
    ReactiveFormsModule,
    FlexComponent,
    TranslatePipe
  ]
})
export class ParticipantQuickAddModalComponent implements OnInit {
  country = input<string>();
  eventId = input<string>();

  isOpen = model<boolean>();

  refreshEventDetails = output<void>();

  isSubmitting = false;
  hasError = false;
  errorMessage = '';
  form: FormGroup<QuickAddFormModel>;

  private bookingService = inject(BookingService);
  private participantFacade = inject(ParticipantFacade);
  private translateService = inject(TranslateService);

  ngOnInit(): void {
    this.form = new FormGroup({
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  submit() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }
    this.isSubmitting = true;

    this.bookingService.quickAddAttendeeToEvent(this.country(), this.eventId(), {
      attendees: [{
        firstname: this.form.controls.firstname.value,
        lastname: this.form.controls.lastname.value,
        email: this.form.controls.email.value
      }]
    }).subscribe({
      next: (response: AddAttendeesResponse) => {
        if (response.attendees[0].errors.length > 0) {
          this.hasError = true;
          this.errorMessage = response.attendees[0].errors.map((error: AttendeeError) => error.message).join('\n');
        } else {
          this.participantFacade.updateParticipantAction({
            email: this.form.controls.email.value,
            inlineLabel: this.translateService.instant('adminEventDetail.inlineNotification.label.quickAddSuccessAction'),
            inlineHeader: this.translateService.instant('adminEventDetail.inlineNotification.header.quickAddSuccess', { userEmail: this.form.controls.email.value }),
            inlineParagraph: this.translateService.instant('adminEventDetail.inlineNotification.paragraph.quickAddSuccess')
          });
          this.participantFacade.getParticipants();
          this.refreshEventDetails.emit();
          this.close();
        }
      },
      error: error => {
        this.hasError = true;
        this.handleError(error);
      },
      complete: () => {
        this.isSubmitting = false;
      }
    });
  }

  getFormError(controlName: string, stateFor: string) {
    return this.form.controls[controlName]?.errors?.[stateFor] === true;
  }

  getFormState(controlName: string): TextFieldWrapperState {
    if (this.form.controls[controlName]?.touched === true) {
      return this.form.controls[controlName].status === 'INVALID' ? 'error' : 'none';
    }
    return 'none';
  }

  close() {
    this.form.reset();
    this.isSubmitting = false;
    this.hasError = false;
    this.errorMessage = '';
    this.isOpen.set(false);
  }

  onDismiss(): void {
    this.isOpen.set(false);
  }

  private handleError(error: HttpErrorResponse) {
    this.errorMessage = `
        Unexpected error occurred.
        ${error.message}

        StatusCode:${error.status}
        RequestId: ${error.headers.get('X-Trace-Id')}`;
  }
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isNonApiRequest, WINDOW, DO_NOT_TRACK } from '@ui/shared/util';
import { v4 as uuid } from 'uuid';

export const XTRACE_ID_PREFIX = 'XTRACE_ID_PREFIX';

@Injectable()
export class XTraceInterceptor implements HttpInterceptor {

  window = inject(WINDOW);
  doNotTrack = inject(DO_NOT_TRACK);

  constructor(@Inject(XTRACE_ID_PREFIX) private traceIdPrefix: string) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (isNonApiRequest(request)) {
      return next.handle(request);
    }
    const xtraceId = `${this.traceIdPrefix}-${uuid()}`;

    const requestClone = request.clone({
      setHeaders: {
        'x-trace-id': xtraceId,
      },
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newrelic = (this.window as any).newrelic;
    if (!this.doNotTrack && newrelic) {
      newrelic.setCustomAttribute('xtraceId', xtraceId);
    }

    return next.handle(requestClone);
  }
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export function teaserIconSVG(iconColor = '#494e51', backgroundColor = '#313639') {
  const svg = `
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 393 295.9" style="enable-background:new 0 0 393 295.9; background-color:${backgroundColor}" xml:space="preserve">
  <style type="text/css">
    .st0{fill:none;}
    .st1{fill:${iconColor};}
    .st2{fill-rule:evenodd;clip-rule:evenodd;fill:${iconColor};}
  </style>
  <g>
    <path class="st1" d="M194.7,146h-23.1v-23.1h23.1V146z M175.5,142.2h15.4v-15.4h-15.4V142.2z"/>
    <path class="st1" d="M194.7,172.9h-23.1v-23.1h23.1V172.9z M175.5,169.1h15.4v-15.4h-15.4V169.1z"/>
    <path class="st1" d="M221.7,172.9h-23.1v-23.1h23.1V172.9z M202.4,169.1h15.4v-15.4h-15.4V169.1z"/>
    <rect id="filled" x="198.6" y="122.9" class="st2" width="23.1" height="23.1"/>
  </g>
  </svg>`;

  return `data:image/svg+xml;base64,${window.btoa(svg)}`;
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TextFormatterUtils {

  convertUnderscoreToHyphen(value: string): string {
    return value.replace('_', '-');
  }
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { LOCALE_ID, Provider } from '@angular/core';
import { LOCALE } from '../locale-token';
import { Locale } from '../models/locale';

export function provideLocaleId(): Provider {
  return { provide: LOCALE_ID, useFactory: (locale: Locale) => locale.code, deps: [LOCALE] };
}

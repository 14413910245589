/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */
// @ts-nocheck

import { capitalize, cloneDeep, merge, snakeCase } from 'lodash-es';
import { WINDOW } from '../window/window.provider';
import { Inject, Injectable, Optional } from '@angular/core';
import { format } from 'date-fns';
import { OneGAComponentClick, OneGADataLayer } from './analytics.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  application = 'PAGMyPorsche';
  currentPage$: ReplaySubject<string> = new ReplaySubject(1);

  private DATE_FORMAT = 'yyyy-MM-dd\'T\'HH:mm:ssxxx';

  private dataLayer: OneGADataLayer;

  constructor(
    @Inject(WINDOW) private window: { pagData: Record<string, unknown>[]; oneGa: unknown[] },
    @Optional() router: Router,
    @Optional() activatedRoute: ActivatedRoute,
  ) {
    this.window.pagData = [];
    if (router && activatedRoute) {
      router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => activatedRoute),
          map((route: ActivatedRoute) => {
            let mappedRoute = route;
            while (mappedRoute.firstChild) {
              mappedRoute = mappedRoute.firstChild;
            }
            return mappedRoute;
          }),
          switchMap((route: ActivatedRoute) => route.data),
          filter(data => data && data['gaPageName']),
          map(data => data['gaPageName']),
        )
        .subscribe(pageName => this.currentPage$.next(pageName));
    }
  }

  public init(): void {
    this.window.pagData = this.window.pagData || [];
    this.window.oneGa = this.window.oneGa || [];

    this.window.oneGa.push({ consentMode_activated: true });
    this.loadGTMScript();
  }

  loadGTMScript() {
    this.window.oneGa.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

    const script = window.document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-W2NKNJZ&l=oneGa';
    window.document.head.append(script);
  }

  trackEvent(event: string, componentClick: OneGAComponentClick, additionalData: Record<string, unknown> = {}, label?: string) {
    const clickElementId = componentClick.disableSnakeCase ? componentClick.clickElementId : snakeCase(componentClick.clickElementId);
    const clickElementName = componentClick.disableSnakeCase ? componentClick.clickElementName : snakeCase(componentClick.clickElementName);

    delete componentClick.disableSnakeCase;

    if (label) {
      this.pushDataLayer(
        merge(additionalData, {
          componentClick: {
            ...componentClick,
            clickElementId,
            clickElementName,
          },
          context: {
            timestamp: this.getDate(),
            eventAction: `${this.application}_${label}_${capitalize(event)}`,
          },
          pushedToGTM: false,
        }),
      );
    } else {
      this.currentPage$.pipe(take(1)).subscribe(pageName =>
        this.pushDataLayer(
          merge(additionalData, {
            componentClick: {
              ...componentClick,
              clickElementId,
              clickElementName,
            },
            context: {
              timestamp: this.getDate(),
              eventAction: `${this.application}_${pageName}_${capitalize(event)}`,
            },
            pushedToGTM: false,
          }),
        ),
      );
    }
  }

  trackPageView(label: string) {
    const event = 'pageload';
    this.setData({
      pageExperience: {
        pageName: snakeCase(label),
        pageId: window.location.pathname,
      },
    });
    this.pushDataLayer({
      context: {
        timestamp: this.getDate(),
        eventAction: `${this.application}_${label}_${capitalize(event)}`,
      },
      pushedToGTM: false,
    });
  }

  setData(data: Record<string, unknown>) {
    this.dataLayer = merge(this.dataLayer, data);
  }

  getData() {
    return this.dataLayer;
  }

  private pushDataLayer(data: Record<string, unknown>) {
    this.window.pagData?.push(merge(cloneDeep(this.dataLayer), data));
  }

  private getDate() {
    return format(new Date(), this.DATE_FORMAT);
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, model, output, signal } from '@angular/core';
import { PorscheDesignSystemModule, ToastManager, ToastMessage } from '@porsche-design-system/components-angular';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BannerNotificationService, FlexComponent, SpacerComponent } from '@ui/shared/ui';
import { BookingService } from '../../../services/endpoints/booking.service';
import { MyContentErrorResponse } from '../../../models/error-response.model.';

@UntilDestroy()
@Component({
  selector: 'mycontent-participant-delete-invitee-modal',
  templateUrl: './participant-delete-invitee-modal.component.html',
  styleUrls: ['./participant-delete-invitee-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    FlexComponent,
    TranslatePipe,
    SpacerComponent
  ]
})
export class ParticipantDeleteInviteeModalComponent {
  country = input<string>();
  eventId = input<string>();
  attendeeId = input<string>();

  isOpen = model<boolean>();

  refreshParticipantsModel = output<void>();

  loading = signal<boolean>(false);

  private bookingService = inject(BookingService);
  private toastManager = inject(ToastManager);
  private bannerNotificationService = inject(BannerNotificationService);
  private translateService = inject(TranslateService);

  submit() {
    this.loading.set(true);
    return this.bookingService
      .deleteAttendeeFromEvent(this.country(), this.eventId(), this.attendeeId())
      .subscribe({
        next: () => {
          this.loading.set(false);
          this.toastManager.addMessage({
            text: this.translateService.instant('adminEventDetail.toast.deleteInviteeSuccessful'),
            state: 'success'
          } as ToastMessage);
          this.refreshParticipantsModel.emit();
          this.close();
        },
        error: (response: MyContentErrorResponse) => {
          this.loading.set(false);
          this.bannerNotificationService.publishMessage({
            state: 'error',
            headline: this.translateService.instant('adminEventDetail.inlineNotification.header.deleteInvitee'),
            message: this.translateService.instant('adminEventDetail.inlineNotification.paragraph.deleteInvitee')
          });
          this.close();
        }
      });
  }

  close() {
    this.isOpen.set(false);
  }

  onDismiss(): void {
    this.isOpen.set(false);
  }
}

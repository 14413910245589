/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, input, OnChanges, output, SimpleChanges } from '@angular/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'mycontent-show-more-box',
  templateUrl: './show-more-box.component.html',
  styleUrls: ['./show-more-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    TranslatePipe
  ]
})
export class ShowMoreBoxComponent implements OnChanges {
  elementId = input<string>();
  numberOfItems = input<number>();

  showMoreItems = output<number>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.numberOfItems) {
      this.showMore();
    }
  }

  showMore(): void {
    this.showMoreItems.emit(this.numberOfItems());
  }
}

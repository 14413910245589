/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function isDateInThePastValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const now = Date.now();
    const selectedDate = Date.parse(control.value);
    const isDateInPast = selectedDate < now;
    return isDateInPast ? {isDateInPast: true} : null;
  };
}

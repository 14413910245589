/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { importProvidersFrom } from '@angular/core';
import { AppComponent } from './app/app.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { OAuthModule } from 'angular-oauth2-oidc';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerService, ENVIRONMENT_ADMIN } from '@ui/shared/util';
import { authoritiesServiceProvider } from './app/providers/authorities-service.provider';
import { initTranslateServiceProvider } from './app/providers';
import { RoleInterceptor } from './app/interceptors/role.interceptor';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { bootstrapApplication, BrowserModule, Title } from '@angular/platform-browser';
import { AuthGuardService } from './app/services/auth/auth-guard.service';
import { PreloadAllModules, provideRouter, withComponentInputBinding, withPreloading, withRouterConfig } from '@angular/router';
import { APP_ROUTES } from './app/app-routes';
import { PollingService, TicketService } from '@admin/page-management';
import { SupportService } from './app/services/endpoints/support.service';
import { AdminTranslateLoaderService } from './app/services/admin-translate-loader.service';
import { environment } from './environments/environment';
import { AdminLanguageService, CountryService, localeIdProvider, localeRegistrationProvider, LocaleRegistrationService } from '@admin/shared';
import { xTraceInterceptorProvider, xTracePrefixProvider } from '@ui/shared/data-access';

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      CommonModule,
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      OAuthModule.forRoot(),
      PorscheDesignSystemModule,
      ReactiveFormsModule,
      MatSelectModule,
      MatOptionModule,
      NgOptimizedImage,
      GoogleMapsModule
    ),
    provideTranslateService({
      loader: {provide: TranslateLoader, useClass: AdminTranslateLoaderService},
    }),
    AuthGuardService,
    Title,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: RoleInterceptor, multi: true },
    initTranslateServiceProvider,
    authoritiesServiceProvider,
    LocaleRegistrationService,
    CountryService,
    DatePickerService,
    PollingService,
    SupportService,
    TicketService,
    localeRegistrationProvider,
    localeIdProvider,
    AdminLanguageService,
    { provide: ENVIRONMENT_ADMIN, useValue: environment },
    provideRouter(APP_ROUTES,
      withPreloading(PreloadAllModules),
      withComponentInputBinding(),
      withRouterConfig({ paramsInheritanceStrategy: 'always' })
    ),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    xTraceInterceptorProvider,
    xTracePrefixProvider,
  ]
})
  .catch(err => console.log(err));

<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<section class="event-details-header">
  <mycontent-event-management-header
    [eventDetails]="eventDetails$ | async"
    [eventId]="eventId()"
    [eventDetailsLoading]="eventDetailsLoading$ | async"
    [currentSpaceId]="currentSpaceId$ | async"
    [country]="country()">
  </mycontent-event-management-header>
</section>
<section class="event-details-header">
  <mycontent-event-management-table
    [country]="country()"
    [timeslots]="timeslots$ | async"
    [timeslotsLoading]="timeslotsLoading$ | async"
    [eventDetails]="eventDetails$ |async"
    [eventId]="eventId()"
    [isDealer]="isDealer()"
    [isAdmin]="isAdmin()"
    [isMarket]="isMarket()"
    [isParticipantsLoading]="participantsLoading$ | async"
    [participantsError]="participantsError$ | async"
    [selectedInvitationStates]="selectedInvitationStates$ | async"
    (refreshTimeslots)="refreshTimeslots()"
    (refreshEventDetails)="refreshEventDetails()">
  </mycontent-event-management-table>
</section>
<mycontent-flex [justifyContent]="'center'" [direction]="'row'">
  @if ((eventDetails$ | async) === false) {
    <p-spinner class="event-details__loading-spinner" [size]="'large'" aria-label="Loading event details"></p-spinner>
  }
</mycontent-flex>

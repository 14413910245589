/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, computed, inject, input, Input, OnDestroy, OnInit, output, Signal, signal, WritableSignal } from '@angular/core';
import { PorscheDesignSystemModule, TagColor } from '@porsche-design-system/components-angular';
import { DateFormatterService } from '@ui/shared/util';
import { Subscription } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { ParticipantSendInviteAllModalComponent } from '../../participant/participant-send-invite-all-modal/participant-send-invite-all-modal.component';
import { ParticipantExportCsvModalComponent } from '../../participant/participant-export-csv-modal/participant-export-csv-modal.component';
import { ParticipantImportCsvModalComponent } from '../../participant/participant-import-csv-modal/participant-import-csv-modal.component';
import { ParticipantQuickAddModalComponent } from '../../participant/participant-quick-add-modal/participant-quick-add-modal.component';
import { EventManagementModel } from '@admin/shared';
import { ParticipantFacade } from '../../../facades/participant.facade';
import { FetchParticipantModel } from '../../../models/participant.model';
import { FilterParticipantsService } from '../../../services/filter-participants.service';
import { SpacerComponent } from '@ui/shared/ui';
import { TimeslotModel } from '@ui/shared/feature-registration';
import { ParticipantStateType } from '../../../models/invitation-state.model';

@Component({
  selector: 'mycontent-event-management-attendee-invite',
  templateUrl: './event-management-attendee-invite.component.html',
  styleUrls: ['./event-management-attendee-invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    ParticipantQuickAddModalComponent,
    ParticipantImportCsvModalComponent,
    ParticipantExportCsvModalComponent,
    ParticipantSendInviteAllModalComponent,
    TranslatePipe,
    SpacerComponent
  ]
})
export class EventManagementAttendeeInviteComponent implements OnInit, OnDestroy {
  isAdmin = input<boolean>();
  isMarket = input<boolean>();
  isDealer = input<boolean>();
  country = input<string>();
  selectedInvitationStates = input<string[]>();
  eventId = input<string>();
  timeslots = input<TimeslotModel[]>();

  refreshEventDetails = output<void>();

  eventDetails: WritableSignal<EventManagementModel> = signal<EventManagementModel>(undefined);
  eventFilters: WritableSignal<FetchParticipantModel> = signal<FetchParticipantModel>(undefined);

  isCapEvent: Signal<boolean> = computed(() => this.eventDetails()?.managementType === 'CAP');

  isQuickAddModalOpen = signal(false);
  isImportCSVOpen = signal(false);
  isExportCSVOpen = signal(false);
  isSendInvitationOpen = signal(false);

  private participantFacade = inject(ParticipantFacade);
  private filterService = inject(FilterParticipantsService);
  private dateFormatterService = inject(DateFormatterService);
  private filterSubscription: Subscription;

  @Input({ required: true }) set setEventDetails(eventDetails: EventManagementModel) {
    this.eventDetails.set(eventDetails);
  }

  getInvitationStateColour = (state: ParticipantStateType): TagColor => this.filterService.getInvitationStateColour(state);

  ngOnInit(): void {
    this.filterSubscription = this.participantFacade.participantsFilter$.subscribe(filter => {
      this.eventFilters.set(filter);
    });
  }

  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
  }

  onQuickAddClick(): void {
    this.isQuickAddModalOpen.set(true);
  }

  onImportCSVClick(): void {
    this.isImportCSVOpen.set(true);
  }

  onExportCSVClick(): void {
    this.isExportCSVOpen.set(true);
  }

  onSendInvitationClick(): void {
    this.isSendInvitationOpen.set(true);
  }

  formatTimeslotTagDate(timeslotId: string): string {
    const timeslot = this.timeslots().find(ts => ts.id === timeslotId);
    if (timeslot) {
      return this.dateFormatterService.formatFullEventRange(timeslot.startDate, timeslot.endDate);
    } else {
      return '-----';
    }
  }

  removeFilter(isStatusFilter: boolean, filterValue: string) {
    const filters = this.participantFacade.getParticipantFilters();
    this.participantFacade.getParticipants({
      ...filters,
      invitationStates: isStatusFilter ? filters.invitationStates.filter(status => status !== filterValue) : filters.invitationStates,
      timeslotIds: !isStatusFilter ? filters.timeslotIds.filter(timeslot => timeslot !== filterValue) : filters.timeslotIds
    });
  }
}

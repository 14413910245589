/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, HostListener, inject } from '@angular/core';
import { FooterComponent } from './components/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthenticationService } from './services/auth/authentication.service';

@Component({
  selector: 'mycontent-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    HeaderComponent,
    RouterOutlet,
    FooterComponent
  ]
})
export class AppComponent {
  private oAuthService = inject(OAuthService);
  private authenticationService = inject(AuthenticationService);

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange(): void {
    this.checkHiddenDocument().then();
  }

  checkHiddenDocument = async () => {
    if (!document.hidden) {
      if (!this.oAuthService.hasValidIdToken()) {
        try {
          await this.oAuthService.refreshToken();
        } catch {
          try {
            await this.authenticationService.handleLogin();
          } catch {
            this.authenticationService.handleLogOut();
          }
        }
      }
    }
  };
}

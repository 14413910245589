/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { ENVIRONMENT_ADMIN } from '@ui/shared/util';

export class TicketService {

  private ADMIN_PATH = 'admin';

  private http: HttpClient = inject(HttpClient);
  private oAuthService: OAuthService = inject(OAuthService);
  private environment = inject(ENVIRONMENT_ADMIN);


  getTicket(eventId: string, country: string): Observable<Blob> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/actions:create-sample-ticket`;

    const headers = this.getAuthHeader();

    return this.http.get(url, {headers, responseType: 'blob'});
  }

  getTicketPerCustomer(eventId: string, attendeeId: string, country: string): Observable<Blob> {
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/invitations/${attendeeId}/ticket`;

    const headers = this.getAuthHeader();

    return this.http.get(url, {headers, responseType: 'blob'});
  }


  private getAuthHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${this.oAuthService.getIdToken()}`);
  }
}

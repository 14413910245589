/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@ui/shared/util';

@Injectable({
  providedIn: 'root',
})
export class CountryService {

  private window: Window = inject(WINDOW);

  getCountryFromUrl(): string {
    const url = this.window.location.href;
    const [,,,country] = url.split('/');
    return country.toUpperCase();
  }
}

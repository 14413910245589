/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export interface RoleModel {
  dealer: string;
  market: string;
  type: RoleTypeEnum;
}

export enum RoleTypeEnum {
  MARKET, DEALER
}

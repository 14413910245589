/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import {
  Directive,
  Input,
  ComponentFactory,
  ComponentRef,
  TemplateRef,
  ViewContainerRef,
  ComponentFactoryResolver,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { GhostComponent } from '../ghost/ghost.component';
import { getConfigProvider } from '../ngx-ghosts-configuration';

@Directive({
  selector: '[mycontentGhostBox]',
  standalone: true,
  providers: [getConfigProvider()],
})
export class GhostBoxDirective implements OnInit, OnChanges {
  @Input() mycontentGhostBoxActive: boolean;
  @Input() mycontentGhostBoxGhostClass: string;
  @Input() myContentGhostBoxHeight: number;
  @Input() mycontentGhostBoxWidth: number;

  private componentFactory: ComponentFactory<GhostComponent>;
  private componentRef: ComponentRef<GhostComponent>;

  constructor(
    private templateRef: TemplateRef<HTMLElement>,
    private viewContainer: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(GhostComponent);
  }

  ngOnInit(): void {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(GhostComponent);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.height || changes.width) {
      this.updateGhostSize();
    }
    if (changes.ghostClass) {
      this.updateGhostClass();
    }
    if (changes.active) {
      this.toggleGhost();
    }
  }

  private toggleGhost() {
    if (this.mycontentGhostBoxActive) {
      this.reattachGhost();
      this.updateGhostSize();
      this.updateGhostClass();
    } else {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  private reattachGhost() {
    this.viewContainer.clear();
    this.componentRef = this.viewContainer.createComponent(this.componentFactory);
  }

  private updateGhostClass() {
    if (this.componentRef) {
      this.componentRef.instance.ghostClass = this.mycontentGhostBoxGhostClass;
    }
  }

  private updateGhostSize() {
    if (!this.componentRef) {
      return;
    }
    if (typeof this.myContentGhostBoxHeight === 'number' && typeof this.mycontentGhostBoxWidth === 'number') {
      this.componentRef.instance.fillHorizontal = false;
      this.componentRef.instance.fillVertical = false;
      this.componentRef.instance.height = this.myContentGhostBoxHeight;
      this.componentRef.instance.width = this.mycontentGhostBoxWidth;
    } else {
      this.componentRef.instance.fillHorizontal = true;
      this.componentRef.instance.fillVertical = true;
      this.componentRef.instance.height = null;
      this.componentRef.instance.width = null;
    }
  }
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Observable } from 'rxjs';
import { CookieConsentServiceName } from './cookie-consent-typings';

export interface CookieConsentStatusProvider {

  init(): Promise<void>;

  isConsentGiven(serviceName: CookieConsentServiceName): Observable<boolean>;

  getCookiePolicyUrl(): string;

  isConsentRequired(): boolean;

  openCookieInformationModal(serviceName: CookieConsentServiceName);

  updateConsent(consentSystemName: CookieConsentServiceName): void;

  getConsentDisplayName(consentSystemName: CookieConsentServiceName): string;
}

<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<div class="edit-timeslot-dialog-container">
  <dialog class="edit-timeslot-dialog" [open]="isModalOpen()" (click)="handleModalClose($event, 'innerDiv')">
    <div class="edit-timeslot-dialog__close-button-section">
      <p-button-pure
        class="edit-timeslot-dialog__close-button"
        [icon]="'close'"
        [size]="'medium'"
        (click)="handleModalClose($event, 'closeButton')"
      ></p-button-pure>
    </div>
    <div class="edit-timeslot-dialog__header-section">
      <p-heading slot="'header'" [size]="'large'">
        {{ isEditModal() ? ('details.timeslot.headerEditTimeslotModal' | translate) : ('details.timeslot.headerNewTimeslotModal' | translate) }}
      </p-heading>
      <mycontent-spacer [size]="'small'"></mycontent-spacer>
    </div>
    <div class="edit-timeslot-dialog__body-section">
      <form [formGroup]="form" (ngSubmit)="saveTimeslotAndCloseModal()">
        <mycontent-flex [direction]="'row'" [justifyContent]="'flex-start'">
          <mycontent-datepicker-form
            [attr.data-testid]="'timeslot-datepicker-start-date'"
            formControlName="startDate"
            [controlLabel]="'details.fieldStartDate' | translate"
            [format]="format()"
            [elementId]="'edit-timeslot-input-start-date'"
            [calendarStartDate]="initCalenderStartDate()"
            [country]="country()"
            [dataTestId]="'timeslot-datepicker-toggle-start-date'"
            [errorState]="getFormControlError('startDate')"
          ></mycontent-datepicker-form>
          <p-text-field-wrapper class="edit-timeslot-modal-input-field__time space-left" [state]="getFormControlState('startTime')">
            <input id="edit-timeslot-input-start-time" [type]="'time'" formControlName="startTime"/>
          </p-text-field-wrapper>
        </mycontent-flex>
        @if (form?.get('startDate').errors?.['isDateInPast']) {
          <div class="event-timeslot-edit__error">
            <p-icon [name]="'information'" [color]="'notification-error'"></p-icon>
            <p-text [color]="'notification-error'">{{ 'details.timeslot.dateInPastErrorMessage' | translate }}</p-text>
          </div>
        }
        <mycontent-spacer [size]="'small'"></mycontent-spacer>
        <mycontent-flex [direction]="'row'" [justifyContent]="'flex-start'">
          <mycontent-datepicker-form
            [attr.data-testid]="'timeslot-datepicker-end-date'"
            formControlName="endDate"
            [controlLabel]="'details.fieldEndDate' | translate"
            [format]="format()"
            [elementId]="'edit-timeslot-input-end-date'"
            [calendarStartDate]="this.calenderEndDateInitializer()"
            [country]="country()"
            [dataTestId]="'timeslot-datepicker-toggle-end-date'"
            [errorState]="getFormControlError('endDate')"
          ></mycontent-datepicker-form>
          <p-text-field-wrapper class="edit-timeslot-modal-input-field__time space-left" [state]="getFormControlState('endTime')">
            <input id="edit-timeslot-input-end-time" [type]="'time'" formControlName="endTime"/>
          </p-text-field-wrapper>
        </mycontent-flex>
        @if (form?.errors?.['isEndDateAfterStartDate']) {
          <div class="event-timeslot-edit__error">
            <p-icon [name]="'information'" [color]="'notification-error'"></p-icon>
            <p-text [color]="'notification-error'">{{ 'details.timeslot.dateErrorMessage' | translate }}</p-text>
          </div>
        }
        @if (form?.get('endDate').errors?.['isDateInPast']) {
          <div class="event-timeslot-edit__error">
            <p-icon [name]="'information'" [color]="'notification-error'"></p-icon>
            <p-text [color]="'notification-error'">{{ 'details.timeslot.dateInPastErrorMessage' | translate }}</p-text>
          </div>
        }
        <mycontent-grid>
          <mycontent-grid-item [size]="5" [usePadding]="false">
            <p-text-field-wrapper
              class="edit-timeslot-modal-input-field__last-line edit-timeslot-modal-input-field__capacity space-top"
              [label]="'details.timeslot.capacity' | translate"
              [state]="getFormControlState('capacity')"
            >
              <input id="edit-timeslot-input-capacity" type="number" formControlName="capacity"/>
            </p-text-field-wrapper>
            @if (form?.get('capacity').errors?.['isFilled']) {
              <div class="event-timeslot-edit__error">
                <p-icon [name]="'information'" [color]="'notification-error'"></p-icon>
                <p-text [color]="'notification-error'">{{ 'details.timeslot.numberErrorMessage' | translate }}</p-text>
              </div>
            }
            @if (form?.get('capacity').errors?.['capacitySmallerRegisteredUsers']) {
              <div class="event-timeslot-edit__error">
                <p-icon [name]="'information'" [color]="'notification-error'"></p-icon>
                <p-text [color]="'notification-error'">{{ 'details.timeslot.capacitySmallerRegisteredUsersError' | translate }}</p-text>
              </div>
            }
          </mycontent-grid-item>
          <mycontent-grid-item [size]="7">
            <p-text-field-wrapper
              class="edit-timeslot-modal-input-field__last-line space-top"
              [state]="getFormControlState('maxCountOfAttendeesPerTicket')"
            >
          <span slot="label">
            {{ 'details.fieldCapacity' | translate }}
            <p-popover [direction]="'right'">{{ 'details.fieldMaxCountOfAttendeesInfo' | translate }}</p-popover>
          </span>
              <input id="edit-timeslot-input-max-attendee-count" type="number" formControlName="maxCountOfAttendeesPerTicket"/>
            </p-text-field-wrapper>
            @if (form?.get('maxCountOfAttendeesPerTicket').errors?.['isFilled']) {
              <div class="event-timeslot-edit__error">
                <p-icon [name]="'information'" [color]="'notification-error'"></p-icon>
                <p-text [color]="'notification-error'">{{ 'details.timeslot.numberErrorMessage' | translate }}</p-text>
              </div>
            }
            @if (form?.errors?.['isPerAttendeeCapacityGreater']) {
              <div class="event-timeslot-edit__error">
                <p-icon [name]="'information'" [color]="'notification-error'"></p-icon>
                <p-text [color]="'notification-error'">{{ 'details.timeslot.tickersPerAttendeeGreaterCapacity' | translate }}</p-text>
              </div>
            }
            @if (form?.get('maxCountOfAttendeesPerTicket').errors?.['isPerAttendeeGreaterThan25']) {
              <div class="event-timeslot-edit__error">
                <p-icon [name]="'information'" [color]="'notification-error'"></p-icon>
                <p-text [color]="'notification-error'">{{ 'adminEventDetail.timeslots.listItem.label.max25Error' | translate }}</p-text>
              </div>
            }
          </mycontent-grid-item>
        </mycontent-grid>
        <mycontent-flex [direction]="'row'" [justifyContent]="'flex-start'">
          <mycontent-spacer [size]="'small'"></mycontent-spacer>
          <p-button-group>
            <p-button
              id="edit-timeslot-button-cancel"
              [type]="'button'"
              [variant]="'secondary'"
              [icon]="'close'"
              (click)="handleModalClose($event, 'cancelButton')"
            >
              {{ 'buttonCancel' | translate }}
            </p-button>
            <p-button id="edit-timeslot-button-save" [type]="'submit'" [variant]="'primary'" [icon]="'check'" [disabled]="form?.invalid">
              {{ 'adminEventDetail.dialog.button.save' | translate }}
            </p-button>
          </p-button-group>
        </mycontent-flex>
      </form>
    </div>
  </dialog>
</div>

<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<main class="content" id="content">
  <main class="content-grid">
    <div class="content-grid-item">
      <mycontent-header></mycontent-header>
      <router-outlet></router-outlet>
    </div>
  </main>
</main>
<mycontent-footer id="footer"></mycontent-footer>

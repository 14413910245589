/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core';
import {
  ch_EN_DateFormat,
  defaultDateFormat,
  gbDateFormat,
  it_EN_DateFormat,
  ja_EN_DateFormat,
  ja_JP_DateFormat,
  krDateFormat,
  myDateFormat,
  nz_auDateFormat,
  sgDateFormat,
  twDateFormat,
  usDateFormat
} from './resources/admin-country-date-formats';
import { DatePickerFormatModel } from './model/date-picker-format.model';
// @ts-ignore
import moment from 'moment';
import { AU, CH, DE, de_DE, en_GB, ES, FR, fr_FR, GB, IT, it_IT, JP, KR, MY, NZ, PT, SG, TW, US } from './resources/admin-country-locale-consts';

@Injectable({
  providedIn: 'root',
})
export class DatePickerService {

  /*
   TODO: @Adam Tonge: leftovers here are mainly used in the timeslot edit modal and create.
   If a custom solution is needed we should move this functionality into the component. Its not really shared functionality.
   */

  // dateString: 2021-08-01T12:00:00+01:00
  private timezoneSuffix = 6;
  private timePosition = 1;
  private dateStringDivider = 'T';
  private utcOffset = 'Z';

  getLocaleFormatForMoment(country: string): string {
    switch (country) {
      case US:
      case GB:
      case SG:
        return 'en';
      case DE:
        return 'de';
      case JP:
        if (this.getLocale() === en_GB) {
          return 'en';
        } else {
          return 'ja';
        }
      case PT:
        return 'pt';
      case ES:
        return 'es';
      case FR:
        return 'fr';
      case CH:
        switch (this.getLocale()) {
          case de_DE:
            return 'de';
          case en_GB:
            return 'en';
          case fr_FR:
            return 'fr';
          case it_IT:
            return 'it';
          default:
            return 'de';
        }
      case IT:
        if (this.getLocale() === en_GB) {
          return 'en';
        } else {
          return 'it';
        }
      default:
        return 'en';
    }
  }

  getDateFormat(country?: string): DatePickerFormatModel {
    switch (country) {
      case GB:
        return gbDateFormat;
      case NZ:
        return nz_auDateFormat;
      case AU:
        return nz_auDateFormat;
      case SG:
        return sgDateFormat;
      case US:
        return usDateFormat;
      case JP:
        return this.getDateFormatForJapan(this.getLocale());
      case KR:
        return krDateFormat;
      case CH:
        return this.getDateFormatForSwitzerland(this.getLocale());
      case IT:
        return this.getDateFormatForItaly(this.getLocale());
      case MY:
        return myDateFormat;
      case TW:
        return twDateFormat;
      default:
        return defaultDateFormat; // BE, NO, PT, ES, DE, FR
    }
  }

  getDateFormatForJapan(locale?: string): DatePickerFormatModel {
    if (locale === en_GB) {
      return ja_EN_DateFormat;
    } else {
      return ja_JP_DateFormat;
    }
  }

  getDateFormatForSwitzerland(locale?: string): DatePickerFormatModel {
    if (locale === en_GB) {
      return ch_EN_DateFormat;
    } else {
      return defaultDateFormat;
    }
  }

  getDateFormatForItaly(locale?: string): DatePickerFormatModel {
    if (locale === en_GB) {
      return it_EN_DateFormat;
    } else {
      return defaultDateFormat;
    }
  }

  getLocale(): string {
    return localStorage.getItem('language');
  }

  getDateStringFromEpoch(epoch: number): string {
    return moment(epoch).format('yyyy-MM-DD');
  }

  getTimeAndRemoveTimezoneFromDateTimeString(dateString: string): string {
    const str = dateString.split(this.dateStringDivider)[this.timePosition];
    return this.removeTimezoneFromDateString(str);
  }

  removeTimezoneFromDateString(dateString: string): string {
    if (dateString) {
      if (dateString.endsWith(this.utcOffset)) {
        return dateString.substring(0, dateString.length - 1);
      } else {
        return dateString.substring(0, dateString.length - this.timezoneSuffix);
      }
    } else {
      return '';
    }
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { APP_INITIALIZER, FactoryProvider, LOCALE_ID } from '@angular/core';
import { LocaleRegistrationService } from '../services/locale-registration.service';
import { AdminLanguageService } from '../services/admin-language.service';
import { en, en_GB } from '@ui/shared/util';
import { CountryService } from '../services/country.service';

export function allLocalesRegistrationFactory(service: LocaleRegistrationService): () => Promise<void> {
  return () => service.registerAllLocales();
}

export function getLocale(countryService: CountryService, adminCountryLanguageService: AdminLanguageService): string {
  const locale = adminCountryLanguageService.getLanguageFromStorage();
  if (locale === en_GB || locale === undefined) {
    return en;
  }
  return locale;
}

export const localeRegistrationProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: allLocalesRegistrationFactory,
  deps: [LocaleRegistrationService],
  multi: true,
};

export const localeIdProvider: FactoryProvider = {
  provide: LOCALE_ID,
  useFactory: getLocale,
  deps: [CountryService, AdminLanguageService],
  multi: false,
};


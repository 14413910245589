/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, InjectionToken, Provider } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { PuiBreakpointName, puiBreakpoints } from './breakpoint';
import { BreakpointTestingController } from './breakpoint-testing-controller';
import { PUI_BREAKPOINT } from './breakpoint.token';

export function provideBreakpointMock(breakpointName: PuiBreakpointName = 'xs'): Provider[] {
  const BREAKPOINT_NAME = new InjectionToken<BehaviorSubject<PuiBreakpointName>>('BREAKPOINT_NAME');

  return [
    { provide: BREAKPOINT_NAME, useFactory: () => new BehaviorSubject<PuiBreakpointName>(breakpointName) },
    {
      provide: BreakpointTestingController,
      useFactory: () => new BreakpointTestingController(inject(BREAKPOINT_NAME)),
    },
    {
      provide: PUI_BREAKPOINT,
      useFactory: () =>
        inject(BREAKPOINT_NAME).pipe(map(name => puiBreakpoints.find(breakpoint => breakpoint.name === name))),
    },
  ];
}

<!--
~  This code is protected by intellectual property rights.
~  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
@if (lock?.status === 'RUNNING') {
  <div>
    <mat-progress-bar mode="determinate" value="{{ getPercentage(lock?.data?.successes, lock?.data?.errors, lock?.data?.total) }}"></mat-progress-bar>
    {{ getPercentage(lock?.data?.successes, lock?.data?.errors, lock?.data?.total) }}%
  </div>
}

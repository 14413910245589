/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export interface SpaceModel {
  spaceName: string;
  spaceId: string;
  defaultLocale: string;
  supportedLocales: string[];
}

<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<mycontent-event-overview-header
  [country]="country()"
  [isAdmin]="isAdmin()"
  [isMarket]="isMarket()"
  [isDealer]="isDealer()"
  [currentSpaceId]="currentSpaceId$ | async"
  [spaces]="spaces$ |async"
  [overviewTrends]="overviewTrends$ | async"
  (refreshTrends)="fetchTrends($event)"
></mycontent-event-overview-header>

<mycontent-event-overview-table
  [country]="country()"
  [unFilteredOnGoingEvents]="unfilteredOnGoingEvents()"
  [unFilteredPastEvents]="unfilteredPastEvents()"
  [onGoingEventsLoading]="onGoingEventsLoading()"
  [pastEventsLoading]="pastEventsLoading()"
></mycontent-event-overview-table>

<mycontent-spacer [size]="'x-large'"></mycontent-spacer>

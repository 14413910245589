/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.oidc.authority,

  // URL of the SPA to redirect the user to after login
  redirectUri: `${environment.oidc.url}auth-callback`,

  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: environment.oidc.client_id,

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'secret',

  responseType: 'code',

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  // scope: 'openid profile email offline_access api',
  scope: 'openid profile',

  // defaults to true for implicit flow and false for code flow
  // as for code the default is using a refresh_token
  // Also see docs section 'Token Refresh'
  useSilentRefresh: false,
  showDebugInformation: false,
  strictDiscoveryDocumentValidation: false,
  sessionChecksEnabled: false,
  postLogoutRedirectUri: `${environment.oidc.logoutUrl}`,
  logoutUrl: `${environment.oidc.cognito}/logout?client_id=${environment.oidc.client_id}&logout_uri=${environment.oidc.logoutUrl}`,
};

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { UntilDestroy } from '@ngneat/until-destroy';
import { MatOption } from '@angular/material/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DateFormatterService } from '@ui/shared/util';
import { Subscription } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { NgClass } from '@angular/common';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { GroupedTimeslotsModel } from '../../../models/timeslot.model';
import { FetchParticipantModel } from '../../../models/participant.model';
import { FilterParticipantsService } from '../../../services/filter-participants.service';
import { ParticipantFacade } from '../../../facades/participant.facade';
import { TimeslotModel } from '@ui/shared/feature-registration';

@UntilDestroy()
@Component({
  selector: 'mycontent-participant-timeslot-filter',
  templateUrl: './participant-timeslot-filter.component.html',
  styleUrls: ['./participant-timeslot-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatSelect,
    NgClass,
    MatSelectTrigger,
    MatOption,
    PorscheDesignSystemModule,
    TranslatePipe
  ]
})
export class ParticipantTimeslotFilterComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('allTimeslotsSelected') allTimeslotsSelected: MatOption;

  form: FormGroup;
  groupedTimeslots: GroupedTimeslotsModel[] = [];
  defaultOption = 'ALL_STATUSES';
  showTimeslotSelect = false;
  fetchParticipants: FetchParticipantModel;

  private dateFormatterService = inject(DateFormatterService);
  private filterService = inject(FilterParticipantsService);
  private participantFacade = inject(ParticipantFacade);
  private filterSubscription: Subscription;

  @Input({ required: true }) set timeslots(timeslots: TimeslotModel[]) {
    if (timeslots?.length > 0) {
      this.groupedTimeslots = this.getGroupedTimeslotsFromTimeslots(timeslots);
    }
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      timeslot: new FormControl(this.groupedTimeslots)
    });

    this.fetchParticipants = this.participantFacade.getParticipantFilters();
  }

  ngAfterViewInit(): void {
    this.filterSubscription = this.participantFacade.participantsFilter$.subscribe(newFilters => {
      this.fetchParticipants = newFilters;

      this.handleTimeslotFilterExternallyChanged(newFilters);
    });

    this.allTimeslotsSelected.deselect();
  }

  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
  }

  getTime(startDate: string, endDate: string): string {
    return this.dateFormatterService.formatFullEventRangeNoStartDate(startDate, endDate);
  }

  getDate(date: string): string {
    return this.dateFormatterService.formatDateNoTime(date);
  }

  handleTimeslotGroupSelection(date: string): void {
    let timeslotFilterSelection = this.form.get('timeslot').value;
    const selectedTimeslotGroup: GroupedTimeslotsModel = this.groupedTimeslots.find(group => group.date === date);

    if (!timeslotFilterSelection.includes(selectedTimeslotGroup?.date)) {
      selectedTimeslotGroup?.timeslots.forEach(timeslot => {
        if (timeslotFilterSelection.includes(timeslot.id)) {
          timeslotFilterSelection = timeslotFilterSelection.filter(selectedFilter => selectedFilter !== timeslot.id);
        }
      });
    } else {
      selectedTimeslotGroup.timeslots.forEach((timeslot: TimeslotModel) => {
        if (!timeslotFilterSelection.includes(timeslot.id)) {
          timeslotFilterSelection.push(timeslot.id);
        }
      });
    }
    this.form.get('timeslot').patchValue(timeslotFilterSelection);

    this.toggleFilterTimeslotPerOne();
  }

  selectTimeslot(selectedTimeslot: string): void {
    const selection: string[] = this.form.get('timeslot').value;
    const { groupIdentifiers, timeslotIdentifiers } = this.filterService.splitTimeslotSelection(selection, this.groupedTimeslots);
    if (groupIdentifiers.length) {
      groupIdentifiers.forEach(selectedGroup => {
        if (this.noGroupMemberSelected(selectedGroup)) {
          const withoutGroupIdentifier = selection.filter(selectedElement => selectedElement !== selectedGroup);
          this.form.get('timeslot').patchValue(withoutGroupIdentifier);
        }
      });
    }

    if (timeslotIdentifiers.length) {
      timeslotIdentifiers.forEach(timeslot => {
        if (selectedTimeslot === timeslot) {
          const groupIdentifier = this.getGroupIdentifierForTimeslot(timeslot, this.groupedTimeslots);
          if (this.isIndeterminate(groupIdentifier) || this.allGroupMemberSelected(groupIdentifier)) {
            selection.push(groupIdentifier);
            this.form.get('timeslot').patchValue(selection);
          }
        }
      });
    }

    this.toggleFilterTimeslotPerOne();
  }

  noGroupMemberSelected(groupIdentifier: string): boolean {
    const selection = this.form.get('timeslot').value;
    const selectedTimeslotGroup = this.groupedTimeslots.find(group => group.date === groupIdentifier);
    return !selectedTimeslotGroup.timeslots.some(timeslot => selection.includes(timeslot.id));
  }

  allGroupMemberSelected(groupIdentifier: string): boolean {
    const selection = this.form.get('timeslot').value;
    const selectedTimeslotGroup = this.groupedTimeslots.find(group => group.date === groupIdentifier);
    return selectedTimeslotGroup.timeslots.some(timeslot => selection.includes(timeslot.id));
  }

  isIndeterminate(groupIdentifier: string): boolean {
    const selection = this.form.get('timeslot').value;
    const selectedTimeslotGroup = this.groupedTimeslots.find(group => group.date === groupIdentifier);
    return selectedTimeslotGroup.timeslots.some(timeslot => !selection.includes(timeslot.id));
  }

  getGroupIdentifierForTimeslot(timeslotId: string, groupedTimeslots: GroupedTimeslotsModel[]): string | null {
    for (const group of groupedTimeslots) {
      const matchingTimeslot = group.timeslots.find(timeslot => timeslot.id === timeslotId);
      if (matchingTimeslot) {
        return group.date;
      }
    }
    return null;
  }

  timeslotFilterDropdownChanged(isOpened: boolean) {
    this.showTimeslotSelect = isOpened;
    if (!isOpened) {
      const selection: string[] = this.form.get('timeslot').value;
      const selections = this.filterService.splitTimeslotSelection(selection, this.groupedTimeslots);
      if (selections.timeslotIdentifiers.toString() !== this.fetchParticipants.timeslotIds.toString()) {
        this.updateParticipants({
          ...this.fetchParticipants,
          timeslotIds: selections.timeslotIdentifiers.filter(timeslotIdentifier => timeslotIdentifier !== this.defaultOption)
        });
      }
    }
  }

  handleTimeslotFilterExternallyChanged(newFilters: FetchParticipantModel) {
    const allTimeslots: string[] = this.form.get('timeslot').value;
    const filteredList: string[] = newFilters.timeslotIds.filter(value => value !== this.defaultOption);

    const { timeslots, groups } = this.splitTimeslotsAndGroups(filteredList);

    const missingValue: string | undefined = allTimeslots.find(value => !newFilters.timeslotIds.includes(value));

    this.form.get('timeslot').patchValue(filteredList);

    this.performTimeslotActions(timeslots);
    this.performGroupActions(groups);
    this.selectTimeslot(missingValue);
  }

  splitTimeslotsAndGroups(filteredList: string[]) {
    const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    const timeslots: string[] = [];
    const groups: string[] = [];

    filteredList.forEach(value => {
      if (uuidPattern.test(value)) {
        timeslots.push(value);
      } else {
        groups.push(value);
      }
    });

    return { timeslots, groups };
  }

  performTimeslotActions(timeslots: string[]) {
    timeslots.forEach(timeslot => {
      this.selectTimeslot(timeslot);
    });
  }

  performGroupActions(groups: string[]) {
    groups.forEach(group => {
      this.handleTimeslotGroupSelection(group);
    });
  }


  getGroupedTimeslotsFromTimeslots(timeslots: TimeslotModel[]): GroupedTimeslotsModel[] {
    const dates = this.getUniqueDatesFromTimeslots(timeslots);
    const groupedTimeslots: GroupedTimeslotsModel[] = [];
    dates.forEach(date => {
      const validTimeslots = timeslots.filter(timeslot => timeslot.startDate.includes(date));
      groupedTimeslots.push({
        date,
        timeslots: validTimeslots
      } as GroupedTimeslotsModel);
    });
    return groupedTimeslots;
  }

  getUniqueDatesFromTimeslots(timeslots: TimeslotModel[]): string[] {
    const dates: string[] = [];
    timeslots.forEach(timeslot => {
      const date = timeslot.startDate;
      dates.push(date.slice(0, 10));
    });
    return Array.from(new Set(dates));
  }

  toggleAllTimeslotsSelection() {
    if (this.allTimeslotsSelected.selected) {
      this.allTimeslotsSelected.select();
      this.form.get('timeslot').patchValue([...(this.getAllGroupedTimeslotIds()) ? this.getAllGroupedTimeslotIds().map(item => item) : undefined, this.defaultOption]);
    } else {
      this.allTimeslotsSelected.deselect();
      this.form.get('timeslot').patchValue([]);
    }
  }

  toggleFilterTimeslotPerOne() {
    const timeslotWithoutDefault = this.form.get('timeslot').value.filter(timeslotSelection => timeslotSelection !== this.defaultOption);
    if (timeslotWithoutDefault.length >= this.getAllGroupedTimeslotIds().length) {
      this.allTimeslotsSelected.select();
      return;
    }
    this.allTimeslotsSelected.deselect();
  }

  getAllGroupedTimeslotIds(): string[] {
    const result: string[] = [];
    this.groupedTimeslots.forEach(({ date, timeslots }) => {
      result.push(date);
      timeslots.forEach(({ id }) => {
        if (id) {
          result.push(id);
        }
      });
    });

    return result;
  }

  updateParticipants(fetchParticipant: FetchParticipantModel) {
    this.participantFacade.getParticipants(fetchParticipant);
  }
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export * from './lib/components/contact-details-tile/contact-details-tile-data';
export * from './lib/components/contact-details/contact-details';
export * from './lib/components/contact-details/contact-details.component';
export * from './lib/components/scroll-to-target/scroll-to-target.component';
export * from './lib/components/scroll-to-target/scroll-to-target.service';

<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->

<mycontent-spacer [size]="'medium'"></mycontent-spacer>
@if (isAdmin()) {
  <p-switch
    [attr.data-testid]="'event-details-event-settings-waiting-list-checkbox'"
    [hideLabel]="false"
    [disabled]="!isWaitingListToggable()"
    [checked]="isWaitingListEnabled()"
    (update)="onUpdateWaitingList($event)"
  >
    {{ 'adminEventDetail.eventSettings.toggle.waitingList' | translate }}
  </p-switch>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  <p-text [size]="'x-small'" [color]="'contrast-medium'">{{ 'adminEventDetail.eventSettings.paragraph.waitingList' | translate }}</p-text>
  <mycontent-spacer [size]="'large'"></mycontent-spacer>
}

<p-text >{{ 'adminEventDetail.eventSettings.title.customRegistration' | translate }}</p-text>
<mycontent-spacer [size]="'small'"></mycontent-spacer>
<p-text [size]="'x-small'" [color]="'contrast-medium'">{{ 'adminEventDetail.eventSettings.paragraph.customRegistration' | translate }}</p-text>
<mycontent-spacer [size]="'small'"></mycontent-spacer>
<div class="event-details-settings__registration-period">
  <form [formGroup]="form">
    <p-text-field-wrapper
      class="event-details-settings__custom-registration"
      [state]="getErrorState()"
      [message]="'adminEventDetail.timeslots.textField.label.customRegistrationError' | translate"
      [unit]="'adminEventDetail.eventSettings.label.hours' | translate"
      [unitPosition]="'suffix'"
      [hideLabel]="true"
    >
      @if (isCustomRegistrationLoading$ | async) {
        <p-spinner class="event-details-settings__custom-registration-loading"></p-spinner>
      }
      <input
        [attr.data-testid]="'event-details-event-settings-registration-deadline'"
        [type]="'number'"
        [formControlName]="'customRegistration'"
        (blur)="onBlur()"
      >
    </p-text-field-wrapper>
  </form>
</div>
<mycontent-spacer [size]="'large'"></mycontent-spacer>

<p-heading [size]="'medium'">{{ 'adminEventDetail.eventSettings.title.publishingSettings' | translate }}</p-heading>
<mycontent-spacer [size]="'small'"></mycontent-spacer>
<p-switch
  [attr.data-testid]="'event-details-event-settings-open-registration-checkbox'"
  [hideLabel]="false"
  [disabled]="form.invalid"
  [checked]="isOpenRegistrationEnabled()"
  (update)="onUpdateOpenRegistration($event)"
>
  {{ 'adminEventDetail.eventSettings.toggle.openRegistration' | translate }}
</p-switch>
<mycontent-spacer [size]="'small'"></mycontent-spacer>
<p-text [size]="'x-small'" [color]="'contrast-medium'">{{ 'adminEventDetail.eventSettings.paragraph.openRegistration' | translate }}</p-text>
@if (isAdminOrMarket()) {
  <mycontent-spacer [size]="'medium'"></mycontent-spacer>
  <p-switch
    [attr.data-testid]="'event-details-event-settings-publish-to-feed-checkbox'"
    [hideLabel]="false"
    [disabled]="!isPublishToFeedAllowed() || form.invalid"
    [checked]="isPublishToFeedEnabled()"
    (update)="onUpdatePublishToFeed($event)"
  >
    {{ 'adminEventDetail.eventSettings.toggle.publishToFeed' | translate }}
  </p-switch>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  <p-text [size]="'x-small'" [color]="'contrast-medium'">
    {{ 'adminEventDetail.eventSettings.paragraph.publishToFeed' | translate }}
    <p-popover>{{ 'adminEventDetail.eventSettings.popover.publishToFeed' | translate }}</p-popover>
  </p-text>
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, input, model } from '@angular/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';

@Component({
  selector: 'mycontent-show-more-show-less-arrow',
  templateUrl: './show-more-show-less-arrow.component.html',
  styleUrls: ['./show-more-show-less-arrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule
]
})
export class ShowMoreShowLessArrowComponent {
  elementId = input<string>();
  hide = model<boolean>(false);

  switchView(show: boolean): void {
    this.hide.set(show);
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, HostBinding, inject, input, Input, ViewEncapsulation } from '@angular/core';
import {
  BreakpointCustomizable,
  FlexItemAlignSelf, FlexItemFlex,
  FlexItemGrow,
  FlexItemOffset,
  FlexItemShrink,
  FlexItemWidth
} from '@porsche-design-system/components-angular';
import { BreakpointService } from '../../breakpoint/breakpoint.service';

const flexItemWidths: { [key in Exclude<FlexItemWidth, 'auto'>]: number } & { none: number; auto: string } = {
  none: 0,
  'one-quarter': 25,
  'one-third': 33.333333,
  half: 50,
  'two-thirds': 66.666667,
  'three-quarters': 75,
  full: 100,
  auto: 'auto',
};

@Component({
  selector: 'mycontent-flex-item',
  templateUrl: 'flex-item.html',
  styleUrls: ['flex-item.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [],
})
export class FlexItemComponent {
  @HostBinding('style.flex-grow')
  @Input() grow: BreakpointCustomizable<FlexItemGrow> = 0;
  @HostBinding('style.flex-shrink')
  @Input() shrink: BreakpointCustomizable<FlexItemShrink> = 1;
  @HostBinding('style.flex-basis')
  @Input() basis: number | 'auto' = 'auto';

  width  = input<BreakpointCustomizable<FlexItemWidth>>('auto');
  offset = input<BreakpointCustomizable<FlexItemOffset>>('none');
  alignSelf = input<BreakpointCustomizable<FlexItemAlignSelf>>('auto');
  flex = input<BreakpointCustomizable<FlexItemFlex>>('initial');

  breakpointService = inject(BreakpointService);

  @HostBinding('style.width')
  get flexItemWidth() {
    const breakpointSize = this.breakpointService.getBreakpointValue(this.width()) as string;
    return `${flexItemWidths[breakpointSize]}%`;
  }

  @HostBinding('style.margin-left')
  get flexItemOffsetMarginLeft() {
    const breakpointOffset = this.breakpointService.getBreakpointValue(this.offset())  as string;
    return `${flexItemWidths[breakpointOffset]}%`;
  }

  @HostBinding('style.align-self')
  get flexItemAlignSelf() {
    return this.breakpointService.getBreakpointValue(this.alignSelf())  as string;
  }
}

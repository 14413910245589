/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { InjectionToken } from '@angular/core';
import { AuthGuard, AuthService } from '@auth0/auth0-angular';
import { AuthConfig } from './oauth.module';

export const AUTH_SERVICE = new InjectionToken<AuthService>('auth_service');
export const AUTH_GUARD = new InjectionToken<AuthGuard>('auth_guard');
export const AUTH_ENABLED = new InjectionToken<boolean>('auth_enabled');
export const AUTH_CONFIG = new InjectionToken<AuthConfig>('auth_config');

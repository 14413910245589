<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<p-button [variant]="'ghost'" [compact]="true" [hideLabel]="true" [icon]="'menu-dots-vertical'" (click)="toggleMenu()"></p-button>

@if (isMenuOpen()) {
  <div class="popover-hamburger-menu__menu">
    <ng-content></ng-content>
  </div>
}

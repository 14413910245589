<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

@for (line of lines; track line) {
  <mycontent-ghost [fillHorizontal]="fillHorizontal(line)" [ghostClass]="ghostClass" [textGhost]="true">
    {{ ghostContent(line) }}
  </mycontent-ghost>
}

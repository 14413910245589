<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->

@if (isCapEvent()) {
  <mycontent-spacer [size]="'medium'"></mycontent-spacer>
  <p-inline-notification
    [heading]="'adminEventDetail.inlineNotification.header.capEvent' | translate"
    [description]="'adminEventDetail.inlineNotification.paragraph.capEvent' | translate"
    [state]="'info'"
    [dismissButton]="false"
  ></p-inline-notification>
  <mycontent-spacer [size]="'medium'"></mycontent-spacer>
}
<p-button
  [attr.data-testid]="'quick-add-button'"
  class="attendee-invite__button-quick-add"
  [variant]="'primary'"
  [icon]="'plus'"
  [disabled]="isCapEvent()"
  (click)="onQuickAddClick()"
>
  {{ 'adminEventDetail.attendance.button.quickAdd' | translate }}
</p-button>
<p-button
  [attr.data-testid]="'upload-csv-button'"
  class="attendee-invite__button-import"
  [variant]="'ghost'"
  [icon]="'upload'"
  [disabled]="isCapEvent()"
  (click)="onImportCSVClick()"
>
  {{ 'details.buttonCSVImport' | translate }}
</p-button>
<p-button
  data-testid='export-csv-button'
  class="attendee-invite__button-export"
  [variant]="'ghost'"
  [icon]="'download'"
  [disabled]="isCapEvent()"
  (click)="onExportCSVClick()"
>
  {{ 'details.buttonCSVExport' | translate }}
</p-button>
@if ((isAdmin || isMarket || isDealer)) {
  <button
    id="send-invitation-mails-button"
    class="attendee-invite__button-invite"
    aria-haspopup="dialog"
    type="button"
    [disabled]="isCapEvent()"
    (click)="onSendInvitationClick()"
  >
    <p-icon [name]="'send'" [theme]="'dark'" [color]="isCapEvent() ? 'contrast-low' : 'primary'"></p-icon>
    {{ 'adminEventDetail.attendance.button.sendInviteMail' | translate }}
  </button>
}


@if (eventFilters().invitationStates.length || eventFilters().timeslotIds.length) {
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  @for (state of eventFilters().invitationStates; track state) {
    <p-tag
      class="attendee-invite__tag"
      [attr.data-testid]="'adminEventDetail.tag.state.'+ state"
      (click)="removeFilter(true, state)"
      [icon]="'close'"
      [color]="getInvitationStateColour(state)"
    >
      {{ 'adminEventDetail.attendance.table.statusLabel.' + state | translate }}
    </p-tag>
  }
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  @for (timeslot of eventFilters().timeslotIds; track timeslot) {
    <p-tag
      class="attendee-invite__tag"
      [attr.data-testid]="'adminEventDetail.tag.timeslot.'+ timeslot"
      [icon]="'close'"
      (click)="removeFilter(false, timeslot)"
    >
      {{ formatTimeslotTagDate(timeslot) }}
    </p-tag>
  }
}
@if(isQuickAddModalOpen()) {
  <mycontent-participant-quick-add-modal
    [(isOpen)]="isQuickAddModalOpen"
    [country]="country()"
    [eventId]="eventId()"
    (refreshEventDetails)="refreshEventDetails.emit()"
  ></mycontent-participant-quick-add-modal>
}
@if (isImportCSVOpen()) {
  <mycontent-participant-import-csv-modal
    [(isOpen)]="isImportCSVOpen"
    [eventId]="eventId()"
    [country]="country()"
  ></mycontent-participant-import-csv-modal>
}
@if (isExportCSVOpen()) {
  <mycontent-participant-export-csv-modal
    [(isOpen)]="isExportCSVOpen"
    [eventId]="eventId()"
    [eventTitle]="eventDetails()?.title"
    [country]="country()"
    [selectedInvitationStates]="selectedInvitationStates()"
  ></mycontent-participant-export-csv-modal>
}
@if (isSendInvitationOpen()) {
  <mycontent-participant-send-invite-all-modal
    [(isOpen)]="isSendInvitationOpen"
    [country]="country()"
    [eventId]="eventId()"
  ></mycontent-participant-send-invite-all-modal>
}

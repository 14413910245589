/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@ui/shared/util';

@Injectable({
  providedIn: 'root',
})
export class ExternalLinkService {

  private window: Window = inject(WINDOW);

  getMyPorscheLink(country: string, locale: string, eventId: string) {
    if (this.window.location.host.includes('preview')) {
      return `https://events.porsche-preview.com/${country}/${locale}/events/${eventId}`;
    } else {
      return `https://events.porsche.com/${country}/${locale}/events/${eventId}`;
    }
  }

  getScannerLink(country: string): string {
    if (this.window.location.host.includes('preview')) {
      return `https://checkin.events.porsche-preview.com/index/${country}`;
    } else {
      return `https://checkin.events.porsche.com/index/${country}`;
    }
  }

  getContentfulLink(currentSpaceId: string, eventId?: string) {
    const contentfulEnvironment = this.window.location.host.includes('preview') || this.window.location.host.includes('localhost') ? 'preview' : 'master';
    return eventId ?
      `https://app.contentful.com/spaces/${currentSpaceId}/environments/${contentfulEnvironment}/entries/${eventId}` :
      `https://app.contentful.com/spaces/${currentSpaceId}/environments/${contentfulEnvironment}/entries`;
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core';
import { OverviewEventsResponseModel } from '../models/overview.model';

@Injectable({
  providedIn: 'root'
})
export class FilterEventsService {

  filterEvents(events: OverviewEventsResponseModel, filterText: string): OverviewEventsResponseModel {
    const filteredEvents = events?.items?.filter(event =>
      event.title.toLowerCase().includes(filterText?.toLowerCase()) || this.filterHost(event.hostNames, filterText).length
    );
    return { items: filteredEvents } as OverviewEventsResponseModel;
  }

  private filterHost(hosts: string[], filterText: string): string[] {
    if (hosts.length > 0 && hosts[0] !== null) {
      return hosts.filter(host => host.toLowerCase().includes(filterText?.toLowerCase()));
    } else {
      return [];
    }
  }
}

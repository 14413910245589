/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { ProfileModel } from '@ui/shared/data-access-profile';
import { authCodeFlowConfig } from './auth.config';
import { AuthoritiesFacade } from '@admin/shared';
import { RedirectService } from '../redirect.service';
import { WINDOW } from '@ui/shared/util';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  profile: ProfileModel;

  private oauthService = inject(OAuthService);
  private authoritiesFacade = inject(AuthoritiesFacade);
  private redirectService = inject(RedirectService);
  private window = inject(WINDOW);

  init() {
    this.oauthService.configure(authCodeFlowConfig);
    this.setupEventListeners();

    const redirectUrl = new URL(this.window.location.href);
    if (redirectUrl.pathname !== '/auth-callback') {
      this.redirectService.setRedirectUrl(redirectUrl.pathname);
    }
    return this.handleLogin();
  }

  public handleLogOut() {
    this.oauthService.logOut();
    this.window.location.href = authCodeFlowConfig.logoutUrl;
  }

  public async handleLogin() {
    return new Promise<void>((resolve, reject) => {
      this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
        if (this.oauthService.hasValidIdToken()) {
          this.authoritiesFacade.fetchUserProfile().subscribe({
            next: profile => {
              this.authoritiesFacade.initializeUserProfileAndLocale(profile);
              resolve();
            },
            error: err => {
              console.error('Failed to fetch user profile', err);
              reject();
              this.handleLogOut();
            }
          });
        } else {
          this.oauthService.initCodeFlow();
        }
      });
    });
  }

  private setupEventListeners() {
    this.oauthService.events.subscribe((event: OAuthEvent) => {
      switch (event.type) {
        case 'token_expires':
          this.oauthService.refreshToken().then(() => {
          }).catch(() => {
            this.handleLogOut();
          });
          break;
        case 'invalid_nonce_in_state':
          this.handleLogOut();
          break;
        default:
          break;
      }
    });
  }
}

<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->
@if (timeslots().length) {
  <section>
    @if (!multiStartDates() && !multipleTimeslotsPerDate()) {
      <p-text>
        <p-icon [name]="'calendar'"></p-icon>
        {{ formatFullEventRange(selectedTimeslot()) }}
      </p-text>
    } @else {
      @if (isOneTimeslotLongerThan24h() || (multiStartDates() && !multipleTimeslotsPerDate())) {
        <section class="spacer-small">
          <p-select
            [name]="'options'"
            [label]="'shared.eventDetails.selectDate' | translate"
            [value]="selectedTimeslot().id"
            (update)="onUpdateTimeslotSelection($event)"
          >
            @for (timeslot of timeslots(); track timeslot) {
              <p-select-option [value]="timeslot.id">{{ formatFullEventRange(timeslot) }}</p-select-option>
            }
          </p-select>
        </section>
      } @else {
        @if (!multiStartDates() && multipleTimeslotsPerDate() && !isOneTimeslotLongerThan24h()) {
          <p-text class="manual-registration__date spacer-bottom-small" [size]="'small'">
            <p-icon class='manual-registration__date-icon' [name]="'calendar'"></p-icon>
            {{ formatEventStartDateNoTime(selectedTimeslot()) }}
          </p-text>
          <mycontent-spacer [size]="'medium'"></mycontent-spacer>
          <p-segmented-control [columns]="{ 'base': 1, 'l': 2}" [value]="selectedTimeslot().id" (update)="selectTimeslotById($event.detail.value)">
            @for (timeslot of timeslots(); let index = $index; track timeslot) {
              @if (index < TIMESLOT_DISPLAY_LIMIT || showMoreTimeslots()) {
                <p-segmented-control-item [value]="timeslot.id">{{ displayTimeRange(timeslot) }}</p-segmented-control-item>
              }
            }
          </p-segmented-control>
          @if (TIMESLOT_DISPLAY_LIMIT < timeslots().length && !showMoreTimeslots()) {
            <mycontent-spacer [size]="'medium'"></mycontent-spacer>
            <p-button
              class="show-more-button"
              [attr.data-testid]="'registration-select-timeslot-show-more-button'"
              [variant]="'secondary'"
              (click)="showMoreTimeslots.set(true)"
              (keydown.enter)="showMoreTimeslots.set(true)"
            >
              {{ 'eventCheckout.button.label.showMore' | translate }}
            </p-button>
          }
        } @else {
          <section class="spacer-small">
            <p-select
              [name]="'options'"
              [attr.data-testid]="'registration-select-timeslot-multi-day-multi-time-date-selection'"
              [label]="'shared.eventDetails.selectDate' | translate"
              [value]="getSelectedTimeSlotIdForDateInMultiDayMultiTime()"
            >
              @for (timeslot of possibleTimeslotDates(); track timeslot) {
                <p-select-option
                  [attr.data-testid]="'registration-select-timeslot-multi-day-multi-time-date-selection-option-' + timeslot.id"
                  [value]="timeslot.id"
                  (click)="selectTimeslotAndCalculatePossibleTimesForMultiDayMultiTimeEvent(timeslot, timeslots())"
                  (keydown.enter)="selectTimeslotAndCalculatePossibleTimesForMultiDayMultiTimeEvent(timeslot, timeslots())"
                >
                  {{ formatEventStartDateNoTime(timeslot) }}
                </p-select-option>
              }
            </p-select>
            <div class="spacer-bottom-small"></div>
            <p-segmented-control [columns]="{ 'base': 1, 'l': 2}" [value]="selectedTimeslot().id" (update)="selectTimeslotById($event.detail.value)">
              @for (timeslot of possibleTimeslotTimesForSpecificDate(); let index = $index; track timeslot) {
                @if (index < TIMESLOT_DISPLAY_LIMIT || showMoreTimeslots()) {
                  <p-segmented-control-item [value]="timeslot.id">{{ displayTimeRange(timeslot) }}</p-segmented-control-item>
                }
              }
            </p-segmented-control>
            @if (TIMESLOT_DISPLAY_LIMIT < possibleTimeslotTimesForSpecificDate().length && !showMoreTimeslots()) {
              <mycontent-spacer [size]="'medium'"></mycontent-spacer>
              <p-button
                class="show-more-button"
                [attr.data-testid]="'registration-select-timeslot-show-more-button'"
                [variant]="'secondary'"
                (click)="showMoreTimeslots.set(true)"
                (keydown.enter)="showMoreTimeslots.set(true)"
              >
                {{ 'eventCheckout.button.label.showMore' | translate }}
              </p-button>
            }
          </section>
        }
      }
    }
  </section>
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mycontent-image-ghost',
  templateUrl: './image-ghost.component.html',
  styleUrls: ['./image-ghost.component.scss'],
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ImageGhostComponent {
  ratioWidth = input<number>();
  ratioHeight = input<number>();
  backgroundColor = input<string>();
  iconColor = input<string>();
  withIcon = input<boolean>();
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */


import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AdminFeatureToggleService } from '../services/admin-feature-toggle.service';

@Injectable({
  providedIn: 'root'
})
export class AdminFeatureToggleGuard implements CanActivate {

  private AdminFeatureToggleService: AdminFeatureToggleService = inject(AdminFeatureToggleService);
  private readonly router: Router = inject(Router);

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isRouteAllowed = this.AdminFeatureToggleService.isEventCreateAndEditEnabled();
    if (!isRouteAllowed) {
      this.router.navigate(['']);
    }
    return isRouteAllowed;
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable, inject } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ProfileService } from '../services/profile.service';
import { ImpersonationService } from '../services/impersonation.service';
import { ProfileModel } from '../models/profile.model';

@Injectable({
  providedIn: 'root'
})
export class AuthoritiesFacade {

  profile: ProfileModel;

  private profileService = inject(ProfileService);
  private impersonationService = inject(ImpersonationService);
  private oauthService = inject(OAuthService);

  fetchUserProfile() {
    return this.profileService.fetchProfileInformation();
  }

  initializeUserProfileAndLocale(profile: ProfileModel) {
    this.profile = profile;
    sessionStorage.setItem('user', `${this.profile.firstName} ${this.profile.lastName}`);
    if (!sessionStorage.getItem('language')) {
      sessionStorage.setItem('language', this.profile.country || 'fallback');
    }
  }

  isAdmin() {
    if (this.oauthService.hasValidIdToken() && this.profile) {
      if (this.impersonationService.isDealerRole() || this.impersonationService.isMarketRole()) {
        return false;
      }
      return this.profile?.isAdmin;
    }
  }

  isMarket() {
    if (this.oauthService.hasValidIdToken() && this.profile) {
      if (this.impersonationService.isMarketRole() && !this.impersonationService.isDealerRole()) {
        return true;
      }
      return this.profile.isMarket;
    }
  }

  isDealer() {
    if (this.oauthService.hasValidIdToken() && this.profile) {
      if (this.impersonationService.isDealerRole() && !this.impersonationService.isMarketRole()) {
        return true;
      }
      return this.profile.isDealer;
    }
  }

  getOrgId(): string {
    return this.profile.orgId;
  }

  isImpersonated() {
    return this.profile?.isImpersonated;
  }
}

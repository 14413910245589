/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */


import { Component, HostBinding, inject, input, ViewEncapsulation } from '@angular/core';
import { clamp } from 'lodash-es';
import { WINDOW } from '@ui/shared/util';

@Component({
  selector: 'mycontent-spacer',
  templateUrl: './spacer.component.html',
  styleUrls: ['./spacer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [],
})
export class SpacerComponent {

  size = input.required<'x-small' | 'small' | 'medium' | 'large' | 'x-large' | 'xx-large'>();

  private window = inject(WINDOW);

  @HostBinding('style.margin-top')
  get spacerGap() {
    switch (this.size()) {
      case 'x-small':
        return `${clamp(4, this.window.visualViewport.width * 0.0025 + 3, 8)}px`;
      case 'small':
        return `${clamp(8, this.window.visualViewport.width * 0.005 + 6, 16)}px`;
      case 'medium':
        return `${clamp(16, this.window.visualViewport.width * 0.0125 + 12, 36)}px`;
      case 'large':
        return `${clamp(32, this.window.visualViewport.width * 0.0275 + 23, 76)}px`;
      case 'x-large':
        return `${clamp(48, this.window.visualViewport.width * 0.03 + 38, 96)}px`;
      case 'xx-large':
        return `${clamp(80, this.window.visualViewport.width * 0.075 + 56, 200)}px`;
      default:
        return '0';
    }
  }
}

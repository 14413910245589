<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<mycontent-grid class="participants-row" [direction]="'row'" [(mycontentAppHover)]="participantRowIsHovered">
  <mycontent-grid-item
    class="participants-row__cell__clickable"
    [size]="3"
    [attr.data-testid]="'participant-row-open-participant-flyout-' + participant()?.attendee?.email"
    [(mycontentAppHover)]="clickablePartOfRowIsHovered"
    (click)="openParticipantDetailsFlyout()"
    (keydown.enter)="openParticipantDetailsFlyout()"
  >
    <p-text
      [ellipsis]="true"
      [size]="'small'"
      [ngClass]="{'participants-row__participant-highlighted': clickablePartOfRowIsHovered()}"
      [weight]="clickablePartOfRowIsHovered() ? 'semi-bold' : 'regular'"
    >
      {{ participant()?.attendee?.firstName }} {{ participant()?.attendee?.lastName }}
    </p-text>
    <p-text [ellipsis]="true" [size]="'x-small'" [weight]="'regular'" [color]="'contrast-medium'">
      {{ participant()?.attendee?.email }}
    </p-text>
  </mycontent-grid-item>
  <mycontent-grid-item
    class="participants-row__cell__clickable__tags participants-row__cell__clickable"
    [size]="3"
    [(mycontentAppHover)]="clickablePartOfRowIsHovered"
    (click)="openParticipantDetailsFlyout()"
    (keydown.enter)="openParticipantDetailsFlyout()"
  >
    <p-tag
      [class]="'participants-row__tag'"
      [color]="getInvitationStateColour(participant()?.state)"
      [attr.data-testid]="'participant-row-' + participant()?.state"
    >
      {{ 'adminEventDetail.attendance.table.statusLabel.' + participant()?.state | translate }} {{ getTagLabelInformation(participant()?.state) }}
    </p-tag>
    @if (isCancelled()) {
      <p-popover
        [attr.data-testid]="'canceled-registration-popover'"
        [direction]="'top'"
        (click)="preventFlyoutOpening($event)"
      >
        {{ participant()?.state || 'unknown' }}:
        {{ participant()?.cancelledAt ? ' ' + cancelledAt() : '' }}
        @if (isRegistrationCancelledByUser()) {
          <p-text>
            {{ 'details.regCustomer' | translate }}
          </p-text>
        }
        @if (!isRegistrationCancelledByUser()) {
          <p-text>
            {{ 'details.regAdmin' | translate }}
          </p-text>
        }
      </p-popover>
    }
    @if (isEditRegistrationUpdatedByAdmin()) {
      <p-popover
        [attr.data-testid]="'updated-registration-admin-popover'"
        [direction]="'top'"
        (click)="preventFlyoutOpening($event)"
        (keydown.enter)="preventFlyoutOpening($event)"
      >
        <p-text>
          {{ 'details.modifiedByAdmin' | translate }}:
        </p-text>
        {{ participant()?.registrationUpdatedAt ? ' ' + registrationUpdatedAt() : '' }}
      </p-popover>
    }
  </mycontent-grid-item>
  <mycontent-grid-item
    class="participants-row__cell__clickable"
    [size]="2"
    [(mycontentAppHover)]="clickablePartOfRowIsHovered"
    (click)="openParticipantDetailsFlyout()"
    (keydown.enter)="openParticipantDetailsFlyout()"
  >
    @if (participant()?.registration?.timeslot) {
      <mycontent-flex [direction]="'row'" [justifyContent]="'center'">
        <mycontent-flex class="participants-row__timeslot-range" [direction]="'column'" [justifyContent]="'center'">
          <p-text [ellipsis]="true" [size]="'small'" [weight]="'regular'">
            {{ getTimeslotTime(participant()?.registration?.timeslot?.startDate) }}
          </p-text>
          <p-text [ellipsis]="true" [size]="'x-small'" [weight]="'regular'" [color]="'contrast-medium'">
            {{ getTimeslotDate(participant()?.registration?.timeslot?.startDate) }}
          </p-text>
        </mycontent-flex>
        <mycontent-flex [direction]="'column'" [justifyContent]="'center'">
          <div class="participants-row__timeslot-range-indicator"></div>
          <div></div>
        </mycontent-flex>
        <mycontent-flex class="participants-row__timeslot-range" [direction]="'column'" [justifyContent]="'center'">
          <p-text [ellipsis]="true" [size]="'small'" [weight]="'regular'">
            {{ getTimeslotTime(participant()?.registration?.timeslot?.endDate) }}
          </p-text>
          <p-text [ellipsis]="true" [size]="'x-small'" [weight]="'regular'" [color]="'contrast-medium'">
            {{ getTimeslotDate(participant()?.registration?.timeslot?.endDate) }}
          </p-text>
        </mycontent-flex>
      </mycontent-flex>
    }
  </mycontent-grid-item>
  <mycontent-grid-item
    class="participants-row__cell participants-row__last_column participants-row__cell__clickable"
    [size]="4"
    [(mycontentAppHover)]="clickablePartOfRowIsHovered"
    (click)="openParticipantDetailsFlyout()"
    (keydown.enter)="openParticipantDetailsFlyout()"
  >
    <mycontent-flex [direction]="'row'" [justifyContent]="'flex-end'">
        @if (hasTicketsToCheckIn()) {
          <p-button
            class="participants-row__checkin-button"
            aria-haspopup="dialog"
            title="{{ 'adminEventDetail.attendance.listItem.buttonHover.checkInUser' | translate }}"
            [attr.data-testid]="'checkin-registration-button-' + participant()?.attendee?.email"
            [type]="'button'"
            [icon]="'qr'"
            [hideLabel]="true"
            [disabled]="isTimeslotEndDateWithin7Days()"
            [variant]="'ghost'"
            (click)="openCheckInModal($event)"
            (keydown.enter)="openCheckInModal($event)"
          ></p-button>
        }
        @if (isRegisteredOrCheckedIn()) {
          <mycontent-participant-download-ticket-button
            [eventId]="eventId()"
            [attendeeId]="participant()?.attendee?.id"
            [country]="country()"
            [hideLabel]="true"
          ></mycontent-participant-download-ticket-button>
        }
        @if (isSendInvitationButtonVisible()) {
          <p-button
            class="participants-row__send-invitation-button"
            aria-haspopup="dialog"
            title="{{ 'participantFlyout.button.sendInvitation' | translate }}"
            [attr.data-testid]="'send-invitation-button-' + participant()?.attendee?.email"
            [type]="'button'"
            [icon]="'send'"
            [hideLabel]="true"
            [variant]="'ghost'"
            (click)="openSendInviteOneModal($event)"
            (keydown.enter)="openSendInviteOneModal($event)"
          ></p-button>
        }
        @if (isResendInvitationButtonVisible()) {
          <p-button
            class="participants-row__resend-invitation-button"
            aria-haspopup="dialog"
            title="{{ 'adminEventDetail.label.reactivateInvitation' | translate }}"
            [attr.data-testid]="'resend-invitation-button-' + participant()?.attendee?.email"
            [type]="'button'"
            [iconSource]="'../../../../assets/resend-email.svg'"
            [hideLabel]="true"
            [variant]="'ghost'"
            (click)="openResendInviteOneModal($event)"
            (keydown.enter)="openResendInviteOneModal($event)"
          ></p-button>
        }
        @if (isRegistered()) {
          <p-button
            class="participants-row__registration-resent-button"
            [attr.data-testid]="'resend-registration-mail-button-' + participant()?.attendee?.email"
            [type]="'button'"
            [iconSource]="'../../../../assets/resend-email.svg'"
            [hideLabel]="true"
            [variant]="'ghost'"
            aria-haspopup="dialog"
            title="{{ 'adminEventDetail.label.resendRegistration' | translate }}"
            (click)="openResendRegistrationModal($event)"
            (keydown.enter)="openResendRegistrationModal($event)"
          ></p-button>
        }
        @if (isInvitedOrCancelledOrWaitingList()) {
          <p-button
            class="participants-row__register-user"
            title="{{'participantFlyout.button.registerCustomer' | translate}}"
            aria-haspopup="dialog"
            [attr.data-testid]="'register-user-button-' + participant()?.attendee?.email"
            [type]="'button'"
            [icon]="'plus'"
            [hideLabel]="true"
            [variant]="'ghost'"
            (click)="openManualRegistrationFlyout($event)"
            (keydown.enter)="openManualRegistrationFlyout($event)"
          ></p-button>
        }
        @if (isRegisteredOrCheckedIn()) {
          <p-button
            title="{{ 'participantFlyout.button.editRegistration' | translate }}"
            [attr.data-testid]="'participant-row-edit-registration-flyout'"
            [variant]="'ghost'"
            [icon]="'edit'"
            [hideLabel]="true"
            (click)="openEditRegistrationFlyout($event)"
            (keydown.enter)="openEditRegistrationFlyout($event)"
          ></p-button>
        }
        @if (isInviteeDeletable()) {
          <p-button
            title="{{ 'adminEventDetail.attendance.listItem.button.deleteInvitee' | translate }}"
            aria-haspopup="dialog"
            [attr.data-testid]="'delete-invitee-open-model-button-' + participant()?.attendee?.email"
            [type]="'button'"
            [icon]="'delete'"
            [variant]="'ghost'"
            [hideLabel]="true"
            (click)="openDeleteInviteeModal($event)"
            (keydown.enter)="openDeleteInviteeModal($event)"
          ></p-button>
        }
    </mycontent-flex>
  </mycontent-grid-item>
</mycontent-grid>
@if (isCheckInModalOpen()) {
  <mycontent-participant-checkin-modal
    [(isOpen)]="isCheckInModalOpen"
    [country]="country()"
    [eventId]="eventId()"
    [tickets]="participant()?.tickets"
    [attendeeId]="participant()?.attendee?.id"
    (updateParticipantModelEventManagementModel)="refreshParticipantModelEventManagementModel()"
  ></mycontent-participant-checkin-modal>
}
@if (isDeleteInviteeModalOpen()) {
  <mycontent-participant-delete-invitee-modal
    [(isOpen)]="isDeleteInviteeModalOpen"
    [country]="country()"
    [eventId]="eventId()"
    [attendeeId]="participant()?.attendee?.id"
    (refreshParticipantsModel)="refreshParticipantsModel.emit()"
  ></mycontent-participant-delete-invitee-modal>
}
@if (isResendRegistrationModalOpen()) {
  <mycontent-participant-resend-registration-modal
    [(isOpen)]="isResendRegistrationModalOpen"
    [eventId]="eventId()"
    [country]="country()"
    [mail]="participant()?.attendee?.email"
    [ciamId]="participant()?.attendee?.ciamId"
  ></mycontent-participant-resend-registration-modal>
}
@if (isSendInviteOneModalOpen()) {
  <mycontent-participant-send-invite-one-modal
    [(isOpen)]="isSendInviteOneModalOpen"
    [eventId]="eventId()"
    [country]="country()"
    [email]="participant()?.attendee?.email"
    [attendeeId]="participant()?.attendee?.id"
  ></mycontent-participant-send-invite-one-modal>
}
@if (isOpenResendInviteOneModal()) {
  <mycontent-participant-resend-invite-modal
    [(isOpen)]="isOpenResendInviteOneModal"
    [eventId]="eventId()"
    [country]="country()"
    [email]="participant()?.attendee?.email"
    [attendeeId]="participant()?.attendee?.id"
  ></mycontent-participant-resend-invite-modal>
}
@if (isRegistrationOpen()) {
  <mycontent-manual-registration-flyout
    [(isOpen)]="isRegistrationOpen"
    [(isEditRegistration)]="isEditRegistration"
    [attendeeId]="participant()?.attendee?.id"
    [eventId]="eventId()"
    [country]="country()"
    [participant]="participant()"
  ></mycontent-manual-registration-flyout>
}
@if (isParticipantDetailsFlyoutOpen()) {
  <mycontent-participant-details-flyout
    [(isOpen)]="isParticipantDetailsFlyoutOpen"
    [participant]="participant()"
    [bookingCancellationText]="bookingCancellationText()"
    [eventId]="eventId()"
    [country]="country()"
    [timezone]="timezone()"
    [waitingListCount]="waitingListCount()"
    (refreshParticipantModelTimeslotModel)="refreshParticipantModelEventManagementModel()"
  ></mycontent-participant-details-flyout>
}

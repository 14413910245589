/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralTranslate',
  standalone: true,
  pure: false
})
export class PluralTranslatePipe implements PipeTransform {

  transform(translationKeyObject: { [key: string]: string }, translationKey: string, number: number): string {
    const keyString = number === 1 ? 'one' : 'other';
    if (translationKeyObject[keyString]) {
      return `${translationKey}.${keyString}`;
    } else {
      return `${translationKey}.${Object.keys(translationKeyObject)[0]}`;
    }
  }
}

<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<p-heading [size]="'medium'">{{ 'adminEventDetail.registerEvent.subtitle.contactDetails' | translate }}</p-heading>
<mycontent-spacer [size]="'x-small'"></mycontent-spacer>
<form [formGroup]="profileForm">
  @if (isBirthdayRequired()) {
    <section [attr.data-testid]="'profile-birthday-section'">
      <p-text [color]="'contrast-medium'">
        <p-icon [color]="'contrast-medium'" [source]="'calendar'"></p-icon>
        {{ 'adminEventDetail.registerEvent.label.contactDetails.birthday' | translate }}
      </p-text>
      <mycontent-spacer [size]="'small'"></mycontent-spacer>
      @if (profile()?.birthday) {
        <p-text [attr.data-testid]="'manual-registration-profile-birthday'">{{ displayBirthday() }}</p-text>
      } @else {
        <p-inline-notification
          [attr.data-testid]="'manual-registration-profile-birthday-missing'"
          [dismissButton]="false"
          [description]="'adminEventDetail.registerEvent.error.birthdayFail' | translate"
          [state]="'error'"
        ></p-inline-notification>
      }
    </section>
  }
  @if (isAddressRequired()) {
    <section [attr.data-testid]="'profile-address-section'">
      <mycontent-spacer [size]="'small'"></mycontent-spacer>
      <p-text [color]="'contrast-medium'">
        <p-icon [color]="'contrast-medium'" [source]="'home'"></p-icon>
        {{ 'adminEventDetail.registerEvent.label.contactDetails.address' | translate }}
      </p-text>
      <mycontent-spacer [size]="'small'"></mycontent-spacer>
      @if (profile()?.addresses?.length > 0 && isAddressRequired()) {
        @for (address of profile()?.addresses; track address) {
          @if (profile()?.addresses?.length === 1) {
            <p-text [attr.data-testid]="'manual-registration-profile-address'">{{ address.formattedAddress }}</p-text>
          } @else {
            <p-radio-button-wrapper [label]="address.formattedAddress" [hideLabel]="false">
              <input
                [attr.data-testid]="'manual-registration-profile-address-' + address.addressId"
                type="radio"
                [value]="address.addressId"
                [name]="'address'"
                formControlName="address"
              />
            </p-radio-button-wrapper>
            <mycontent-spacer [size]="'x-small'"></mycontent-spacer>
          }
        }
      } @else {
        <p-inline-notification
          [attr.data-testid]="'manual-registration-profile-address-missing'"
          [dismissButton]="false"
          [description]="'adminEventDetail.registerEvent.error.addressFail' | translate"
          [state]="'error'"
        ></p-inline-notification>
      }
    </section>
  }
  @if (isPhoneRequired()) {
    <section [attr.data-testid]="'profile-phone-section'">
      <mycontent-spacer [size]="'small'"></mycontent-spacer>
      <p-text [color]="'contrast-medium'">
        <p-icon [color]="'contrast-medium'" [source]="'phone'"></p-icon>
        {{ 'adminEventDetail.registerEvent.label.contactDetails.phoneNumber' | translate }}
      </p-text>
      <mycontent-spacer [size]="'small'"></mycontent-spacer>
      @if (profile()?.phones?.length > 0) {
        @for (phone of profile()?.phones; track phone) {
          @if (profile()?.phones?.length === 1) {
            <p-text [attr.data-testid]="'manual-registration-profile-phone'">{{ phone.number }}</p-text>
          } @else {
            <p-radio-button-wrapper [label]="phone.number" [hideLabel]="false">
              <input
                [attr.data-testid]="'manual-registration-profile-phone-' + phone.number"
                type="radio"
                [value]="phone.phoneId"
                [name]="'phone'"
                formControlName="phone"
              />
            </p-radio-button-wrapper>
            <mycontent-spacer [size]="'x-small'"></mycontent-spacer>
          }
        }
      } @else {
        <p-inline-notification
          [attr.data-testid]="'manual-registration-profile-phone-missing'"
          [dismissButton]="false"
          [description]="'adminEventDetail.registerEvent.error.phoneNumberFail' | translate"
          [state]="'error'"
        ></p-inline-notification>
      }
    </section>
  }
</form>

<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<p-modal class="participant-cancel-modal" [open]="isOpen()" (dismiss)="closeCancelParticipant()">
  <p-heading slot="header" size="large" tag="h2">{{ 'details.regCancelHeader' | translate }}</p-heading>
  @if (bookingCancellationText()) {
    <p-text>
      <em>{{ bookingCancellationText() }}</em>
      <br>
    </p-text>
  }
  <p-text class="participant-cancel-modal-text">{{ 'details.regCancelText' | translate }}</p-text>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  <p-button-group class="participant-cancel-modal-buttons">
    <p-button
      [attr.data-testid]="'cancel-registration-button-cancel'"
      [type]="'button'"
      [variant]="'primary'"
      [icon]="'check'"
      (click)="triggerCancelParticipant()"
      [loading]="cancelParticipantLoading()"
    >
      {{ 'confirm' | translate }}
    </p-button>
    <p-button
      [attr.data-testid]="'cancel-registration-button-back'"
      [type]="'button'"
      [variant]="'secondary'"
      [icon]="'close'"
      (click)="closeCancelParticipant()"
    >
      {{ 'backButton' | translate }}
    </p-button>
  </p-button-group>
</p-modal>

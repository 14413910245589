/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, HostBinding, inject, input, ViewEncapsulation } from '@angular/core';
import { BreakpointCustomizable, GridItemSize } from '@porsche-design-system/components-angular';
import { BreakpointService } from '../../breakpoint/breakpoint.service';
import { clamp } from 'lodash-es';
import { WINDOW } from '@ui/shared/util';

const gridItemWidths = [
  0, 8.333333, 16.666667, 25, 33.333333, 41.666667, 50, 58.333333, 66.666667, 75, 83.333333, 91.666667, 100,
];

@Component({
  selector: 'mycontent-grid-item',
  templateUrl: 'grid-item.html',
  styleUrls: ['grid-item.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
})
export class GridItemComponent {
  @HostBinding('style.align-self')
  alignSelf = input<'auto' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'>('auto');

  size = input<BreakpointCustomizable<GridItemSize>>(1);
  offset = input<number>();
  usePadding = input<boolean>(true);

  private readonly breakpointService = inject(BreakpointService);
  private readonly window = inject(WINDOW);

  @HostBinding('style.width')
  get gridSizeWidth() {
    const breakpointSize = this.breakpointService.getBreakpointValue(this.size()) as number;
    return `${gridItemWidths[breakpointSize]}%`;
  }

  @HostBinding('style.min-width')
  get gridSizeMinWidth() {
    const breakpointSize = this.breakpointService.getBreakpointValue(this.size()) as number;
    return `${gridItemWidths[breakpointSize]}%`;
  }

  @HostBinding('style.margin-left')
  get gridOffsetMarginLeft() {
    const breakpointOffset = this.breakpointService.getBreakpointValue(this.offset())  as number;
    return `${gridItemWidths[breakpointOffset]}%`;
  }

  @HostBinding('style.padding-left')
  get gridPaddingLeft() {
    return this.calculatePadding();
  }

  @HostBinding('style.padding-right')
  get gridPaddingRight() {
    return this.calculatePadding();
  }

  calculatePadding(): string {
    if (this.usePadding()) {
      const roundedValue = Math.round(clamp(16, this.window.visualViewport.width * 0.0125 + 12, 36));
      return `${roundedValue / 2}px`;
    } else {
      return '0px';
    }
  }
}

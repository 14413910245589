/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core';
import { TagColor } from '@porsche-design-system/components-angular';
import { ParticipantModel } from '../models/participant.model';
import { GroupedTimeslotsModel } from '../models/timeslot.model';
import {
  ADDED,
  CANCELED,
  CHECKED_IN,
  INVITED,
  ON_WAITING_LIST,
  ParticipantStateType,
  PORSCHE_ID_NOT_FOUND,
  READY_FOR_INVITATION,
  REGISTERED,
  UNDEFINED
} from '../models/invitation-state.model';

@Injectable({
  providedIn: 'root'
})
export class FilterParticipantsService {

  filterParticipants(participants: ParticipantModel[], filterText: string): ParticipantModel[] {
    if (filterText) {
      return participants?.filter(participant =>
        this.includedInParticipantFullName(participant, filterText)
        || this.includedInParticipantMail(participant, filterText)
      );
    }
    return participants;
  }

  splitTimeslotSelection(selections: string[], groupedTimeslots: GroupedTimeslotsModel[]) {
    const groupIdentifiers: string[] = [];
    const timeslotIdentifiers: string[] = [];

    selections.forEach(selection => {
      const hasMatchingDate = groupedTimeslots.some(group => group.date === selection);
      if (hasMatchingDate) {
        groupIdentifiers.push(selection);
      }
      if (!hasMatchingDate) {
        timeslotIdentifiers.push(selection);
      }
    });
    return { groupIdentifiers, timeslotIdentifiers };
  }

  getInvitationStateColour(state: ParticipantStateType, useAlternativeColour: boolean = false): TagColor {
    switch (state) {
      case INVITED:
      case ADDED:
      case UNDEFINED:
      case READY_FOR_INVITATION:
        return useAlternativeColour ? 'background-base' : 'background-surface';
      case REGISTERED:
        return 'notification-success-soft';
      case CANCELED:
      case PORSCHE_ID_NOT_FOUND:
        return 'notification-error-soft';
      case CHECKED_IN:
        return 'notification-info-soft';
      case ON_WAITING_LIST:
        return 'notification-warning-soft';
    }
  }

  private includedInParticipantFullName(participant: ParticipantModel, filterText: string): boolean {
    return `${participant?.attendee?.firstName} ${participant?.attendee?.lastName}`.toLowerCase().includes(filterText?.toLowerCase());
  }

  private includedInParticipantMail(participant: ParticipantModel, filterText: string): boolean {
    return participant.attendee.email.toLowerCase().includes(filterText?.toLowerCase());
  }
}

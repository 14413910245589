<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->

<p-button-pure
  [class]="'copy-to-clipboard'"
  [icon]="savedToClipboard() ? 'check' : 'copy'"
  (click)="copyToClipboard()"
>
  <ng-content></ng-content>
</p-button-pure>


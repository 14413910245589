<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<p-modal [open]="isOpen()" (dismiss)="onDismiss()">
  <p-heading slot="header" [size]="'large'" [tag]="'h2'">{{ 'adminEventDetail.dialog.title.quickAdd' | translate }}</p-heading>
  <form [formGroup]="form" accept-charset="UTF-8">
    <div class="quick-add">
      <mycontent-flex [direction]="'row'" [justifyContent]="'space-between'">
        <p-text-field-wrapper
          class="quick-add-modal-input-field__large space-right quick-add__textfield"
          [label]="'adminEventDetail.dialog.label.name' | translate"
          [state]="getFormState('firstname')"
          [message]="'forms.error.infoIsRequired' | translate"
        >
          <input
            id="quick-add-input-name-first-name"
            [required]="true"
            [type]="'text'"
            [placeholder]="'adminEventDetail.dialog.label.placeholderName' | translate"
            [formControlName]="'firstname'"
          />
        </p-text-field-wrapper>
        <p-text-field-wrapper
          class="quick-add-modal-input-field__small space-left quick-add__textfield"
          [label]="'adminEventDetail.dialog.label.lastname' | translate"
          [state]="getFormState('lastname')"
          [message]="'forms.error.infoIsRequired' | translate"
        >
          <input
            id="quick-add-input-name-last-name"
            [required]="true"
            [type]="'text'"
            [placeholder]="'adminEventDetail.dialog.label.placeholderLastname' | translate"
            [formControlName]="'lastname'"
          />
        </p-text-field-wrapper>
      </mycontent-flex>
      <mycontent-flex [direction]="'row'" [justifyContent]="'flex-start'">
        <p-text-field-wrapper
          class="quick-add-modal-input-field__small space-left quick-add__textfield"
          [label]="'adminEventDetail.dialog.label.porscheID' | translate"
          [state]="getFormState('email')"
        >
          <input
            id="quick-add-input-email"
            [required]="true"
            [type]="'email'"
            [placeholder]="'adminEventDetail.dialog.label.placeholderEmail' | translate"
            [formControlName]="'email'"
          />
          @if (getFormError('email', 'email')) {
            <span slot="message" id="quick-add-email-invalid">{{ 'forms.error.invalidEmail' |translate}}</span>
          }
          @if (getFormError('email', 'required')) {
            <span slot="message" id="quick-add-email-required">{{ 'forms.error.infoIsRequired' |translate}}</span>
          }
        </p-text-field-wrapper>
      </mycontent-flex>
      @if (hasError) {
        <mycontent-flex>
          <div id="quick-add-error" class="quick-add__error">
            <pre>{{errorMessage}}</pre>
          </div>
        </mycontent-flex>
      }
      <mycontent-flex [direction]="'row'" [justifyContent]="'flex-start'">
        <p-button-group class="footer quick-add__footer">
          <p-button
            [attr.data-testid]="'quick-add-modal-accept-btn'"
            [icon]="'check'"
            [loading]="isSubmitting"
            (click)="submit()"
          >
            {{'adminEventDetail.dialog.button.addInvitee' | translate}}
          </p-button>
          <p-button
            [attr.data-testid]="'quick-add-modal-cancel-btn'"
            [icon]="'close'"
            [type]="'button'"
            [variant]="'secondary'"
            (click)="close()"
          >
            {{ 'buttonCancel' | translate }}
          </p-button>
        </p-button-group>
      </mycontent-flex>
    </div>
  </form>
</p-modal>

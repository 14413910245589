/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */


import { Injectable, signal } from '@angular/core';
import { AuthorizationParams } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class TeaserPageStateService {
  url = signal<string>('');
  authorizationParams = signal<AuthorizationParams>({});

  reset() {
    this.url.set('');
    this.authorizationParams.set({});
  }
}

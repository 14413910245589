<!--
  ~  This code is protected by intellectual property rights.
  ~  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->


<p-modal
  [open]="isOpen()"
  [aria]="{ 'aria-label': ('adminDeleteEventModal.title' | translate: { eventTitle: event()?.title }) }"
  (dismiss)="isOpen.set(false)"
>
  <p-heading slot="header" [size]="'large'" [tag]="'h2'">{{ 'adminDeleteEventModal.title' | translate: { eventTitle: event()?.title} }}</p-heading>
  <p-text>{{ 'adminDeleteEventModal.paragraph' | translate: {eventTitle: event()?.title} }}</p-text>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  <form [formGroup]="form">
    <p-text-field-wrapper>
      <input
        id="event-overview-delete-event-input-delete-event"
        [attr.data-testId]="'event-admin-delete-event-delete-input'"
        [type]="'text'"
        [name]="'deleteEvent'"
        [formControlName]="'deleteEvent'"
        [autocomplete]="'off'"
      />
    </p-text-field-wrapper>
    <mycontent-spacer [size]="'small'"></mycontent-spacer>
    @if (!!(error$ | async)) {
      <mycontent-spacer [size]="'medium'"></mycontent-spacer>
      <p-text [color]="'notification-error'">{{ 'error.generic' | translate }}</p-text>
    }
    <mycontent-spacer [size]="'medium'"></mycontent-spacer>
    <p-button-group class="event-overview-delete-event-modal__buttons mycontent-modal-container__button-group mycontent-modal-container__button-group--pds">
      <p-button
        class="event-overview-delete-event-modal__button mycontent-modal-container__button mycontent-modal-container__button--pds"
        [attr.data-testId]="'event-admin-delete-event-delete-button'"
        [variant]="'primary'"
        [type]="'submit'"
        [disabled]="!form.valid"
        (click)="onSubmit()"
        [loading]="loading$ | async"
        [aria]="{'aria-label': 'adminEventManagementModal.button.complete' | translate }"
      >
        {{ 'adminDeleteEventModal.button.confirm' | translate }}
      </p-button>

      <p-button
        class="event-overview-delete-event-modal__button mycontent-modal-container__button mycontent-modal-container__button--pds"
        [variant]="'tertiary'"
        (click)="isOpen.set(false)"
      >
        {{ 'cancel' | translate }}
      </p-button>
    </p-button-group>
  </form>
</p-modal>

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, signal } from '@angular/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';

@Component({
  selector: 'mycontent-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule
  ]
})
export class CopyToClipboardComponent {
  copyValue = input.required<string>();

  savedToClipboard = signal<boolean>(false);

  async copyToClipboard() {
    await navigator.clipboard.writeText(this.copyValue());
    this.savedToClipboard.set(true);
    setTimeout(() => {
      this.savedToClipboard.set(false);
    }, 1000);
  }
}

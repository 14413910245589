/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */


import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LockStatusModel } from '../../models/lock-status.model';

@Component({
  selector: 'mycontent-get-polling-status-percentage',
  templateUrl: './get-polling-status-percentage.component.html',
  styleUrls: ['./get-polling-status-percentage.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatProgressBarModule
  ]
})
export class GetPollingStatusPercentageComponent {

  @Input() lock: LockStatusModel;

  getPercentage(successes: number, errors: number, total: number): number {
    const result = Math.round(((successes + errors) / total) * 100);
    return isNaN(result) ? 0 : result;
  }
}

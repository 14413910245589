/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { CookieConsentModel, USC_CONSENT_CHANGED_EVENT, UscCustomEvent } from './usc-consent.model';
import { CookieConsentStatusProvider } from '../../cookie-consent-status-provider';
import { CookieConsentServiceName, SERVICENAME_BY_TECHNICAL_NAME, TECHNICAL_NAME_MAPPING } from '../../cookie-consent-typings';
import { WINDOW } from '../../../window/window.provider';
import { ScriptLoaderService } from '../../script-loader.service';

export interface WindowWithUserCentrics extends Window {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  usercentrics: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  GlobalConsent: any;
}

@Injectable({
  providedIn: 'root'
})
export class UscCookieConsentStatusProviderService implements CookieConsentStatusProvider {
  public static USC_INIT_EVENT_NAME = 'ucInit';
  public static USC_EVENT_NAME = 'usercentrics-events';
  public static USC_UI_EVENT_NAME = 'ucEvents';

  private readonly uscEventName;
  private initialized = false;
  private serviceNameConsentMap: {
    [key in CookieConsentServiceName]?: ReplaySubject<boolean>;
  } = {};

  constructor(
    @Inject(WINDOW) protected window: WindowWithUserCentrics,
    protected scriptLoader: ScriptLoaderService,
  ) {
    this.uscEventName = UscCookieConsentStatusProviderService.USC_EVENT_NAME;
    this.initServiceLoadedMap();
  }

  /**
   * Fetch all required scripts and init the usercentrics object if enabled
   * Listen for usercentrics events to check for user's consents
   */
  public init(): Promise<void> {
    if (this.initialized) {
      return Promise.resolve();
    }

    return Promise.all([
      this.addEventListeners(),
      this.loadUdgUcScripts()
    ]).then(() => {
      this.initialized = true;
      console.log(`Usercentrics scripts loaded.`);
      return Promise.resolve();
    }).catch(reason => {
      console.error({
          name: 'Could not load Usercentrics scripts.',
          message: JSON.stringify(reason)
        });
        return Promise.resolve();
      }
    );
  }

  /**
   * @return true for gdpr countries or other countries requiring cookie consent
   */
  isConsentRequired(): boolean {
    return true;
  }


  /**
   * Update consent status by calling the USC API to either grant or remove consent
   * @param serviceName
   * @param status
   */
  public updateConsent(serviceName: CookieConsentServiceName, status = true): void {
    this.window?.usercentrics?.updateConsents([{
      templateId: TECHNICAL_NAME_MAPPING[serviceName],
      status
    }]);
  }

  /**
   * Re-open the usercentrics modal with the current cookie information
   * @param serviceName
   */
  public openCookieInformationModal(serviceName: CookieConsentServiceName): void {
    this.window.usercentrics.updateConsentInfoModalIsVisible(true, this.getConsent(serviceName)?.templateId);
  }

  /**
   * Return constructed url to cookiePolicy page in connect store
   */
  public getCookiePolicyUrl(): string {
    return this.window.GlobalConsent?.UrlCookiePolicy;
  }

  getConsentDisplayName(consentSystemName: CookieConsentServiceName): string {
    return this.getConsent(consentSystemName)?.dataProcessor;
  }

  /**
   * Checks if a user has given consent for a specific service
   * @param serviceName
   */
  public isConsentGiven(serviceName: CookieConsentServiceName): Observable<boolean> {
    return this.serviceNameConsentMap[serviceName];
  }

  /**
   * Listen for the ui_changed event of type firstLayer, which indicates if the banner is shown.
   * This is always fired when the script has loaded or when a user interaction closes the element.
   * In all cases, when the banner is not visible, we can safely determine the status from local storage.
   * @private
   */
  private addEventListeners(): Promise<void> {
    return new Promise(resolve => {
      this.window.addEventListener(UscCookieConsentStatusProviderService.USC_INIT_EVENT_NAME, () => {
        this.initUscConsentStatus();
        resolve();
      });
      this.window.addEventListener(this.uscEventName, (e: UscCustomEvent) =>
        this.updateUscConsentStatus(e));
      this.window.addEventListener(
        UscCookieConsentStatusProviderService.USC_UI_EVENT_NAME, (e: UscCustomEvent) =>
          this.updateUscConsentStatus(e));
    });
  }

  private async loadUdgUcScripts(): Promise<void> {
    await this.scriptLoader.load('https://www.porsche.com/redesign-scripts/vendor/udg-uc-sdk.min.js', {}, false);
  }

  /**
   * Gives the all consents
   */
  private getConsents(): CookieConsentModel[] {
    if (!this.window.usercentrics.getConsents) {
      return [];
    }
    return this.window.usercentrics.getConsents() || [];
  }

  /**
   * Gives one consent if it exits
   */
  private getConsent(serviceName: CookieConsentServiceName): CookieConsentModel {
    return this.getConsents().find(elem => elem.technicalName === TECHNICAL_NAME_MAPPING[serviceName]);
  }

  private initServiceLoadedMap(): void {
    Object.values(CookieConsentServiceName)
      .forEach(serviceName => this.serviceNameConsentMap[serviceName] = new ReplaySubject<boolean>());
  }

  private initUscConsentStatus() {
    const consentMap = this.getConsents()
      .filter(consent => consent.technicalName !== null)
      .reduce((result, consent) => ({ ...result, [consent.technicalName]: consent }), {});

    Object.keys(this.serviceNameConsentMap).forEach(serviceName => {
      const consent = consentMap[TECHNICAL_NAME_MAPPING[serviceName]];
      if (consent) {
        this.serviceNameConsentMap[serviceName].next(consent.consentStatus);
      }
    });
  }

  private updateUscConsentStatus(uscEvent: UscCustomEvent) {
    const consentChangedData = uscEvent.detail?.data;
    if (consentChangedData?.event === USC_CONSENT_CHANGED_EVENT) {
      this.serviceNameConsentMap[SERVICENAME_BY_TECHNICAL_NAME[consentChangedData.name]]?.next(consentChangedData.status);
    }
  }
}

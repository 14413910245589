<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<p-modal  [open]="isOpen()" (dismiss)="onDismiss()">
  <p-heading slot="header" size="large" tag="h2">{{ 'adminEventDetail.dialog.title.checkInUser' | translate }}</p-heading>
  <p-text>{{ 'adminEventDetail.dialog.paragraph.checkInUser' | translate }}</p-text>
  <form [formGroup]="form">
    <p-select-wrapper class="participants-table__header-selector" [hideLabel]="true" [dropdownDirection]="'auto'">
      <select formControlName="numberOfTickets">
        @for (ticket of ticketCount; track ticket; let i = $index) {
          <option [value]="i + 1">{{ i + 1 }}</option>
        }
      </select>
    </p-select-wrapper>
  </form>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  <p-button-group class="footer">
    <p-button [type]="'submit'" (click)="onSubmit()">{{ 'confirm' |translate }}</p-button>
    <p-button
      [type]="'button'"
      [variant]="'secondary'"
      [icon]="'close'"
      [loading]="checkinParticipantLoading()"
      (click)="onDismiss()"
    >
      {{ 'buttonCancel' |translate }}
    </p-button>
  </p-button-group>
</p-modal>

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export const timeZones: Record<string, string> = {
  'Europe/Andorra': 'ad',
  'Asia/Dubai': 'ae',
  'Asia/Kabul': 'af',
  'Europe/Tirane': 'al',
  'Asia/Yerevan': 'am',
  'Antarctica/Casey': 'aq',
  'Antarctica/Davis': 'aq',
  'Antarctica/Mawson': 'aq',
  'Antarctica/Palmer': 'aq',
  'Antarctica/Rothera': 'aq',
  'Antarctica/Troll': 'aq',
  'America/Argentina/Buenos_Aires': 'ar',
  'America/Argentina/Cordoba': 'ar',
  'America/Argentina/Salta': 'ar',
  'America/Argentina/Jujuy': 'ar',
  'America/Argentina/Tucuman': 'ar',
  'America/Argentina/Catamarca': 'ar',
  'America/Argentina/La_Rioja': 'ar',
  'America/Argentina/San_Juan': 'ar',
  'America/Argentina/Mendoza': 'ar',
  'America/Argentina/San_Luis': 'ar',
  'America/Argentina/Rio_Gallegos': 'ar',
  'America/Argentina/Ushuaia': 'ar',
  'Pacific/Pago_Pago': 'as',
  'Europe/Vienna': 'at',
  'Australia/Lord_Howe': 'au',
  'Antarctica/Macquarie': 'au',
  'Australia/Hobart': 'au',
  'Australia/Melbourne': 'au',
  'Australia/Sydney': 'au',
  'Australia/Broken_Hill': 'au',
  'Australia/Brisbane': 'au',
  'Australia/Lindeman': 'au',
  'Australia/Adelaide': 'au',
  'Australia/Darwin': 'au',
  'Australia/Perth': 'au',
  'Australia/Eucla': 'au',
  'Asia/Baku': 'az',
  'America/Barbados': 'bb',
  'Asia/Dhaka': 'bd',
  'Europe/Brussels': 'be',
  'Europe/Sofia': 'bg',
  'Atlantic/Bermuda': 'bm',
  'America/La_Paz': 'bo',
  'America/Noronha': 'br',
  'America/Belem': 'br',
  'America/Fortaleza': 'br',
  'America/Recife': 'br',
  'America/Araguaina': 'br',
  'America/Maceio': 'br',
  'America/Bahia': 'br',
  'America/Sao_Paulo': 'br',
  'America/Campo_Grande': 'br',
  'America/Cuiaba': 'br',
  'America/Santarem': 'br',
  'America/Porto_Velho': 'br',
  'America/Boa_Vista': 'br',
  'America/Manaus': 'br',
  'America/Eirunepe': 'br',
  'America/Rio_Branco': 'br',
  'Asia/Thimphu': 'bt',
  'Europe/Minsk': 'by',
  'America/Belize': 'bz',
  'America/St_Johns': 'ca',
  'America/Halifax': 'ca',
  'America/Glace_Bay': 'ca',
  'America/Moncton': 'ca',
  'America/Goose_Bay': 'ca',
  'America/Toronto': 'ca',
  'America/Nipigon': 'ca',
  'America/Thunder_Bay': 'ca',
  'America/Iqaluit': 'ca',
  'America/Pangnirtung': 'ca',
  'America/Winnipeg': 'ca',
  'America/Rainy_River': 'ca',
  'America/Resolute': 'ca',
  'America/Rankin_Inlet': 'ca',
  'America/Regina': 'ca',
  'America/Swift_Current': 'ca',
  'America/Edmonton': 'ca',
  'America/Cambridge_Bay': 'ca',
  'America/Yellowknife': 'ca',
  'America/Inuvik': 'ca',
  'America/Dawson_Creek': 'ca',
  'America/Fort_Nelson': 'ca',
  'America/Whitehorse': 'ca',
  'America/Dawson': 'ca',
  'America/Vancouver': 'ca',
  'Europe/Zurich': 'ch',
  'Africa/Abidjan': 'ci',
  'Pacific/Rarotonga': 'ck',
  'America/Santiago': 'cl',
  'America/Punta_Arenas': 'cl',
  'Pacific/Easter': 'cl',
  'Asia/Shanghai': 'cn',
  'Asia/Urumqi': 'cn',
  'America/Bogota': 'co',
  'America/Costa_Rica': 'cr',
  'America/Havana': 'cu',
  'Atlantic/Cape_Verde': 'cv',
  'Asia/Nicosia': 'cy',
  'Asia/Famagusta': 'cy',
  'Europe/Prague': 'cz',
  'Europe/Berlin': 'de',
  'America/Santo_Domingo': 'do',
  'Africa/Algiers': 'dz',
  'America/Guayaquil': 'ec',
  'Pacific/Galapagos': 'ec',
  'Europe/Tallinn': 'ee',
  'Africa/Cairo': 'eg',
  'Africa/El_Aaiun': 'eh',
  'Europe/Madrid': 'es',
  'Africa/Ceuta': 'es',
  'Atlantic/Canary': 'es',
  'Europe/Helsinki': 'fi',
  'Pacific/Fiji': 'fj',
  'Atlantic/Stanley': 'fk',
  'Pacific/Kosrae': 'fm',
  'Atlantic/Faroe': 'fo',
  'Europe/Paris': 'fr',
  'Europe/London': 'gb',
  'Asia/Tbilisi': 'ge',
  'America/Cayenne': 'gf',
  'Europe/Gibraltar': 'gi',
  'America/Nuuk': 'gl',
  'America/Danmarkshavn': 'gl',
  'America/Scoresbysund': 'gl',
  'America/Thule': 'gl',
  'Europe/Athens': 'gr',
  'Atlantic/South_Georgia': 'gs',
  'America/Guatemala': 'gt',
  'Pacific/Guam': 'gu',
  'Africa/Bissau': 'gw',
  'America/Guyana': 'gy',
  'Asia/Hong_Kong': 'hk',
  'America/Tegucigalpa': 'hn',
  'America/Port-au-Prince': 'ht',
  'Europe/Budapest': 'hu',
  'Asia/Jakarta': 'id',
  'Asia/Pontianak': 'id',
  'Asia/Makassar': 'id',
  'Asia/Jayapura': 'id',
  'Europe/Dublin': 'ie',
  'Asia/Jerusalem': 'il',
  'Asia/Kolkata': 'in',
  'Indian/Chagos': 'io',
  'Asia/Baghdad': 'iq',
  'Asia/Tehran': 'ir',
  'Europe/Rome': 'it',
  'America/Jamaica': 'jm',
  'Asia/Amman': 'jo',
  'Asia/Tokyo': 'jp',
  'Africa/Nairobi': 'ke',
  'Asia/Bishkek': 'kg',
  'Pacific/Tarawa': 'ki',
  'Pacific/Kanton': 'ki',
  'Pacific/Kiritimati': 'ki',
  'Asia/Pyongyang': 'kp',
  'Asia/Seoul': 'kr',
  'Asia/Almaty': 'kz',
  'Asia/Qyzylorda': 'kz',
  'Asia/Qostanay': 'kz',
  'Asia/Aqtobe': 'kz',
  'Asia/Aqtau': 'kz',
  'Asia/Atyrau': 'kz',
  'Asia/Oral': 'kz',
  'Asia/Beirut': 'lb',
  'Asia/Colombo': 'lk',
  'Africa/Monrovia': 'lr',
  'Europe/Vilnius': 'lt',
  'Europe/Riga': 'lv',
  'Africa/Tripoli': 'ly',
  'Africa/Casablanca': 'ma',
  'Europe/Chisinau': 'md',
  'Pacific/Kwajalein': 'mh',
  'Asia/Yangon': 'mm',
  'Asia/Ulaanbaatar': 'mn',
  'Asia/Hovd': 'mn',
  'Asia/Choibalsan': 'mn',
  'Asia/Macau': 'mo',
  'America/Martinique': 'mq',
  'Europe/Malta': 'mt',
  'Indian/Mauritius': 'mu',
  'Indian/Maldives': 'mv',
  'America/Mexico_City': 'mx',
  'America/Cancun': 'mx',
  'America/Merida': 'mx',
  'America/Monterrey': 'mx',
  'America/Matamoros': 'mx',
  'America/Mazatlan': 'mx',
  'America/Chihuahua': 'mx',
  'America/Ojinaga': 'mx',
  'America/Hermosillo': 'mx',
  'America/Tijuana': 'mx',
  'America/Bahia_Banderas': 'mx',
  'Asia/Kuching': 'my',
  'Africa/Maputo': 'mz',
  'Africa/Windhoek': 'na',
  'Pacific/Noumea': 'nc',
  'Pacific/Norfolk': 'nf',
  'Africa/Lagos': 'ng',
  'America/Managua': 'ni',
  'Asia/Kathmandu': 'np',
  'Pacific/Nauru': 'nr',
  'Pacific/Niue': 'nu',
  'Pacific/Auckland': 'nz',
  'Pacific/Chatham': 'nz',
  'America/Panama': 'pa',
  'America/Lima': 'pe',
  'Pacific/Tahiti': 'pf',
  'Pacific/Marquesas': 'pf',
  'Pacific/Gambier': 'pf',
  'Pacific/Port_Moresby': 'pg',
  'Pacific/Bougainville': 'pg',
  'Asia/Manila': 'ph',
  'Asia/Karachi': 'pk',
  'Europe/Warsaw': 'pl',
  'America/Miquelon': 'pm',
  'Pacific/Pitcairn': 'pn',
  'America/Puerto_Rico': 'pr',
  'Asia/Gaza': 'ps',
  'Asia/Hebron': 'ps',
  'Europe/Lisbon': 'pt',
  'Atlantic/Madeira': 'pt',
  'Atlantic/Azores': 'pt',
  'Pacific/Palau': 'pw',
  'America/Asuncion': 'py',
  'Asia/Qatar': 'qa',
  'Europe/Bucharest': 'ro',
  'Europe/Belgrade': 'rs',
  'Europe/Kaliningrad': 'ru',
  'Europe/Moscow': 'ru',
  'Europe/Simferopol': 'ua',
  'Europe/Kirov': 'ru',
  'Europe/Volgograd': 'ru',
  'Europe/Astrakhan': 'ru',
  'Europe/Saratov': 'ru',
  'Europe/Ulyanovsk': 'ru',
  'Europe/Samara': 'ru',
  'Asia/Yekaterinburg': 'ru',
  'Asia/Omsk': 'ru',
  'Asia/Novosibirsk': 'ru',
  'Asia/Barnaul': 'ru',
  'Asia/Tomsk': 'ru',
  'Asia/Novokuznetsk': 'ru',
  'Asia/Krasnoyarsk': 'ru',
  'Asia/Irkutsk': 'ru',
  'Asia/Chita': 'ru',
  'Asia/Yakutsk': 'ru',
  'Asia/Khandyga': 'ru',
  'Asia/Vladivostok': 'ru',
  'Asia/Ust-Nera': 'ru',
  'Asia/Magadan': 'ru',
  'Asia/Sakhalin': 'ru',
  'Asia/Srednekolymsk': 'ru',
  'Asia/Kamchatka': 'ru',
  'Asia/Anadyr': 'ru',
  'Asia/Riyadh': 'sa',
  'Pacific/Guadalcanal': 'sb',
  'Africa/Khartoum': 'sd',
  'Asia/Singapore': 'sg',
  'America/Paramaribo': 'sr',
  'Africa/Juba': 'ss',
  'Africa/Sao_Tome': 'st',
  'America/El_Salvador': 'sv',
  'Asia/Damascus': 'sy',
  'America/Grand_Turk': 'tc',
  'Africa/Ndjamena': 'td',
  'Asia/Bangkok': 'th',
  'Asia/Dushanbe': 'tj',
  'Pacific/Fakaofo': 'tk',
  'Asia/Dili': 'tl',
  'Asia/Ashgabat': 'tm',
  'Africa/Tunis': 'tn',
  'Pacific/Tongatapu': 'to',
  'Europe/Istanbul': 'tr',
  'Asia/Taipei': 'tw',
  'Europe/Kyiv': 'ua',
  'America/New_York': 'us',
  'America/Detroit': 'us',
  'America/Kentucky/Louisville': 'us',
  'America/Kentucky/Monticello': 'us',
  'America/Indiana/Indianapolis': 'us',
  'America/Indiana/Vincennes': 'us',
  'America/Indiana/Winamac': 'us',
  'America/Indiana/Marengo': 'us',
  'America/Indiana/Petersburg': 'us',
  'America/Indiana/Vevay': 'us',
  'America/Chicago': 'us',
  'America/Indiana/Tell_City': 'us',
  'America/Indiana/Knox': 'us',
  'America/Menominee': 'us',
  'America/North_Dakota/Center': 'us',
  'America/North_Dakota/New_Salem': 'us',
  'America/North_Dakota/Beulah': 'us',
  'America/Denver': 'us',
  'America/Boise': 'us',
  'America/Phoenix': 'us',
  'America/Los_Angeles': 'us',
  'America/Anchorage': 'us',
  'America/Juneau': 'us',
  'America/Sitka': 'us',
  'America/Metlakatla': 'us',
  'America/Yakutat': 'us',
  'America/Nome': 'us',
  'America/Adak': 'us',
  'Pacific/Honolulu': 'us',
  'America/Montevideo': 'uy',
  'Asia/Samarkand': 'uz',
  'Asia/Tashkent': 'uz',
  'America/Caracas': 've',
  'Asia/Ho_Chi_Minh': 'vn',
  'Pacific/Efate': 'vu',
  'Pacific/Apia': 'ws',
  'Africa/Johannesburg': 'za',
  'America/Antigua': 'ag',
  'America/Anguilla': 'ai',
  'Africa/Luanda': 'ao',
  'Antarctica/McMurdo': 'aq',
  'Antarctica/DumontDUrville': 'aq',
  'Antarctica/Syowa': 'aq',
  'Antarctica/Vostok': 'aq',
  'America/Aruba': 'aw',
  'Europe/Mariehamn': 'ax',
  'Europe/Sarajevo': 'ba',
  'Africa/Ouagadougou': 'bf',
  'Asia/Bahrain': 'bh',
  'Africa/Bujumbura': 'bi',
  'Africa/Porto-Novo': 'bj',
  'America/St_Barthelemy': 'bl',
  'Asia/Brunei': 'bn',
  'America/Kralendijk': 'bq',
  'America/Nassau': 'bs',
  'Africa/Gaborone': 'bw',
  'America/Blanc-Sablon': 'ca',
  'America/Atikokan': 'ca',
  'America/Creston': 'ca',
  'Indian/Cocos': 'cc',
  'Africa/Kinshasa': 'cd',
  'Africa/Lubumbashi': 'cd',
  'Africa/Bangui': 'cf',
  'Africa/Brazzaville': 'cg',
  'Africa/Douala': 'cm',
  'America/Curacao': 'cw',
  'Indian/Christmas': 'cx',
  'Europe/Busingen': 'de',
  'Africa/Djibouti': 'dj',
  'Europe/Copenhagen': 'dk',
  'America/Dominica': 'dm',
  'Africa/Asmara': 'er',
  'Africa/Addis_Ababa': 'et',
  'Pacific/Chuuk': 'fm',
  'Pacific/Pohnpei': 'fm',
  'Africa/Libreville': 'ga',
  'America/Grenada': 'gd',
  'Europe/Guernsey': 'gg',
  'Africa/Accra': 'gh',
  'Africa/Banjul': 'gm',
  'Africa/Conakry': 'gn',
  'America/Guadeloupe': 'gp',
  'Africa/Malabo': 'gq',
  'Europe/Zagreb': 'hr',
  'Europe/Isle_of_Man': 'im',
  'Atlantic/Reykjavik': 'is',
  'Europe/Jersey': 'je',
  'Asia/Phnom_Penh': 'kh',
  'Indian/Comoro': 'km',
  'America/St_Kitts': 'kn',
  'Asia/Kuwait': 'kw',
  'America/Cayman': 'ky',
  'Asia/Vientiane': 'la',
  'America/St_Lucia': 'lc',
  'Europe/Vaduz': 'li',
  'Africa/Maseru': 'ls',
  'Europe/Luxembourg': 'lu',
  'Europe/Monaco': 'mc',
  'Europe/Podgorica': 'me',
  'America/Marigot': 'mf',
  'Indian/Antananarivo': 'mg',
  'Pacific/Majuro': 'mh',
  'Europe/Skopje': 'mk',
  'Africa/Bamako': 'ml',
  'Pacific/Saipan': 'mp',
  'Africa/Nouakchott': 'mr',
  'America/Montserrat': 'ms',
  'Africa/Blantyre': 'mw',
  'Asia/Kuala_Lumpur': 'my',
  'Africa/Niamey': 'ne',
  'Europe/Amsterdam': 'nl',
  'Europe/Oslo': 'no',
  'Asia/Muscat': 'om',
  'Indian/Reunion': 're',
  'Africa/Kigali': 'rw',
  'Indian/Mahe': 'sc',
  'Europe/Stockholm': 'se',
  'Atlantic/St_Helena': 'sh',
  'Europe/Ljubljana': 'si',
  'Arctic/Longyearbyen': 'sj',
  'Europe/Bratislava': 'sk',
  'Africa/Freetown': 'sl',
  'Europe/San_Marino': 'sm',
  'Africa/Dakar': 'sn',
  'Africa/Mogadishu': 'so',
  'America/Lower_Princes': 'sx',
  'Africa/Mbabane': 'sz',
  'Indian/Kerguelen': 'tf',
  'Africa/Lome': 'tg',
  'America/Port_of_Spain': 'tt',
  'Pacific/Funafuti': 'tv',
  'Africa/Dar_es_Salaam': 'tz',
  'Africa/Kampala': 'ug',
  'Pacific/Midway': 'um',
  'Pacific/Wake': 'um',
  'Europe/Vatican': 'va',
  'America/St_Vincent': 'vc',
  'America/Tortola': 'vg',
  'America/St_Thomas': 'vi',
  'Pacific/Wallis': 'wf',
  'Asia/Aden': 'ye',
  'Indian/Mayotte': 'yt',
  'Africa/Lusaka': 'zm',
  'Africa/Harare': 'zw',
};

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, inject, input, OnInit } from '@angular/core';
import { WINDOW } from '@ui/shared/util';

@Component({
  selector: 'mycontent-scroll-to-target',
  template: '',
  standalone: true,
  providers: [
    {provide: window, useValue: WINDOW}
  ]
})
export class ScrollToTargetComponent implements OnInit {
  target = input<string>();

  private window: Window = inject(WINDOW);

  ngOnInit() {
    if (this.target()) {
      const selectorElem = this.window.document.querySelector(this.target());
      if (selectorElem && this.window.getComputedStyle(selectorElem).display !== 'none') {
        selectorElem.scrollIntoView({ behavior: 'smooth', block: 'start' });
        return;
      }
    }
    this.window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, HostBinding, inject, Input, ViewEncapsulation } from '@angular/core';
import { BreakpointCustomizable, GridDirection } from '@porsche-design-system/components-angular';
import { BreakpointService } from '../../breakpoint/breakpoint.service';

@Component({
  selector: 'mycontent-grid',
  templateUrl: 'grid.html',
  styleUrls: ['grid.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [],
})
export class GridComponent {

  @Input({ transform: (value: string): ({
      base?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
      xs?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
      s?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
      m?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
      l?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
      xl?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
      xxl?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
    } & {
      base: 'row' | 'row-reverse' | 'column' | 'column-reverse'
    }) | 'row' | 'row-reverse' | 'column' | 'column-reverse' => value as BreakpointCustomizable<GridDirection>, required: false }) public direction: BreakpointCustomizable<GridDirection> = 'row';

  breakpointService = inject(BreakpointService);

  @HostBinding('style.flex-direction')
  get flexDirection() {
    return this.breakpointService.getBreakpointValue(this.direction);
  }

}

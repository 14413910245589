/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, model, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { PorscheDesignSystemModule, SegmentedControlUpdateEventDetail, ToastManager } from '@porsche-design-system/components-angular';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { EventManagementFacade, EventManagementModel, EventsManagementService, PatchEventRequest } from '@admin/shared';
import { OverviewEventResponseModel } from '../../models/overview.model';
import { SpacerComponent } from '@ui/shared/ui';

@Component({
  selector: 'mycontent-event-lifecycle-modal',
  templateUrl: './event-lifecycle-modal.component.html',
  styleUrls: ['./event-lifecycle-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    TranslatePipe,
    ReactiveFormsModule,
    AsyncPipe,
    HttpClientModule,
    SpacerComponent
  ],
  providers: [EventManagementFacade, EventsManagementService]
})
export class EventLifecycleModalComponent {
  country = input<string>();
  event = input<OverviewEventResponseModel>();

  isOpen = model<boolean>();

  managementType = signal<string>(undefined);

  private readonly eventsFacade = inject(EventManagementFacade);
  private readonly router = inject(Router);
  private readonly toastManager = inject(ToastManager);
  private readonly translateService = inject(TranslateService);

  /* eslint-disable @typescript-eslint/member-ordering */
  error$ = this.eventsFacade.updateEventError$;
  loading$ = this.eventsFacade.updateEventLoading$;

  onSubmit(): void {
    const patchEventRequest: PatchEventRequest = {
      managementType: this.managementType()
    } as PatchEventRequest;
    this.eventsFacade.updateEvent(this.country(), this.event().id, patchEventRequest).subscribe((eventDetails: EventManagementModel) => {
      this.toastManager.addMessage({
        text: this.translateService.instant('adminEventDetail.toast.selectManagementTypeSuccessful'),
        state: 'success'
      });
      if (eventDetails) {
        this.router.navigate([`/${this.country()}/events/${eventDetails.id}`]);
      }
    });
  }

  onUpdate(e: CustomEvent<SegmentedControlUpdateEventDetail>) {
    this.managementType.set(e.detail.value as string);
  }
}

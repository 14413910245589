<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<div>
  <div class="event-details-timeslot__header-item">
    <p-button
      id="add-timeslot-button"
      class="event-details-timeslot__add-timeslot-button"
      [variant]="'secondary'"
      [icon]="'add'"
      (click)="openTimeslotModalForCreation()">
      {{ 'adminEventDetail.timeslots.button.addTimeslot' | translate }}
    </p-button>
  </div>
</div>
@for (timeslotGroup of groupedTimeslots(); track timeslotGroup.timeslots) {
  <p-text
    data-testid="event-timeslot-group"
    class="event-timeslot-row__timeslot-group"
    [weight]="'semi-bold'"
    [size]="'medium'">
    {{ formatDate(timeslotGroup.date) }}
  </p-text>
  @for (timeslot of timeslotGroup.timeslots; track timeslot; let last = $last) {
    <mycontent-event-management-timeslot-row
      [timeslot]="timeslot"
      [timeslotsLoading]="timeslotsLoading()"
      [hoursBeforeRegistrationCloses]="eventDetails()?.hoursBeforeRegistrationCloses"
      (openDeleteModalEmitter)="openDeleteTimeslotModal($event)"
      (openModalEmitter)="openTimeslotModalForEditing($event)">
    </mycontent-event-management-timeslot-row>
    @if (!last) {
      <p-divider class="event-timeslot-row__divider"></p-divider>
    }
  }
}
@if (isCreateTimeslotModalOpen) {
  <mycontent-flex [direction]="'row'">
    <mycontent-event-management-timeslot-edit-modal
      [setTimeslot]="selectedTimeslot"
      [isEditModal]="isEditModal"
      [eventStartDate]="eventDetails()?.startDate"
      [eventEndDate]="eventDetails()?.endDate"
      [(isModalOpen)]="isCreateTimeslotModalOpen"
      (closeModal)="handleEditTimeslotModal($event)">
    </mycontent-event-management-timeslot-edit-modal>
  </mycontent-flex>
}
@if (isDeleteTimeslotModalOpen) {
  <mycontent-flex [direction]="'row'">
    <mycontent-event-management-timeslot-delete-modal
      [timeslot]="selectedTimeslot"
      [(isModalOpen)]="isDeleteTimeslotModalOpen"
      (deleteModal)="deleteTimeslot($event)">
    </mycontent-event-management-timeslot-delete-modal>
  </mycontent-flex>
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core';
import { addDays, isAfter } from 'date-fns';

const TIMEZONE_SUFFIX = 6;
const UTC_OFFSET = 'Z';
const validTimezoneRegex = /^[+|-](0[0-9]|1[0-2]):(00|30|45)$/;

@Injectable({
  providedIn: 'root',
})
export class DateUtilService {

  getCurrentYearDates(): { startDate: string; endDate: string } {
    const currentDate = new Date();

    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(currentDate.getFullYear(), 11, 31);
    endDate.setHours(23, 59, 59, 999);

    const startDateString = startDate.toISOString();
    const endDateString = endDate.toISOString();

    return {
      startDate: startDateString,
      endDate: endDateString,
    };
  }

  getCurrentWeekDates(): { startDate: string; endDate: string } {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();

    const daysUntilStartOfWeek = currentDay === 0 ? 6 : currentDay - 1;

    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - daysUntilStartOfWeek);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);
    endDate.setHours(23, 59, 59, 999);

    const startDateString = startDate.toISOString();
    const endDateString = endDate.toISOString();

    return {
      startDate: startDateString,
      endDate: endDateString,
    };
  }

  getLastMonthDates() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const firstDayOfPreviousMonth = new Date(currentYear, currentMonth - 1, 1);

    const lastDayOfPreviousMonth = new Date(currentYear, currentMonth, 0, 23, 59, 59, 999);

    const startDateString = firstDayOfPreviousMonth.toISOString();
    const endDateString = lastDayOfPreviousMonth.toISOString();

    return {
      startDate: startDateString,
      endDate: endDateString,
    };
  }

  getLastCalendarWeekDates(): { startDate: string; endDate: string } {
    const currentDate = new Date();

    const lastSunday = new Date(currentDate);
    lastSunday.setDate(currentDate.getDate() - currentDate.getDay());
    lastSunday.setHours(23, 59, 59, 999);

    const previousSunday = new Date(lastSunday);
    previousSunday.setDate(lastSunday.getDate() - 6);
    previousSunday.setHours(0, 0, 0, 0);

    const startDateString = previousSunday.toISOString();
    const endDateString = lastSunday.toISOString();

    return {
      startDate: startDateString,
      endDate: endDateString,
    };
  }

  getNextMonthDates(): { startDate: string; endDate: string } {
    const currentDate = new Date();

    const nextMonthStartDate = new Date(currentDate);
    nextMonthStartDate.setMonth(currentDate.getMonth() + 1, 1);
    nextMonthStartDate.setHours(0, 0, 0, 0);

    const nextMonthEndDate = new Date(currentDate);
    nextMonthEndDate.setMonth(currentDate.getMonth() + 2, 0);
    nextMonthEndDate.setHours(23, 59, 59, 999);

    const startDateString = nextMonthStartDate.toISOString();
    const endDateString = nextMonthEndDate.toISOString();

    return {
      startDate: startDateString,
      endDate: endDateString,
    };
  }

  getLast24HoursDate(): string {
    const currentDate = new Date();

    const date = new Date(currentDate.getTime() - (24 * 60 * 60 * 1000));

    return date.toISOString();
  }

  getLast7DaysDate(): string {
    const currentDate = new Date();

    const date = new Date(currentDate.getTime() - (7 * 24 * 60 * 60 * 1000));

    return date.toISOString();
  }

  getLast30DaysDate(): string {
    const currentDate = new Date();

    const date = new Date(currentDate.getTime() - (30 * 24 * 60 * 60 * 1000));

    return date.toISOString();
  }

  isMoreThanNextDay(startDateString: string, endDateString: string): boolean {
    const startDateWithoutTimezone = this.removeTimezoneFromDateString(startDateString);
    const endDateWithoutTimezone = this.removeTimezoneFromDateString(endDateString);
    const startDate = new Date(startDateWithoutTimezone);
    const endDate = new Date(endDateWithoutTimezone);

    const nextDayEndDate = addDays(startDate, 1);

    return isAfter(endDate, nextDayEndDate);
  }

  isNextDay(startDateString: string, endDateString: string): boolean {
    const startDateWithoutTimezone = this.removeTimezoneFromDateString(startDateString);
    const endDateWithoutTimezone = this.removeTimezoneFromDateString(endDateString);
    const startDate = new Date(startDateWithoutTimezone);
    const endDate = new Date(endDateWithoutTimezone);

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    const diffInDays = Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));

    return diffInDays === 1;
  }

  getDateFromStringDateTimeObject(date: string): string {
    return date.toString().split('T')[0];
  }

  getTimeFromStringDateTimeObject(date: string): string {
    return date.toString().slice(0, -6).split('T')[1];
  }

  getDateTimeWithoutTimezone(dateString: string): string {
    if (dateString) {
      if (dateString.endsWith(UTC_OFFSET)) {
        return dateString.substring(0, dateString.length - 1);
      } else {
        return dateString.substring(0, dateString.length - TIMEZONE_SUFFIX);
      }
    }
  }

  getTimezoneFromDateStringOrNull(dateTimeString: string): string {
    if (dateTimeString.endsWith('Z')) {
      return '+00:00';
    }

    const timezone = dateTimeString.substring(dateTimeString.length - TIMEZONE_SUFFIX);
    return validTimezoneRegex.test(timezone) ? timezone : null;
  }

  isSameDay(startDateStr: string, endDateStr: string): boolean {
    const startYear = this.getDateYearMonthDay(startDateStr).year;
    const startMonth = this.getDateYearMonthDay(startDateStr).month;
    const startDay = this.getDateYearMonthDay(startDateStr).day;

    const endYear = this.getDateYearMonthDay(endDateStr).year;
    const endMonth = this.getDateYearMonthDay(endDateStr).month;
    const endDay = this.getDateYearMonthDay(endDateStr).day;

    return startYear === endYear && startMonth === endMonth && startDay === endDay;
  }

  private getDateYearMonthDay(date: string): { year: number; month: number; day: number } {
    const dateParts = date.match(/^(\d{4})-(\d{2})-(\d{2})T/);
    if (!dateParts) {
      throw new Error('Invalid date string format.');
    }
    return {
      year: parseInt(dateParts[1]),
      month: parseInt(dateParts[2]),
      day: parseInt(dateParts[3]),
    };
  }

  private removeTimezoneFromDateString(dateString: string): string {
    if (dateString.includes('Z')) {
      return dateString.replace('Z', '');
    }
    if (dateString.includes('+') || dateString.slice(-TIMEZONE_SUFFIX).includes('-')) {
      return dateString.slice(0, dateString.length - TIMEZONE_SUFFIX);
    }
    return dateString;
  }
}

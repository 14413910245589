/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { BreakpointCustomizable, BreakpointValues } from '@porsche-design-system/components-angular';
import { inject, Injectable } from '@angular/core';
import { PUI_BREAKPOINT } from './index';
import { toSignal } from '@angular/core/rxjs-interop';

export type BreakpointValue = string | number | boolean;
const breakpoints = ['base', 'xs', 's', 'm', 'l', 'xl', 'xxl'];

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  breakpoint = toSignal(inject(PUI_BREAKPOINT));

  getBreakpointValue(rawValue: BreakpointCustomizable<unknown>): BreakpointValue {
    const value = parseJSON(rawValue as BreakpointCustomizable<BreakpointValue>);
    if (typeof value === 'object') {
      const foundBreakpoint = Object
        .keys(value)
        .filter((key: string) => {
          if (key === this.breakpoint().name) {
            return true;
          }
          if (breakpoints.indexOf(this.breakpoint().name) > breakpoints.indexOf(key as string)) {
            return true;
          }
        });
      if (foundBreakpoint.length > 0) {
        return value[foundBreakpoint[foundBreakpoint.length - 1]];
      } else {
        return value.base;
      }
    } else {
      return value;
    }
  }
}

export function parseJSON(prop: BreakpointCustomizable<BreakpointValue>): BreakpointValues<BreakpointValue> | BreakpointValue {
  if (typeof prop === 'string') {
    try {
      // prop is potentially JSON parsable string, e.g. "{ base: 'block', l: 'inline' }" or "true" or "false"
      return JSON.parse(
        prop
          .replace(/'/g, '"') // convert single quotes to double quotes
          .replace(/[\s"]?([a-z]+)[\s"]?:([^//])/g, '"$1":$2') // wrap keys in double quotes if they don't have them but ignore potential urls
      );
    } catch {
      // prop is string, e.g. "block" or "inline"
      return prop;
    }
  } else {
    // prop is object, e.g. { base: 'block', l: 'inline' } or number, e.g. 123 or boolean, e.g. true
    return prop;
  }
}

<!--
  ~  This code is protected by intellectual property rights.
  ~  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<p-text class="mycontent-mat-datepicker__label">{{ controlLabel() }}</p-text>
<mat-form-field class="mycontent-mat-datepicker" [subscriptSizing]="'dynamic'" (click)="picker.open()">
  <mat-label>
    <p-text>{{ format().toLowerCase() }}</p-text>
  </mat-label>
  <input id="{{elementId()}}" matInput [matDatepicker]="picker" [formControl]="date" (click)="picker.open()" readonly>
  <mat-datepicker-toggle matIconSuffix [for]="picker" [attr.data-testid]="dataTestId()">
    <mat-icon matDatepickerToggleIcon>
      <img src="/assets/calendar.svg"/>
    </mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #picker [startAt]="calendarStartDate()"></mat-datepicker>
</mat-form-field>


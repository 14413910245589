/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, computed, inject, input, output, signal } from '@angular/core';
import { PorscheDesignSystemModule, TagColor } from '@porsche-design-system/components-angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ParticipantDeleteInviteeModalComponent } from '../participant-delete-invitee-modal/participant-delete-invitee-modal.component';
import { ParticipantCheckinModalComponent } from '../participant-checkin-modal/participant-checkin-modal.component';
import { ParticipantCancelModalComponent } from '../participant-cancel-modal/participant-cancel-modal.component';
import { ParticipantResendRegistrationModalComponent } from '../participant-resend-registration-modal/participant-resend-registration-modal.component';
import { ParticipantModel } from '../../../models/participant.model';
import { FilterParticipantsService } from '../../../services/filter-participants.service';
import {
  ADDED,
  CANCELED,
  CHECKED_IN,
  INVITED,
  ON_WAITING_LIST,
  ParticipantStateType,
  PORSCHE_ID_NOT_FOUND,
  READY_FOR_INVITATION,
  REGISTERED,
  UNDEFINED
} from '../../../models/invitation-state.model';
import { ManualRegistrationFlyoutComponent } from '../../registration/manual-registration-flyout/manual-registration-flyout.component';
import { ParticipantDetailsFlyoutComponent } from '../participant-details-flyout/participant-details-flyout.component';
import { FlexComponent, FlexItemComponent, GridComponent, GridItemComponent, ShowMoreShowLessArrowComponent } from '@ui/shared/ui';
import { ParticipantSendInviteOneModalComponent } from '../participant-send-invite-one-modal/participant-send-invite-one-modal.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { DateFormatterService, HoverDirective } from '@ui/shared/util';
import { ParticipantDownloadTicketButtonComponent } from '../participant-download-ticket-button/participant-download-ticket-button.component';
import { ParticipantResendInviteModalComponent } from '../participant-resend-invite-modal/participant-resend-invite-modal.component';

@UntilDestroy()
@Component({
  selector: 'mycontent-participant-row',
  templateUrl: './participant-row.component.html',
  styleUrls: ['./participant-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    FlexComponent,
    ShowMoreShowLessArrowComponent,
    FlexItemComponent,
    ParticipantCancelModalComponent,
    ParticipantCheckinModalComponent,
    ParticipantDeleteInviteeModalComponent,
    ParticipantSendInviteOneModalComponent,
    AsyncPipe,
    TranslatePipe,
    ParticipantResendRegistrationModalComponent,
    ManualRegistrationFlyoutComponent,
    GridComponent,
    GridItemComponent,
    ParticipantDetailsFlyoutComponent,
    NgClass,
    HoverDirective,
    ParticipantDownloadTicketButtonComponent,
    ParticipantResendInviteModalComponent
  ]
})
export class ParticipantRowComponent {
  participant = input<ParticipantModel>();
  eventId = input<string>();
  bookingCancellationText = input<string>();
  country = input<string>();
  timezone = input<string>();
  waitingListCount = input<number>();

  refreshParticipantsModel = output<void>();
  refreshEventManagementModel = output<void>();

  isRegistrationOpen = signal<boolean>(false);
  isEditRegistration = signal<boolean>(false);
  isDeleteInviteeModalOpen = signal<boolean>(false);
  isCheckInModalOpen = signal<boolean>(false);
  isResendRegistrationModalOpen = signal<boolean>(false);
  isSendInviteOneModalOpen = signal<boolean>(false);
  isOpenResendInviteOneModal = signal<boolean>(false);
  isParticipantDetailsFlyoutOpen = signal<boolean>(false);
  participantRowIsHovered = signal(false);
  clickablePartOfRowIsHovered = signal(false);

  hasTicketsToCheckIn = computed(() => this.participant()?.tickets?.filter(ticket => !ticket.checkedInAt).length > 0);
  isInviteeDeletable = computed(() => [READY_FOR_INVITATION, UNDEFINED, ADDED, PORSCHE_ID_NOT_FOUND].includes(this.participant()?.state));

  isSendInvitationButtonVisible = computed(() => this.participant()?.invitedAt === undefined);
  isResendInvitationButtonVisible = computed(() => this.participant()?.invitedAt && this.isInvitedOrPorscheIdNotFound());

  getNumberOfCheckIns = computed(() => this.participant()?.checkedInTicketCount ?? 0);

  isInvited = computed(() => this.participant()?.state === INVITED);
  isPorscheIdNotFound = computed(() => this.participant()?.state === PORSCHE_ID_NOT_FOUND);
  isRegistered = computed(() => this.participant()?.state === REGISTERED);
  isOnWaitingList = computed(() => this.participant()?.state === ON_WAITING_LIST);
  isCancelled = computed(() => this.participant()?.state === CANCELED);

  isInvitedOrCancelledOrWaitingList = computed(() => this.isInvited() || this.isCancelled() || this.isOnWaitingList());
  isInvitedOrPorscheIdNotFound = computed(() => this.isInvited() || this.isPorscheIdNotFound());
  isRegistrationCancelledByUser = computed(() => this.participant()?.cancelledByUser);
  isRegisteredOrCheckedIn = computed(() => this.participant()?.state === REGISTERED || this.participant()?.state === CHECKED_IN);
  isEditRegistrationUpdatedByAdmin = computed(() => this.participant()?.registrationUpdatedByAdmin && (this.participant()?.state === REGISTERED || this.participant()?.state === CHECKED_IN));

  cancelledAt = computed(() => this.dateFormatterService.formatDateWithTime(this.participant().cancelledAt));
  registrationUpdatedAt = computed(() => this.dateFormatterService.formatDateWithTime(this.participant().registrationUpdatedAt));

  isTimeslotEndDateWithin7Days = computed(() => {
    if (this.participant()?.registration?.timeslot?.endDate) {
      const endDate = new Date(this.participant()?.registration?.timeslot?.endDate);
      const sevenDaysFromNow = new Date();
      endDate.setDate(endDate.getDate() + 7);
      return endDate <= sevenDaysFromNow;
    } else {
      return false;
    }
  });

  private readonly dateFormatterService = inject(DateFormatterService);
  private readonly filterService = inject(FilterParticipantsService);

  refreshParticipantModelEventManagementModel(): void {
    this.refreshParticipantsModel.emit();
    this.refreshEventManagementModel.emit();
  }

  getTimeslotTime(dateString: string): string {
    return this.dateFormatterService.formatTime(dateString);
  }

  getTimeslotDate(dateString: string): string {
    return this.dateFormatterService.formatDateNoYearNoTime(dateString);
  }

  getInvitationStateColour(state: ParticipantStateType): TagColor {
    return this.filterService.getInvitationStateColour(state, this.participantRowIsHovered());
  }

  openDeleteInviteeModal(event: MouseEvent): void {
    this.preventFlyoutOpening(event);
    this.isDeleteInviteeModalOpen.set(true);
  }

  openResendRegistrationModal(event: MouseEvent) {
    this.preventFlyoutOpening(event);
    this.isResendRegistrationModalOpen.set(true);
  }

  openSendInviteOneModal(event: MouseEvent) {
    this.preventFlyoutOpening(event);
    this.isSendInviteOneModalOpen.set(true);
  }

  openResendInviteOneModal(event: MouseEvent) {
    this.preventFlyoutOpening(event);
    this.isOpenResendInviteOneModal.set(true);
  }

  openCheckInModal(event: MouseEvent): void {
    this.preventFlyoutOpening(event);
    this.isCheckInModalOpen.set(true);
  }

  openParticipantDetailsFlyout(): void {
    this.isParticipantDetailsFlyoutOpen.set(true);
  }

  openManualRegistrationFlyout(event: MouseEvent) {
    this.preventFlyoutOpening(event);
    this.isRegistrationOpen.set(true);
  }

  openEditRegistrationFlyout(event: MouseEvent) {
    this.preventFlyoutOpening(event);
    this.isRegistrationOpen.set(true);
    this.isEditRegistration.set(true);
  }

  getTagLabelInformation(state: string) {
    switch (state) {
      case REGISTERED:
        return `: ${this.participant()?.tickets?.length}`;
      case CHECKED_IN:
        return `: ${this.getNumberOfCheckIns()}/${this.participant()?.tickets?.length}`;
      default:
        return '';
    }
  }

  preventFlyoutOpening(event: MouseEvent): void {
    event.stopPropagation();
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { AddAttendeesRequest, AddAttendeesResponse } from '../../models/add-user.model';
import { ResendRegistrationRequests, ResendRegistrationResponse } from '../../models/resend-registration.model';
import { ENVIRONMENT_ADMIN } from '@ui/shared/util';

@Injectable({
  providedIn: 'root',
})
export class BookingService {

  private ADMIN_PATH = 'admin';

  private environment = inject(ENVIRONMENT_ADMIN);
  private http = inject(HttpClient);
  private oAuthService = inject(OAuthService);

  quickAddAttendeeToEvent(country: string, eventId: string, userInfo: AddAttendeesRequest): Observable<AddAttendeesResponse> {
    const authHeaders = this.getAuthHeader();
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/invitations/action:add-attendees`;
    const headers = new HttpHeaders()
      .set('Authorization', authHeaders.get('Authorization'))
      .set('Content-Type', 'application/json;charset=UTF-8');
    return this.http.put<AddAttendeesResponse>(url, userInfo, {headers});
  }

  deleteAttendeeFromEvent(country: string, eventId: string, attendeeId: string): Observable<void> {
    const authHeaders = this.getAuthHeader();
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/invitations/${attendeeId}/action:delete`;
    const headers = new HttpHeaders()
      .set('Authorization', authHeaders.get('Authorization'));
    return this.http.delete<void>(url, {headers});
  }

  resendRegistrationEmailForUser(country: string, eventId: string, userInfo: ResendRegistrationRequests): Observable<ResendRegistrationResponse> {
    const authHeaders = this.getAuthHeader();
    const url = `${this.environment.backend.url}/${this.ADMIN_PATH}/${country}/events/${eventId}/invitations/action:resend-registration-confirmation`;
    const headers = new HttpHeaders()
      .set('Authorization', authHeaders.get('Authorization'))
      .set('Content-Type', 'application/json;charset=UTF-8');

    return this.http.put<ResendRegistrationResponse>(url, userInfo, {headers});
  }

  private getAuthHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${this.oAuthService.getIdToken()}`);
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, model } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslatePipe } from '@ngx-translate/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { FileService } from '../../../services/endpoints/file.service';
import { SpacerComponent } from '@ui/shared/ui';

@UntilDestroy()
@Component({
  selector: 'mycontent-participant-export-csv-modal',
  templateUrl: './participant-export-csv-modal.component.html',
  styleUrls: ['./participant-export-csv-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    TranslatePipe,
    SpacerComponent
  ]
})
export class ParticipantExportCsvModalComponent {
  eventId = input<string>();
  eventTitle = input<string>();
  country = input<string>();
  selectedInvitationStates = input<string[]>();

  isOpen = model<boolean>();

  private fileService = inject(FileService);

  closeDownloadCSV(): void {
    this.isOpen.set(false);
  }

  onDismiss(): void {
    this.isOpen.set(false);
  }

  downloadCSV(): void {
    this.exportCSV();
    this.isOpen.set(false);
  }

  exportCSV(): void {
    this.fileService.getCsvExportFileData(this.country(), this.eventId(), this.selectedInvitationStates())
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((response: any) => {
          const title = this.eventTitle().replace(/ /g, '_').replace(/[^a-zA-Z0-9_-]/g, '');
          const filename = `${title}.csv`;
          const dataType = response.type;
          const binaryData = [];
          const downloadLink = document.createElement('a');
          binaryData.push(response);
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.parentNode.removeChild(downloadLink);
        }
      );
  }
}

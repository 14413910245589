/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core';
import { imageSkeletonSVG } from './image-skeleton.svg';
import { DomSanitizer } from '@angular/platform-browser';
import { teaserIconSVG } from './teaser-icon.svg';

@Injectable({
  providedIn: 'root',
})
export class ImageSkeletonService {
  constructor(private sanatizer: DomSanitizer) {}

  public getImageSkeletonSvgAsData(withIcon?: boolean, iconColor?: string, backgroundColor?: string) {
    return this.sanatizer.bypassSecurityTrustUrl(imageSkeletonSVG(withIcon, iconColor, backgroundColor));
  }

  public getImageSkeletonSvgAsBackgroundImage(withIcon?: boolean, iconColor?: string, backgroundColor?: string) {
    return this.sanatizer.bypassSecurityTrustStyle(`url(${imageSkeletonSVG(withIcon, iconColor, backgroundColor)})`);
  }

  public getTeaserIconSvgAsBackgroundImage(iconColor?: string, backgroundColor?: string) {
    return this.sanatizer.bypassSecurityTrustStyle(`url(${teaserIconSVG(iconColor, backgroundColor)})`);
  }
}

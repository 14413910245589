/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Directive, HostBinding, HostListener, input, output } from '@angular/core';

export interface FileHandle {
  file: File;
  name: string;
}

@Directive({
  selector: '[mycontentDragDropFile]',
  standalone: true,
})
export class DragDropFileDirective {
  @HostBinding('style.background-color') private background: string;

  readonly PDS_THEME_LIGHT_BACKGROUND_SURFACE = '#eeeff2';
  backgroundColour = input<string>(this.PDS_THEME_LIGHT_BACKGROUND_SURFACE);

  files = output<FileHandle[]>();

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#999';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = this.backgroundColour();
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#eee';

    const files: FileHandle[] = [];
    for (let i = 0; i < evt.dataTransfer.files.length; i++) {
      const file = evt.dataTransfer.files[i];
      const fileName = evt.dataTransfer.files[i].name;
      files.push({ file, name: fileName });
    }
    if (files.length > 0) {
      this.files.emit(files);
    }
  }
}

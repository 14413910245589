<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->

<mycontent-spacer [size]="'small'"></mycontent-spacer>
<p-button-pure
  [icon]="'arrow-head-left'"
  [routerLink]="'/'+ country()?.toLowerCase() + '/events'"
  [attr.data-testid]="'general-back-button'"
>
  {{ 'backButton' | translate }}
</p-button-pure>
<mycontent-spacer [size]="'small'"></mycontent-spacer>
<mycontent-flex [direction]="'row'" [justifyContent]="'space-between'">
  <mycontent-flex-item>
    <ng-container *mycontentGhostText="eventDetailsLoading(); length: 30;">
      <p-heading [attr.data-testid]="'event-management-header-title'" [size]="'large'" [tag]="'h3'">{{ eventDetails()?.title }}</p-heading>
    </ng-container>
  </mycontent-flex-item>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  <mycontent-flex-item [alignSelf]="'center'">
    <div class="event-management-header__item-last-updated">
      <p-text [color]="'contrast-medium'" [align]="'end'">{{ 'adminEditEvent.label.lastUpdated' | translate }}</p-text>
      <p-text [color]="'contrast-medium'">{{ formattedLastUpdatedDateTimeString() }}</p-text>
    </div>
    @if (isEventCreateAndEditEnabled()) {
      <div class="event-management-header__item">
        <p-button
          class="event-management-header__edit-button"
          [variant]="'ghost'"
          [icon]="'edit'"
          (click)="goToEditPage()"
        >
          {{ 'adminEventDetail.button.editEvent' | translate }}
        </p-button>
      </div>
    }
    <div class="event-management-header__item">
      <mycontent-popover-hamburger-menu>
        @if (isAdminOrMarket()) {
          <p-button-pure
            class="event-management-header__link-section-link-spacing"
            [attr.data-testid]="'open-contentful'"
            [icon]="'none'"
            (click)="openContentfulLink()"
          >
            <img ngSrc="../../../../assets/contentful-logo.svg" height="24" width="24" class="event-management-header__icon-spacing">
            <span class="event-management-header__contentful-link-text">{{ 'list.buttonGoToContentful' | translate }}</span>
          </p-button-pure>
        }
        <mycontent-spacer [size]="'small'"></mycontent-spacer>
        <p-button-pure
          class="event-management-header__link-section-link-spacing"
          [attr.data-testid]="'pdf-reader-button'"
          [loading]="ticketLoading()"
          [iconSource]="'../../../../assets/preview-ticket-secondary.svg'"
          (click)="openTicketInNewTab()"
        >
          {{ 'adminEventDetail.mainAction.button.sampleTicket' | translate }}

        </p-button-pure>
        <mycontent-spacer [size]="'small'"></mycontent-spacer>
        <p-link-pure
          class="event-management-header__link-section-link-spacing"
          [attr.data-testid]="'event-management-header-link'"
          [id]="'openScanner'"
          [href]="getScannerLink()"
          [target]="'_blank'"
          [icon]="'qr'"
        >
          {{ 'adminEventDetail.mainAction.button.scanner' | translate }}
        </p-link-pure>
      </mycontent-popover-hamburger-menu>
    </div>
  </mycontent-flex-item>
</mycontent-flex>
<mycontent-spacer [size]="'small'"></mycontent-spacer>
<section class="event-management-header__stats">
  <mycontent-flex [direction]="'row'" [justifyContent]="'flex-start'" [wrap]="'wrap'">
    @if (eventDetails()?.hostNames?.length) {
      @for (host of eventDetails().hostNames; track host) {
        <p-tag class="event-management-header__tag" [color]="'background-surface'">
          {{ host }}
        </p-tag>
        <mycontent-spacer [size]="'small'"></mycontent-spacer>
      }
    }
    @if (eventDetails()?.managementType === 'CAP') {
      <p-tag class="event-management-header__tag" [color]="'primary'">
        {{ 'adminMain.listItem.label.capEvent' | translate }}
      </p-tag>
      <mycontent-spacer [size]="'small'"></mycontent-spacer>
    }
    @if (eventDetails()?.isOpenRegistration) {
      <p-tag class="event-management-header__tag" [color]="'background-surface'">
        {{ 'adminEventDetail.overview.label.openRegistration' | translate }}
      </p-tag>
      <mycontent-spacer [size]="'small'"></mycontent-spacer>
    }
  </mycontent-flex>
  <mycontent-spacer [size]="'medium'"></mycontent-spacer>
  <mycontent-flex>
    <mycontent-flex-item [grow]="2">
      <p-text [color]="'contrast-high'">
        {{ 'adminEventDetail.overview.label.eventDuration' | translate }}
      </p-text>
      <ng-container *mycontentGhostText="eventDetailsLoading(); length: 'fill';">
        @if (!!eventDetails()) {
          <p-text class="event-management-header__text-section">
            {{ formatDateWithTime(eventDetails()?.startDate) }} - {{ formatDateWithTime(eventDetails()?.endDate) }}
            ({{ getTimezone(eventDetails()?.startDate) }})
          </p-text>
        }
      </ng-container>
    </mycontent-flex-item>
    <mycontent-flex-item [grow]="2">
      <p-text [color]="'contrast-high'">
        {{ 'adminEventDetail.overview.label.eventAddress' | translate }}
      </p-text>
      <ng-container *mycontentGhostText="eventDetailsLoading(); length: 'fill';">
        <p-text class="event-management-header__text-section">
          {{ getAddress() }}
        </p-text>
      </ng-container>
    </mycontent-flex-item>
    <mycontent-flex-item [grow]="1">
      <p-text [color]="'contrast-high'">
        {{ 'adminEventDetail.overview.label.eventLink' | translate }}
        @if (!eventDetails()?.isOpenRegistration) {
          <p-popover>{{ 'adminEventDetail.overview.label.invitedEvent' | translate }}</p-popover>
        }
      </p-text>
      <div class="event-management-header__tag-link-container">
        @for (locale of locales(); track locale; ) {
          <p-button class="event-management-header__tag-link" [variant]="'ghost'" [compact]="true">
            <mycontent-copy-to-clipboard [copyValue]="this.getMyPorscheLink(this.country(), locale, this.eventId())">
              {{ locale }}
            </mycontent-copy-to-clipboard>
          </p-button>
        }
      </div>
    </mycontent-flex-item>
  </mycontent-flex>
  <mycontent-spacer [size]="'medium'"></mycontent-spacer>
  <mycontent-flex [justifyContent]="'space-between'" [gap]="'small'">
    <mycontent-flex-item [grow]="1">
      <div class="event-management-header__stats-tag">
        <p-text>
          {{ 'adminEventDetail.overview.label.invitations' | translate }}
        </p-text>
        <ng-container *mycontentGhostText="eventDetailsLoading(); length: 'fill';">
          <p-text [weight]="'semi-bold'">
            {{ eventDetails()?.invitationCount }}
          </p-text>
        </ng-container>
      </div>
    </mycontent-flex-item>
    <mycontent-flex-item [grow]="1">
      <div class="event-management-header__stats-tag">
        <p-text>
          {{ 'adminEventDetail.overview.label.capacity' | translate }}
        </p-text>
        <ng-container *mycontentGhostText="eventDetailsLoading(); length: 'fill';">
          <p-text [weight]="'semi-bold'">
            {{ eventDetails()?.capacity }}
          </p-text>
        </ng-container>
      </div>
    </mycontent-flex-item>
    <mycontent-flex-item [grow]="1">
      <div class="event-management-header__stats-tag">
        <p-text>
          {{ 'adminEventDetail.overview.label.tickets' | translate }}
        </p-text>
        <ng-container *mycontentGhostText="eventDetailsLoading(); length: 'fill';">
          <p-text [weight]="'semi-bold'">
            {{ eventDetails()?.registeredTicketCount }}
          </p-text>
        </ng-container>
      </div>
    </mycontent-flex-item>
    <mycontent-flex-item [grow]="1">
      <div class="event-management-header__stats-tag">
        <p-text>
          {{ 'adminEventDetail.overview.label.checkins' | translate }}
        </p-text>
        <ng-container *mycontentGhostText="eventDetailsLoading(); length: 'fill';">
          <p-text [weight]="'semi-bold'">
            {{ eventDetails()?.checkedInTicketCount }}
          </p-text>
        </ng-container>
      </div>
    </mycontent-flex-item>
    @if (isAdmin()) {
      <mycontent-flex-item [grow]="1">
        <div class="event-management-header__stats-tag">
          <p-text>
            {{ 'adminEventDetail.overview.label.waitingList' | translate }}
          </p-text>
          <ng-container *mycontentGhostText="eventDetailsLoading(); length: 'fill';">
            <p-text [weight]="'semi-bold'">
              {{ eventDetails()?.waitingListCount }}
            </p-text>
          </ng-container>
        </div>
      </mycontent-flex-item>
    }
    <mycontent-flex-item [grow]="1">
      <div class="event-management-header__stats-tag color-error-soft">
        <p-text>
          {{ 'adminEventDetail.overview.label.cancels' | translate }}
        </p-text>
        <ng-container *mycontentGhostText="eventDetailsLoading(); length: 'fill';">
          <p-text [weight]="'semi-bold'">
            {{ eventDetails()?.cancelledRegistrationCount }}
          </p-text>
        </ng-container>
      </div>
    </mycontent-flex-item>
  </mycontent-flex>
</section>
<mycontent-spacer [size]="'large'"></mycontent-spacer>


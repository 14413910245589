<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

@if (spaces()?.length > 1) {
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  <p-button-pure [routerLink]="'/'" [icon]="'arrow-head-left'" [attr.data-testid]="'general-back-button'">
    {{ 'backButton' | translate }}
  </p-button-pure>
}
<mycontent-spacer [size]="'small'"></mycontent-spacer>
<mycontent-grid>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  <mycontent-grid-item [size]="isAdminOrMarket() || isEventCreateAndEditEnabled() ? 6 : 12" [usePadding]="false">
    <p-heading [size]="'xx-large'" [tag]="'h3'">{{ 'list.countryHeadline' | translate }} {{ country()?.toUpperCase() }}</p-heading>
  </mycontent-grid-item>
  @if (isAdminOrMarket() || isEventCreateAndEditEnabled()) {
    <mycontent-grid-item class="event-overview__link-section" [size]="6" [usePadding]="false">
      <div class="event-overview__link-section-link">
        @if (isEventCreateAndEditEnabled()) {
          <p-button
            class="event-overview__link-section-link-spacing"
            [attr.data-testid]="'event-admin-create-btn-open'"
            [icon]="'plus'"
            [variant]="'primary'"
            [routerLink]="'/' + country() + '/create'"
          >
            {{ 'adminCreateEvent.button.createEvent' | translate }}
          </p-button>
        }
        @if (isAdminOrMarket()) {
          <p-link
            class="event-overview__link-section-link-spacing"
            [id]="'newEvent'"
            [variant]="'secondary'"
            [href]="getContentfulLink()"
            [target]="'_blank'"
          >
            <img ngSrc="../../../../assets/contentful-logo.svg" height="18" width="18" class="event-overview__icon-spacing">
            {{ 'list.buttonGoToContentful' | translate }}
          </p-link>
        }
      </div>
    </mycontent-grid-item>
  }
</mycontent-grid>
<section class="event-overview">
  <mycontent-grid>
    <mycontent-grid-item [size]="12" class="event-overview__stats-header">
      <mycontent-flex [direction]="'row'" [justifyContent]="'flex-start'">
        <form [formGroup]="form">
          <p-select-wrapper [label]="''" [hideLabel]="true" [dropdownDirection]="'down'">
            <select name="overviewTrends" [formControlName]="'overviewTrends'">
              @for (trendsEnum of userTrendFilters; track trendsEnum) {
                <option [value]="trendsEnum">{{ 'adminMain.overviewAreaSelect.label.' + trendsEnum | translate }}</option>
              }
            </select>
          </p-select-wrapper>
        </form>
      </mycontent-flex>
      <mycontent-flex [direction]="'row'" [justifyContent]="'start'">
        <div class="event-overview__stats-header-subsection-left">
          <mycontent-flex [direction]="'row'" [justifyContent]="'start'">
            <p-text [size]="'x-small'" [align]="'end'" [weight]="'semi-bold'">
              {{ 'adminMain.overviewArea.label.invitations' | translate }}
            </p-text>
          </mycontent-flex>
          <mycontent-flex [direction]="'row'" [justifyContent]="'start'">
            <p-text [weight]="'semi-bold'" [attr.data-testid]="'event-overview-invitations'">
              {{ overviewTrends()?.invitationsSentCount }}
            </p-text>
          </mycontent-flex>
        </div>
        <div class="event-overview__stats-header-subsection-left">
          <mycontent-flex [direction]="'row'" [justifyContent]="'start'">
            <p-text [size]="'x-small'" [align]="'end'" [weight]="'semi-bold'">
              {{ 'adminMain.overviewArea.label.ticketsCreated' | translate }}
            </p-text>
          </mycontent-flex>
          <mycontent-flex [direction]="'row'" [justifyContent]="'start'">
            <p-text [weight]="'semi-bold'" [attr.data-testid]="'event-overview-ticketsCreated'">
              {{ overviewTrends()?.ticketsCreatedCount }}
            </p-text>
          </mycontent-flex>
        </div>
        <div class="event-overview__stats-header-subsection-left">
          <mycontent-flex [direction]="'row'" [justifyContent]="'start'">
            <p-text [size]="'x-small'" [align]="'end'" [weight]="'semi-bold'">
              {{ 'adminMain.overviewArea.label.checkins' | translate }}
            </p-text>
          </mycontent-flex>
          <mycontent-flex [direction]="'row'" [justifyContent]="'start'">
            <p-text [weight]="'semi-bold'" [attr.data-testid]="'event-overview-checkins'">
              {{ overviewTrends()?.checkInCount }}
            </p-text>
          </mycontent-flex>
        </div>
        <div class="event-overview__stats-header-subsection">
          <mycontent-flex [direction]="'row'" [justifyContent]="'start'">
            <p-text [color]="'notification-error'" [size]="'x-small'" [align]="'end'" [weight]="'semi-bold'">
              {{ 'adminMain.overviewArea.label.cancelledTickets' | translate }}
            </p-text>
          </mycontent-flex>
          <mycontent-flex [direction]="'row'" [justifyContent]="'start'">
            <p-text [color]="'notification-error'" [weight]="'semi-bold'" [attr.data-testid]="'event-overview-cancelledTickets'">
              {{ overviewTrends()?.cancellationCount }}
            </p-text>
          </mycontent-flex>
        </div>
      </mycontent-flex>
    </mycontent-grid-item>
  </mycontent-grid>
</section>

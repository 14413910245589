/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, merge, Observable, of, share, Subject, switchMap, takeUntil, tap, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { HttpResponse } from '@angular/common/http';
import { sortBy } from 'lodash-es';
import { CancelParticipantModel, CheckInParticipantModel, FetchParticipantModel, ParticipantActionModel, ParticipantModel } from '../models/participant.model';
import { ParticipantService } from '../services/endpoints/participant.service';
import { FilterParticipantsService } from '../services/filter-participants.service';
import { CheckinModel } from '../models/checkin.model';
import { READY_FOR_INVITATION } from '../models/invitation-state.model';

export interface Pagination<T> {
  list: T[];
  totalItemsCount: number;
  itemsPerPage: number;
  activePage: number;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ParticipantFacade {

  private filterDefault: FetchParticipantModel = {
    country: '',
    invitationStates: [],
    timeslotIds: [],
    eventId: ''
  };
  private fetchParticipantSubject = new Subject<FetchParticipantModel>();

  private participantsSuccessSubject = new BehaviorSubject<ParticipantModel[]>([]);
  private participantsLoadingSubject = new BehaviorSubject<boolean>(false);
  private participantsErrorSubject = new BehaviorSubject<unknown>(null);
  private participantsTextFilterSubject = new BehaviorSubject<string>(null);

  private participantActionSubject = new Subject<ParticipantActionModel>();

  private participantFilterSubject = new BehaviorSubject<FetchParticipantModel>(this.filterDefault);

  /* eslint-disable @typescript-eslint/member-ordering */
  readonly participantsSuccess$ = this.participantsSuccessSubject.asObservable();
  readonly participantsLoading$ = this.participantsLoadingSubject.asObservable();
  readonly participantsError$ = this.participantsErrorSubject.asObservable();
  readonly participantsFilter$ = this.participantFilterSubject.asObservable();
  readonly participantAction$ = this.participantActionSubject.asObservable();
  readonly participantsTextFilter$ = this.participantsTextFilterSubject.asObservable();

  private participantService = inject(ParticipantService);
  private filterService = inject(FilterParticipantsService);

  private setParticipantFilters(filter: FetchParticipantModel) {
    this.participantFilterSubject.next({
      ...filter,
      invitationStates: filter.invitationStates.filter(el => el !== 'ALL_STATUSES')
    });
  }

  private sortParticipantsByName(unsortedParticipants: ParticipantModel[]): ParticipantModel[] {
    return sortBy(unsortedParticipants, 'attendee.firstName');
  }

  constructor() {
    this.fetchParticipantSubject.pipe(
      switchMap(fetchParticipantModel => {
        this.setParticipantFilters(fetchParticipantModel);

        const participantsRequest$ = timer(500).pipe(
          switchMap(() => this.participantService.getParticipants(fetchParticipantModel)),
          map(participants => {
            this.participantsSuccessSubject.next(this.sortParticipantsByName(participants.items));
          }),
          catchError(error => {
            console.log('get participants failed');
            this.participantsErrorSubject.next(error);
            return of();
          }),
          tap(() => this.participantsLoadingSubject.next(false)),
          share()
        );

        const loadingDelay$ = timer(100).pipe(
          tap(() => {
            this.participantsSuccessSubject.next([]);
            this.participantsLoadingSubject.next(true);
          }),
          takeUntil(participantsRequest$)
        );

        return merge(loadingDelay$, participantsRequest$);
      })
    ).subscribe();
  }

  getParticipants(fetchParticipantModel: FetchParticipantModel = undefined): void {
    const fetchParticipantModelToSet = fetchParticipantModel ? fetchParticipantModel : this.participantFilterSubject.value;
    // this will trigger the switchMap and ensure that only one request is active at a time
    // to prevent two racing requests from updating the state at the same time and leading to unexpected behavior
    this.fetchParticipantSubject.next(fetchParticipantModelToSet);
  }

  switchParticipantPage(pagination: Pagination<ParticipantModel>): Pagination<ParticipantModel> {
    const filteredParticipants = this.filterService.filterParticipants(this.participantsSuccessSubject.value, this.getFilteredText());
    const startIndex = (pagination.activePage - 1) * pagination.itemsPerPage;
    pagination.list = filteredParticipants.slice(startIndex, startIndex + pagination.itemsPerPage);
    pagination.totalItemsCount = filteredParticipants.length;
    return pagination;
  }

  cancelParticipant(cancelParticipant: CancelParticipantModel): Observable<HttpResponse<unknown>> {
    return this.participantService.cancelParticipantRegistration(cancelParticipant.country, cancelParticipant.eventId, cancelParticipant.attendeeId);
  }

  checkInParticipant(checkInParticipant: CheckInParticipantModel): Observable<HttpResponse<CheckinModel>> {
    return this.participantService.checkInParticipant(
      checkInParticipant.country, checkInParticipant.eventId, checkInParticipant.attendeeId, Number(checkInParticipant.ticketCount)
    );
  }

  getParticipantFilters(): FetchParticipantModel {
    return this.participantFilterSubject.value;
  }

  getCountOfReadyToInviteParticipants(): string {
    return this.participantsSuccessSubject.value.filter(participant => participant.state === READY_FOR_INVITATION).length.toString();
  }

  updateParticipantAction(participantAction: ParticipantActionModel): void {
    this.participantActionSubject.next(participantAction);
  }

  updateParticipantFilterNameOrEmail(filterText: string): void {
    this.participantsTextFilterSubject.next(filterText);
  }

  getFilteredText(): string {
    return this.participantsTextFilterSubject.value;
  }

  reset(): void {
    this.setParticipantFilters(this.filterDefault);
    this.updateParticipantAction(undefined);
    this.updateParticipantFilterNameOrEmail(undefined);
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core';
import { countries } from '@ui/shared/util-locale';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  getLocaleForCountry(countryCode: string): string[] {
    const country = countries.find(it => it.code === countryCode.toLowerCase());
    return country?.languages.map(it => it.code) ?? [];
  }
}

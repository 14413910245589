/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { InjectionToken } from '@angular/core';
import { Locale } from './models/locale';

export const LOCALE = new InjectionToken<Locale>('LOCALE');
export const PROFILE_LOCALE = new InjectionToken<string>('PROFILE_LOCALE');

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Observable } from 'rxjs';

export function getCurrentPosition(window: Window): Observable<GeolocationPosition> {
  return new Observable(subscriber => {
    window.navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
      subscriber.next(position);
      subscriber.complete();
    });
  });
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, computed, effect, inject, input, OnChanges, OnInit, signal, SimpleChanges } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { AuthoritiesFacade, EventManagementFacade, EventManagementSettingsFormModel, PatchEventRequest } from '@admin/shared';
import { AsyncPipe } from '@angular/common';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { PorscheDesignSystemModule, SwitchUpdateEventDetail } from '@porsche-design-system/components-angular';
import { TimeslotFacade } from '../../../facades/timeslot.facade';
import { BannerNotificationService, SpacerComponent } from '@ui/shared/ui';

@UntilDestroy()
@Component({
  selector: 'mycontent-event-management-settings',
  templateUrl: './event-management-settings.component.html',
  styleUrls: ['./event-management-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    TranslatePipe,
    ReactiveFormsModule,
    PorscheDesignSystemModule,
    SpacerComponent
  ]
})
export class EventManagementSettingsComponent implements OnInit, OnChanges {
  eventId = input<string>();
  country = input<string>();
  hoursBeforeRegistrationCloses = input<number>();
  isOpenRegistrationEnabled = input<boolean>();
  isPublishToFeedEnabled = input<boolean>();
  isWaitingListEnabled = input<boolean>(false);
  waitingListCount = input<number>(0);

  isSubmitted = signal<boolean>(false);
  isOnBlur = signal<boolean>(false);

  isPublishToFeedAllowed = computed(() => this.isOpenRegistrationEnabled());
  isWaitingListToggable = computed(() => this.waitingListCount() === 0);

  eventFacade: EventManagementFacade = inject(EventManagementFacade);
  timeslotFacade: TimeslotFacade = inject(TimeslotFacade);
  bannerNotificationService: BannerNotificationService = inject(BannerNotificationService);
  translateService: TranslateService = inject(TranslateService);
  authoritiesService = inject(AuthoritiesFacade);

  isCustomRegistrationLoading$ = this.eventFacade.updateEventLoading$;

  form: FormGroup<EventManagementSettingsFormModel> = new FormGroup<EventManagementSettingsFormModel>({
    customRegistration: new FormControl(24, { validators: [Validators.required, Validators.min(0)] })
  });

  constructor() {
    effect(() => {
      // in the case where hoursBeforeRegistrationCloses was set to 0 the initial value of 24 was not updated
      if (this.hoursBeforeRegistrationCloses() || this.hoursBeforeRegistrationCloses() === 0) {
        this.form.controls.customRegistration.patchValue(this.hoursBeforeRegistrationCloses(), { emitEvent: false });
      }
    });
  }

  ngOnInit() {
    this.form.controls.customRegistration.valueChanges
      .pipe(
        debounceTime(3000),
        distinctUntilChanged()
      )
      .subscribe(() => {
        if (this.isOnBlur()) {
          this.isOnBlur.set(false);
        } else {
          this.updateEvent(this.getPatchEventRequest());
        }
      });
  }

  onBlur() {
    if (this.form.get('customRegistration').value !== this.hoursBeforeRegistrationCloses()) {
      this.isOnBlur.set(true);
      this.getPatchEventRequest();
      this.updateEvent(this.getPatchEventRequest());
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hoursBeforeRegistrationCloses) {
      this.form.controls.customRegistration.patchValue(changes.hoursBeforeRegistrationCloses.currentValue, { emitEvent: false });
    }
  }

  getPatchEventRequest(): PatchEventRequest {
    return {
      hoursBeforeRegistrationCloses: this.form.get('customRegistration').value,
      isOpenRegistration: this.isOpenRegistrationEnabled(),
      isPublic: this.isAdminOrMarket() ? this.isPublishToFeedEnabled() : null,
      isWaitingListEnabled: this.isWaitingListEnabled()
    };
  }

  getErrorState() {
    return this.form.invalid && this.isSubmitted() ? 'error' : 'none';
  }

  refreshEventManagementModel() {
    this.eventFacade.getEventManagement(this.country(), this.eventId());
  }

  refreshTimeslots() {
    this.timeslotFacade.getTimeslots(this.country(), this.eventId());
  }

  onUpdateOpenRegistration(event: CustomEvent<SwitchUpdateEventDetail>) {
    if (event.detail.checked) {
      this.updateEvent({ ...this.getPatchEventRequest(), isOpenRegistration: event.detail.checked });
    } else {
      this.updateEvent({
        ...this.getPatchEventRequest(),
        isOpenRegistration: event.detail.checked,
        isPublic: this.isAdminOrMarket() ? event.detail.checked : null
      });
    }
  }

  onUpdatePublishToFeed(event: CustomEvent<SwitchUpdateEventDetail>) {
    this.updateEvent({ ...this.getPatchEventRequest(), isPublic: event.detail.checked });
  }

  onUpdateWaitingList(event: CustomEvent<SwitchUpdateEventDetail>) {
    this.updateEvent({ ...this.getPatchEventRequest(), isWaitingListEnabled: event.detail.checked });
  }

  isAdminOrMarket() {
    return this.authoritiesService.isAdmin() || this.authoritiesService.isMarket();
  }

  isAdmin() {
    return this.authoritiesService.isAdmin();
  }

  updateEvent(patchEventRequest: PatchEventRequest) {
    const oldPatchValue = this.getPatchEventRequest();
    const openRegistrationUpdate = oldPatchValue.isOpenRegistration !== patchEventRequest.isOpenRegistration;
    const publishToFeedUpdate = oldPatchValue.isPublic !== patchEventRequest.isPublic;
    const waitingListEnabledUpdate = oldPatchValue.isWaitingListEnabled !== patchEventRequest.isWaitingListEnabled;

    this.isSubmitted.set(true);
    if (this.form.valid) {
      this.eventFacade.updateEvent(this.country(), this.eventId(), patchEventRequest)
        .subscribe({
          next: () => {
            this.refreshEventManagementModel();
            this.refreshTimeslots();
            this.isSubmitted.set(false);
          },
          error: () => {
            if (this.isAdminOrMarket() && publishToFeedUpdate) {
              this.publishErrorBannerNotification(patchEventRequest.isPublic ?
                'adminEventDetail.banner.title.publishToFeedActivationFail' : 'adminEventDetail.banner.title.publishToFeedDeactivationFail'
              );
            }
            if (openRegistrationUpdate) {
              this.publishErrorBannerNotification(patchEventRequest.isOpenRegistration ?
                'adminEventDetail.banner.title.openRegistrationActivationFail' : 'adminEventDetail.banner.title.openRegistrationDeactivationFail'
              );
            }
            if (waitingListEnabledUpdate) {
              this.publishErrorBannerNotification(patchEventRequest.isWaitingListEnabled ?
                'adminEventDetail.banner.title.waitingListActivationFail' : 'adminEventDetail.banner.title.waitingListDeactivationFail'
              );
            }
          }
        });
    }
  }

  publishErrorBannerNotification(errorMessage: string) {
    this.bannerNotificationService.publishMessage({
      state: 'error',
      headline: this.translateService.instant(errorMessage),
      message: this.translateService.instant('adminEventDetail.banner.description.genericFail')
    });
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Routes } from '@angular/router';
import { CountrySelectorComponent } from './components/country-selector/country-selector.component';
import { ShellComponent } from './components/shell/shell.component';
import { StatusPageComponent } from './components/status-page/status-page.component';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { EventManagementComponent } from '@admin/page-management';
import { ToolsComponent } from './components/tools/tools.component';
import { EventOverviewComponent } from '@admin/page-overview';
import { AdminFeatureToggleGuard } from '@admin/shared';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: 'status',
        component: StatusPageComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'tools',
        component: ToolsComponent,
        canActivate: [AuthGuardService],

      },
      {
        path: '',
        component: CountrySelectorComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: ':country/events/:id',
        canActivate: [AuthGuardService],
        component: EventManagementComponent,
      },
      {
        path: ':country/create',
        loadChildren: () => import('@admin/page-create').then(pages => pages.EVENT_CREATE_ROUTES),
        canActivate: [AuthGuardService, AdminFeatureToggleGuard],
      },
      {
        path: ':country/edit/:eventId',
        loadComponent: () => import('@admin/page-create').then(exports => exports.EventCreateComponent),
        canActivate: [AuthGuardService, AdminFeatureToggleGuard],
        data: {
          edit: true,
        }
      },
      {
        path: ':country/events',
        canActivate: [AuthGuardService],
        component: EventOverviewComponent,
      },
      {
        path: 'auth-callback',
        component: AuthCallbackComponent,
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/',
  },
];


/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, computed, inject, input, model, output, signal, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PorscheDesignSystemModule, TagColor } from '@porsche-design-system/components-angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslatePipe } from '@ngx-translate/core';
import { CopyToClipboardComponent, FlexComponent, FlexItemComponent, GridComponent, GridItemComponent, SpacerComponent } from '@ui/shared/ui';
import { ParticipantModel } from '../../../models/participant.model';
import { FilterParticipantsService } from '../../../services/filter-participants.service';
import { DateFormatterService } from '@ui/shared/util';
import { ParticipantCheckinModalComponent } from '../participant-checkin-modal/participant-checkin-modal.component';
import { ParticipantResendRegistrationModalComponent } from '../participant-resend-registration-modal/participant-resend-registration-modal.component';
import { ParticipantSendInviteOneModalComponent } from '../participant-send-invite-one-modal/participant-send-invite-one-modal.component';
import { ParticipantDeleteInviteeModalComponent } from '../participant-delete-invitee-modal/participant-delete-invitee-modal.component';
import { AsyncPipe } from '@angular/common';
import { ParticipantCancelModalComponent } from '../participant-cancel-modal/participant-cancel-modal.component';
import { ManualRegistrationFlyoutComponent } from '../../registration/manual-registration-flyout/manual-registration-flyout.component';
import { I_PREFER_NOT_TO_ANSWER } from '@ui/shared/feature-registration';
import {
  ADDED,
  CANCELED,
  CHECKED_IN,
  INVITED,
  ON_WAITING_LIST,
  ParticipantStateType,
  PORSCHE_ID_NOT_FOUND,
  READY_FOR_INVITATION,
  REGISTERED,
  UNDEFINED
} from '../../../models/invitation-state.model';
import { ParticipantDownloadTicketButtonComponent } from '../participant-download-ticket-button/participant-download-ticket-button.component';
import { ParticipantResendInviteModalComponent } from '../participant-resend-invite-modal/participant-resend-invite-modal.component';

@UntilDestroy()
@Component({
  selector: 'mycontent-participant-details-flyout',
  templateUrl: './participant-details-flyout.component.html',
  styleUrls: ['./participant-details-flyout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    PorscheDesignSystemModule,
    FormsModule,
    ReactiveFormsModule,
    FlexComponent,
    TranslatePipe,
    FlexItemComponent,
    SpacerComponent,
    GridComponent,
    GridItemComponent,
    CopyToClipboardComponent,
    ParticipantCheckinModalComponent,
    ParticipantResendRegistrationModalComponent,
    ParticipantSendInviteOneModalComponent,
    ParticipantDeleteInviteeModalComponent,
    AsyncPipe,
    ParticipantCancelModalComponent,
    ManualRegistrationFlyoutComponent,
    ParticipantDownloadTicketButtonComponent,
    ParticipantResendInviteModalComponent
  ]
})
export class ParticipantDetailsFlyoutComponent {
  readonly I_PREFER_NOT_TO_ANSWER = I_PREFER_NOT_TO_ANSWER;

  participant = input<ParticipantModel>();
  eventId = input<string>();
  bookingCancellationText = input<string>();
  country = input<string>();
  timezone = input<string>();
  waitingListCount = input<number>();

  isOpen = model<boolean>();

  refreshParticipantModelTimeslotModel = output<void>();

  isCheckInRegistrationOpen = signal<boolean>(false);
  isParticipantRegistrationResendModalOpen = signal<boolean>(false);
  isParticipantInvitationResendModalOpen = signal<boolean>(false);
  isSendSingleInvitationModalOpen = signal<boolean>(false);
  isDeleteInviteeOpen = signal<boolean>(false);
  isCancelModalOpen = signal<boolean>(false);
  isRegistrationOpen = signal<boolean>(false);
  isEditRegistration = signal<boolean>(false);

  addedAt = computed(() =>
    this.participant().addedAt !== undefined ? this.dateFormatterService.formatDateWithTime(this.participant().addedAt) : ''
  );
  invitedAt = computed(() =>
    this.participant().invitedAt !== undefined ? this.dateFormatterService.formatDateWithTime(this.participant().invitedAt) : ''
  );
  registeredAt = computed(() =>
    this.participant().registeredAt !== undefined ? this.dateFormatterService.formatDateWithTime(this.participant().registeredAt) : ''
  );
  checkedInAt = computed(() =>
    this.isCheckedIn() ? this.dateFormatterService.formatDateWithTime(this.participant().tickets[0].checkedInAt) : ''
  );
  cancelledAt = computed(() => this.dateFormatterService.formatDateWithTime(this.participant().cancelledAt));
  registrationUpdatedAt = computed(() => this.dateFormatterService.formatDateWithTime(this.participant().registrationUpdatedAt));
  joinedWaitingListAt = computed(() => this.dateFormatterService.formatDateWithTime(this.participant().joinedWaitingListAt));

  isRegisteredOrCheckedIn = computed(() => this.isRegistered() || this.isCheckedIn());
  isInvitedWithInvitationSentAt = computed(() => this.participant()?.invitedAt && this.isInvited());
  isInvitedWithoutInvitationSentAt = computed(() => !this.participant()?.invitedAt && this.isInvited());
  isPorscheIdNotFoundWithInvitationSentAt = computed(() => this.participant()?.invitedAt && this.isPorscheIdNotFound());
  isPorscheIdNotFoundWithoutInvitationSentAt = computed(() => !this.participant()?.invitedAt && this.isPorscheIdNotFound());
  isCheckedIn = computed(() => this.participant()?.state === CHECKED_IN);
  isRegistered = computed(() => this.participant()?.state === REGISTERED);
  isCancelled = computed(() => this.participant()?.state === CANCELED);
  isInvited = computed(() => this.participant()?.state === INVITED);
  isReadyForInvitation = computed(() => this.participant()?.state === READY_FOR_INVITATION);
  isAdded = computed(() => this.participant()?.state === ADDED);
  isUndefined = computed(() => this.participant()?.state === UNDEFINED);
  isPorscheIdNotFound = computed(() => this.participant()?.state === PORSCHE_ID_NOT_FOUND);
  isOnWaitingList = computed(() => this.participant()?.state === ON_WAITING_LIST);

  isParticipantRegisteredToCapManagedEvent = computed(() =>
    this.participant()?.capBpId || this.participant()?.capActivityId || this.participant()?.capCampaignId
  );
  isRegistrationCancelledByUser = computed(() => this.participant()?.cancelledByUser);
  isEditRegistrationUpdatedByAdmin = computed(() => this.participant()?.registrationUpdatedByAdmin && (this.participant()?.state === REGISTERED || this.participant()?.state === CHECKED_IN));
  startDate = computed(() => this.dateFormatterService.formatDateNoYearNoTime(this.participant().registration.timeslot.startDate));
  startTime = computed(() => this.dateFormatterService.formatTime(this.participant().registration.timeslot.startDate));
  endDate = computed(() => this.dateFormatterService.formatDateNoYearNoTime(this.participant().registration.timeslot.endDate));
  endTime = computed(() => this.dateFormatterService.formatTime(this.participant().registration.timeslot.endDate));
  isTimeslotEndDateWithin7Days = computed(() => {
    if (this.participant()?.registration?.timeslot?.endDate) {
      const endDate = new Date(this.participant()?.registration?.timeslot?.endDate);
      const sevenDaysFromNow = new Date();
      endDate.setDate(endDate.getDate() + 7);
      return endDate <= sevenDaysFromNow;
    } else {
      return false;
    }
  });

  private readonly filterService = inject(FilterParticipantsService);
  private readonly dateFormatterService: DateFormatterService = inject(DateFormatterService);

  getInvitationStateColour(state: ParticipantStateType): TagColor {
    return this.filterService.getInvitationStateColour(state);
  }

  openCheckInModal(): void {
    this.isCheckInRegistrationOpen.set(true);
  }

  openResendRegistrationModal() {
    this.isParticipantRegistrationResendModalOpen.set(true);
  }

  openResendInvitationModal() {
    this.isParticipantInvitationResendModalOpen.set(true);
  }

  openSendSingleInvitationModal() {
    this.isSendSingleInvitationModalOpen.set(true);
  }

  openDeleteInviteeModal(): void {
    this.isDeleteInviteeOpen.set(true);
  }

  openCancelModal(): void {
    this.isCancelModalOpen.set(true);
  }

  openManualRegistrationFlyout(): void {
    this.isRegistrationOpen.set(true);
  }

  openEditRegistrationModal(): void {
    this.isRegistrationOpen.set(true);
    this.isEditRegistration.set(true);
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, map, Observable, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DateUtilService } from '@ui/shared/util';
import { OverviewEventsResponseModel, OverviewTrendsEnum, OverviewTrendsModel, TrendsModel } from '../models/overview.model';
import { EventsOverviewService } from '../services/events-overview.service';

@Injectable({
  providedIn: 'root',
})
export class EventsOverviewFacade {
  private overviewErrorSubject = new BehaviorSubject<unknown>(null);
  private unfilteredOnGoingEventsLoadingSubject = new BehaviorSubject<boolean>(false);
  private unfilteredOnGoingEventsSuccessSubject = new BehaviorSubject<OverviewEventsResponseModel>(null);
  private unfilteredPastEventsLoadingSubject = new BehaviorSubject<boolean>(false);
  private unfilteredPastEventsSuccessSubject = new BehaviorSubject<OverviewEventsResponseModel>(null);

  /* eslint-disable @typescript-eslint/member-ordering */
  readonly unfilteredOnGoingEventsLoading$ = this.unfilteredOnGoingEventsLoadingSubject.asObservable();
  readonly unfilteredOnGoingEventsSuccess$ = this.unfilteredOnGoingEventsSuccessSubject.asObservable();
  readonly unfilteredPastEventsLoading$ = this.unfilteredPastEventsLoadingSubject.asObservable();
  readonly unfilteredPastEventsSuccess$ = this.unfilteredPastEventsSuccessSubject.asObservable();

  private eventsOverviewService: EventsOverviewService = inject(EventsOverviewService);
  private dateUtilService: DateUtilService = inject(DateUtilService);

  getOverviewTrends(fetchTrendsModel: TrendsModel): Observable<OverviewTrendsModel> {
    return this.eventsOverviewService.getOverviewTrends(fetchTrendsModel).pipe(
      map(overview => overview),
      catchError(error => {
        console.log('get overview trends failed');
        this.overviewErrorSubject.next(error);
        return EMPTY;
      }),
    );
  }

  updateOverviewTrendsParams(fetchTrendsModel: TrendsModel, filter: string) {
    switch (filter) {
      case OverviewTrendsEnum.ALL_TIME: {
        return {...fetchTrendsModel, since: ''};
      }
      case OverviewTrendsEnum.LAST_30_DAYS: {
        const {startDate} = this.dateUtilService.getLastMonthDates();
        return {...fetchTrendsModel, since: startDate};
      }
      case OverviewTrendsEnum.LAST_7_DAYS: {
        const {startDate} = this.dateUtilService.getLastCalendarWeekDates();
        return {...fetchTrendsModel, since: startDate};
      }
      case OverviewTrendsEnum.LAST_24_HOURS: {
        const date = this.dateUtilService.getLast24HoursDate();
        return {...fetchTrendsModel, since: date};
      }
      default: {
        return fetchTrendsModel;
      }
    }
  }

  getOnGoingEvents(country: string) {
    this.unfilteredOnGoingEventsLoadingSubject.next(true);
    return this.eventsOverviewService.getOverviewEvents(country, '', '', '', this.now())
      .pipe(
        catchError(() => {
          this.unfilteredOnGoingEventsLoadingSubject.next(false);
          return EMPTY;
        }),
        tap((overviewEvents: OverviewEventsResponseModel) => {
          this.unfilteredOnGoingEventsSuccessSubject.next(overviewEvents);
          this.unfilteredOnGoingEventsLoadingSubject.next(false);
        }),
      );
  }

  getPastEvents(country: string) {
    this.unfilteredPastEventsLoadingSubject.next(true);
    return this.eventsOverviewService.getOverviewEvents(country, '', this.now())
      .pipe(
        catchError(() => {
          this.unfilteredPastEventsLoadingSubject.next(false);
          return EMPTY;
        }),
        tap((overviewEvents: OverviewEventsResponseModel) => {
          this.unfilteredPastEventsSuccessSubject.next(overviewEvents);
          this.unfilteredPastEventsLoadingSubject.next(false);
        }),
      );
  }

  private now() {
    const date = new Date();
    return date.toISOString();
  }
}

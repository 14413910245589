/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ContactDetails } from './contact-details';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { TranslatePipe } from '@ngx-translate/core';
import { GhostTextDirective, InfoCardComponent, PUI_BREAKPOINT, PuiBreakpoint, SpacerComponent } from '@ui/shared/ui';
import { AsyncPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mycontent-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    TranslatePipe,
    SpacerComponent,
    InfoCardComponent,
    AsyncPipe,
    GhostTextDirective
  ]
})
export class ContactDetailsComponent {
  @Input() data: ContactDetails;

  @Output() contactClickInfo: EventEmitter<'telephone' | 'email' | 'address' | 'webpage'> = new EventEmitter();

  breakpoint$: Observable<PuiBreakpoint> = inject(PUI_BREAKPOINT);
  isMobileView$ = this.breakpoint$.pipe(
    map(breakpoint => {
      /* istanbul ignore next */
      switch (breakpoint.name) {
        case 'base':
        case 'xs':
        case 's':
        case 'm':
          return true;
        default:
          return false;
      }
    })
  );

  get webpageLink() {
    return this.data.webpage.startsWith('http') ? this.data.webpage : `http://${this.data.webpage}`;
  }
}

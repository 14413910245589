/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

// @ts-nocheck
export declare const breakpoints: readonly ["base", "xs", "s", "m", "l", "xl", "xxl"];
export type Breakpoint = (typeof breakpoints)[number];
export const breakpoint: Breakpoint = {base: 0, xs: 480, s: 760, m: 1000, l: 1300, xl: 1760, xxl: 1920};

export type PuiBreakpointName = 'base' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

export interface PuiBreakpoint {
  name: PuiBreakpointName;
  minSize: number;
  maxSize: number;
}

export const puiBreakpoints: PuiBreakpoint[] = Object.keys(breakpoint).map((key, index, arr) => ({
  name: key as PuiBreakpointName,
  minSize: breakpoint[key],
  maxSize: index < Object.keys(breakpoint).length - 1 ? breakpoint[arr[index + 1] as keyof string] - 1 : Number.MAX_SAFE_INTEGER,
}));

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Country } from '../models/country';
import { Language } from '../models/language';
import { Locale } from '../models/locale';
import { LocaleLanguage } from '../models/locale-language';
import { parseLocaleCode } from './parse-locale-code';

export function getCombinedLocaleCode(countryCode: string, localeCode: string) {
  return `${localeCode.substring(0, 2)}_${countryCode.toUpperCase()}`;
}

export function buildLocale(country: Country, language: Language): Locale {
  const parsedLanguage = parseLocaleCode(language.code);
  return {
    code: `${parsedLanguage.languageCodeShort}_${country.code.toUpperCase()}`,
    codeDashed: `${parsedLanguage.languageCodeShort}-${country.code.toUpperCase()}`,
    country: {
      code: country.code,
    },
    language: buildLocaleLanguage(language, language),
    languages: country.languages.map(it => buildLocaleLanguage(it, language)),
  };
}

function buildLocaleLanguage(language: Language, activeLanguage: Language): LocaleLanguage {
  const parsed = parseLocaleCode(language.code);
  return {
    code: language.code,
    codeDashed: `${parsed.languageCodeShort}-${parsed.countryCode}`,
    codeShort: parsed.languageCodeShort,
    name: language.names[activeLanguage.code],
  };
}

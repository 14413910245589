/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from '@angular/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { TranslatePipe } from '@ngx-translate/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GhostTextDirective } from '../ngx-ghosts';

@Component({
  selector: 'mycontent-line-break-paragraph',
  templateUrl: './line-break-paragraph.component.html',
  styleUrls: ['./line-break-paragraph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    TranslatePipe,
    GhostTextDirective
  ]
})
export class LineBreakParagraphComponent {
  content = input<string>();

  sanitizer = inject(DomSanitizer);

  sanitizeContent: Signal<SafeHtml> = computed(() => this.sanitizer.bypassSecurityTrustHtml(this.content()?.replace(/\n/g, '<br>')));
}

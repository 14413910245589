/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export const initTranslateServiceProviderFactory = (translateService: TranslateService) => () => {
  let locale = localStorage.getItem('language');
  if (!locale) {
    locale = 'en_GB';
  }
  translateService.setDefaultLang(locale);
};

export const initTranslateServiceProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: initTranslateServiceProviderFactory,
  deps: [TranslateService],
  multi: true
};

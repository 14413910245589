/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EventManagementModel, PatchEventRequest } from '../models/event-management.model';
import { EventsManagementService } from '../services/events-management.service';

@Injectable({
  providedIn: 'root',
})
export class EventManagementFacade {
  private updateEventLoadingSubject = new BehaviorSubject<boolean>(false);
  private updateEventErrorSubject = new BehaviorSubject<unknown>(null);
  private eventManagementLoadingSubject = new BehaviorSubject<boolean>(false);
  private eventManagementErrorSubject = new BehaviorSubject<unknown>(null);
  private eventManagementSuccessSubject = new BehaviorSubject<EventManagementModel>(null);
  private deleteEventLoadingSubject = new BehaviorSubject<boolean>(false);
  private deleteEventErrorSubject = new BehaviorSubject<unknown>(null);

  /* eslint-disable @typescript-eslint/member-ordering */
  readonly updateEventLoading$ = this.updateEventLoadingSubject.asObservable();
  readonly updateEventError$ = this.updateEventErrorSubject.asObservable();
  readonly eventManagementLoading$ = this.eventManagementLoadingSubject.asObservable();
  readonly eventManagementSuccess$ = this.eventManagementSuccessSubject.asObservable();
  readonly deleteEventLoading$ = this.updateEventLoadingSubject.asObservable();
  readonly deleteEventError$ = this.updateEventErrorSubject.asObservable();

  private readonly eventService = inject(EventsManagementService);

  updateEvent(country: string, eventId: string,  updateEventRequest: PatchEventRequest) {
    this.updateEventLoadingSubject.next(true);
    this.updateEventErrorSubject.next(null);
    return this.eventService.updateEvent(country, eventId, updateEventRequest)
      .pipe(
        catchError(error => {
          this.updateEventErrorSubject.next(error);
          this.updateEventLoadingSubject.next(false);
          return throwError(() => error);
        }),
        tap(() => this.updateEventLoadingSubject.next(false)),
      );
  }

  getEventManagement(country: string, eventId: string) {
    this.eventManagementLoadingSubject.next(true);
    this.eventManagementErrorSubject.next(null);
    return this.eventService.getEventManagement(country, eventId)
      .pipe(
        catchError(error => {
          this.eventManagementErrorSubject.next(error);
          this.eventManagementLoadingSubject.next(false);
          return EMPTY;
        }),
        tap(eventDetails => {
          this.eventManagementSuccessSubject.next(eventDetails);
          this.eventManagementLoadingSubject.next(false);
        }),
      ).subscribe();
  }

  deleteEvent(country: string, eventId: string) {
    this.deleteEventLoadingSubject.next(true);
    this.deleteEventErrorSubject.next(null);
    return this.eventService.deleteEvent(country, eventId)
      .pipe(
        catchError(error => {
          this.deleteEventErrorSubject.next(error);
          this.deleteEventLoadingSubject.next(false);
          return EMPTY;
        }),
        tap(() => this.deleteEventLoadingSubject.next(false)),
      );
  }
}

<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<p-button
  title="{{ 'participantFlyout.button.downloadTicket' | translate }}"
  [ngClass]="hideLabel() ? 'participant-row-download-ticket': 'participant-row-download-ticket-wide'"
  [attr.data-testid]="'participant-row-download-ticket'"
  [loading]="isTicketLoading()"
  [variant]="'ghost'"
  [icon]="'download'"
  [hideLabel]="hideLabel()"
  (click)="openTicketInNewTab($event)"
  (keydown.enter)="openTicketInNewTab($event)"
>
  {{ 'participantFlyout.button.downloadTicket' | translate }}
</p-button>

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component } from '@angular/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { RouterOutlet } from '@angular/router';
import { NotificationBannerComponent } from '@ui/shared/ui';

@Component({
  selector: 'mycontent-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    NotificationBannerComponent,
    PorscheDesignSystemModule
  ]
})
export class ShellComponent {

}

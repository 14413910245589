/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, input, model, OnChanges, signal, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PaginationUpdateEvent, PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { TranslatePipe } from '@ngx-translate/core';
import { EventOverviewRowComponent } from '../event-overview-row/event-overview-row.component';
import { FlexComponent, FlexItemComponent, SpacerComponent } from '@ui/shared/ui';
import { OverviewEventResponseModel, OverviewEventsResponseModel } from '../../models/overview.model';

@Component({
  selector: 'mycontent-event-overview-tab',
  templateUrl: './event-overview-tab.component.html',
  styleUrls: ['./event-overview-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FlexComponent,
    FlexItemComponent,
    PorscheDesignSystemModule,
    EventOverviewRowComponent,
    TranslatePipe,
    SpacerComponent
  ]
})
export class EventOverviewTabComponent implements OnChanges {
  country = input<string>();
  loading = input<boolean>();
  eventsAll = input<OverviewEventsResponseModel>();
  currentTabIndex = input<number>(0);

  eventsActivePage = model<number>(1);

  eventsPaginated = signal<OverviewEventResponseModel[]>([]);
  eventsItemsPerPage = signal<number>(8);

  form: FormGroup;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eventsAll) {
      const startIndex = this.startIndex(this.eventsActivePage(), this.eventsItemsPerPage());
      const endIndex = startIndex + this.eventsItemsPerPage();
      this.eventsPaginated.set(changes.eventsAll.currentValue?.items?.slice(startIndex, endIndex));
    }
  }

  switchPageEvents(paginationUpdateEvent: CustomEvent<PaginationUpdateEvent>) {
    this.eventsActivePage.set(paginationUpdateEvent.detail.page);
    const startIndex = this.startIndex(this.eventsActivePage(), this.eventsItemsPerPage());
    this.eventsPaginated.set(this.eventsAll()?.items?.slice(startIndex, startIndex + this.eventsItemsPerPage()));
  }

  startIndex(activePage: number, itemsPerPage: number) {
    return (activePage - 1) * itemsPerPage;
  }
}

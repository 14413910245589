/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, Input, model, OnInit, output, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { PorscheDesignSystemModule, ToastManager, ToastMessage } from '@porsche-design-system/components-angular';
import { CheckInParticipantModel, TicketModel } from '../../../models/participant.model';
import { BannerNotificationService, SpacerComponent } from '@ui/shared/ui';
import { HttpErrorResponse } from '@angular/common/http';
import { DateFormatterService } from '@ui/shared/util';
import { ParticipantFacade } from '../../../facades/participant.facade';

@Component({
  selector: 'mycontent-participant-checkin-modal',
  templateUrl: './participant-checkin-modal.component.html',
  styleUrls: ['./participant-checkin-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    FormsModule,
    ReactiveFormsModule,
    TranslatePipe,
    SpacerComponent
  ]
})
export class ParticipantCheckinModalComponent implements OnInit {
  eventId = input<string>();
  country = input<string>();
  attendeeId = input<string>();

  isOpen = model<boolean>();

  updateParticipantModelEventManagementModel = output<void>();

  checkinParticipantLoading = signal<boolean>(false);

  form: FormGroup;
  ticketCount: TicketModel[];

  private dateFormatterService = inject(DateFormatterService);
  private bannerNotificationService = inject(BannerNotificationService);
  private participantFacade = inject(ParticipantFacade);
  private translateService = inject(TranslateService);
  private toastManager = inject(ToastManager);

  @Input({ required: true }) set tickets(ticketModel: TicketModel[]) {
    this.ticketCount = ticketModel?.filter(ticket => !ticket.checkedInAt);
  }

  ngOnInit() {
    this.form = new FormGroup({
      numberOfTickets: new FormControl(this.tickets?.length, Validators.required)
    });
  }

  onDismiss(): void {
    this.isOpen.set(false);
  }

  onSubmit(): void {
    if (!this.form.valid) {
      return;
    }
    this.checkInParticipant({
      country: this.country(),
      eventId: this.eventId(),
      attendeeId: this.attendeeId(),
      ticketCount: this.form.value.numberOfTickets
    } as CheckInParticipantModel);
  }

  checkInParticipant(checkInParticipant: CheckInParticipantModel): void {
    this.checkinParticipantLoading.set(true);
    this.participantFacade.checkInParticipant(checkInParticipant).subscribe({
      next: () => {
        this.checkinParticipantLoading.set(false);
        this.isOpen.set(false);
        const message = this.translateService.instant('adminEventDetail.successMessage.checkInSuccessful');
        this.showToastMessage(message, 'success');
        this.updateParticipantModelEventManagementModel.emit();
      },
      error: (error: HttpErrorResponse) => {
        let additionalInformation: string;
        this.isOpen.set(false);
        this.checkinParticipantLoading.set(false);
        if (error.error.details) {
          additionalInformation = this.dateFormatterService.formatDateTimeShort(error.error.details);
        }
        this.bannerNotificationService.publishMessage({
          state: 'error',
          message: this.getCheckInError(error),
          additionalInformation
        });
      }
    });
  }

  showToastMessage(message: string, state: string) {
    this.toastManager.addMessage({
      text: message,
      state
    } as ToastMessage);
  }

  getCheckInError(error: HttpErrorResponse): string {
    let errorMessage = '';
    errorMessage = 'errorMessageGeneralError';

    if (error.status === 400 || error.status === 404) {
      switch (error.error.code) {
        case 'TICKET_ALREADY_CHECKED_IN':
          error.error.message = 'errorMessageTicketAlreadyCheckedIn';
          break;
        case 'REGISTRATION_CANCELLED':
          error.error.message = 'errorMessageRegistrationCancelled';
          break;
        case 'INVALID_CHECK_IN_TIME':
          error.error.message = 'errorMessageInvalidCheckInTime';
          break;
        case 'TICKET_NOT_FOUND':
          error.error.message = 'errorMessageTicketNotFound';
          break;
        case 'EVENT_NOT_FOUND':
          error.error.message = 'errorMessageEventNotFound';
          break;
        default:
          break;
      }
    }
    return this.translateService.instant(errorMessage);
  }
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal, ViewEncapsulation } from '@angular/core';
import { PaginationUpdateEventDetail, PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslatePipe } from '@ngx-translate/core';
import { ParticipantRowComponent } from '../participant-row/participant-row.component';
import { FlexComponent, FlexItemComponent, GhostTextDirective, GridComponent, GridItemComponent, SpacerComponent } from '@ui/shared/ui';
import { ParticipantTimeslotFilterComponent } from '../participant-timeslot-filter/participant-timeslot-filter.component';
import { ParticipantStatusFilterComponent } from '../participant-status-filter/participant-status-filter.component';
import { EventManagementFacade, EventManagementModel } from '@admin/shared';
import { FetchParticipantModel, FilterParticipantFormModel, ParticipantModel } from '../../../models/participant.model';
import { Pagination, ParticipantFacade } from '../../../facades/participant.facade';
import { ParticipantActionBannerComponent } from '../participant-action-banner/participant-action-banner.component';
import { TimeslotModel } from '@ui/shared/feature-registration';

@UntilDestroy()
@Component({
  selector: 'mycontent-participant-table',
  templateUrl: './participant-table.component.html',
  styleUrls: ['./participant-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    PorscheDesignSystemModule,
    ParticipantStatusFilterComponent,
    ParticipantTimeslotFilterComponent,
    FlexComponent,
    ParticipantRowComponent,
    TranslatePipe,
    ParticipantActionBannerComponent,
    GridComponent,
    GridItemComponent,
    SpacerComponent,
    FlexItemComponent,
    GhostTextDirective
  ]
})
export class ParticipantTableComponent implements OnInit {
  timeslots = input<TimeslotModel[]>();
  country = input<string>();
  eventDetails = input<EventManagementModel>();
  eventId = input<string>();
  isParticipantsLoading = input<boolean>();

  paginatedParticipants = signal<Pagination<ParticipantModel>>({
    activePage: 1,
    itemsPerPage: 9,
    list: [],
    totalItemsCount: 0
  });

  form: FormGroup<FilterParticipantFormModel>;
  timeslotId: string;

  private participantFacade = inject(ParticipantFacade);
  private eventManagementFacade = inject(EventManagementFacade);

  ngOnInit() {
    this.form = new FormGroup<FilterParticipantFormModel>({
      filter: new FormControl()
    });

    this.form.controls.filter.valueChanges.subscribe(filteredText => {
      if (filteredText !== this.participantFacade.getFilteredText()) {
        this.participantFacade.updateParticipantFilterNameOrEmail(filteredText);
      }
      this.paginatedParticipants.set(this.participantFacade.switchParticipantPage({ ...this.paginatedParticipants(), activePage: 1 }));
    });

    this.participantFacade.participantsSuccess$.subscribe(() => {
      this.paginatedParticipants.set(this.participantFacade.switchParticipantPage({ ...this.paginatedParticipants(), activePage: 1 }));
    });

    this.participantFacade.participantsTextFilter$.subscribe(newFilterText => {
      this.form.controls.filter.patchValue(newFilterText);
    });
  }

  switchPageParticipants(paginationUpdateEvent: CustomEvent<PaginationUpdateEventDetail>) {
    this.paginatedParticipants.set(this.participantFacade.switchParticipantPage(
      {
        ...this.paginatedParticipants(),
        activePage: paginationUpdateEvent.detail.page
      })
    );
  }

  updateParticipants(participantFilters: FetchParticipantModel = undefined): void {
    if (participantFilters) {
      this.participantFacade.getParticipants(participantFilters);
    } else {
      this.participantFacade.getParticipants();
    }
  }

  refreshEventManagementModel(): void {
    this.eventManagementFacade.getEventManagement(this.country(), this.eventId());
  }
}

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export abstract class FooterConfig {
    enableLanguageChooser?: boolean;
    showFooterLinkChina?: boolean;
    showFooterLinkUSA?: boolean;
}

/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export interface LockStatusModel {
  status: LockEnum;
  data: {
    total: number;
    successes: number;
    errors: number;
  };
}

export enum LockEnum {
  RUNNING = 'RUNNING', // its locked
  FINISHED_OK = 'FINISHED_OK', // was locked -> its free
  FINISHED_ERROR = 'FINISHED_ERROR', // was locked -> its free
  NONE = 'NONE', // never locker -> its free
}

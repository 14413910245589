/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */


import { DatePickerFormatModel } from '../model/date-picker-format.model';

// TODO update so no longer need in UI and can be removed
export const defaultDateFormat: DatePickerFormatModel  = {
  datePickerFormat: 'DD.MM.yyyy',
};

export const gbDateFormat: DatePickerFormatModel  = {
  datePickerFormat: 'DD/MM/YYYY',
};

export const sgDateFormat: DatePickerFormatModel  = {
  datePickerFormat: 'DD/MM/YYYY',
};

export const usDateFormat: DatePickerFormatModel  = {
  datePickerFormat: 'MM/DD/YY',
};

export const ja_JP_DateFormat: DatePickerFormatModel  = {
  datePickerFormat: 'YY/MM/DD',
};

export const ja_EN_DateFormat: DatePickerFormatModel  = {
  datePickerFormat: 'YY/MM/DD',
};

export const krDateFormat: DatePickerFormatModel  = {
  datePickerFormat: 'YY/MM/DD',
};

export const ch_EN_DateFormat: DatePickerFormatModel  = {
  datePickerFormat: 'DD/MM/YYYY',
};

export const it_EN_DateFormat: DatePickerFormatModel  = {
  datePickerFormat: 'DD/MM/YYYY',
};

export const myDateFormat: DatePickerFormatModel  = {
  datePickerFormat: 'DD/MM/YYYY',
};

export const twDateFormat: DatePickerFormatModel  = {
  datePickerFormat: 'YYYY/MM/DD',
};

export const nz_auDateFormat: DatePickerFormatModel  = {
  datePickerFormat: 'DD/MM/YYYY',
};

/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export function parseLocaleCode(code: string): { languageCodeShort: string; countryCode: string } {
  const matches = new RegExp(/([a-zA-Z]{2})[-_]([a-zA-Z]{2})/).exec(code);
  if (matches) {
    return {
      languageCodeShort: matches[1],
      countryCode: matches[2],
    };
  }
  return null;
}

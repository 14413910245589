/*
 *  This code is protected by intellectual property rights.
 *  Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 *  (c) 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { BannerState } from '@porsche-design-system/components-angular';

export interface BannerMessage {
  message: string;
  headline?: string;
  additionalInformation?: string;
  timeout?: number;
  state: BannerState;
}

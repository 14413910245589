<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<p-flyout [footerBehavior]="'fixed'" [open]="isOpen()" [position]="'end'" (dismiss)="isOpen.set(false)">
  <div>
    <p-text class="participants-details-flyout__header-item" [size]="'large'">
      {{ participant()?.attendee?.firstName }} {{ participant()?.attendee?.lastName }}
    </p-text>
    <p-tag class="participants-details-flyout__header-item-tag" [color]="getInvitationStateColour(participant()?.state)">
      {{ 'adminEventDetail.attendance.table.statusLabel.' + participant()?.state | translate }}
    </p-tag>
  </div>
  <mycontent-spacer [size]="'small'"></mycontent-spacer>
  <div>
    <mycontent-flex [gap]="'small'">
      <mycontent-flex-item>
        <p-icon [color]="'contrast-medium'" [size]="'x-small'" [name]="'email'"></p-icon>
        <p-text class="participants-details-flyout__body-item" [size]="'x-small'" [color]="'contrast-medium'">{{ participant()?.attendee?.email }}</p-text>
      </mycontent-flex-item>
      @if (participant()?.registration?.personalData?.street1) {
        <mycontent-flex-item>
          <p-icon [color]="'contrast-medium'" [size]="'x-small'" [name]="'pin'"></p-icon>
          <p-text class="participants-details-flyout__body-item" [size]="'x-small'" [color]="'contrast-medium'">
            {{ participant()?.registration?.personalData.street1 }} {{ participant()?.registration?.personalData.houseNumber }}
            , {{ participant()?.registration?.personalData.postalCode }} {{ participant()?.registration?.personalData.city }}
          </p-text>
        </mycontent-flex-item>
      }
    </mycontent-flex>
    <mycontent-spacer [size]="'small'"></mycontent-spacer>
    <div>
      @if (participant()?.addedAt) {
        <p-tag class="participants-details-flyout__state-tag" [color]="'background-surface'">
          {{ 'adminEventDetail.attendance.table.statusLabel.ADDED' | translate }}: {{ addedAt() }}
        </p-tag>
      }
      @if (participant()?.invitedAt) {
        <p-tag class="participants-details-flyout__state-tag" [color]="'background-surface'">
          {{ 'adminEventDetail.attendance.table.statusLabel.INVITED' | translate }}: {{ invitedAt() }}
        </p-tag>
      }
      @if (participant()?.joinedWaitingListAt) {
        <p-tag class="participants-details-flyout__state-tag" [color]="'background-surface'">
          {{ 'adminEventDetail.attendance.table.statusLabel.ON_WAITING_LIST' | translate }}: {{ joinedWaitingListAt() }}
        </p-tag>
      }
      @if (participant()?.registeredAt) {
        <p-tag class="participants-details-flyout__state-tag" [color]="'background-surface'">
          {{ 'adminEventDetail.attendance.table.statusLabel.REGISTERED' | translate }}: {{ registeredAt() }}
        </p-tag>
      }
      @if (isCheckedIn()) {
        <p-tag class="participants-details-flyout__state-tag" [color]="'background-surface'">
          {{ 'adminEventDetail.attendance.table.statusLabel.CHECKED_IN' | translate }}: {{ checkedInAt() }}
        </p-tag>
      }
      @if (participant()?.cancelledAt) {
        <p-tag class="participants-details-flyout__state-tag" [color]="'background-surface'">
          @if (isRegistrationCancelledByUser()) {
            {{ 'details.regCustomer' | translate }}: {{ cancelledAt() }}

          }
          @if (!isRegistrationCancelledByUser()) {
            {{ 'details.regAdmin' | translate }}: {{ cancelledAt() }}
          }
        </p-tag>
      }
      @if (isEditRegistrationUpdatedByAdmin()) {
        <p-tag class="participants-details-flyout__state-tag" [color]="'background-surface'">
          {{ 'details.modifiedByAdmin' | translate }}: {{ registrationUpdatedAt() }}
        </p-tag>
      }
    </div>
    <mycontent-spacer [size]="'medium'"></mycontent-spacer>
    @if (isCancelled() || isInvited() || isReadyForInvitation() || isPorscheIdNotFound() || isAdded() || isUndefined()) {
      <div class="participants-details-flyout__text-container">
        @if (isCancelled()) {
          <p-text [size]="'small'" [color]="'contrast-medium'">{{ 'participantFlyout.paragraph.cancelledState.placeholderText' | translate }}</p-text>
        }
        @if (isInvited()) {
          <p-text [size]="'small'" [color]="'contrast-medium'">{{ 'participantFlyout.paragraph.invitedState.placeholderText' | translate }}</p-text>
        }
        @if (isReadyForInvitation()) {
          <p-text [size]="'small'" [color]="'contrast-medium'">{{ 'participantFlyout.paragraph.readyForInvitationState.placeholderText' | translate }}</p-text>
        }
        @if (isPorscheIdNotFound()) {
          <p-text [size]="'small'" [color]="'contrast-medium'">{{ 'participantFlyout.paragraph.noIDState.placeholderText' | translate }}</p-text>
        }
        @if (isAdded()) {
          <p-text [size]="'small'" [color]="'contrast-medium'">{{ 'participantFlyout.paragraph.addedState.placeholderText' | translate }}</p-text>
        }
        @if (isUndefined()) {
          <p-text [size]="'small'" [color]="'contrast-medium'">{{ 'participantFlyout.paragraph.undefinedState.placeholderText' | translate }}</p-text>
        }
      </div>
    }
    @if (isOnWaitingList()) {
      <p-text [size]="'xx-small'" [color]="'contrast-medium'">{{ 'participantFlyout.label.waitingListPosition' | translate }}</p-text>
      <p-text>{{ participant()?.positionInWaitingList }}/{{ waitingListCount() }}</p-text>
    }
    @if (isRegisteredOrCheckedIn()) {
      <mycontent-flex>
        <mycontent-flex-item>
          <p-text [size]="'x-small'" [color]="'contrast-medium'">{{ 'participantFlyout.label.selectedTimeslot' | translate }}</p-text>
          <p-text class="participants-details-flyout__date" [size]="'x-small'" [color]="'contrast-medium'">{{ startDate() }}</p-text>
          <p-text class="participants-details-flyout__date" [size]="'x-small'">&nbsp;{{ startTime() }} -</p-text>
          <p-text class="participants-details-flyout__date" [size]="'x-small'" [color]="'contrast-medium'">
            &nbsp;{{ endDate() }}
          </p-text>
          <p-text class="participants-details-flyout__date" [size]="'x-small'">&nbsp;{{ endTime() }}</p-text>
        </mycontent-flex-item>
        <mycontent-flex-item>
          <div class="participants-details-flyout__timeslot-gap"></div>
        </mycontent-flex-item>
        <mycontent-flex-item>
          <p-text [size]="'x-small'" [color]="'contrast-medium'">{{ 'participantFlyout.label.participantNumber' | translate }}</p-text>
          <p-text [attr.data-testid]="'participant-flyout-ticket-count'">{{ participant().tickets?.length }}</p-text>
        </mycontent-flex-item>
      </mycontent-flex>

      <mycontent-spacer [size]="'medium'"></mycontent-spacer>
      @if (participant()?.registration && participant()?.registration.dynamicData?.formData.length > 0) {
        <p-heading [size]="'medium'">{{ 'participantFlyout.header.formResponse' | translate }}</p-heading>
        @for (dData of participant().registration.dynamicData.formData; track dData; let j = $index) {
          <mycontent-spacer [size]="'small'"></mycontent-spacer>
          <p-text [color]="'contrast-medium'" [size]="'x-small'">{{ dData.question }}</p-text>
          <p-text [size]="'small'" [attr.data-testid]="'dynamic-data-answer-' + j">
            @if (dData.answer === I_PREFER_NOT_TO_ANSWER) {
              {{ 'eventCheckout.label.checkbox.noAnswer' | translate }}
            } @else {
              {{ dData.answer }}
            }
          </p-text>
        }
      }
    }
    @if (isRegisteredOrCheckedIn() && isParticipantRegisteredToCapManagedEvent()) {
      <mycontent-spacer [size]="'medium'"></mycontent-spacer>
      <mycontent-flex [direction]="'row'" [justifyContent]="'space-between'">
        <mycontent-flex-item>
          <p-text [color]="'contrast-medium'" [size]="'xx-small'">{{ 'adminEventDetail.attendance.listItem.dropdown.capCampaign' | translate }}</p-text>
          <p-text class="participants-details__cap-data" [size]="'xx-small'">
            {{ participant()?.capCampaignId }}
            <mycontent-copy-to-clipboard [copyValue]="participant()?.capCampaignId"></mycontent-copy-to-clipboard>
          </p-text>
        </mycontent-flex-item>
        <mycontent-flex-item>
          <p-text [color]="'contrast-medium'" [size]="'xx-small'">{{ 'adminEventDetail.attendance.listItem.dropdown.capActivity' | translate }}</p-text>
          <p-text class="participants-details__cap-data" [size]="'xx-small'">
            {{ participant()?.capActivityId }}
            <mycontent-copy-to-clipboard [copyValue]="participant()?.capActivityId"></mycontent-copy-to-clipboard>
          </p-text>
        </mycontent-flex-item>
        <mycontent-flex-item>
          <p-text [color]="'contrast-medium'" [size]="'xx-small'">{{ 'adminEventDetail.attendance.listItem.dropdown.capUser' | translate }}</p-text>
          <p-text class="participants-details__cap-data" [size]="'xx-small'">
            {{ participant()?.capBpId }}
            <mycontent-copy-to-clipboard [copyValue]="participant()?.capBpId"></mycontent-copy-to-clipboard>
          </p-text>
        </mycontent-flex-item>
      </mycontent-flex>
    }
  </div>
  <div slot="footer">
    @if (isRegistered()) {
      <mycontent-flex [alignContent]="'stretch'" [gap]="'small'">
        <p-button
          class="participants-details-flyout__grid-button"
          [attr.data-testid]="'participant-details-checkin-customer-modal'"
          [variant]="'primary'"
          [icon]="'qr'"
          (click)="openCheckInModal()"
          (keydown.enter)="openCheckInModal()"
        >
          {{ 'participantFlyout.button.checkinCustomer' | translate }}
        </p-button>
        <mycontent-participant-download-ticket-button
          class="participants-details-flyout__grid-button"
          [eventId]="eventId()"
          [attendeeId]="participant()?.attendee?.id"
          [country]="country()"
          [hideLabel]="false"
        ></mycontent-participant-download-ticket-button>
      </mycontent-flex>
      <mycontent-spacer [size]="'small'"></mycontent-spacer>
      <mycontent-flex [alignContent]="'stretch'" [gap]="'small'">
        <p-button
          class="participants-details-flyout__grid-button"
          [attr.data-testid]="'participant-details-resend-registration-modal'"
          [variant]="'ghost'"
          [iconSource]="'../../../../assets/resend-email.svg'"
          (click)="openResendRegistrationModal()"
          (keydown.enter)="openResendRegistrationModal()"
        >
          {{ 'participantFlyout.button.resendConfirmation' | translate }}
        </p-button>
        <p-button
          class="participants-details-flyout__grid-button"
          [attr.data-testid]="'participant-details-edit-registration-flyout'"
          [variant]="'ghost'"
          [icon]="'edit'"
          (click)="openEditRegistrationModal()"
          (keydown.enter)="openEditRegistrationModal()"
        >
          {{ 'participantFlyout.button.editRegistration' | translate }}
        </p-button>
      </mycontent-flex>
    }
    @if (isCheckedIn()) {
      <mycontent-flex [alignContent]="'stretch'" [gap]="'small'">
        <mycontent-flex-item [width]="'half'">
          <p-button
            class="participants-details-flyout__grid-button"
            aria-haspopup="dialog"
            [attr.data-testid]="'participant-details-checkin-customer-modal'"
            [type]="'button'"
            [icon]="'qr'"
            [disabled]="isTimeslotEndDateWithin7Days()"
            [variant]="'primary'"
            (click)="openCheckInModal()"
            (keydown.enter)="openCheckInModal()"
          >
            {{ 'participantFlyout.button.checkinCustomer' | translate }}
          </p-button>
        </mycontent-flex-item>
        <mycontent-flex-item [width]="'half'">
          <mycontent-participant-download-ticket-button
            class="participants-details-flyout__grid-button"
            [eventId]="eventId()"
            [attendeeId]="participant()?.attendee?.id"
            [country]="country()"
            [hideLabel]="false"
          ></mycontent-participant-download-ticket-button>
        </mycontent-flex-item>
      </mycontent-flex>
      <mycontent-spacer [size]="'small'"></mycontent-spacer>
      <mycontent-flex [alignContent]="'stretch'" [gap]="'small'">
        <mycontent-flex-item [width]="'half'">
          <p-button
            class="participants-details-flyout__grid-button"
            [attr.data-testid]="'participant-details-edit-registration-flyout'"
            [variant]="'ghost'"
            [icon]="'edit'"
            (click)="openEditRegistrationModal()"
            (keydown.enter)="openEditRegistrationModal()"
          >
            {{ 'participantFlyout.button.editRegistration' | translate }}
          </p-button>
        </mycontent-flex-item>
        <mycontent-flex-item [width]="'half'"></mycontent-flex-item>
      </mycontent-flex>
    }
    @if (isInvitedWithoutInvitationSentAt() || isCancelled() || isOnWaitingList()) {
      <p-button
        [attr.data-testid]="'participant-details-manual-registration-modal'"
        [variant]="'ghost'"
        [icon]="'plus'"
        (click)="openManualRegistrationFlyout()"
        (keydown.enter)="openManualRegistrationFlyout()"
      >
        {{ 'participantFlyout.button.registerCustomer' | translate }}
      </p-button>
    }

    @if (isRegistered()) {
      <mycontent-spacer [size]="'medium'"></mycontent-spacer>
      <mycontent-flex [justifyContent]="'center'">
        <p-text
          class="participants-details-flyout__register-cancel-button"
          [attr.data-testid]="'participant-details-cancel-participant-modal'"
          [color]="'notification-error'"
          [align]="'start'"
          (click)="openCancelModal()"
          (keydown.enter)="openCancelModal()"
        >
          {{ 'participantFlyout.button.cancelTicket' | translate }}
        </p-text>
      </mycontent-flex>
    }
    @if (isReadyForInvitation()) {
      <mycontent-flex [alignItems]="'center'" [gap]="'medium'">
        <p-button
          [attr.data-testid]="'participant-details-send-invitation-modal'"
          [variant]="'ghost'"
          [icon]="'send'"
          (click)="openSendSingleInvitationModal()"
          (keydown.enter)="openSendSingleInvitationModal()"
        >
          {{ 'participantFlyout.button.sendInvitation' | translate }}
        </p-button>
        <p-text
          class="participants-details-flyout__cancel-button"
          [attr.data-testid]="'participant-details-delete-invitee-modal'"
          [color]="'notification-error'"
          [align]="'center'"
          (click)="openDeleteInviteeModal()"
          (keydown.enter)="openDeleteInviteeModal()"
        >
          <p-icon [name]="'delete'" [color]="'notification-error'"></p-icon>
          {{ 'participantFlyout.button.deleteCustomer' | translate }}
        </p-text>
      </mycontent-flex>
    }
    @if (isInvitedWithInvitationSentAt()) {
      <mycontent-flex [alignContent]="'stretch'" [gap]="'small'">
        <p-button
          class="participants-details-flyout__grid-button"
          [attr.data-testid]="'participant-details-manual-registration-modal'"
          [variant]="'ghost'"
          [icon]="'plus'"
          (click)="openManualRegistrationFlyout()"
          (keydown.enter)="openManualRegistrationFlyout()"
        >
          {{ 'participantFlyout.button.registerCustomer' | translate }}
        </p-button>
        <p-button
          class="participants-details-flyout__grid-button"
          [attr.data-testid]="'participant-details-resend-invitation-modal'"
          [variant]="'ghost'"
          [iconSource]="'../../../../assets/resend-email.svg'"
          (click)="openResendInvitationModal()"
          (keydown.enter)="openResendInvitationModal()"
        >
          {{ 'adminEventDetail.label.reactivateInvitation' | translate }}
        </p-button>
      </mycontent-flex>
    }
    @if (isPorscheIdNotFoundWithInvitationSentAt()) {
      <mycontent-flex [alignItems]="'center'" [gap]="'medium'">
        <p-button
          [attr.data-testid]="'participant-details-resend-invitation-modal'"
          [variant]="'ghost'"
          [iconSource]="'../../../../assets/resend-email.svg'"
          (click)="openResendInvitationModal()"
          (keydown.enter)="openResendInvitationModal()"
        >
          {{ 'adminEventDetail.label.reactivateInvitation' | translate }}
        </p-button>
        <p-text
          class="participants-details-flyout__cancel-button"
          [attr.data-testid]="'participant-details-delete-invitee-modal'"
          [color]="'notification-error'"
          [align]="'center'"
          (click)="openDeleteInviteeModal()"
          (keydown.enter)="openDeleteInviteeModal()"
        >
          <p-icon [name]="'delete'" [color]="'notification-error'"></p-icon>
          {{ 'participantFlyout.button.deleteCustomer' | translate }}
        </p-text>
      </mycontent-flex>
    }
    @if (isPorscheIdNotFoundWithoutInvitationSentAt() || isAdded() || isUndefined()) {
      <p-text
        class="participants-details-flyout__cancel-button"
        [attr.data-testid]="'participant-details-delete-invitee-modal'"
        [color]="'notification-error'"
        [align]="'center'"
        (click)="openDeleteInviteeModal()"
        (keydown.enter)="openDeleteInviteeModal()"
      >
        <p-icon [name]="'delete'" [color]="'notification-error'"></p-icon>
        {{ 'participantFlyout.button.deleteCustomer' | translate }}
      </p-text>
    }
    <mycontent-spacer [size]="'medium'"></mycontent-spacer>
  </div>
</p-flyout>
@if (isCheckInRegistrationOpen()) {
  <mycontent-participant-checkin-modal
    [(isOpen)]="isCheckInRegistrationOpen"
    [country]="country()"
    [eventId]="eventId()"
    [tickets]="participant()?.tickets"
    [attendeeId]="participant()?.attendee?.id"
    (updateParticipantModelEventManagementModel)="refreshParticipantModelTimeslotModel.emit()"
  ></mycontent-participant-checkin-modal>
}
@if (isParticipantRegistrationResendModalOpen()) {
  <mycontent-participant-resend-registration-modal
    [(isOpen)]="isParticipantRegistrationResendModalOpen"
    [eventId]="eventId()"
    [country]="country()"
    [mail]="participant()?.attendee?.email"
    [ciamId]="participant()?.attendee?.ciamId"
  ></mycontent-participant-resend-registration-modal>
}
@if (isParticipantInvitationResendModalOpen()) {
  <mycontent-participant-resend-invite-modal
    [(isOpen)]="isParticipantInvitationResendModalOpen"
    [eventId]="eventId()"
    [country]="country()"
    [email]="participant()?.attendee?.email"
    [attendeeId]="participant()?.attendee?.id"
  ></mycontent-participant-resend-invite-modal>
}
@if (isSendSingleInvitationModalOpen()) {
  <mycontent-participant-send-invite-one-modal
    [(isOpen)]="isSendSingleInvitationModalOpen"
    [eventId]="eventId()"
    [country]="country()"
    [email]="participant()?.attendee?.email"
    [attendeeId]="participant()?.attendee?.id"
  ></mycontent-participant-send-invite-one-modal>
}
@if (isDeleteInviteeOpen()) {
  <mycontent-participant-delete-invitee-modal
    [(isOpen)]="isDeleteInviteeOpen"
    [country]="country()"
    [eventId]="eventId()"
    [attendeeId]="participant()?.attendee?.id"
    (refreshParticipantsModel)="refreshParticipantModelTimeslotModel.emit()"
  ></mycontent-participant-delete-invitee-modal>
}
@if (isCancelModalOpen()) {
  <mycontent-participant-cancel-modal
    [(isOpen)]="isCancelModalOpen"
    [bookingCancellationText]="bookingCancellationText()"
    [country]="country()"
    [eventId]="eventId()"
    [attendeeId]="participant()?.attendee?.id"
    (updateParticipantModelEventManagementModel)="refreshParticipantModelTimeslotModel.emit()">
  </mycontent-participant-cancel-modal>
}
@if (isRegistrationOpen()) {
  <mycontent-manual-registration-flyout
    [(isOpen)]="isRegistrationOpen"
    [(isEditRegistration)]="isEditRegistration"
    [attendeeId]="participant()?.attendee?.id"
    [eventId]="eventId()"
    [country]="country()"
    [participant]="participant()"
  ></mycontent-manual-registration-flyout>
}
